import React, { 
    Fragment, useRef, useCallback, useState, useEffect
} from "react";
import {
    AppBar, Toolbar, Button, Tooltip, Typography, IconButton, Avatar, Divider,
    Popper, Grow, Paper, ClickAwayListener, ListItemIcon,
    Drawer, Box, List, ListItem, ListItemText, Collapse, Grid, Link, Badge, 
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faBars, faInfo, faGear, //faFile, //faIdCard,
    faTicket, faRoadBridge, faShieldHalved, faTrashRestore, faBell,
    faSearch,
    // faUsers, faUsersViewfinder,
} from "@fortawesome/free-solid-svg-icons";
import { DatapriseLogo } from "../images/main.logo";
import { 
    ExitToApp as ExitIcon, ExpandLess, ExpandMore, 
    // Flare as LightMode, NightsStay as DarkMode
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useDappContext } from "../auth/dapp.context";
import { 
  AddressCardIcon,
  AddressesIcon, ComputerIcon, HomeIcon, ListIcon, ManageServiceIcon, 
  // MyIncidentsIcon, MyServiceRequestIcon, 
  NewRequestIcon, NewTicketIcon, RectangleListIcon, SinglePaneIcon, 
  // TicketsOrgIcon, TicketsUserIcon 
} from "../images/dapp.icons";
import { UserPreferences } from "../components/register/user.preferences";
import { CustomAlert } from "../alerts/alert.snackbar";
import clsx from "clsx";
import { AlertDrawer } from "../components/home/alerts.drawer";
import { SearchProvider } from "../components/home/search.provider";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { GlobalSearch } from "../components/home/global.search";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "transparent !important",
        zIndex: theme.zIndex.drawer + 112,
        '& .global-search-grow': {
          display: '',
          width: "260px",
          opacity: 1,
          transition: "all 1.5s",
        },
        '& .global-search-shrink': {
          width: "80px",
          opacity: 0,
          transition: "all 1.5s",
        }
      },
    usermenu: {
        zIndex: theme.zIndex.drawer + 200,
    }
}))

const useBadgeStyle = makeStyles(() => ({
  root: {
    '& .MuiBadge-badge': {
      border: "2px solid rgb(0, 0, 0, 0.8)",
    },
  }
}));

export const TopNavBar = (props) => {
    const { onMenuClick, onThemeChange, loggedIn, onLogout } = props;
    const [openUserMenu, setOpenUserMenu] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openAlerts, setOpenAlerts] = useState(false);
    const [showCollapse, setShowCollapse] = useState(false);
    const [userPrefs, setUserPrefs] = useState({
      open: false, onClose: () => {},
      onSave: () => {}, onError: () => {},
      contactID: null
    });
    const [showMsg, setShowMsg] = useState({
      open: false, severity: "success", message: '',
      onSnackbarClose: () => {},
    });
    const userMenuAnchor = useRef(undefined);
    const alertsAnchor = useRef(undefined);
    const prevOpen = useRef(openUserMenu);
    const classes = useStyles();
    const dappCntx = useDappContext();
    // const nav = useLocation();

    const handleUserMenu = (event) => {
        setOpenUserMenu((prevOpen) => !prevOpen);
    };

    const handleUserMenuClose = useCallback((event) => {
        if (userMenuAnchor.current && userMenuAnchor.current.contains(event.target))
          return;

        setOpenUserMenu(false);
    },[userMenuAnchor]);

    const handleKeyDown = (event) => {
        event.preventDefault();
        if (event.key === 'Tab'){
            setOpenUserMenu(false);
        }
    };

    const handleMenuClick = (params) => {
        closeDrawer();
        if (onMenuClick && typeof onMenuClick === 'function'){
            onMenuClick(params);
        }
    };

    const handleLogout = useCallback((event) => {
        event.preventDefault();
        setOpenUserMenu(false);
        //auth.signout(() => {console.log(`this is executed by auth callback`)});
        if (!!onLogout && typeof onLogout === 'function'){
          dappCntx.signOut(() => {
            // console.log(`set user to null in the global context`)
          });
          onLogout()
        }
        // eslint-disable-next-line
    }, [onLogout]);

    const showDrawer = () => {
        setOpenDrawer((openDrawer) => !openDrawer);
        setShowCollapse((showCollapse) => !showCollapse);
    };

    const closeDrawer = () => {
        setOpenDrawer(false);
        setShowCollapse(false);
    };

    const showSuccess = useCallback(() => {
      setShowMsg({
        ...showMsg, open: true, severity: 'success',
        message: 'User Preferences Updated',
        onSnackbarClose: () => {setShowMsg({...showMsg, open: false, severity: ''})}
      });
    }, [showMsg])

    const showError = useCallback(() => {
      setShowMsg({
        ...showMsg, open: true, severity: 'error',
        message: `Update failed`,
        onSnackbarClose: () => {setShowMsg({...showMsg, open: false, severity: ''})}
      });
    }, [showMsg]);

    const handlePreferences = useCallback(() => {
      setOpenUserMenu(false);
      setUserPrefs({...userPrefs, open: true, onClose: () => {
        setUserPrefs({...userPrefs, open: false, contactID: null})
      }, contactID: dappCntx.user.user.ContactID,
      onSave: showSuccess,
      onError: showError,
    });
    }, [userPrefs, dappCntx, showError, showSuccess]);

    useEffect(() => {
        if (prevOpen.current && !openUserMenu){
            userMenuAnchor.current.focus();
        }
        setOpenAlerts(false);
        prevOpen.current = openUserMenu;
    }, [openUserMenu]);

    const handleAbout = useCallback(() => {
        console.log(`this is placement for about box`);
        setOpenUserMenu(false);
    }, []);

    const handleAlertsClick = useCallback(() => {
      setOpenAlerts(openAlerts => !openAlerts);
    }, []);

    return (
      <Typography component={`div`}>
        <AppBar position="relative" className={classes.appBar}>
        <TopToolbar loggedIn={loggedIn} 
            userMenuAnchor={userMenuAnchor}
            alertsAnchor={alertsAnchor}
            onUserMenuClick={handleUserMenu}
            onShowDrawer={showDrawer}
            onAlertsClick={handleAlertsClick}
        />
        </AppBar>
        {/* <AppBar position="relative" style={{ backgroundColor: "transparent" }}>
          {(!!loggedIn && nav.pathname === "/" &&
            <Toolbar style={{
              display: 'flex',
              flexFlow: "column",
              alignItems: "start",
              marginLeft: "12rem",
              padding: "3rem 0 3rem 0"
            }}>
              <Typography component={`div`} variant="h4">
                {`Hello, ${dappCntx?.user?.user?.FirstName || ''}`}
              </Typography>
              <Typography component={`div`} variant="h5">
                What would you like to do today?
              </Typography>
              <SearchProvider>
                <GlobalSearch /> 
              </SearchProvider>
            </Toolbar>) || <Fragment />}
        </AppBar> */}
        {!!loggedIn ? <UserMenu 
            open={openUserMenu}
            anchor={userMenuAnchor}
            onClose={handleUserMenuClose}
            onLogout={handleLogout}
            onKeyDown={handleKeyDown}
            onAbout={handleAbout}
            onPreferences={handlePreferences}
            themeChanged={onThemeChange}
        /> : <Fragment />}

        {!!loggedIn ? <LeftNavigationMenu 
            onMenuClick={handleMenuClick} 
            open={openDrawer}
            onDrawerClose={closeDrawer}
            onPreferences={handlePreferences}
            showCollapse={showCollapse}
        /> : <Fragment />}

        {(!!loggedIn && 
        <AlertDrawer 
            open={openAlerts}
            onCloseAlerts={() => setOpenAlerts(false)}
        />
        ) || <Fragment/>}

        <UserPreferences {...userPrefs}/>
        <CustomAlert {...showMsg}/>
      </Typography>
    );
}

const TopToolbar = (props) => {
  const { 
    userMenuAnchor, alertsAnchor, onUserMenuClick, onAlertsClick,
    onShowDrawer, loggedIn 
  } = props;
  const dappCtnx = useDappContext();
  const [initials, setInitials] = useState("00");
  const classes = useStyles();
  // const theme = useTheme();
  const badgeCls = useBadgeStyle();
  const [hideSearch, setHideSearch] = useState(true);
  const [showSearch, setShowSearch] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (!dappCtnx.user) return;
    setInitials(`${dappCtnx.user.user.FirstName.charAt(0)}${dappCtnx.user.user.LastName.charAt(0)}`);
  }, [dappCtnx.user]);

  const showHideSearch = useCallback(() => {
    setShowSearch(false);
    setHideSearch(!hideSearch); 
  }, [hideSearch]);

  const handleSelected = useCallback(() => {
    setHideSearch(true);
  }, []);

  const handleTransitionEnd = useCallback(() => {
    setShowSearch(hideSearch);
  }, [hideSearch]);

  return (
    <Fragment>
    <Toolbar className={classes.toptoolbar}>
        {(!!loggedIn &&
        <Fragment>
        <Tooltip title={`Main Menu`}>
          <Button
            startIcon={<FontAwesomeIcon icon={faBars} style={{fontSize: "1.6rem"}} />}
            color="inherit"
            onClick={onShowDrawer}
            variant="text"
            style={{minWidth: "unset"}}
          />
        </Tooltip>
        {/* <Tooltip title="Home">
          <Button 
            startIcon={<FontAwesomeIcon icon={faHomeLg} style={{fontSize: "2.0rem"}}/>}
            color="inherit"
            onClick={handleGoHome}
          />
        </Tooltip>        */}
        </Fragment>
        ) || <Fragment />}
      <Link href="http://www.dataprise.com" target={`_blank`} style={{paddingLeft: "1.2rem"}}>
        <DatapriseLogo />
      </Link>
      {(!!loggedIn &&
        <Typography
          component={`div`}
          style={{
            paddingLeft: "2em",
            fontStyle: "italic",
            fontSize: "1.2rem",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {`${dappCtnx.user?.user.OrganizationName} - Support Center - ${dappCtnx.title}`}
        </Typography>) || <Fragment />}
      <Typography component={`div`} style={{ flexGrow: 1 }} />

      {(!!loggedIn && 
        <Fragment>
          {(location.pathname !== "/" && <Fragment>
          <SearchProvider>
            <GlobalSearch 
              // hidden={hideSearch} 
              handleOpenSearchClick={showHideSearch} 
              onSelected={handleSelected}
              onTransitionEnd={handleTransitionEnd}
              className={`${(hideSearch && 'global-search-shrink') || 'global-search-grow'} 'global-search-hide'`}
            />
          </SearchProvider>
          <Tooltip title="Global Search">
            <span>
              <FontAwesomeIcon icon={faSearch} onClick={showHideSearch} 
                style={{
                  opacity: 0.8, fontSize: "1rem", 
                  display: (!showSearch && "none")
                }}
              />
            </span>
          </Tooltip>
          </Fragment>) || <Fragment/>}
          <Tooltip title="Customer Alerts">
            <span>
              <IconButton ref={alertsAnchor} onClick={onAlertsClick} disabled={(dappCtnx?.alertsCount || 0) <= 0}>
                <Badge badgeContent={dappCtnx?.alertsCount || 0} color="primary" overlap="circular"
                  className={badgeCls.root}
                >
                  <FontAwesomeIcon icon={faBell} style={{color: "whitesmoke"}}/>
                </Badge>
              </IconButton> 
            </span>
        </Tooltip>
        <IconButton ref={userMenuAnchor} onClick={onUserMenuClick}>
          <Avatar>{initials}</Avatar>
        </IconButton>
        </Fragment>) || <Fragment />}
    </Toolbar>
    </Fragment>
  );
};

const UserMenu = (props) => {
  const {open,anchor,onClose,onLogout,onKeyDown,onAbout, onPreferences, themeChanged,...other} = props;
  const classes = useStyles();
  const dappCntx = useDappContext();

  return (
    <Popper
      {...other}
      open={open}
      anchorEl={anchor.current}
      role={undefined}
      transition
      disablePortal={false}
      className={classes.usermenu}    
      style={{maxWidth: "35%", minWidth: "280px"}}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom', 
          }}
        >
          <Paper> 
            <ClickAwayListener onClickAway={onClose}>
              <List
                dense
                style={{ minWidth: 180}}
                id="account-menu"
                onKeyDown={onKeyDown}
              >
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faInfo} style={{color: "#36f", fontSize: "2.4rem"}} />
                  </ListItemIcon>
                  <ListItemText 
                    primary={
                      <Fragment>
                        <Typography component={`div`} style={{fontSize: "0.75rem"}}>
                          <Grid container>
                            <Grid item xs={4}>
                              <Typography component={`div`} style={{fontSize: "inherit"}}>email:</Typography> 
                            </Grid>
                            <Grid item xs={8}>
                              <Typography component={`div`} style={{fontSize: "inherit"}}>{dappCntx.user?.user.UserName}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography component={`div`} style={{fontSize: "inherit"}}>User Name:</Typography> 
                            </Grid>
                            <Grid item xs={8}>
                              <Typography component={`div`} style={{fontSize: "inherit"}}>
                                {`${dappCntx.user?.user.LastName}, ${dappCntx.user?.user.FirstName}`}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography component={`div`} style={{fontSize: "inherit"}}>UserType:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography component={`div`} style={{fontSize: "inherit"}}>
                                {dappCntx.user?.user.UserType}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography component={`div`} style={{fontSize: "inherit"}}>Organization:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography component={`div`} style={{fontSize: "inherit"}}>
                                {dappCntx.user?.user.OrganizationName}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                            <Typography component={`div`} style={{fontSize: "inherit"}} variant="body1">Last Login Date:</Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography component={`div`} style={{fontSize: "inherit", opacity: "0.6"}} variant="body2">
                              {dappCntx.user?.user?.LastLoginDate && 
                              `${new Date(dappCntx.user?.user?.LastLoginDate).toLocaleDateString()} 
                              ${new Date(dappCntx.user?.user?.LastLoginDate).toLocaleTimeString()}`}
                            </Typography>
                          </Grid>
                          </Grid>
                        {JSON.stringify(dappCntx?.profile?.attributesMap?.ccData[0])}
                      </Typography>                      
                      </Fragment>
                    }></ListItemText>
                </ListItem> 
                <Divider orientation="horizontal" />
                {/* <MenuItem onClick={onLogout}>
                  <ListItemIcon>
                    <ExitIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography>Logout</Typography>
                </MenuItem> */}
                <ListItem>
                  <Typography component={`div`} style={{width: "inherit", fontSize: "0.75rem"}}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Tooltip title="User Preferences">
                          <Button 
                            onClick={onPreferences}
                            variant="text" 
                            style={{fontSize: "inherit"}}
                            startIcon={<FontAwesomeIcon icon={faGear}/>} 
                            color="primary">
                              Preferences
                          </Button>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={6} style={{alignItems: "end", display: "flex", flexFlow: "column"}}>
                        <Button
                          onClick={onLogout}
                          variant="text"
                          style={{fontSize: "inherit"}}
                          startIcon={<ExitIcon fontSize="small"/>}
                          color="secondary"
                        >
                          Logout
                        </Button>
                      </Grid>
                    </Grid>
                  </Typography>                  
                </ListItem>
              </List>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

const useDrawerStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    '& .MuiDrawer-paperAnchorDockedLeft': {
      borderRight: "none !important"
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  }, 
}));

export const LeftNavigationMenu = (props) => {
    const {open, onDrawerClose, onMenuClick, showCollapse} = props;
    const dappCntx = useDappContext();
    const [menu2nd, setMenu2nd] = useState();

    const drwrcls = useDrawerStyles();

    useEffect(() => {
      if (!dappCntx.user) return;
      if (dappCntx.user?.user?.["UserType"] === "Operator" || dappCntx.user?.user?.["UserType"] === "User"){
        setMenu2nd(secondMenu.slice(0, 1));
      } else {
        setMenu2nd(secondMenu);
      }
    }, [dappCntx.user]);    

    return (
      <Drawer open={open} onClose={onDrawerClose}
        variant="permanent"
        className={clsx(drwrcls.drawer, {
          [drwrcls.drawerOpen]: open,
          [drwrcls.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [drwrcls.drawerOpen]: open,
            [drwrcls.drawerClose]: !open,
          }),
        }}
      >
        <Toolbar style={{backgroundColor: "#0e0c0f"}} />
        <Box style={{ overflowX: "hidden", display:"flex", flexFlow: "column" }}>
            <CustomLeftMenu menus={firstMenu} onMenuClick={(parm) => onMenuClick(parm)} />
            {(dappCntx?.user?.user.UserType === "Administrator" && 
            <Fragment>
            <Divider orientation="horizontal"/>
            <MainMenu 
              title="Contacts"
              itemText="Contacts"
              mainIcon={<RectangleListIcon/>}
              menus={contactsMenu} 
              itemSelected={(param) => onMenuClick(param)}
              showCollapse={showCollapse}
            /></Fragment>) || <Fragment/>}
            <Divider orientation="horizontal"/>
            <CustomLeftMenu menus={menu2nd} onMenuClick={(parm) => onMenuClick(parm)}/>
            {/* <Divider orientation="horizontal"/>
            <CustomLeftMenu menus={thirdMenu} onMenuClick={(parm) => onMenuClick(parm)}/> */}
            <Divider orientation="horizontal"/>
            <MainMenu 
              title="My Tickets"
              itemText="USER"
              // mainIcon={<TicketsUserIcon/>}
              menus={myTicketsMenu} 
              itemSelected={(param) => onMenuClick(param)}
              showCollapse={showCollapse}
              style={{backgroundColor: "#bbc0c4", color: "window"}}              
            />

            {(dappCntx.user?.user?.["UserType"] === "Administrator" || dappCntx.user?.user?.["UserType"] === "Operator") &&
              <Fragment>
              <Divider orientation="horizontal"/>
              <MainMenu
                title="Org Tickets"
                itemText="ORG"
                // mainIcon={<TicketsOrgIcon/>}
                menus={fullTicketsMenu}
                itemSelected={(param) => onMenuClick(param)}
                showCollapse={showCollapse}
                style={{backgroundColor: "#bbc0c4", color: "window"}}
              />
              </Fragment>
            }

            {/* <Divider orientation="horizontal"/>
            <CustomLeftMenu menus={fourthMenu} onMenuClick={(parm) => onMenuClick(parm)}/> */}

            {dappCntx.user?.user?.["UserType"] === "Administrator" &&
              <Divider orientation="horizontal"/>}
            {dappCntx.user?.user?.["UserType"] === "Administrator" &&
              <ReportsMenu menus={reportsMenu} reportSelected={(parm) => onMenuClick(parm)}/>
            }
            {dappCntx.user?.user?.["UserType"] === "Administrator" &&
              <Divider orientation="horizontal"/>}
            {/* <Typography component={`div`} style={{display: "flex", flexFlow: "column", flexGrow: 1}}>

            </Typography>
            <CustomLeftMenu menus={
              [{ title: 'Preferences', icon: faGear, path: "/", key: 'prefs' }]              
            } onMenuClick={onPreferences}
              showDivider={false}
            /> */}
        </Box>
      </Drawer>
    );
}

const CustomLeftMenu = (props) => {
    const { menus, onMenuClick, showDivider, ...other } = props;
    return (
        <div {...other}>
        {!!showDivider && <Divider orientation="horizontal"/>}
          <List dense>
            {menus?.map((item) => (
              <Tooltip title={item.title} key={`${item.key}-key`}>
              <ListItem key={`${item.key}-key`} button 
                onClick={() => onMenuClick({ pathto: item.path })}                
              >
                  <ListItemIcon>
                    {(!item.icon.type && <FontAwesomeIcon icon={item.icon} style={{color: '#55bef2'}}/>) 
                    || item.icon}
                  </ListItemIcon>
                  <ListItemText>{item.title}</ListItemText>
              </ListItem>
              </Tooltip>
            ))}
        </List>
        </div>
    );
}


const firstMenu = [
    { title: 'Home', icon: <HomeIcon/>, path: '/', key: 'home' },
];

const contactsMenu = [
  { title: 'Contacts', icon: <AddressesIcon style={{fontSize: '1.5rem'}}/>, path: '/contacts', key:'contact' },
  { title: 'New Invites', icon: <AddressCardIcon style={{fontSize: '1.5rem'}}/>, path: '/contacts/new-invites', key:'ninvites' },
  { title: 'View Invites', icon: <ListIcon style={{fontSize: '1.5rem'}}/>, path: '/contacts/view-invites', key:'vinvites' },
];

const secondMenu = [
    { title: 'Devices', icon: <ComputerIcon/>, path: "/devices", key: 'device'},
    { title: 'Jobs', icon: <ManageServiceIcon />, path: "/jobs",key: 'jobs'},
];

// const thirdMenu = [
//     { title: 'Balances', icon: faScaleBalanced, path: "/balances", key: 'balance'},
//     { title: 'Managed Services', icon: faPeopleRoof, path: "/services", key: 'service'},
// ]

// const fourthMenu = [
//     { title: 'Files', icon: faFile, path: "/files", key: 'files'},
//     // { title: 'Surveys', icon: faSquarePollVertical, path: "/surveys", key: 'survey'},
// ];

const fullTicketsMenu = [
  { title: 'Incidents', icon: <NewTicketIcon style={{color: "#55bef2"}}/>, path: "/incidents", key: "incident"},
  { title: 'Service Requests', icon: <NewRequestIcon style={{color: "#55bef2"}}/>, path: "/requests", key: 'request'},
]

const myTicketsMenu = [
  // { title: 'My Incidents', icon: <NewTicketIcon/>, path: `${"/myincidents/"}${Buffer.from(`${Math.random() * new Date().getSeconds()}`).toString('base64')}`, key: "myincident"},
  { title: 'My Incidents', icon: <NewTicketIcon/>, path: "/myincidents/", key: "myincident"},
  { title: 'My Service Requests', icon: <NewRequestIcon style={{color: "#55bef2"}}/>, path: "/myrequests/", key: 'myrequest'},
]

const reportsMenu = [
    { 
        title: 'Support Tickets', icon: faTicket, 
        path: "/reports/5cd25672-8481-4423-8d43-1adda13e07da", 
        key: 'support', 
    },
    { 
        title: 'Infrastructure Management', icon: faRoadBridge, 
        path: "/reports/4e3b7b07-a7e5-4146-87f9-d3ca2d8f03b3", 
        key: 'manage', 
    },
    { 
        title: 'Cyber Security', icon: faShieldHalved, 
        path: "/reports/0d656542-aada-4d93-b95e-7d8d861e6340", 
        key: 'security', 
    },
    { 
        title: 'Backup / Disaster Recovery', icon: faTrashRestore, 
        path: "/reports/b1af8913-80d5-4f90-bc94-771a1511ed88", 
        key: 'ticket', 
    },
]

const ReportsMenu = props => {
  const { menus, reportSelected } = props;
  const [expand, setExpand] = useState(false);

  const reportMenuClicked = useCallback(
    props => {
      if (reportSelected && typeof reportSelected === 'function') {
        reportSelected(props);
      }
    },
    [reportSelected],
  );

  return (
    <List dense>
      <Tooltip title="Reports">
      <ListItem onClick={() => setExpand(!expand)} button>
        <ListItemIcon>
          <SinglePaneIcon/>
        </ListItemIcon>
        <ListItemText primary="Reports" />
        {expand ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      </Tooltip>
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <List component={`div`} disablePadding>
        {menus.map((item, index) => (
          <Tooltip title={item.title} key={`${item.key}`}>
          <ListItem button 
            key={`${item.key}-key`}
            onClick={() =>
              reportMenuClicked({
                title: item.title,
                key: `${item.key}`,
                params: item.params,
                pathto: item.path,
              })
            }
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={item.icon} style={{color: '#55bef2'}} />
            </ListItemIcon>
            <ListItemText primary={item.title} secondary={item.subtitle} />
          </ListItem>
          </Tooltip>
        ))}
        </List>
      </Collapse>
    </List>
  );
};

const MainMenu = (props) => {
  const { title, mainIcon, menus, itemSelected, itemText, showCollapse, ...other } = props;
  const [expand, setExpand] = useState(false);

  const ticketMenuClicked = useCallback((props) => {
      if (itemSelected && typeof itemSelected === 'function') {
        itemSelected(props);
      }
    }, [itemSelected]);

  return (
    <List dense disablePadding>
      <Tooltip title={title}>
      <ListItem onClick={() => setExpand(!expand)} button {...other}>
        {!!mainIcon && <ListItemIcon>
          {mainIcon}
        </ListItemIcon>}
        {(!!itemText && <ListItemText>{itemText}</ListItemText>) || <Fragment/>}
        {(showCollapse && ((expand && <ExpandLess />) || <ExpandMore />)) || <></>}
      </ListItem>
      </Tooltip>
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <List component={`div`} disablePadding>
        {menus.map((item, index) => (
          <Tooltip title={item.title} key={`${item.key}-key`}>
          <ListItem button 
            key={`${item.key}-key`}
            onClick={() =>
              ticketMenuClicked({
                title: item.title,
                key: `${item.key}`,
                pathto: item.path,
              })
            }
          >
            <ListItemIcon>
              {/* <FontAwesomeIcon icon={item.icon} style={{color: '#55bef2'}}/> */}
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.title} secondary={item.subtitle} />
          </ListItem>          
          </Tooltip>
        ))}
        </List>
      </Collapse>
    </List>
  );
};
