import { CoreForm } from "@kineticdata/react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { Fragment, useCallback, useRef } from "react";
import { KappFormLayout, KappPending, useDialogButtonStyles, useFormStyle, useTabStyles } from "../../controls/shared.components";

export const UserPreferences = ({
    contactID, open, onClose, onSave, onError, ...other
}) => {

    const cls = useTabStyles();
    const kForm = useRef();
    const btnCls = useDialogButtonStyles();

    const handleFormUpdate = useCallback(() => {
        kForm.current.submitPage();
        onClose();
    }, [kForm, onClose]);

    const handleLoaded = useCallback((form) => {
        kForm.current = form;
    }, [kForm]);

    return (
        <Fragment>
            <Dialog {...other} open={open} onClose={onClose}>
                <DialogTitle style={{ padding: 'unset', backgroundColor: '#007dba', color: 'window' }}>
                    <Typography component={`div`} className={cls.dappTitle}>
                        User Preferences
                        <IconButton onClick={onClose} className={cls.closeIcon}>
                            <Close/>
                        </IconButton>
                    </Typography>
                </DialogTitle>
                <DialogContent style={{minWidth: 480, minHeight: 420, padding: "unset"}}>
                    <UserPreferencesForm 
                        onSave={onSave} 
                        onError={onError} 
                        contactID={contactID} 
                        onLoaded={handleLoaded}/>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" className={btnCls.okbutton} onClick={handleFormUpdate}>Update</Button>
                    <Button variant="contained" className={btnCls.closebutton} onClick={onClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
};

export const UserPreferencesForm = ({onSave, onError, onLoaded, contactID}) => {
    const styles = useFormStyle();

    return (
        <Typography component={`div`} name="core-form-container"
            className={styles.root}
        style={{
          backgroundColor: "window",
          display: "flex",
          flexFlow: "column",
          overflowY: "auto",
        }}>
        <CoreForm
            kapp="customer-portal"
            form="user-preferences"
            onError={onError}
            onCompleted={onSave}
            loaded={onLoaded}
            values={{ContactID: `${contactID}`}}
            components={{
                Pending: KappPending,
                Layout: KappFormLayout,
        }}
      />
      </Typography>
    );
  };
  