import React, { useEffect, useState, useMemo } from "react";
import { getDataUniversal } from "../../apicalls/universal.api";
import PropTypes from 'prop-types';
import { DappBooleanAsIcon } from "../../controls/shared.components";

export const useManagedServicesData = (props) => {
    const { usr, apiparms } = props;
    const [srvcreq, setSrvcReq] = useState(null);

    useEffect(() => {
        try{
            getDataUniversal({user: usr, apiparams: apiparms, cmstype: "managedservice"}).then((response) => {
                setSrvcReq(response.data);
            }).catch((error) => {
                console.error(`managed services api ${error.toString()}`);
            });
        } catch (exp) {
            console.error(`managed services api ${exp.toString()}`);
        }

    }, [apiparms, usr]);

    return srvcreq;
}

useManagedServicesData.PropTypes = {
    usr: PropTypes.object.isRequired,
    apiParams: PropTypes.object.isRequired,
}

export const useManagedColumns = () => {
    const priceFormatInfo = useMemo(() => new Intl.NumberFormat('en-US', {
        style: 'currency', currency: 'USD', minimumFractionDigits: 2
    }), []);
    
    const quantityFormatInfo = useMemo(() => new Intl.NumberFormat('en-US', {
         minimumFractionDigits: 2
    }), []);    
    
    return useMemo(() => [
        {
            field: "Spacer", headerName: " ", width: 40, disableColumnMenu: true, sortable: false,
        },
        {
            field: "RateDesc", headerName: "Code", width: 120, disableColumnMenu: true,
            sortable: true,
        },
        {
            field: "Description", headerName: "Description", flex: 1.8, disableColumnMenu: true,
            sortable: true,
        },
        {
            field: "SellPrice", headerName: "Price", flex: .4, disableColumnMenu: true,
            valueFormatter: (params) => { return priceFormatInfo.format(params.value); }, 
            align: 'right', sortable: true, type: "number",
        },
        {
            field: "Quantity", headerName: "Quantity", flex: .4, disableColumnMenu: true,
            valueFormatter: (params) => {return quantityFormatInfo.format(params.value);}, 
            align: "right", sortable: true, type: "number",
        },
        {
            field: "OKtoInvoice", headerName: "OK To Invoice", type: "boolean", width: 160, 
            disableColumnMenu: true, align: "center", sortable: false,
            renderCell: (params) => { return <DappBooleanAsIcon value={params.value}/> }
        },
    ], [priceFormatInfo, quantityFormatInfo]);
}