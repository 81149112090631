import React, {useEffect, useRef, useState, useCallback} from "react";
import { useDialogStyles } from "../../modals/dialog.style";
import { useFilterStyles } from "../../modals/filter.styles";
import { useDappContext } from "../../../auth/dapp.context";
import { useSavedFilterColumns } from "../columns.incidents";
import { useDataFromBridge } from "../../../controls/custom.hooks";
import { Button, Popover, Typography } from "@material-ui/core";
import { useGridApiRef } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useGridStyles } from "../../../controls/datagrid.striped";
import clsx from "clsx";

export const LoadFilter = (props) => {
    const {
        showFilter, hideFilter, parentEl, applyFilter, currentFilter, 
        searchType
    } = props;

    const [filterData, setFilterData] = useState();
    const [xuser, setXuser] = useState();
    const [loadingFilters, setLoadingFilters] = useState(true);
    const [totalFilterRows, setTotalFilterRows] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState();
    const [apiValues, setApiValues] = useState();

    const diagStyles = useDialogStyles();
    const filterStyles = useFilterStyles();
    const tranRef = useRef(null);
    const dappCntx = useDappContext();
    const columns = useSavedFilterColumns();

    useEffect(() => {
        if (!dappCntx?.user) return;
        setXuser(dappCntx.user.user);
    }, [dappCntx])

    useEffect(() => {
        if (!!apiValues) return;
        setApiValues({
            SearchType: searchType,
            GetTotal: true,
        });
    }, [apiValues, searchType]);

    const filterApiCall = useDataFromBridge({
        xUser: xuser, apiValues: apiValues,
        bridgedResourceName: "Get Filters",
    });

    const handleClose = useCallback((params) => {
        setSelectedFilter(null);
        hideFilter();
    }, [hideFilter]);

    useEffect(() => {
        if (!filterApiCall) return;

        setLoadingFilters(false);
        setFilterData(filterApiCall?.data?.records.map(x => {return {...x, id: parseInt(x.SearchCriteriaID)}}));
        setTotalFilterRows(filterApiCall?.data?.metadata?.count);

    }, [filterApiCall]);

    const onFilterPageChange = useCallback((params) => {

    }, []);

    const onSelectFilterRow = useCallback((params) => {
        let row = (params?.length > 0 && filterData.find(x => x.id === parseInt(params[0]))) || null;
        setSelectedFilter(row);
    }, [filterData]);

    const handleApply = useCallback((params) => {
        if (!!applyFilter && typeof applyFilter === 'function'){
            applyFilter(selectedFilter);
        }
    }, [selectedFilter, applyFilter]);

    return (
        <Popover open={showFilter} anchorEl={parentEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transitionDuration={800}
        >
                <Typography component={'div'} ref={tranRef} className={filterStyles["wrapper-root"]}>
                    <Typography component={'div'} className="title-wrap">
                        <Typography component={'div'} className="filter-prompt">
                            Selected Filter:
                        </Typography> 
                        <Typography component={'div'} className="filter-name">
                            {selectedFilter?.["SearchName"] || currentFilter?.["SearchName"] || ''}
                        </Typography>
                    </Typography>
                    <Typography component={`div`} className={diagStyles["filter-wrapper"]}>
                        <FilterGrid 
                            rows={filterData}
                            columns={columns} 
                            loading={loadingFilters}
                            count={totalFilterRows}
                            handlePageChange={onFilterPageChange}
                            handleRowSelect={onSelectFilterRow}
                            currentFilter={currentFilter}
                        />
                    </Typography>
                    <Typography component={`div`} className={diagStyles["filter-actions"]}>
                        <Button title="Apply" onClick={handleApply} color="primary" disabled={!selectedFilter}>Apply</Button>
                        <Button title="Cancel" onClick={handleClose} color="secondary">Cancel</Button>
                    </Typography>
                </Typography>
        </Popover>
    );
};

const FilterGrid = (props) => {
    const {
        rows, columns, loading, count, handlePageChange, handleSortChange,
        handleRowSelect, currentFilter,
        ...other
    } = props;
    
    const gridcls = useGridStyles();
    const apiref = useGridApiRef();

    useEffect(() => {
        if (!currentFilter) return;
        apiref && apiref.current.selectRow(currentFilter.id);
    }, [apiref, currentFilter]);

    return (
        <DataGridPro
            className={clsx(gridcls[`gridheadfoot-home`], gridcls[`gridrows-home`])}
            {...other}
            apiRef={apiref}
            columns={columns}
            density="compact"
            paginationMode={`client`}
            pagination            
            autoPageSize
            rows={rows || []}
            loading={loading}
            rowCount={count || 0}
            disableMultipleRowSelection={true}
            onRowSelectionModelChange={handleRowSelect}
            onPaginationModelChange={handlePageChange}
            rowHeight={32}
            columnHeaderHeight={32}
        />
    );
}
