import { 
    Dialog, DialogActions, DialogContent, Grid, Typography, useMediaQuery, useTheme,
    MenuItem, FormControlLabel
} from "@mui/material";
import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";
import { useDialogStyles } from "../../modals/dialog.style";
import { FilterDate, FilterDef, FilterDialogTitle, FilterFieldDef, FilterOKCancelButtons } from "./filter.components";
import { useFilterStyles } from "../../modals/filter.styles";
import { AssigneeDropDown } from "./assignee.dropdown";
import { useDappContext } from "../../../auth/dapp.context";
import { useTicketsContext } from "../../incidents/incident.provider";
import { useDataFromBridge } from "../../../controls/custom.hooks";
import { DropDownField } from "./drop.down";
import { DappToolbarSwitch } from "../../../controls/shared.components";
import { LoadFilter } from "../../incidents/filter/filter.load";
import { SaveFilter } from "../../incidents/filter/filter.save";
import { KineticWebAPICall } from "../../../apicalls/contact.api";
import { INCIDENT_FILTER_WEBAPI_SAVE } from "../../../controls/dapp.constants";
import { useServiceRequestContext } from "./filter.provider";

// const PriotityLevelValues = [
//     {"PriorityValue": "Priority1"},
//     {"PriorityValue": "Priority2"},
//     {"PriorityValue": "Priority3"},
//     {"PriorityValue": "Priority4"}
// ];

const RequestTypeValues = [
    {"RequestTypeValue": "To Do"},
    {"RequestTypeValue": "Continuous Process Improvement"},
    {"RequestTypeValue": "Emergency"},
    {"RequestTypeValue": "General Service"},
    {"RequestTypeValue": "Priority Scoring Improvement"},
    {"RequestTypeValue": "Project"},
    {"RequestTypeValue": "Remote Work"},
    {"RequestTypeValue": "Site Visit"}
]

const RequestPriorityValues = [
    {"RequestPriorityValue": "HIGH"},
    {"RequestPriorityValue": "LOW"},
    {"RequestPriorityValue": "NORMAL"}
]

const filterMapping = [
    {oldField: "rcbHDAssignedTo", newField: "assignedTo"},
    {oldField: "FilterStartDate", newField: "OpenDateStart"},
    {oldField: "FilterEndDate", newField: "OpenDateStop"},
    {oldField: "RequestedStartDate", newField: "RequestedStartDate"},
    {oldField: "RequestedEndDate", newField: "RequestedStopDate"},
    {oldField: "cbShowScheduledRequests", newField: "IsRequestScheduled"},
    {oldField: "rcbHDDepartment", newField: "TSMDepartmentID"},
    {oldField: "rcbHDResponsibleDepartment", newField: "ResponsibleDepartmentID"},
    {oldField: "rcbHDFilterStatus", newField: "Status"},
    {oldField: "rcbRequestType", newField: "RequestType"},
    {oldField: "rcbRequestPriority", newField: "RequestPriority"},
    {oldField: "rcbPriorityLevel", newField: "PriorityLevel"},
    // {oldField: "rcbRequestTarget", newField: "IncidentTarget"},
    {oldField: "chkSharedServiceRequests", newField: "IsAssigneeSharedOperator"},
];

export const TicketFilterDialog = (props) => {
    const {showDialog, handleClose, handleOkClick} = props;
    const theme = useTheme();
    const diagSize = useMediaQuery(theme.breakpoints.down('md'));
    // const [formValues, setFormValues] = useState({});
    const [showAssigneeLkp, setShowAssigneeLkp] = useState(false);
    const [assigneeData, setAssigneeData] = useState();
    const [xuser, setXuser] = useState();
    const [apivals, setapivals] = useState();
    const [assigneeBridge, setAssigneeBridge] = useState();    
    const [deptsData, setDeptsData] = useState();
    const [ticketDeptsBridge, setTicketDeptsBridge] = useState();
    const [showFilter, setShowFilter] = useState(false);
    const [showSave, setShowSave] = useState(false);
    const diagStyles = useDialogStyles();
    const filterStyles = useFilterStyles();
    const dappCntx = useDappContext();
    const srvcContx = useTicketsContext();    

    const titleEl = useRef();

    const srvcCntx = useServiceRequestContext();

    useEffect(() => {
        if (!apivals){
            setapivals({random: 0});
            setAssigneeBridge('TicketAssignee');
            setTicketDeptsBridge('TicketDepartments');
        }
    }, [apivals]);

    const handleCloseFilter = useCallback(() => {
        setShowSave(false);
    }, []);

    const showMessage = useCallback((props) => {
        const {severity, message} = props;
        srvcContx.setShowAlert({...srvcContx.showAlert, 
          open: true, severity: severity, message: message,
          onSnackbarClose: () => { 
            srvcContx.setShowAlert({...srvcContx.showAlert, open: false, message: "random"})
        },
        });
      }, [srvcContx]);

    const handleSaveFilter = useCallback((f2s) => {
        try {
            KineticWebAPICall({
                user: xuser, data: {Filter: f2s}, url: INCIDENT_FILTER_WEBAPI_SAVE
            }).then((response) => {
                showMessage({severity: "success", message: "Filter saved"});
                srvcCntx.setSavedFilter(f2s);
                setShowSave(false);
            }).catch((error) => {
                showMessage({severity: "error", message: `Save filter failed: ${error}`});
            })
        } catch (exp) {
            showMessage({severity: "error", message: `Save filter failed with: ${exp}`});
        }

    }, [xuser, showMessage, srvcCntx]);

    const onClearFilter = useCallback(() => {
        srvcCntx.setSavedFilter(null);
        srvcCntx.setFormValues({...srvcCntx.formValues, ...srvcCntx.InitFilterValues});
        srvcCntx.setFilter(f => srvcCntx.initLoadParams)
    }, [srvcCntx]);

    const onLoadFilter = useCallback(() => {
        console.log(`this is where we're loading the filter`);
        setShowFilter(true);
    }, []);

    const onClose = useCallback(() => {
        if (handleClose && typeof handleClose === 'function'){
            handleClose();
        }
    }, [handleClose])

    useEffect(() => {
        if (!!srvcCntx.formValues && JSON.stringify(srvcCntx.formValues) !== '{}') return;               
        srvcCntx.setFormValues({...srvcCntx.formValues, ...(srvcCntx?.filter || srvcCntx.InitFilterValues)});
        // console.log(`saved filter values: ${JSON.stringify(srvcCntx.filter)}`);
    }, [srvcCntx, srvcCntx.formValues]);

    const handleChange = useCallback((e) => {
        srvcCntx.setFormValues({...srvcCntx.formValues, [`${e.target.id || e.target.name}`]: e.target.value});
    }, [srvcCntx]);

    const handleSubmitFilter = useCallback(() => {
        if (handleOkClick && typeof handleOkClick === 'function'){
            srvcCntx.setFilter(f => srvcCntx.formValues);
            handleOkClick({filterValues: {...srvcCntx.formValues}});
        }
    }, [handleOkClick, srvcCntx]);

    useEffect(() => {
        if (!dappCntx.sharedOperator) return;
        setShowAssigneeLkp( 
            (dappCntx?.user?.user?.UserType !== "User") &&
            (dappCntx.sharedOperator && Number(dappCntx.sharedOperator.IsHDSharedOperator) === -1)
        );
    }, [dappCntx]);

    const onAutoCompleteChange = useCallback((e, value) => {
        srvcCntx.setFormValues({...srvcCntx.formValues, assignedTo: value?.UserID || ' '});        
    }, [srvcCntx]);    

    useEffect(() => {
        if (!dappCntx?.user) return;
        setXuser(dappCntx.user.user);
      }, [dappCntx])
    
      const assignees = useDataFromBridge({
        xUser: xuser, apiValues: apivals,
        bridgedResourceName: assigneeBridge,
      });
    
      useEffect(() => {
        if (!assignees ) return;
        let simplified = assignees?.data?.records?.map((option) => {
            return { UserID: option?.["Assignee ID"],
                UserName: `${option?.["Assignee DisplayName"]} - ${option?.["Assignee ID"]}`}
        });
        setAssigneeData(simplified);
      }, [assignees]);    

      const handleCheckChange = useCallback((e) => {
        srvcCntx.setFormValues({ ...srvcCntx.formValues, [`${e.target.id || e.target.name}`]: e.target.checked});
    }, [srvcCntx]);

    const departments = useDataFromBridge({
        xUser: xuser, apiValues: apivals,
        bridgedResourceName: ticketDeptsBridge,
    });

    useEffect(() => {
        if (!departments) return;
        setDeptsData(departments?.data?.records);
    }, [departments]);

    const applyValues2Filter = useCallback(() => {
        let flds = [];
        Object.entries(srvcCntx.formValues).map((fld) => {
            let fieldName = filterMapping.find(fm => fm.newField === fld[0])?.oldField;

            if (fieldName){
                flds.push({
                    ...FilterFieldDef, 
                    WebControlName: `${fieldName}`,
                    WebControlValue: fld[1],
                });  
            }                
            return fieldName;
        });
        return flds;
    }, [srvcCntx]);
    
    const applyFilterValues = useCallback((fields) => {
        let newFields = {};
        fields.map(fld => {
            let fieldName = !!fld && filterMapping.find(fm => fm.oldField === fld.WebControlName)?.newField;

            if (fieldName && fld?.WebControlValue !== '-1'){
                newFields = {
                    ...newFields, 
                    [`${fieldName}`]: (fld.WebControlValue === '<ALL>' && ' ') ||  
                    (fieldName.match(/Date/gi)?.length > 0 ? new Date(fld.WebControlValue).toJSON().split('T')[0] : fld.WebControlValue)
                };  
            }
                
            return fieldName;
        });
        srvcCntx.setFormValues({...srvcCntx.formValues, ...newFields});
    }, [srvcCntx]);

    const hideFilter = useCallback(() => {
        setShowFilter(false);
    }, []);

    const onSaveFilter = useCallback(() => {
        let flt = { 
            ...((!!srvcCntx.savedFilter && srvcCntx.savedFilter) || FilterDef), 
            SearchPage: "Request Grid",
            SearchType: "Request",
            CCWebUserID: dappCntx.user.user.CCWebUserID,
            PrimaryContactID: dappCntx.user.user.PrimaryContactID,
            Fields: applyValues2Filter()
        };
        srvcCntx.setSavedFilter(flt);

        setShowSave(!showSave);
    }, [srvcCntx, showSave, applyValues2Filter, dappCntx]);

    const handleApplyFilter = useCallback((filterRecord) => {
        srvcContx.setSavedFilter(filterRecord);
        applyFilterValues(JSON.parse(filterRecord.Fields));
        hideFilter();
    }, [hideFilter, applyFilterValues, srvcContx]);

    const handleClearFilter = useCallback(() => {
        srvcCntx.setFormValues({...srvcCntx.formValues, ...srvcCntx.initLoadParams});
        if (handleOkClick && typeof handleOkClick === 'function'){
            handleOkClick({filterValues: srvcCntx.initLoadParams});
        }
    }, [handleOkClick, srvcCntx]);

    return (
        <Fragment>
            <Dialog open={showDialog || false} onClose={onClose} fullScreen={diagSize}>
                <FilterDialogTitle diagStyles={diagStyles} 
                    handleSave={onSaveFilter}
                    handleClear={onClearFilter}
                    handleLoadFilter={onLoadFilter}
                    handleClose={onClose}
                    titleEl={titleEl}
                />
                <DialogContent>
                    <form onSubmit={handleSubmitFilter}>
                        <Typography component={'div'} className={filterStyles["wrapper-root"]}>
                            <Typography component={'div'} className="title-wrap">
                                <Typography component={'div'} className="filter-prompt">
                                    Selected Filter:
                                </Typography>
                                <Typography component={'div'} className="filter-name">
                                    {srvcContx?.savedFilter?.["SearchName"] || ''}
                                </Typography>
                            </Typography>
                        </Typography>
                        <Grid container spacing={3}>
                            {(showAssigneeLkp &&
                                <Grid item xs={12}>
                                    <AssigneeDropDown 
                                        formValues={srvcCntx.formValues} 
                                        handleAutoCompleteChange={onAutoCompleteChange} 
                                        assigneeData={assigneeData}
                                    />
                                </Grid>) || <Fragment />}
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                <DropDownField 
                                        id="PriorityLevel"
                                        name="PriorityLevel"
                                        label="Level"
                                        onChange={handleChange}
                                        value={srvcCntx.formValues?.PriorityLevel || ' '}
                                    >
                                        <MenuItem key="blank" value=" " sx={{opacity: "0.8"}}>{`(All)`}</MenuItem>
                                        {PriotityLevelValues.map((value, index) => {
                                            return (
                                                <MenuItem key={`class-${index}`} value={value.PriorityValue}>
                                                    {value.PriorityValue}
                                                </MenuItem>
                                            );                                            
                                        })}
                                    </DropDownField>
                                </Grid>
                                <Grid item xs={6}>
                                    <DropDownField
                                        id="IncidentTarget"
                                        name="IncidentTarget"
                                        label="Target"
                                        onChange={handleChange}
                                        value={formValues?.IncidentTarget || " "}
                                    >
                                        <MenuItem key="blank" value=" " sx={{opacity: "0.8"}}>{'(All)'}</MenuItem>
                                        {IncidentTargetValues.map((value, index) => {
                                            return (
                                                <MenuItem key={`class-${index}`} value={value["TargetValue"]}>
                                                    {value["TargetType"]}
                                                </MenuItem>
                                            );
                                        })}
                                    </DropDownField>
                                </Grid>
                            </Grid>
                        </Grid> */}
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <DropDownField id="RequestType"
                                        name="RequestType"
                                        label="Type"
                                        onChange={handleChange}
                                        value={srvcCntx.formValues?.RequestType || ' '}
                                    >
                                        <MenuItem key="blank" value=' ' sx={{opacity: '0.8'}}>{'(All)'}</MenuItem>
                                        {RequestTypeValues.map((value, index) => {
                                            return (
                                                <MenuItem key={`class-${index}`} value={value.RequestTypeValue}>
                                                    {value.RequestTypeValue}
                                                </MenuItem>
                                            );
                                        })}
                                    </DropDownField>
                                </Grid>                                
                                <Grid item xs={6}>
                                    <DropDownField id="RequestPriority"
                                        name="RequestPriority"
                                        label="Level"
                                        value={srvcCntx.formValues?.RequestPriority}
                                        onChange={handleChange}
                                    >
                                        <MenuItem key="blank" value=" " sx={{opacity: '0.8'}}>{'(All)'}</MenuItem>
                                        {RequestPriorityValues.map((value, index) => {
                                            return (
                                                <MenuItem key={`class-${index}`} value={value.RequestPriorityValue}>
                                                    {value.RequestPriorityValue}
                                                </MenuItem>
                                            );                                            
                                        })}
                                    </DropDownField>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <DropDownField id="ResponsibleDeptID"
                                        name="ResponsibleDeptID"
                                        label="Resp. Department"
                                        value={srvcCntx.formValues?.ResponsibleDeptID}
                                        onChange={handleChange}
                                    >
                                        <MenuItem key="blank" value=' ' sx={{opacity: "0.8"}}>{`(All)`}</MenuItem>
                                        {deptsData?.map((value, indx) => {
                                            return (
                                                <MenuItem key={`dept-${indx}`} value={value["Department ID"]}>
                                                    {value["Department Name"]}
                                                </MenuItem>
                                            );
                                        })}
                                    </DropDownField>
                                </Grid>
                                <Grid item xs={6}>
                                {/* <DropDownField id="TSMDepartmentID"
                                        name="TSMDepartmentID"
                                        label="TSM Department"
                                        value={srvcCntx.formValues?.TSMDepartmentID}
                                        onChange={handleChange}
                                    >
                                        <MenuItem key="blank" value=' ' sx={{opacity: "0.8"}}>{`(All)`}</MenuItem>
                                        {deptsData?.map((value, indx) => {
                                            return (
                                                <MenuItem key={`dept-${indx}`} value={value["Department ID"]}>
                                                    {value["Department Name"]}
                                                </MenuItem>
                                            );
                                        })}
                                    </DropDownField> */}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{ paddingTop: "0.8rem" }}>
                            <Grid item xs={2}>
                                <Grid container spacing={1}>
                                    {/* <Grid item xs={6}>
                                        <FormControlLabel
                                            className={diagStyles["my-switch"]}
                                            control={
                                                <DappToolbarSwitch
                                                    checked={srvcCntx.formValues?.IsAssigneeSharedOperator && (/true/ig).test(srvcCntx.formValues?.IsAssigneeSharedOperator)}
                                                    onChange={handleCheckChange}
                                                    name="IsAssigneeSharedOperator" />
                                            }
                                            label={`${((!srvcCntx.formValues?.IsAssigneeSharedOperator || (/false/ig).test(srvcCntx.formValues?.IsAssigneeSharedOperator)) && "Not") || ''} Shared`}
                                        />
                                    </Grid> */}
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            className={diagStyles["my-switch"]}
                                            control={
                                                <DappToolbarSwitch
                                                    checked={srvcCntx.formValues?.IsVIP && (/true/ig).test(srvcCntx.formValues?.IsVIP)}
                                                    onChange={handleCheckChange}
                                                    style={{fontSize: "0.6rem"}}
                                                    name="IsVIP" />
                                            }
                                            label={`${((!srvcCntx.formValues?.IsVIP || (/false/ig).test(srvcCntx.formValues?.IsVIP)) && "No") || ''} VIP`}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={5}>
                                <FilterDate
                                    id="OpenDateStart"
                                    label="Open Date Start"
                                    // value={formValues?.OpenDateStart ||
                                    //     new Date(new Date().setFullYear(new Date().getFullYear() - 3)).toJSON().split('T')[0]
                                    // }
                                    value={srvcCntx.formValues?.OpenDateStart || ' '
                                        // new Date(new Date().setFullYear(new Date().getFullYear() - 3)).toJSON().split('T')[0]
                                    }
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FilterDate
                                    id="OpenDateStop"
                                    label="Open Date Stop"
                                    value={srvcCntx.formValues?.OpenDateStop || ' ' /*new Date().toJSON().split('T')[0]*/}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                            <Grid item xs={12}>
                            <fieldset>
                                <legend style={{fontSize: "0.6rem"}}>
                                        {`${((!srvcCntx.formValues?.IsRequestScheduled || (/false/ig).test(srvcCntx.formValues.IsRequestScheduled)) && "Not") || ''} Scheduled`}
                                </legend>
                                <Grid container spacing={3} sx={{ paddingTop: "0.8rem" }}>
                            <Grid item xs={2}>                                
                                <FormControlLabel
                                    control={
                                        <DappToolbarSwitch 
                                            checked={(srvcCntx.formValues?.IsRequestScheduled && (/true/ig).test(srvcCntx.formValues?.IsRequestScheduled)) || false}
                                            onChange={handleCheckChange}
                                            name="IsRequestScheduled"                                            
                                        />
                                    }
                                    // label={`${(!formValues?.IsRequestScheduled && "No") || ''} Schld`}
                                    // sx={{fontSize: "0.6rem"}}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FilterDate
                                    id="RequestedStartDate"
                                    label="Requested Start"                                    
                                    disabled={!srvcCntx.formValues?.IsRequestScheduled}
                                    value={srvcCntx.formValues?.RequestedStartDate || 
                                        new Date(new Date().setFullYear(new Date().getFullYear() - 3)).toJSON().split('T')[0]
                                    }
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FilterDate
                                    id="RequestedStopDate"
                                    label="Requested Stop"
                                    disabled={!srvcCntx.formValues?.IsRequestScheduled}
                                    value={srvcCntx.formValues?.RequestedStopDate || new Date().toJSON().split('T')[0]}
                                    onChange={handleChange}
                                />
                            </Grid>
                            </Grid>
                            </fieldset>
                            </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <FilterOKCancelButtons 
                        OkButtonClick={handleSubmitFilter} 
                        CancelButtonClick={onClose}
                        ClearButtonClick={handleClearFilter} 
                        formValues={srvcCntx.formValues}                        
                    />
                </DialogActions>
                <LoadFilter
                    showFilter={showFilter} 
                    hideFilter={hideFilter} 
                    parentEl={titleEl.current}
                    applyFilter={handleApplyFilter}
                    currentFilter={srvcContx?.savedFilter}
                    searchType='Request'
                />
                <SaveFilter
                    showSave={showSave}
                    handleClose={handleCloseFilter}
                    parentEl={titleEl.current}
                    handleSave={handleSaveFilter}
                />
            </Dialog>
        </Fragment>
    );
}
