import { Paper, Typography } from "@material-ui/core";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useFormStyle } from "../../controls/shared.components";
import { BottomInfo } from "../bottom/bottom.info";
import { useNavigationContext } from "../../navigation/navigation.provider";
import { CoreForm } from '@kineticdata/react';
import { useHistory } from "react-router-dom";

export const PendingContact = () => {    
    const [message, setMessage] = useState('Invalid Parameters...');
    const frmCls = useFormStyle();
    const navCntx = useNavigationContext();
    const history = useHistory();

    const frm = useRef();

    useEffect(() => {
        if (!navCntx || !navCntx?.bodyParams) return;
        setMessage("Processing...");
    }, [navCntx]);

    const handleError = useCallback((params) => {
        console.log(`form failure with: `, params);
    }, []);

    const handleSaveComplete = useCallback((params) => {
        setMessage(`Contact has been ::: ${(navCntx.bodyParams.approved === 'true' && 'Approved') || 'Disapproved'}`);
        setTimeout(() => {
            history.replace('/');
        }, 2000);
    }, [navCntx, history]);

    const handleLoaded = useCallback((kForm) => {
        if (!!frm.current) return;
        frm.current = kForm;   
        setTimeout(() => {
            if (!navCntx?.bodyParams) return;
            console.log(`submitting page...`);
            kForm.submitPage();
        }, 2000);     
    }, [frm, navCntx]);

    return (
        <Fragment>
            <Typography component={`div`} 
              className={frmCls.root}
              style={{
                minWidth: "50%", minHeight: "50%", margin: "auto", justifyContent: "center", alignItems: "center"
              }}>
              <Paper name="core-form-container" 
              style={{
                minWidth: 350,
                minHeight: 380,
                backgroundColor: "window",
                borderRadius: 15,
                display: "flex",
                flexFlow: "column",
                textAlign: "center",
                justifyContent: "center"
              }}>
                 {message}
                 <span style={{display: 'none'}}>
                 <CoreForm
                  kapp="customer-portal"
                  form={"pending-contact-approval"}
                  public={true}
                  loaded={(frm) => handleLoaded(frm)}
                  onError={handleError}
                  onCompleted={handleSaveComplete}
                  values={{"Pending Contact Object": JSON.stringify(navCntx?.bodyParams || {})}}
                  components={{
                    Pending: Pending,
                    Layout: FormLayout,
                  }}
                />
                </span> 
              </Paper>
            </Typography>
            <Typography className="bottom-info-wrapper" component={'div'}>
                <BottomInfo />                
            </Typography>
        </Fragment>
    )
}

const FormLayout = ({ form, content}) => {
    return (
    <div className="row">
      <div className="col-12">
        <div className="form-header">{form?.name}</div>
        <div className="form-container">
          {content}
        </div>
      </div>
    </div>
)
};
  
const Pending = () => (<div className="loading">Loading form...</div>);