import React, { useEffect, useState } from "react";
import { Grid, Link, Paper, Typography } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useDappContext } from "../../auth/dapp.context";
import { useDataFromBridge } from "../../controls/custom.hooks";
import { indigo, pink, green } from "@material-ui/core/colors";

export const IncidentStats = () => {
    const {id} = useParams();
    const [stats, setStats] = useState();
    const [xuser, setXuser] = useState();
    const [apiparams, setApiparams] = useState();
    const dappCntx = useDappContext();
    const history = useHistory();

    useEffect(() => {
        if (!dappCntx.user) return;
        setXuser(dappCntx.user.user);
    }, [dappCntx]);

    useEffect(() => {
        if (!id) return;
        setApiparams({IncidentID: id});
    }, [id]);

    const bridgecall = useDataFromBridge({
        xUser: xuser, apiValues: apiparams,
        bridgedResourceName: "Statistics for Incidents"
    });

    useEffect(() => {
        if (!bridgecall) return;        
        setStats(bridgecall.data.record);
    }, [bridgecall]);

    return (
        <Paper elevation={3}>
            <Typography component={`div`} style={{padding:"1rem"}}>
                Your Help Desk Statistics
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography component={`div`} style={{textAlign: "end"}}>
                        OPEN <Typography component={`span`} style={{backgroundColor: green[100], color: "inherit"}}>SEV1</Typography>
                    </Typography>
                </Grid>
                <Grid item sx={6}>
                    <Typography component={`div`} style={{textAlign: "center"}}>
                        {stats?.NumberOpenSev1Incidents}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography component={`div`} style={{textAlign: "end"}}>
                        OPEN <Typography component={`span`} style={{backgroundColor: `${indigo[300]}`, color: "white"}}>SEV2</Typography>
                    </Typography>
                </Grid>
                <Grid item sx={6}>
                    <Typography component={`div`} style={{textAlign: "center"}}>
                        {stats?.NumberOpenSev2Incidents}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography component={`div`} style={{textAlign: "end"}}>
                        OPEN <Typography component={`span`} style={{backgroundColor: pink[300], color: "white"}}>SEV3</Typography>
                    </Typography>
                </Grid>
                <Grid item sx={6}>
                    <Typography component={`div`} style={{textAlign: "center"}}>
                        {stats?.NumberOpenSev3Incidents}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography component={`div`} style={{textAlign: "end", fontWeight: "bold"}}>TOTAL OPEN</Typography>
                </Grid>
                <Grid item sx={6}>
                    <Typography component={`div`} style={{textAlign: "center"}}>
                        {stats?.NumberOpenIncidents}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography component={`div`} style={{textAlign: "end"}}>Last Reported Incident</Typography>
                </Grid>
                <Grid item sx={6}>
                    <Typography component={`div`} style={{textAlign: "center"}}>
                        {stats?.LastIncidentID && 
                        ((dappCntx?.user?.user.UserType !== 'User' &&
                        <Link component={`button`} underline="none" variant="body2"
                        onClick={() => history.push(`/incident/view/${stats?.LastIncidentID}`)}>
                            {stats?.LastIncidentNo}
                        </Link>) ||
                        <Typography component={`span`} variant='body2'>
                            {stats?.LastIncidentNo}
                        </Typography>)}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography component={`div`} style={{textAlign: "end"}}>Most Common Type</Typography>
                </Grid>
                <Grid item sx={6}>
                    <Typography component={`div`} style={{textAlign: "center"}}>
                        {stats?.MostCommonIncidentClass}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography component={`div`} style={{textAlign: "end"}}># Most Common Type</Typography>
                </Grid>
                <Grid item sx={6}>
                    <Typography component={`div`} style={{textAlign: "center"}}>
                        {stats?.MostCommonIncidentClassIDCount}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};