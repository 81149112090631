import React from "react";
import { useCallback } from "react";
import { 
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography 
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

export const DappConfirm = (props) => {
    const {open, title, question, handleResult} = props;

    const handleCancel = useCallback(() => {
        if (handleResult && typeof handleResult === 'function')
            handleResult({accepted: false});
    }, [handleResult])

    const handleOk = useCallback(() => {
        if (handleResult && typeof handleResult === 'function')
            handleResult({accepted: true});
    }, [handleResult])

    return (
        <Dialog onClose={handleCancel} open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent style={{minWidth: 350, display: "flex"}}>
                <Grid container>
                    <Grid item xs={2}>
                        <FontAwesomeIcon icon={faWarning} style={{fontSize: "3.5rem", color: "#F5921F"}}/>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography component='div' style={{flexWrap: "wrap", alignContent: "center"}}>
                            {question}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="outlined" onClick={handleOk}>OK</Button>
                <Button color="secondary" variant="outlined" onClick={handleCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}