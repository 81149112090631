import React, { Fragment, useCallback, useEffect, useState } from "react";
import { rowsperpage, WrapperPaper } from "../../../controls/shared.components";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useGridStyles } from "../../../controls/datagrid.striped";
import { CustomAlert } from "../../../alerts/alert.snackbar";
import { useDataFromBridge } from "../../../controls/custom.hooks";
import { useDappContext } from "../../../auth/dapp.context";
import clsx from "clsx";
// import { useHistory, useLocation } from "react-router-dom";
import { ViewInviteToolbar } from "./invite.toolbar";
import { useViewInviteColumns } from "./invite.hooks";
import { DappConfirm } from "../../modals/modal.confirmation";
import { cancelApiCall, deleteInvites, sendInvites } from "../../../apicalls/contact.api";

const initLoadParams = {
    AscDesc: 'DESC',
    GetTotal: true,
    IncludeInactive: false,
    PageNum: 1,
    PageSize: rowsperpage[0],
    PageToken: ' ',
    OrderBy: 'CCWebInvitationID',
    SearchValue: ' ',
  }
  
const bridgedResourceName = "Contacts2Invite"
  
export const ViewInvites = (props) => {
    const { ...other } = props;
    const [loading, setLoading] = useState(true);
    const [apiparms, setApiparms] = useState(initLoadParams);
    const [griddata, setGridData] = useState([]);
    const [pageNum, setPageNum] = useState(0);
    const [pageSize, setPageSize] = useState(rowsperpage[0]);
    const [selectedRows, setSelectedRows] = useState();
    const [pagetoken, setPageToken] = useState(' ');
    const gridClasses = useGridStyles();
    const dappCntx = useDappContext();

    const [showConfirmation, setShowConfirmation] = useState({
        open: false, title: '', question: '', handleRsult: () => {}
    });

    // const history = useHistory();
    // const location = useLocation();

    const [showAlert, setShowAlert] = useState({
      open: false, severity: 'success', message: '',
      onSnackBarClose: () => {},
    });

    const contacts = useDataFromBridge({
      xUser: dappCntx.user?.user, apiValues: apiparms,
      bridgedResourceName: bridgedResourceName 
    });

    const contactColumns = useViewInviteColumns({
        handleViewContact: ({ContactID}) => console.log(`navigating to ${ContactID}`),
    });

    const handlePageChange = useCallback((model, details) => {
      setGridData([]);
      if (model.pageSize !== pageSize)
        model.page = 0;
      setPageNum(model.page);
      setPageSize(model.pageSize);
      setLoading(true);
      setApiparms({...apiparms, 
        PageNum: model.page + 1, 
        PageSize: model.pageSize, 
        PageToken: pagetoken
      });
    }, [apiparms, pageSize, pagetoken]);

    const showError = useCallback(({message}) => {
      setShowAlert({...showAlert, open: true, 
          severity: "error", message: message,
          onSnackBarClose: () => { setShowAlert({open: false, message: "random"})}
      });
    }, [showAlert])

    const showSuccess = useCallback(({message}) => {
        setShowAlert({...showAlert, open: true, 
            severity: "success", message: message,
            onSnackBarClose: () => { setShowAlert({open: false, message: "random"})}
        });
      }, [showAlert])
      
    useEffect(() => {
      if (!contacts) return;

      setLoading(false);
      if (!contacts.success) {
          showError({message: contacts.data.Message || contacts.data.message});
          return;
      }

      if (!!contacts.data?.error){
        showError({message: contacts.data?.error?.message});
        return;
      }
      
      setPageToken(contacts.data?.metadata?.nextPageToken);
      setGridData((contacts.data.records || contacts.data.Data).map((row, indx) => { return {...row, id: indx}}));
      // eslint-disable-next-line
    }, [contacts]);

    const handleRefresh = useCallback(() => {
      setLoading(true);
      setGridData([]);
      setTimeout(() => {
        setApiparms({...apiparms, PageToken: 'iuw823=='});
        setApiparms({...apiparms, PageToken: ' '});
      }, 1500);
    }, [apiparms]);

    const handleSelectionChange = useCallback((selection) => {
      setSelectedRows(selection.map(x => griddata.find(rec => x === rec.id)));
    }, [griddata]);

    useEffect(() => {
      dappCntx.dappSubtitle("Invite");
    }, [dappCntx]);

    const handleSendInvite = useCallback(({accepted}) => {
        setShowConfirmation({...showConfirmation, 
            open: false, title: 'random', 
            question: 'lksd', handleResult: () => {}});
        
        if (!accepted) return;
        try{
            sendInvites({user: dappCntx.user?.user, data: selectedRows}).then((response) => {
                showSuccess({message: `Invitations sent successfully to ${selectedRows.length} contacts with runid: ${response.data.runId}`});
                handleRefresh();
            }).catch((error) => {
                showError({message: error.toString()});
            });
        } catch (ex) {
            showError(ex.toString());
        }
        return () => {
            cancelApiCall();
        }
    }, [showConfirmation, selectedRows, dappCntx, showSuccess, showError, handleRefresh]); 

    const handleDeleteInvite = useCallback(({accepted}) => {
        setShowConfirmation({...showConfirmation, 
            open: false, title: 'random', 
            question: 'lksd', handleResult: () => {}});
        
        if (!accepted) return;
        const inviteIds = selectedRows.map(x => x.CCWebInvitationID);
        try{
            deleteInvites({user: dappCntx.user?.user, data: inviteIds}).then((response) => {
                showSuccess({message: `Invitations successfully deleted for: ${selectedRows.length} contacts with runId: ${response.data.runId}`});
                handleRefresh();
            }).catch((error) => {
                showError({message: `Failed to delete invites with: ${error.toString()}`});
            })
        } catch (ex) {
            showError({message: `Delete invites API call failed with: ${ex.toString()}`});
        }
        return () => {
            cancelApiCall();
        }
    }, [showConfirmation, handleRefresh, dappCntx, showSuccess, showError, selectedRows]);

    const handleInvite = useCallback(() => {
        setShowConfirmation({ ...showConfirmation, 
            open: true,
            title: `Re-sending Invitation${(selectedRows.length > 1 && 's') || ''}`,
            question: `Are you sure you want to re-send invitations to ${selectedRows.length} contacts?`,
            handleResult: handleSendInvite,
        })
    }, [showConfirmation, selectedRows, handleSendInvite]);

    const handleDelete = useCallback(() => {
        setShowConfirmation({ ...showConfirmation, 
            open: true,
            title: `Deleting Invitation${(selectedRows.length > 1 && 's') || ''}`,
            question: `Are you sure you want to delete invitations for ${selectedRows.length} contacts?`,
            handleResult: handleDeleteInvite
        })
    }, [showConfirmation, selectedRows, handleDeleteInvite])

    const handleSearchValueChange = useCallback((val) => {
      setGridData([]);
      setLoading(true);
      setApiparms({...apiparms, SearchValue: (!!val && val) || ' '})
    }, [apiparms])

    const handleSortChange = useCallback((sortmodel) => {
      if (!sortmodel) return;
      if (!!sortmodel && sortmodel.length <= 0) return;
      if (apiparms.OrderBy === sortmodel[0].field && apiparms.AscDesc === sortmodel[0].sort){
        return;
      }

      setGridData([]);
      setLoading(true);

      setApiparms({
        ...apiparms,
        OrderBy: sortmodel[0].field,
        AscDesc: sortmodel[0].sort,
      });
    }, [apiparms]);

    return (
      <Fragment {...other}>
        <ViewInviteToolbar 
          onRefresh={handleRefresh}
          handleInvite={handleInvite}
          disableInvite={(selectedRows?.length || 0) <= 0}
          handleDelete={handleDelete}
          disableDelete={(selectedRows?.length || 0) <= 0}
          handleSearch={handleSearchValueChange}
        />
        <WrapperPaper>
          <DataGridPro className={clsx(gridClasses.gridheadfoot, gridClasses.gridrows)}
            columns={contactColumns}
            page={pageNum}
            density="compact"
            paginationMode="server"
            autoPageSize
            isRowSelectable={(params) => params.row["Is Invited CMS"] !== '-1' && params.row["Is Active CMS"] !== '-1'}
            rows={griddata}
            loading={loading}
            rowCount={contacts?.data?.metadata?.size || 0}
            pageSizeOptions={rowsperpage}
            checkboxSelection 
            disableSelectionOnClick
            onRowSelectionModelChange={handleSelectionChange}
            onPaginationModelChange={handlePageChange}
            sortingMode="server"
            onSortModelChange={handleSortChange}
            pagination
            initialState={{pagination: {
              paginationModel: {
                page: pageNum,
                pageSize: pageSize
              }
            }}}
          />
        </WrapperPaper>
        <CustomAlert {...showAlert}/>
        <DappConfirm { ...showConfirmation}/>
      </Fragment>
    );
}

