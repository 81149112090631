import React, {useState, useEffect} from "react";
import { Typography } from '@material-ui/core';
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from 'powerbi-client';
import { useReportToken } from "./token.hook";
import { useLocation } from "react-router";
import { EMBEDURL as reportUrl, LIVEPOWERBI, LINKTIMEOUT } from "../../controls/dapp.constants";
import { useDappContext } from "../../auth/dapp.context";
import { CustomAlert } from "../../alerts/alert.snackbar";
import { WrapperPaper } from "../../controls/shared.components";


const reportConfig = {
    type: 'report',
    tokenType: models.TokenType.Embed,
    embedUrl: reportUrl,
    id: undefined,
    accessToken: undefined,
    settings: {
        filterPaneEnabled: false,
        panes: {
            pageNavigation: {
                visible: true,
                position: models.PageNavigationPosition.Left
            }
        }
    }
}

const reportLinks = [{
    pathname: "/reports/5cd25672-8481-4423-8d43-1adda13e07da", 
    params: {
        EmbedReportId: '5cd25672-8481-4423-8d43-1adda13e07da',
        DatasetId: '5de85eb0-e078-4db2-9e26-6d84b5e56ae3',
        Username: 'ddxdemouser@dataprise.com',
        Name: 'Support Tickets',
    }
},{
    pathname: "/reports/4e3b7b07-a7e5-4146-87f9-d3ca2d8f03b3", 
    params: {
        EmbedReportId: '4e3b7b07-a7e5-4146-87f9-d3ca2d8f03b3',
        DatasetId: '6d070b50-20db-4f63-9634-0a5a1a573efc',
        Username: 'ddxdemouser@dataprise.com',
        Name: 'Infrastructure Management',
    }
},{
    pathname: "/reports/0d656542-aada-4d93-b95e-7d8d861e6340", 
    params: 
    {
        EmbedReportId: '0d656542-aada-4d93-b95e-7d8d861e6340',
        DatasetId: 'a9499f4c-641a-4214-a660-60f0833c0961',
        Username: 'ddxdemouser@dataprise.com', 
        Name: 'Cyber Security',
    }
},{
    pathname: "/reports/b1af8913-80d5-4f90-bc94-771a1511ed88", 
    params: 
    {
        EmbedReportId: 'b1af8913-80d5-4f90-bc94-771a1511ed88',
        DatasetId: '505c6a9d-2480-4099-a40a-6300a89362ef',
        Username: 'ddxdemouser@dataprise.com', 
        Name: 'Backup / Disaster Recovery',
    }
}]

const defaultReportValue = {EmbedReportId: null, DatasetId: null, Username: null};

export const PowerBIEmbeddedReport = () => {
    const [repProp, setRepProp] = useState(defaultReportValue);
    // const [report, setReport] = useState();
    const dataFromLink = useLocation();
    const dappCntx = useDappContext();
    const liveReports = LIVEPOWERBI && LIVEPOWERBI === 'true';
    const linkTime = LINKTIMEOUT;

    const [showAlert, setShowAlert] = useState({
        open: false, severity: "error", message: "",
        onSnackBarClose: () => {},
    });

    const currRep = reportLinks.find((rep) => rep.pathname === dataFromLink.pathname);

    useEffect(() => {
        if (!dappCntx || !dappCntx.user) return;
        setRepProp({
            EmbedReportId: currRep.params.EmbedReportId,
            DatasetId: currRep.params.DatasetId,
            Username: (!liveReports && currRep.params.Username) || dappCntx?.user?.user.UserName,
            // Username: dappCntx?.user?.user.UserName,
        });
        dappCntx.dappSubtitle(currRep.params.Name, () => {});
    }, [dappCntx, currRep.params, liveReports])

        
    const [config, setConfig] = useState(undefined);

    const token = useReportToken({
        reportId: repProp.EmbedReportId,
        dataSetId: repProp.DatasetId,
        userName: repProp.Username,
    });

    const eventHandlersMap = new Map([
        ['loaded', () => {
            console.log('Report loaded...');
        }],
        ['rendered', () => {
            console.log('Report rendered...');
        }],
        ['error', (event) => {
            if (event){
                console.error(event.detail);
            }
        }]
    ]);

    useEffect(() => {
        if (!!config && !!config.id && config.id !== repProp.EmbedReportId) {   
            setTimeout(() => {
                setConfig({...reportConfig, accessToken: null, id: repProp.EmbedReportId})
            }, linkTime || 500);
        } else {
        if (!token.success && token.errorMessage !== ""){
            setShowAlert({
                open: true, severity: "error", message: token.errorMessage,
                onSnackBarClose: () => setShowAlert({open: false, severity: "error", message: "random"}),                    
            })
        }
        !!token.reportToken &&
        setConfig({...reportConfig, accessToken: token.reportToken, id: repProp.EmbedReportId})
    }
    // eslint-disable-next-line
    }, [token, repProp, reportConfig]);

    return (
        <WrapperPaper>
            {!!config && config.accessToken ?
            <PowerBIEmbed 
                embedConfig={config}
                eventHandlers={eventHandlersMap}
                cssClassName={'report-style-class powerbi-reports'}
                getEmbeddedComponent={(embed) => {
                    // console.log(`Embedded object of type ${embed.embedtype} received`, config);
                    // setReport(embed);
                }}
            /> : <div>Loading Report...</div>}
            <CustomAlert {...showAlert}/>
        </WrapperPaper>
    );
}

export const PowerBIEmbeddedReport_Mod = () => {
    const dataFromLink = useLocation();

    const currRep = reportLinks.find((rep, idx) => rep.pathname === dataFromLink.pathname);
    const { EmbedReportId, DatasetId, Username} = currRep.params || defaultReportValue;

    const [report, setReport] = useState();
    const [config, setConfig] = useState(undefined);

    const token = useReportToken({
        reportId: EmbedReportId, 
        dataSetId: DatasetId, 
        userName: Username, 
    });

    const eventHandlersMap = new Map([
        ['loaded', () => {
            console.log('Report loaded', JSON.stringify(report));
        }],
        ['rendered', () => {
            console.log('Report rendered', JSON.stringify(report));
        }],
        ['error', (event) => {
            if (event){
                console.error(event.detail);
            }
        }]
    ]);

    useEffect(() => {
        if (!!config && config.id !== EmbedReportId){
            setConfig(undefined);
        } else {
            setConfig({...reportConfig, accessToken: token, id: EmbedReportId})
        }
        // eslint-disable-next-line
    }, [token, EmbedReportId]);

    return (
        <Typography component={`div`} sx={{display: 'flex', flex: '1 1 auto', overflow: 'hidden'}}>
            {!!config && config.accessToken ?
            <PowerBIEmbed 
                embedConfig={config}
                eventHandlers={eventHandlersMap}
                cssClassName={'report-style-class powerbi-reports'}
                getEmbeddedComponent={(embed) => {
                    console.log(`Embedded object of type ${embed.embedtype} received`);
                    setReport(embed);
                }}
            /> : <></>}
        </Typography>
    );
}




