import React from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.background.paper,
    }
}));

export const LoadingProgress = (props) => {
    const {open} = props;
    const classes = useStyles();

    return (
        <Backdrop open={open} className={classes.backdrop}>
            <CircularProgress />
        </Backdrop>
    )
}