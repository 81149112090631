import { CoreForm } from "@kineticdata/react";
import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import React from "react";
import { DappDialogTitle, KappFormLayout, KappPending, useDialogButtonStyles, useFormStyle } from "../../../controls/shared.components";

export const JobReportModal = (props) => {
    const {record, open, handleClose} = props;
    const cls = useFormStyle();
    const btnCls = useDialogButtonStyles();
    return (
      <Dialog open={open} onClose={handleClose}>
        <DappDialogTitle title="Job Report Detail" handleClose={handleClose} />
        <DialogContent className={cls.root} style={{width: "520px", maxHeight: "480px", overflowY: "auto"}}>
            <JobReportViewForm jobid={record?.["Time Data ID"]}/>                  
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className={btnCls.closebutton} onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    )
}

const JobReportViewForm = ({jobid}) => {
  return (
    <CoreForm
      kapp="customer-portal"
      form="view-job-report"
      values={{TimeDataID: jobid}}
      components={{
        Pending: KappPending,
        Layout: KappFormLayout,
      }}
    />
  );
};
