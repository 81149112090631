import React, { Fragment, useState, useCallback, useEffect, useRef } from "react";
import { Box, Grid } from "@material-ui/core";
import { CustomAlert } from "../../alerts/alert.snackbar";
import { useFormStyle, WrapperPaper } from "../../controls/shared.components";
import { CoreForm } from "@kineticdata/react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDappContext } from "../../auth/dapp.context";
import { IncidentEditToolbar } from "./incidents.toolbar";
import swal from "sweetalert";
import QueryString from "qs";

export const AddIncidentContactDevice = () => {
    const { id } = useParams();
    const location = useLocation();
    const [formLoadError, setFormLoadError] = useState(null);
    const [showMessage, setShowMessage] = useState({
        open: false, severity: "error", message: "",
        onSnackBarClose: () => {},
    });
    const [auth2view, setAuth2View] = useState(true);
    const cls = useFormStyle();
    const history = useHistory();
    const frm = useRef(); 
    const [qsParms, setQsParms] = useState();   

    useEffect(() => {
      if (!!qsParms) return;
      setQsParms(QueryString.parse(location.search.replace('?', '')));    
    }, [location, qsParms]);

    const dappCntx = useDappContext();
    useEffect(() => {
        dappCntx.dappSubtitle("New Incident", () => {});
    }, [dappCntx]);

    const handleError = useCallback((e) => {
      if (!!formLoadError) return;
        setShowMessage({ ...showMessage, 
            open: true, message: `something failed on form`, severity: "error",
            onSnackBarClose: () => setShowMessage({ open: false, message: 'random'}),
        });
        setFormLoadError(e);
    }, [formLoadError, showMessage]);

    const handleSaveComplete = useCallback(() => {
      setShowMessage({ ...showMessage,
        open: true, message: "Incident data has been successfully updated",
        severity: "success", onSnackBarClose: () => setShowMessage({ open: false, message: 'random'})
      });
      setTimeout(() => { history.replace("/incidents") }, 2500);
    }, [showMessage, history]);

    const handleSave = useCallback(() => {
      swal({
        title: "Save Changes?",
        content: {
          element: "span",
          attributes: {
            innerHTML: `You're about to create new ticket`,
          },
        },
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: {
          confirm: true,
          cancel: true,
        },
        dangerMode: false,
      }).then((accepted) => {
        accepted && frm.current && frm.current.submitPage && typeof frm.current.submitPage === 'function' &&
        frm.current.submitPage();
      });
    }, [frm]);

    const handleCancel = useCallback(() => {
      swal({
        title: "Are you sure?",
        text: "If you have unsaved changes, they will be lost.",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: {
          confirm: true,
          cancel: true,
        },
        dangerMode: false,
      }).then((accepted) => {
        if (accepted){
          history.goBack();
        }        
      });
    }, [history]);

    const handleLoaded = useCallback((form) => {
      frm.current = form;
    }, [frm]);

    useEffect(() => {
      setAuth2View(!(dappCntx?.user?.user.UserType === "User" && dappCntx?.user?.user.ContactID.toString() !== id));
    }, [dappCntx, id]);

    useEffect(() => {
      if (auth2view) return;
      setShowMessage({
        open: true, message: "You're not authorized to view or edit this ticket",
        severity: "error", 
      })
    }, [auth2view]);

    return (
      <Fragment>
        <IncidentEditToolbar 
          isNew={true} 
          handleStats={() => {}} 
          handleFiles={() => {}} 
          handleSaveForm={handleSave}
          handleCancelChanges={handleCancel}
          handleStatusUpdate={() => {}}
          type={"edit"}
        />
        <WrapperPaper isForm={true}>
          <Grid container spacing={2}>
            <Grid item xs={8} className={cls.root}>
              <Box component={`div`} name="box-around-core">
                <CoreForm
                  kapp="customer-portal"
                  form='new-incident'
                  onError={handleError}
                  loaded={handleLoaded}
                  onCompleted={handleSaveComplete}
                  values={
                    qsParms?.type === 'contact'
                      ? { ContactID: `${id}`, DeviceType: '' }
                      : { DeviceID: `${id}`, DeviceType: `${qsParms?.devicetype}` }
                  }
                  components={{
                    Pending: Pending,
                    Layout: FormLayout,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </WrapperPaper>
        <CustomAlert {...showMessage} />
      </Fragment>
    );
}

const FormLayout = ({ form, content}) => {
    return (
    <div className="row">
      <div className="col-12">
        <div className="form-header">{form?.name}</div>
        <div className="form-container">
          {content}
        </div>
      </div>
    </div>
)
};
  
const Pending = () => (<div className="loading">Loading form...</div>);