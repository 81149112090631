import React, { Fragment, useState, useCallback, useEffect, useRef } from "react";
import { CustomAlert } from "../../alerts/alert.snackbar";
import { useFormStyle, WrapperPaper } from "../../controls/shared.components";
import { CoreForm } from "@kineticdata/react";
import { useHistory, useLocation, useParams, Prompt } from "react-router-dom";
import { useDappContext } from "../../auth/dapp.context";
import { IncidentEditToolbar } from "./incidents.toolbar";
import { Box, Grid, makeStyles, Toolbar, Typography } from "@material-ui/core";
import { IncidentStats } from "./incident.stats";
import { useDataFromBridge } from "../../controls/custom.hooks";
import { REQUEST_STATUS_UPDATE_WEBAPI_INC, SRVCREQROUTE } from "../../controls/dapp.constants";
import swal from "sweetalert";
import { cancelApiCall, requestStatusUpdate } from "../../apicalls/contact.api";

export const useAuthStyle = makeStyles(() => ({
  withAuth: {
    display: "flex",
    flex: "1 1 auto",
    alignItems: "center",
    width: "100%",
    '& .MuiTypography-root': {
      display: 'flex',
      flexFlow: "column",
      width: "inherit",
      alignItems: "center",
    }
  }
}))

export const AddEditIncident = ({type}) => {
    const { id } = useParams();
    const history = useHistory();
    const location = useLocation();
    const cls = useFormStyle();
    const [ticketbyid, setTicketByID] = useState();
    const [toolbarTitle, setToolbarTitle] = useState();
    const [xuser, setXuser] = useState();
    const [auth2view, setAuth2View] = useState(true);
    const [editEnabled, setEditEnabled] = useState(false);
    const [ask2leave, setAsk2Leave] = useState(true);

    const [showMessage, setShowMessage] = useState({
        open: false, severity: "error", message: "",
        onSnackBarClose: () => {}, 
    });

    const frm = useRef();

    const authCls = useAuthStyle();

    const dappCntx = useDappContext();

    useEffect(() => {
        dappCntx.dappSubtitle(`${(type === "view" && "View") || "Edit"} Incident`);
    }, [dappCntx, id, type]);

    const handleError = useCallback((e) => {
        console.error(`something failed on form`, e);
        let message;
        setShowMessage({ ...showMessage, 
            open: true, message: message || message || `something failed on form`, severity: "error",
            onSnackBarClose: () => setShowMessage({ open: false, message: 'random'}),
    });
    }, [showMessage]);

    const handleSaveComplete = useCallback(() => {
        setShowMessage({ ...showMessage,
            open: true, message: "Incident data has been successfully updated",
            severity: "success", onSnackBarClose: () => setShowMessage({ open: false, message: 'random'})
        })
        console.log(`trying to get prior location`, history.state?.prevPath || location.state?.prevPath);
        setTimeout(() => { history.replace(location.state?.prevPath || "/incidents") }, 2500);
    }, [showMessage, history, location]);

    useEffect(() => {
      if (!dappCntx.currentRecord) return;
      setToolbarTitle([`${dappCntx.currentRecord["IncidentNo"]}`, `${dappCntx.currentRecord?.ShortDescription || ''}`]);
    }, [dappCntx.currentRecord])

    useEffect(() => {
      if (!dappCntx?.user) return;
      setXuser(dappCntx.user.user);
    }, [dappCntx]);

    const ticket = useDataFromBridge({
      xUser: xuser, apiValues: ticketbyid,
      bridgedResourceName: "Incident by ID"
    });

    useEffect(() => {
      if (!id) return;
      setTicketByID({IncidentID: id});
    }, [id])
  
    useEffect(() => {
      if (!ticket?.data) return;

      if (!!ticket.data?.error) {
        handleError({message: ticket?.data?.error.message});
        setAuth2View(false);
        return;
      }

      if (ticket.data?.record?.CID !== dappCntx.user?.user?.CID){
        handleError({message: "Not Authorized"});
        setAuth2View(false);
        return;
      }

      dappCntx.setCurrentRecord(ticket?.data.record);
      if (dappCntx?.user?.user.UserType === "User" && ticket?.data?.record?.ContactID !== dappCntx?.user?.user.ContactID.toString()){
        setAuth2View(false);
      }
      // setEditEnabled(dappCntx?.user?.user?.UserType !== "User" && 
      // (parseInt(Number(ticket?.data?.record?.ResponsibleDepartmentID)) === -1))
      setEditEnabled(dappCntx?.user?.user?.UserType !== "User")
      // eslint-disable-next-line
    }, [dappCntx, ticket]);

    useEffect(() => {
      if (!dappCntx?.currentRecord || dappCntx.currentRecord.IncidentID !== id) return;
      dappCntx.currentRecord.IncidentType !== "Incident" && history.replace(`${SRVCREQROUTE}${id}`);
    }, [dappCntx, id, history, showMessage, ticket]);

    const handleLoaded = useCallback((form) => {
      frm.current = form;
    }, [frm]);  

    useEffect(() => {
      if (auth2view) return;
      setShowMessage({
        open: true, message: "You're not authorized to view or edit this ticket",
        severity: "error", 
      })
    }, [auth2view]);

    const handleSave = useCallback(() => {
      swal({
        title: "Save Changes?",
        content: {
          element: "span",
          attributes: {
            innerHTML: `You're about to update ticket<br/><b>${dappCntx.currentRecord["IncidentNo"]}<b>`,
          },
        },
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: {
          confirm: true,
          cancel: true,
        },
        dangerMode: false,
      }).then((accepted) => {
        accepted && frm.current && frm.current.submitPage && typeof frm.current.submitPage === 'function' &&        
        frm.current.submitPage();
        setAsk2Leave(false);
      });
    }, [frm, dappCntx]);

    const handleCancel = useCallback(() => {
      swal({
        title: "Are you sure?",
        text: "If you have unsaved changes, they will be lost.",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: {
          confirm: true,
          cancel: true,
        },
        dangerMode: false,
      }).then((accepted) => {
        if (accepted){
          setAsk2Leave(false);
          history.goBack();
        }        
      });
    }, [history]);

    const showError = useCallback((errMsg) => {
      swal({
        title: "Error",
        text: `Failed to request status update with: 
          ${errMsg.message}`,
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: {
          confirm: true,
        },
        dangerMode: true,
      })
    }, []);

    const handleStatusUpdate = useCallback(() => {
      // let btns = frm.current.find('.submit-button');
      // let stsBtn = btns.find(btn => btn.name === "status-request-button");
      swal({
        title: "Request Status Update?",
        text: "This action will issue a request for status update on this ticket",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: {
          confirm: true,
          cancel: true,
        },
        dangerMode: false,
      }).then((accepted) => {
        if (accepted){
          // !!stsBtn && stsBtn.click();
          try{
            requestStatusUpdate({
              user: dappCntx.user?.user, data: id,
              url: REQUEST_STATUS_UPDATE_WEBAPI_INC
            }).then((response) => {
                // showSuccess({message: `Contact is disabled for: ${response.data.runId}`});
                history.goBack();
            }).catch((error) => {
                showError({message: `${error.toString()}`});
            })
            } catch (ex) {
                showError({message: `${ex.toString()}`});
            }
            return () => {
                cancelApiCall();
            }
        }        
      });
    }, [dappCntx, history, id, showError]);

    return (
      <Fragment>
        {(auth2view && 
        <IncidentEditToolbar isNew={false}
          handleActions={() => history.push(`/incident/actions/${id}`)}
          handleStats={() => history.push(`/incident/stats/${id}`)}
          handleFiles={() => history.push(`/incident/files/${id}`)}
          handleSaveForm={handleSave}
          handleCancelChanges={handleCancel}
          handleStatusUpdate={handleStatusUpdate}
          title1={toolbarTitle?.[0]} title2={toolbarTitle?.[1]}
          type={type}
          editEnabled={editEnabled}
        />) || <Toolbar/>}
        <WrapperPaper isForm={true}>
          <Grid container spacing={2}>
            <Grid item xs={8} className={cls.root} style={{display: (auth2view && "block") || "flex"}}>
              <Box component={`div`} name="box-around-core" className={authCls.withAuth}>
                {(auth2view && 
                ((type === "view" &&                
                <CoreForm
                  kapp="customer-portal"
                  form="view-incident"
                  loaded={handleLoaded}
                  onError={handleError} 
                  onCompleted={handleSaveComplete} //deprecated
                  values={id && { IncidentID: `${id}` }}
                  components={{
                    Pending: Pending,
                    Layout: FormLayout,
                  }}
                />) ||
                <CoreForm
                  kapp="customer-portal"
                  form="update-incident"
                  loaded={handleLoaded}
                  onError={handleError} 
                  onCompleted={handleSaveComplete} //deprecated
                  values={id && { IncidentID: `${id}` }}
                  components={{
                    Pending: Pending,
                    Layout: FormLayout,
                  }}
                />)) || 
                <Typography component={`div`}>
                  You're not authorized to view/edit this ticket
                </Typography>
                }
              </Box>
            </Grid>
            <Grid item xs={4} style={{padding: "1.5rem"}}>
              {(dappCntx?.user?.user.UserType !== "User" && 
              <IncidentStats />
              ) || <Fragment/>}
            </Grid>
          </Grid>
        </WrapperPaper>
        <CustomAlert {...showMessage} />
        <Prompt when={ask2leave && type !== "view"} message={"Are you sure you want to leave?"} />
      </Fragment>
    );
}

const FormLayout = ({ form, content}) => {
    return (
    <div className="row">
      <div className="col-12">
        <div className="form-header">{form?.name}</div>
        <div className="form-container">
          {content}
        </div>
      </div>
    </div>
)
};
  
const Pending = () => (<div className="loading">Loading form...</div>);