import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// 'original' kapp components and routes
import { Profile } from "../components/KAppComponents/Profile";
import { Form } from "../components/KAppComponents/Form";
import { FormList } from "../components/KAppComponents/FormList";
import { KappList } from "../components/KAppComponents/KappList";
import { SubmissionList } from "../components/KAppComponents/SubmissionList";
import { NotFound } from "../components/KAppComponents/NotFound";
// current login
import { DLogin } from "../components/KAppComponents/Login";
// import { Home } from "../components/home/home.main";
import { HomeV2 } from "../components/home/home.v2.main";
import { Contacts } from "../components/contacts/contact.main";
import { AddEditContact } from "../components/contacts/contact.edit";
import { Devices } from "../components/devices/devices.main";
import { Jobs } from "../components/jobs/jobs.main";
import { ServiceRequests } from "../components/services requests/service.request.main";
import { ManagedServices } from "../components/managed services/managed.services.main";
import { CustomerBalances } from "../components/balances/balances.main";
import { Surveys } from "../components/surveys/survey.main";
import { PowerBIEmbeddedReport } from "../components/reports/reports.main";
import { AddEditIncident } from "../components/incidents/incidents.edit";
import { AddIncidentContactDevice } from "../components/incidents/incidents.add";
import { AddServiceRequest, EditServiceRequest } from "../components/services requests/service.request.add";
import { AddEditDevice } from "../components/devices/device.edit";
import { AddEditJobs } from "../components/jobs/jobs.edit";
import { ProductList } from "../components/products/product.main";
import { JobReportList } from "../components/jobs/reports/job.report";
import { ActionList } from "../components/actions/actions.main";
import { Incidents } from "../components/incidents/incidents.main";
import { ContactAccess } from "../components/contacts/contact.access";
import { ContactPreferences } from "../components/contacts/contact.preferences";
import { NewAction } from "../components/actions/actions.new";
import { useDappContext } from "../auth/dapp.context";
import { InvoiceDetails } from "../components/balances/details/invoicedetails.main";
import { RegisterForm } from "../components/register/new.user";
import { PasswordRetrieve } from "../components/register/pass.retrieve";
import { NomatchRedirects } from "../components/oldlinks/links.redirect";
import { EmailPreferences } from "../components/register/email.pref";
import { CONTACTROUTE, INCIDENTROUTE, JOBROUTE, SRVCREQROUTE } from "../controls/dapp.constants";
import { ViewInvites } from "../components/contacts/invite/invite.view";
import { Contacts2Invite } from "../components/contacts/invite/invite.main";
import { RegisterVerifyForm } from "../components/register/register.verify";
import { TicketsProvider } from "../components/incidents/incident.provider";
import { PendingContact } from "../components/register/contact.pending";
// import { InvitationForm } from "@kineticdata/react";
// import { SplitPanelTestLayout } from "../components/tests/test.layout";

//TODO: File browser expiriments
//import { DappFileManager } from "../components/files/file.main";

// export const INCIDENTROUTE = "/incident/edit/";
// export const SRVCREQROUTE = "/servicerequest/edit";
// export const CONTACTROUTE = "/contacts/edit/";
// export const JOBROUTE = "/jobs/edit/";


export const KAppRoutes = ({setBreadcrumbs, profile}) => {
    console.log(`trying to navigate to ${profile}`);
    return (
      <Switch>
        <Route path="/profile" render={() => (<Profile setCrumbs={setBreadcrumbs} profile={profile} />)} exact/>
        <Route path={['/', '/kapps']} render={() => (
            <KappList setCrumbs={setBreadcrumbs} authorized={profile && profile.authorization['Modification']}/>
          )} exact/>
        <Route path="/kapps/:kappSlug" exact>
          <Redirect to="forms" />
        </Route>
        <Route path="/kapps/:kappSlug/forms" render={() => <FormList setCrumbs={setBreadcrumbs} />} exact/>
        <Route path="/kapps/:kappSlug/forms/:formSlug" render={() => <Form setCrumbs={setBreadcrumbs} />} exact/>
        <Route path="/kapps/:kappSlug/forms/:formSlug/submissions" render={() => <SubmissionList setCrumbs={setBreadcrumbs} />} exact/>
        <Route path="/kapps/:kappSlug/forms/:formSlug/submissions/:id" render={() => <Form setCrumbs={setBreadcrumbs} />} exact />
        <Route component={NotFound} />
      </Switch>
    );
}

export const DAppRoutes = (props) => {
    const {loggedIn, setBreadcrumbs, profile, ...params} = props;
    const dappCntx = useDappContext();
    const [isadmin, setIsAdmin] = useState(false);
    
    useEffect(() => {
      if (!dappCntx?.user) return;
      setIsAdmin(dappCntx.user.user.UserType === "Administrator");
    }, [dappCntx])

    return loggedIn ? (
        <Switch>
            <Route path="/" render={() => <TicketsProvider><HomeV2/></TicketsProvider>} exact />
            <Route path={`${INCIDENTROUTE}:id`} render={() => <AddEditIncident type="edit"/>} exact/>
            <Route path="/incident/view/:id" render={() => <AddEditIncident type="view" />} exact/>
            <Route path="/incident/add/:id" render={() => <AddIncidentContactDevice />}/>
            <Route path={`${SRVCREQROUTE}:id`} render={() => <EditServiceRequest type="update" />} exact/>
            <Route path="/servicerequest/add/:id" render={() => <AddServiceRequest/>} exact/>
            <Route path="/servicerequest/view/:id" render={() => <EditServiceRequest type="view" />} exact/>
            <Route path="/devices" render={() => <Devices/>} exact/>
            <Route path="/devices/add" render={() => <AddEditDevice/>} exact/>
            <Route path="/devices/edit/:id" render={() => <AddEditDevice/>} exact/>

            {isadmin && <Route path="/contacts" render={()  => <Contacts/>} exact/>}
            {isadmin && <Route path="/contacts/add" render={() => <AddEditContact />} exact/>}
            {isadmin && <Route path={`${CONTACTROUTE}:id`} render={() => <AddEditContact type="edit"/>} exact/>}
            {isadmin && <Route path="/contacts/view/:id" render={() => <AddEditContact type="view" />} exact/>}
            {isadmin && <Route path="/contacts/new-invites" render={() => <Contacts2Invite />} exact/>}
            {isadmin && <Route path="/contacts/view-invites" render={() => <ViewInvites />} exact/>}

            {isadmin && <Route path="/jobs" render={() => <Jobs/>} exact/>}

            <Route path="/services" render={() => <ManagedServices/>} exact/>
            <Route path="/incidents" render={() => <Incidents contactOnly={dappCntx.user?.user?.["UserType"] === "User"}/>} exact/>
            <Route path="/requests" render={() => <ServiceRequests contactOnly={dappCntx.user?.user?.["UserType"] === "User"}/>} exact/>
            <Route path="/myincidents/:some?" render={() => <Incidents contactOnly={true}/>} exact/>
            <Route path="/myrequests/:some?" render={() => <ServiceRequests contactOnly={true}/>} exact/>
            {/* <Route path="/files" render={() => <DappFileManager/>} exact/> */}
            <Route path="/surveys" render={() => <Surveys/>}></Route>
            <Route path="/balances" render={() => <CustomerBalances/>}></Route>
            {isadmin && <Route path="/reports/:reportId" render={() => <PowerBIEmbeddedReport {...params}/>} exact />}
            <Route path="/login" render={() => <DLogin {...params}/>}/>

            {isadmin && <Route path="/jobs/add" render={() => <AddEditJobs/>} exact/>}
            {isadmin && <Route path={`${JOBROUTE}:jobid`} render={() => <AddEditJobs/>} exact/>}
            {isadmin && <Route path="/jobs/products/:jobid" render={() => <ProductList/>} exact/>}
            {isadmin && <Route path="/jobs/jobreports/:jobid/:invid?" render={() => <JobReportList/>} exact/>}
            {isadmin && <Route path="/jobs/services/:jobid" render={() => <ManagedServices/>} exact/>}
            {isadmin && <Route path="/jobs/balances/:jobid" render={() => <CustomerBalances/>} exact/>}
            {isadmin && <Route path="/jobs/balances/details/:id" render={() => <InvoiceDetails/>} exact/>}

            <Route path="/incident/actions/:id" render={() => <ActionList />} exact/>
            <Route path="/servicerequest/actions/:id" render={() => <ActionList />} exact/>
            <Route path="/incident/stats/:id" render={() => <div></div>}/>
            <Route path="/incident/files/:id" render={() => <div></div>}/>
            <Route path="/actions/add:id" render={() => <NewAction/>} exact/>

            {isadmin && <Route path="/contact/devices/:id" render={() => <Devices/>} exact/>}
            {isadmin && <Route path="/contact/requests/:id" render={() => <ServiceRequests/>} exact/>}
            {isadmin && <Route path="/contact/surveys/:id" render={() => <Surveys/>}/>}
            {isadmin && <Route path="/contact/access/:id" render={() => <ContactAccess/>} exact/>}
            {isadmin && <Route path="/contact/preferences/:id" render={() => <ContactPreferences/>} exact/>}

            <Route path="/kapps/:kappSlug" exact>
              <Redirect to="forms" />
            </Route>
            <Route path="/kapps/:kappSlug/forms" render={() => <FormList setCrumbs={setBreadcrumbs} />} exact/>
            <Route path="/kapps/:kappSlug/forms/:formSlug?" render={() => <Form setCrumbs={setBreadcrumbs} />} />
            <Route path="/kapps/:kappSlug/forms/:formSlug/:id" render={() => <Form setCrumbs={setBreadcrumbs} />} exact/>
            <Route path="/kapps/:kappSlug/forms/:formSlug/submissions" render={() => <SubmissionList setCrumbs={setBreadcrumbs} />} exact/>
            <Route path="/kapps/:kappSlug/forms/:formSlug/submissions/:id" render={() => <Form setCrumbs={setBreadcrumbs} />} exact />

            {/* <Route path="/test" render={() => <SplitPanelTestLayout/>} exact/> */}
            <Route path="*" render={() => <div>No match found for path</div>}/>
        </Switch> 
    ) : (
    <Switch>
          <Route path="/register/:gid?" render={() => <RegisterForm/>} />
          <Route path="/verify/:gid?" render={() => <RegisterVerifyForm/>} exact />
          <Route path="/retrieve" render={() => <PasswordRetrieve type="retrieve"/>} exact/>
          <Route path="/reset/:gid?" render={() => <PasswordRetrieve type="reset"/>} exact/>
          <Route path="/preferences" render={() => <EmailPreferences/>} exact/>
          <Route path="/pendingcontact" render={() => <PendingContact/>} exact/>
          <Route path=":stc?" exact>
            <Redirect to="/"/>
          </Route>
          <Route path="*" render={() => <NomatchRedirects />}/>
    </Switch>
    )
}


