import React, { useEffect, useState, useMemo, useCallback } from "react";
import { getDataUniversal } from "../../apicalls/universal.api";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Tooltip, IconButton, Link, Typography } from "@material-ui/core";
import { useDappContext } from "../../auth/dapp.context";
import { Fragment } from "react";

export const useServicesData = (props) => {
    const { usr, apiparms } = props;
    const [srvcreq, setSrvcReq] = useState(null);

    useEffect(() => {
        try{
            getDataUniversal({user: usr, apiparams: apiparms, cmstype: "servicerequest"}).then((response) => {
                setSrvcReq({success: true, data: response.data});
            }).catch((error) => {
                setSrvcReq({success: error.response.data})
            });
        } catch (exp) {
            setSrvcReq({success: false, data: exp.Message})
        }

    }, [apiparms, usr]);

    return srvcreq;
}

useServicesData.PropTypes = {
    usr: PropTypes.object.isRequired,
    apiParams: PropTypes.object.isRequired,
}

export const useServicesColumns = (props) => {
    const {handleEdit, handleView, handleContactView} = props;
    const dappCntx = useDappContext();

    const showSelectedRecord = useCallback((params) => {
        dappCntx.setCurrentRecord(params.row);
        if (params.type === "view") {
            handleView({row: params.row})
         } else {
            handleEdit({row: params.row});
         }
    }, [dappCntx, handleEdit, handleView]);

    return useMemo(() => [
        {
            field: "Spacer", headerName: " ", width: 40, disableColumnMenu: true,
        },
        {
            field: "IncidentNo", headerName: "Ticket #", flex: .25, disableColumnMenu: true,
            renderCell: (params) => {
                return (
                  <Tooltip title="View Ticket">
                    <Link
                      underline="none"
                      variant="body2"
                      component={`button`}
                      onClick={() => showSelectedRecord({...params, type: "view"})}
                    >
                      {params.row['IncidentNo']}
                    </Link>
                  </Tooltip>
                );
            }            
        },
        {
            field: "AssignedToName", headerName: "Assigned To", flex: .3, disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {

                return (
                    <Fragment>
                        {(params.row.ContactID && params.row.AssignedToPrimaryContactID === dappCntx?.user?.user.PrimaryContactID.toString() &&
                        <Tooltip title="View Contact">
                            <Link
                                underline="none"
                                component={'button'}
                                onClick={() => handleContactView({row: params.row})}
                                variant="body2"
                            >
                                {params.row.AssignedToName}
                            </Link>                        
                        </Tooltip>) || 
                        <Typography component={`span`} variant="body2">
                            {params.row.AssignedToName}
                        </Typography>}
                    </Fragment>
                )
            }
        },
        {
            field: "Status", headerName: "Status", flex: 0.2, disableColumnMenu: true,
        },
        {
            field: "OpenDate", headerName: "Opened", type: "date", flex: 0.2, disableColumnMenu: true,
            valueFormatter: ({value}) => new Date(value).toLocaleDateString()
        },
        {
            field: "ShortDescription", headerName: "Short Description", flex: 1, disableColumnMenu: true,
        },
        {
            field: "Actions", headerName: " ", width: 40, disableColumnMenu: true, sortable: false,
            renderCell: (params) => {
                return (
                    <React.Fragment>
                        {((dappCntx?.user?.user.UserType !== "User") && 
                        // (parseInt(Number(params.row.AssignedUserID)) < 0 || parseInt(Number(params.row.ReAssignmentTemplateID)) < 0)) &&
                        <Tooltip title="Edit Service Request">
                        <IconButton id="editRecord" color="primary" style={{fontSize: '.90rem'}}
                            onClick={() => showSelectedRecord({...params, type: "edit"})}>
                            <FontAwesomeIcon icon={faEdit}/>
                        </IconButton>
                        </Tooltip>) || <Fragment/>}
                    </React.Fragment>
                );
            },
        },
    ], [showSelectedRecord, handleContactView, dappCntx]);
}

export const useServicesColumnsShort = (props) => {
    const {handleEdit, handleView, } = props;
    const dappCntx = useDappContext();

    const showSelectedRecord = useCallback((params) => {
        dappCntx.setCurrentRecord(params.row);
        if (params.type === "view") {
            handleView({row: params.row})
         } else {
            handleEdit({row: params.row});
         }
    }, [dappCntx, handleEdit, handleView]);

    return useMemo(() => [
        {
            field: "IncidentNo", headerName: "Ticket #", flex: .25, disableColumnMenu: true,
            renderCell: (params) => {
                return (
                  <Tooltip title="View Ticket">
                    <Link
                      underline="none"
                      variant="body2"
                      component={`button`}
                      onClick={() => showSelectedRecord({...params, type: "view"})}
                    >
                      {params.row['IncidentNo']}
                    </Link>
                  </Tooltip>
                );
            }            
        },
        {
            field: "Status", headerName: "Status", flex: 0.2, disableColumnMenu: true,
        },
        {
            field: "ShortDescription", headerName: "Short Description", flex: 1, disableColumnMenu: true,
        },
        {
            field: "Actions", headerName: " ", width: 40, disableColumnMenu: true, sortable: false,
            renderCell: (params) => {
                return (
                    <React.Fragment>
                        {((dappCntx?.user?.user.UserType !== "User" && 
                        (parseInt(Number(params.row.AssignedUserID)) < 0 || parseInt(Number(params.row.ReAssignmentTemplateID)) < 0)) &&
                        <Tooltip title="Edit Service Request">
                        <IconButton id="editRecord" color="primary" style={{fontSize: '.90rem'}}
                            onClick={() => showSelectedRecord({...params, type: "edit"})}>
                            <FontAwesomeIcon icon={faEdit}/>
                        </IconButton>
                        </Tooltip>) || <Fragment/>}
                    </React.Fragment>
                );
            },
        },
    ], [showSelectedRecord, dappCntx]);
}