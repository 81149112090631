import React, { Fragment, useCallback, useState, useEffect, } from "react";
import { Toolbar } from "@material-ui/core";
import { CustomAlert } from "../../alerts/alert.snackbar";
import { useParams } from "react-router-dom";
import { WrapperPaper } from "../../controls/shared.components";
import { useDappContext } from "../../auth/dapp.context";
import { DeviceSplitView } from "./device.splitview";

export const AddEditDevice = () => {
    const { id } = useParams();
    const dappCntx = useDappContext();

    const [showMessage, setShowMessage] = useState({
        open: false, severity:"error", message: "",
        onSnackBarClose: () => {}
    });

    const handleError = useCallback(({message}) => {
        setShowMessage({...showMessage, 
            open: true, severity: "error", message: message,
            onSnackBarClose: () => setShowMessage({...showMessage, open: false, message: "random"}),
        });        
    }, [showMessage]);

    useEffect(() => {
        dappCntx.dappSubtitle(`${(!!id && 'Edit') || 'New'} Device`, () => {});
        // eslint-disable-next-line
    }, [dappCntx.title]);

    return (
        <Fragment>
            <Toolbar />
            <WrapperPaper>
                <DeviceSplitView onError={handleError} />
            </WrapperPaper>
            <CustomAlert {...showMessage}/>
        </Fragment>
    )
};




