import { Toolbar, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { WrapperPaper } from "../../controls/shared.components";

export const ContactPreferences = () => {
    const {id} = useParams();

    return (
        <Fragment>
            <Toolbar />
            <WrapperPaper>
                <Typography component={`div`}>
                    Contact Preferences placeholder for contact id: {id}
                </Typography>
            </WrapperPaper>
        </Fragment>
    )    
}