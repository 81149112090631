import React, { Fragment, useState, useCallback, useEffect } from "react";
import { Typography, Paper, } from "@material-ui/core";
import { FormLayout, Pending, useFormStyle, useWrappereStyles } from "../../controls/shared.components";
import { BottomInfo } from "../bottom/bottom.info";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { CustomAlert } from "../../alerts/alert.snackbar";
import { CoreForm } from "@kineticdata/react";

export const RegisterForm = () => {
  const [showError, setShowError] = useState({
    open: false, message: "",
    severity: "success", 
    onSnackbarClose: () => {}
  });
  const wrapCls = useWrappereStyles();
  const history = useHistory();
  const frmCls = useFormStyle();
  const {gid} = useParams();
  const {search} = useLocation();
  const [formValue, setFormValue] = useState();
  const [formType, setFormType] = useState("user-registration");

  const handleSaveComplete = () => history.replace("/");
  const handleError = useCallback((parms) => {
    setShowError({...showError, open: true, severity: "error", message: JSON.stringify(parms),
    onSnackbarClose: () => setShowError({...showError, open: false, message: "random"})
  });
  }, [showError]);

  useEffect(() => {
    if (gid) {
      setFormValue(gid);
      setFormType("self-register");
      return;
    }
  }, [gid]);

  useEffect(() => {
    if (search){
      search.split("?")[1].split("&").map((value, index) => 
      (value.split("=")[0] === "IGID") && setFormValue(value.split("=")[1]));
      setFormType("user-registration-invite");
      return;
    }
  }, [search]);

  return (
      <Fragment>
          <Typography component={`div`}
            className={frmCls.root}
            style={{
              minWidth: "50%", minHeight: "50%", margin: "auto", justifyContent: "center", alignItems: "center"
            }}>
            <Paper name="core-form-container" elevation={6}
              style={{
                minWidth: 350,
                minHeight: 380,
                backgroundColor: "window",
                borderRadius: 15,
                display: "flex",
                flexFlow: "column",
                overflowY: "auto",
              }}>
              <CoreForm 
                kapp="customer-portal"
                form={formType}
                public={true}
                onError={handleError}
                onCompleted={handleSaveComplete}
                values={(formValue && { IGID: `${formValue}` }) || null}
                components={{
                  Pending: Pending,
                  Layout: FormLayout,
                }}
              />
            </Paper>
            </Typography>
            <Typography component={`div`} className={wrapCls.bottom}>
              <BottomInfo/>
            </Typography>
            <CustomAlert {...showError}/>
      </Fragment>
  )
}