import React, { Fragment } from "react";
import { useEffect, useState, useMemo } from "react";
import { getDataUniversal } from "../../apicalls/universal.api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import PropTypes from 'prop-types';
import { IconButton, Link, Tooltip } from "@material-ui/core";

export const useBalancesData = (props) => {
    const { usr, apiparms } = props;
    const [srvcreq, setSrvcReq] = useState(null);

    useEffect(() => {
        try{
            getDataUniversal({user: usr, apiparams: apiparms, cmstype: "customerbalance"}).then((response) => {
                setSrvcReq(response.data);
            }).catch((error) => {
                console.error(`managed services api ${error.toString()}`);
            });
        } catch (exp) {
            console.error(`managed services api ${exp.toString()}`);
        }

    }, [apiparms, usr]);

    return srvcreq;
}

useBalancesData.PropTypes = {
    usr: PropTypes.object.isRequired,
    apiParams: PropTypes.object.isRequired,
}

export const SortMapper = [
    {field: "Transaction Number", sortBy: "TransNum"},
    {field: "Transaction Date", sortBy: "TransDate"},
    {field: "Job Number", sortBy: "JobNum"}
]

export const useBalancesColumns = ({handleViewDetails, handleViewTime}) => {
    const priceFormatInfo = useMemo(() => new Intl.NumberFormat('en-US', {
        style: 'currency', currency: 'USD', minimumFractionDigits: 2
    }), []);
    
    return useMemo(() => [
        {
            field: "Spacer", headerName: " ", width: 40, disableColumnMenu: true, sortable: false,
        },
        {
            field: "Transaction Number", headerName: "Invoice Num", flex: 1, disableColumnMenu: true,
            sortable: false, 
            renderCell: (params) => {
                const row = params.api.getRow(params.id);
                return (
                    (row["Transaction Type"] === "INVOICE" &&
                    <Tooltip title="View Details">
                        <Link underline="none"
                            component={`button`} variant="body2"
                            onClick={() => handleViewDetails({invoiceRecord: row})}
                        >
                        {params.row["Transaction Number"]}
                        </Link>
                    </Tooltip>) || <Fragment>{params.row["Transaction Number"]}</Fragment>
                )
            }
        },
        {
            field: "Include Time Activity", headerName: "Time Activity", flex: 0.5, disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                return (
                    ((Number(params.row?.["Include Time Activity"]) === -1) &&
                    <Tooltip title="View Time">
                        <Link underline="none" component="button" variant="body2"
                            onClick={() => handleViewTime({invoiceID: params.row["Transaction ID"]})}
                        >
                            Time Data
                        </Link>
                    </Tooltip>) || <></>
                )
            }
        }, 
        {
            field: "Transaction Type", headerName: "Type", flex: 0.5, disableColumnMenu: true,
            sortable: false,
        },
        {
            field: "Transaction Date", headerName: "Date", flex: 1, disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                const trDate = params.row['Transaction Date'];
                return trDate && `${new Date(trDate).toLocaleDateString()} ${new Date(trDate).toLocaleTimeString()}`;
            } 
        },
        {
            field: "Amount", headerName: "Total", width: 160, headerAlign: "right", 
            disableColumnMenu: true, align: "right", sortable: false,
            cellClassName: (params) => { 
                return (
                    (params.row["Transaction Type"] === "PAYMENT" || params.row["Transaction Type"] === "CREDIT MEMO") 
                    && "cell-total-amount-negative") || "cell-total-amount" 
            },
            valueFormatter: (params) => { 
                return priceFormatInfo.format((params.value < 0 && params.value * -1) || params.value); 
            }
        },
        {
            field: "Actions", headerName: " ", width: 40, disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                return (
                    (params.row["Transaction Type"] === "INVOICE" &&
                    <React.Fragment>
                        <Tooltip title="View Details">
                        <IconButton id="viewDetails" color="primary" style={{fontSize: '.90rem'}}
                            onClick={() => handleViewDetails({invoiceRecord: params.row})}>
                            <FontAwesomeIcon icon={faFileInvoiceDollar}/>
                        </IconButton>
                        </Tooltip>
                    </React.Fragment>) || <></>
                );
            },
        },
    ], [priceFormatInfo, handleViewDetails, handleViewTime]);
}