import React, { useMemo } from "react";
// import { Tooltip, IconButton } from "@material-ui/core";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { CellTextWithToolTip } from "../../controls/shared.components";

export const useProductColumns = () => {
    return useMemo(
      () => [
        {
          field: 'Spacer',
          headerName: ' ',
          width: 40,
          disableColumnMenu: true,
        },
        {
          field: 'PONum',
          headerName: 'PO Number',
          width: 110,
          disableColumnMenu: true,
        },
        {
          field: 'JobNum',
          headerName: 'Job Number',
          width: 110,
          disableColumnMenu: true,
        },
        {
          field: 'PartNo',
          headerName: 'Part #',
          flex: 0.2,
          disableColumnMenu: true,
          renderCell: (params) => <CellTextWithToolTip value={params.row.PartNo}/>
        },
        {
          field: 'InternalShortDesc',
          headerName: 'Product',  
          flex: 0.5,
          disableColumnMenu: true,
          renderCell: (params) => <CellTextWithToolTip value={params.row.InternalShortDesc}/>
        },
        {
          field: 'Description',
          headerName: 'Description',
          flex: 0.5,
          disableColumnMenu: true,
          renderCell: (params) => <CellTextWithToolTip value={params.row.Description}/>
        },
        {
          field: "Status",
          headerName: "Status",
          flex: .2,
          disableColumnMenu: true,
        },
        {
          field: "DeliveredDate",
          headerName: "Delivered",
          width: 130,
          disableColumnMenu: true,
          renderCell: params => new Date(params.value).toLocaleDateString(),
        },
        // {
        //   field: 'Actions',
        //   headerName: ' ',
        //   width: 40,
        //   disableColumnMenu: true,
        //   renderCell: params => {
        //     return (
        //       <Fragment>
        //         <Tooltip title="View Details">
        //           <IconButton
        //             id="editRecord"
        //             onClick={() => handleView({ row: params.row })}
        //             color="primary"
        //             style={{ fontSize: '.9rem' }}
        //           >
        //             <FontAwesomeIcon icon={faCircleInfo} />
        //           </IconButton>
        //         </Tooltip>
        //       </Fragment>
        //     );
        //   },
        // },
      ],
      [],
    );
  };
