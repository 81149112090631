import React from 'react';
import { Tooltip, Button, Paper, Typography, LinearProgress, Switch, DialogTitle, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Check as CheckIcon, Clear as ClearIcon, Close } from '@material-ui/icons';
import { grey, blue, red, lightGreen, lightBlue } from "@material-ui/core/colors";
import clsx from 'clsx';
import { BottomInfo } from '../components/bottom/bottom.info';

const useStyles = makeStyles(theme => ({
  paperwrap: {
    margin: '.6em',
    display: 'flex',
    flexFlow: 'column',
    height: 'inherit',
    marginLeft: '4.8rem',
    marginRight: '0.2rem',
  },
  checkicon: {
    fontSize: '1.2rem',
    fontWeight: 600,
    color: lightGreen[300],
  },
  clearicon: {
    fontSize: '1.2rem',
    fontWeight: 600,
    color: red[800],
  },
  linearwait: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export const rowsperpage = [10, 15, 25, 50];

const useToolbarButtonStyle = makeStyles((theme) => ({
  maincls: {
    '& .MuiButton-root': {
      color: lightBlue[500],
      '&:hover': {
        color: '#fff',
      }
    },
    '& .Mui-disabled': {
      color: lightBlue[600],
      opacity: 0.4,
    },
  },
}));

export const ToolbarButton = props => {
  const { name, handleClick, tooltip, icon, ...other } = props;
  const cls = useToolbarButtonStyle();

  return (
    <Tooltip title={tooltip}>
      <span className={cls.maincls}>
        <Button
          {...other}
          variant="outlined"
          onClick={handleClick}
          startIcon={icon}
        >
          {name}
        </Button>
      </span>
    </Tooltip>
  );
};

ToolbarButton.propTypes = {
  name: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  tooltip: PropTypes.string.isRequired,
};

export const WrapperPaperKappForm = ({ children, ...other }) => {
  const classes = useStyles();
  return (
    //TODO: Add a wrapper for this and add bottom disclaimer here
    <Paper elevation={2} className={classes.paperwrap} { ...other}>
      {children}
    </Paper>
    //TODO: Bottom info disclaimer is going here
  );
};

export const DappBooleanAsIcon = ({value}) => {
  const cls = useStyles();
  return (
    parseInt(value) === -1 ? 
    <CheckIcon className={cls.checkicon}/> : 
    <ClearIcon className={cls.clearicon}/> 
  );
}

export const useToolbarStyle = makeStyles((theme) => ({
  toolbarroot: {
    paddingLeft: '4.8rem',
      '& .MuiButton-textPrimary': {
        color: theme.palette.info.dark,
      },
      '& .MuiTypography-body1': {
        color: theme.palette.primary.contrastText,
      },
      '& .MuiTypography-body2': {
        color: theme.palette.grey[400],
      },
      '& .MuiOutlinedInput-root': {
        backgroundColor: theme.palette.common.white,
      },
      '& .ticket-subtitle-body': {
        maxWidth: "420px",
        overflow: "hidden",
        whiteSpace: "nowrap",    
        margin: "auto",
        textOverflow: "ellipsis",
      },
    },
  lightswitch: {
      color: theme.palette.info.main,
  },
  switchlabel: {
      color: theme.palette.info.main,
  }
}))

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
          <Typography component={`div`} style={{height: "inherit"}}>{children}</Typography>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export const LinearWait = (props) => {
  const cls = useStyles();
  return (
    <div className={cls.linearwait}>
      <LinearProgress color='secondary'/>            
    </div>
  )
}

export const useToolBarStyle = makeStyles((theme) => ({
  toolbarroot: {
    paddingLeft: '4.8rem',
      '& .MuiButton-textPrimary': {
        color: theme.palette.info.main,
        
      },
      '& .Mui-disabled': {
        color: "#185281",
      },
      '& .MuiOutlinedInput-root': {
        backgroundColor: theme.palette.common.white,
      },
  },
  lightswitch: {
      color: theme.palette.info.main,
  },
  switchlabel: {
      color: theme.palette.info.main,
  }
}))


export const useFormStyle = makeStyles((theme) => ({
  root: {
      '& .row': {
          width: "90%",
          margin: "auto",
      },
      '& .col-12': {

      },
      '& .form-header': {
          fontSize: '1.8rem',
          fontWeight: 'bold',
          display: "none",
      },
      '& .section-title': {
          textAlign: 'center',
          fontWeight: 700,
          paddingBottom: ".2rem",
      },
      '& .form-group': {
          display: "flex",
          flexFlow: "column",
          paddingBottom: '.2rem',
      },
      '& .field-label': {
          fontSize: '1rem',
          color: grey[900],
          flexGrow: 1,
      },
      '& .section-group': {
          padding: "5px 5px 5px 5px",
          boxShadow: "3px 3px 5px rgb(0 0 0 / 20%)",
          borderRadius: "5px",
          border: "1px solid",
          borderColor: grey[400],
          marginTop: "1rem",
      },
      '& .submit-section': {
          marginTop: '1rem',
          display: "flex",
          flexFlow: "column",
          width: "30%",
          margin: "auto",
      },
      '& .submit-button': {
          color: "white",
          backgroundColor: blue[500],
          border: "none",
          height: "1.6rem",
          fontSize: "1rem",
          minWidth: "5rem",
          borderRadius: "4px",
          cursor: "pointer",
      },
      '& .submit-button:hover': {
          backgroundColor: "rgb(3, 18, 161)",
          color: "white",
      },
      '& .submit-button:disabled': {
        backgroundColor: "rgb(77, 76, 75)",
        color: "white",
        cursor: "default",
      },
      '& .inner-title > span': {
          fontSize: "1.2rem",
          fontWeight: 600,
          color: blue[600],
          paddingLeft: ".4rem",
      },
      '& .form-view-group': {
        display: "flex",
        flexFlow: "row",
        paddingBottom: '.2rem',        
      },
      '& .form-view-group label':{
        fontWeight: 600,
      },
      '& .outer-section-group': {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        minWidth: '30em',
        '& .section-group-dvc': {
          flexGrow: 1,
          padding: '.2rem',
          '& .section-title': {
            backgroundColor: "#007dba",
            color: "window",
          },
          '& .content-div': {
            backgroundColor: "#d4e8f3",
          },
          '& .form-view-group': {
            display: "flex",
            flexFlow: "row",
          }
        }
      },
    // '& .radio': {
    //   display: "flex",
    //   flexFlow: "row",
    //   '& .field-label': {
    //     flexGrow: .2,
    //     textAlign: "end",
    //     width: "30%",
    //   },
    // },
    '& .radio': {
      display: "flex",
      flexFlow: "row",
    },
    '& .checkbox': {
      display: "flex",
      flexFlow: "row",
    },
},
paperform: {
  overflowY: "auto",
  overflowX: "hidden",
  margin: '0 2rem 1rem 2rem',
  display: "flex",
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: "auto",
},
}));

export const useDeviceViewStyle = makeStyles(() => ({
  root: {
    overflow: "auto",
    '& .form-header': {
      fontSize: '1.8rem',
      fontWeight: 'bold',
      display: "none",
      },
      '& .form-view-group': {
        display: "flex",
        flexFlow: "row",
        paddingBottom: '.2rem',        
      },
      '& .form-view-group label':{
        fontWeight: 600,
        minWidth: "10rem",
      },
      '& .outer-section-group': {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        minWidth: '30em',
        '& .section-group-dvc': {
          flexGrow: 1,
          textAlign: "center",
          padding: '.2rem',
          '& .section-title': {
            backgroundColor: "#007dba",
            color: "window",
          },
          '& .content-div': {
            backgroundColor: "#d4e8f3",
            display: "flex",
            flexFlow: "column",
          },
          '& .form-view-group': {
            display: "flex",
            flexFlow: "row",
          }
        }
      },
      '& .tooltip': {
        position: "relative",
        display: "inline-block",
        cursor: 'default',
      },
      /* Tooltip text */
      '& .tooltip .tooltiptext': {
        visibility: "hidden",
        backgroundColor: "rgba(102, 98, 98, 0.85)",
        minWidth: "350px",
        color: "window",
        fontSize: "0.68rem",
        padding: "8px",
        borderRadius: "6px",
        textAlign: "left",
      
        /* Position the tooltip text - see examples below! */
        position: "absolute",
        zIndex: "1",
      },

      /* Show the tooltip text when you mouse over the tooltip container */
      '& .tooltip:hover .tooltiptext': {
        visibility: "visible",
        left: "50%",
        transform: "translate(-50%, 1.8rem)",
      },
    },
    "device-form-wrapper": {
      flex: "1 1 auto", 
      display: "flex", 
      flexFlow: "column",
    },  
}));

export const KappFormLayout = ({ form, content}) => {
  return (
  <div className="row">
    <div className="col-12">
      <div className="form-header">{form?.name}</div>
      <div className="form-container">
        {content}
      </div>
    </div>
  </div>
)
};

export const KappPending = () => (<div className="loading">Loading form...</div>);

export const useSplitStyle = makeStyles((theme) => ({
  mainPanel: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexFlow: "row",
  },
  verticalDivider: {
      border: `5px solid ${grey[400]}`,
      cursor: "col-resize",
  },
  horizontalDivider: {
      border: `5px solid ${grey[400]}`,
      cursor: 'row-resize',
  },
  leftSplitPanel: {
      minWidth: "65%",
      display: "flex",
      flexFlow: "column",
  },
  rightSplitPanel: {
      width: "100%",
  },
  topSplitPanel: {
      display: "flex",
      flexFlow: "column",
      height: "100%"
  },
  bottomSplitPanel: {
      display: "flex",
      flex: "1 1 auto",
  }
}));

export const CellTextWithToolTip = ({ value }) => {
  return (
    <Tooltip
      title={
        <Typography
          component={`div`}
          style={{
            whiteSpace: 'nowrap',
            maxWidth: '350px',
            fontSize: 'smaller',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {value}
        </Typography>
      }
    >
      <Typography
        component={`div`}
        style={{
          whiteSpace: 'nowrap',
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: "inherit",
        }}
      >
        {value}
      </Typography>
    </Tooltip>
  );
};

export const DappToolbarSwitch = withStyles({
  switchBase: {
    color: blue[300],
    '&$checked': {
      color: blue[500],      
    },
    '&$checked + $track': {
      backgroundColor: blue[500],
    },    
  },
  checked: {},
  track: { backgroundColor: blue[500]},
})(Switch);

export const useTabStyles = makeStyles(() => ({
  dappTabs: {
    '& .MuiTab-root': {
      color: "window",
      backgroundColor: "#55bff2",
      opacity: "0.5",
    },
    '& .Mui-selected': {
      opacity: "1",
      backgroundColor: "#007dba",
    },
    '& .home-tab': {
      fontSize: "0.7rem",
    }
  },
  dappTitle: {
    minHeight: 60, 
    display: "flex", 
    justifyContent: "center", 
    alignItems: "center", 
    textAlign: "center",    
  },
  closeIcon: {
    position: 'absolute', 
    right: 8, 
    top: 8, 
    color: 'inherit', 
    backgroundColor: '#bc202f'
  }
}));

export const useCardStyles = makeStyles(() => ({
  actionArea: {
      '& .MuiCardActionArea-root': {
          color: 'inherit',
          backgroundColor: 'window',
          border: "2px solid #55bff2",
          borderRadius: "18px",
          '& .MuiTypography-body2': {
              color: '#bc202f',
          },
          '& .svg-inline--fa': {
            color: '#55bff2',
          },
      },
      '& .MuiCardActionArea-root:hover': {
          color: 'window',
          backgroundColor: '#007dba',
          border: "2px solid window",
          '& .MuiTypography-body2': {
              color: 'window',
          },
          '& .svg-inline--fa': {
            color: 'window',
          },
      },
      '& .MuiCardActionArea-root disabled': {
        color: 'inherit',
        backgroundColor: 'window',
        border: "2px solid #55bff2",
        borderRadius: "18px",
        cursor: "deafult",
        '& .MuiTypography-body2': {
            color: '#bc202f',
        },
        '& .svg-inline--fa': {
          color: '#55bff2',
        },
    },
  },
  'card-action-top': {
    borderRadius: "18px",
    '& .MuiCardActionArea-root': {
        color: 'inherit',
        backgroundColor: 'window',
        border: "1px solid #bfbfbf",
        borderRadius: "18px",
        '& .MuiTypography-body2': {
            color: '#bc202f',
        },
        '& .svg-inline--fa': {
          color: '#55bff2',
        },
    },
    '& .MuiCardActionArea-root:hover': {
        color: 'window',
        // backgroundColor: '#007dba',
        backgroundImage: "linear-gradient(45deg, #4F77AD, #6D72AE, #4882AE)",
        border: "1px solid window",
        '& .MuiTypography-body2': {
            color: 'window',
        },
        '& .svg-inline--fa': {
          color: 'window',
        },
        '& .card-description':{
          color: "window",
        },
    },
    '& .MuiCardActionArea-root disabled': {
      color: 'inherit',
      backgroundColor: 'window',
      border: "2px solid #55bff2",
      borderRadius: "18px",
      cursor: "deafult",
      '& .MuiTypography-body2': {
          color: '#bc202f',
      },
      '& .svg-inline--fa': {
        color: '#55bff2',
      },
    },
    '& .card-description': {
      fontWeight: 100,
      color: "#55bff2",
      fontSize: "0.6rem",
      textTransform: "uppercase",
    },
  },
  'card-action-left': {
    borderRadius: "18px !important",
    textAlign: "start",
    '& .MuiCardActionArea-root':{
      borderRadius: "18px",
    }
  },
  noActionArea: {
    '& .MuiCardContent-root': {
        color: 'inherit',
        backgroundColor: 'window',
        border: "2px solid #55bff2",
        borderRadius: "18px",
        '& .MuiTypography-body2': {
            color: '#bc202f',
        },
        '& .svg-inline--fa': {
          color: '#55bff2',
        },
    },
  },
  teamCard: {
    '& .MuiCardContent-root': {
      color: 'window',
      background: 'transparent',
      '& .MuiTypography-body2': {
        color: '#fff',
        fontSize: "0.65rem"
      },
      '& .svg-inline--fa': {
        color: '#55bff2',
      },
      '& .team-title': {
        fontSize: "1.0rem",
        fontWeight: 600,
      },
      '& .team-name': {
        fontSize: "0.8rem",
        color: "#55bff2",
      },
      '& .team-name > a': {
        color: "#55bff2",
      }
    }
  },
}));  

export const useWrappereStyles = makeStyles((theme) => ({
  root: {
      flex: "1 1 auto",
      display: "flex",
      flexFlow: "column",
      // marginBottom: "4rem",
  },
  topcontainer: {
      flex: "1 1 auto",
      backgroundColor: "window",
      overflowY: "auto",
      overflowX: "hidden",
  },
  bottomHome: {
    flex: "0 1 6rem",
    display: "flex",
    flexFlow: "column",
  },
  bottom: {
      display: "flex",
      flexFlow: "column",
  },
  bottomForm: {
    display: "flex",
    flexFlow: "column",
}

}));


export const WrapperPaper = ({ isForm, children, ...other }) => {
  const classes = useStyles();
  const wrapClasses = useWrappereStyles();

  return (
    <Typography className={wrapClasses.root} component={`div`} style={{overflowY: "auto", overflowX: "hidden"}}>
      <Paper elevation={2} className={clsx(classes.paperwrap, wrapClasses.topcontainer)} { ...other}>
        {children}
      </Paper>
      <Typography name="bottom-wrapper" component={`div`} className={(isForm && wrapClasses.bottomForm) || wrapClasses.bottom}>
        <BottomInfo />
      </Typography>
    </Typography>
  );
};


export const FormLayout = ({ form, content}) => {
  return (
  <div className="row">
    <div className="col-12">
      <div className="form-header">{form?.name}</div>
      <div className="form-container">
        {content}
      </div>
    </div>
  </div>
)
};

export const Pending = () => (<div className="loading">Loading form...</div>);

export const DappDialogTitle = (props) => {
  const {title, handleClose} = props;
  const tabcls = useTabStyles();

  return (
    <DialogTitle style={{ padding: 'unset', backgroundColor: '#007dba', color: 'window' }}>
      <Typography component={`div`} className={tabcls.dappTitle}>
        {title}
        <IconButton onClick={handleClose} className={tabcls.closeIcon}>
          <Close />
        </IconButton>
      </Typography>
    </DialogTitle>
  )
}

export const useDialogButtonStyles = makeStyles(() => ({
  okbutton: {
    backgroundColor: '#60b4e4',
    color: '#fff'    
  },
  closebutton: {
    backgroundColor: '#472453',
    color: '#fff'
  }

}));