import React, { createContext, useContext, useState } from "react";

const NavigationContext = createContext();

export const NavigationProvider = (props) => {
    const {children} = props;
    const [bodyParams, setBodyParams] = useState();

    const value = {
        bodyParams, setBodyParams
    };

    return (
        <NavigationContext.Provider value={value}>
            {children}
        </NavigationContext.Provider>
    )
}

export const useNavigationContext = () => useContext(NavigationContext);

