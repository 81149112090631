import React, { Fragment, useCallback, useState, useEffect, useRef } from "react";
import { CoreForm } from "@kineticdata/react";
import { CustomAlert } from "../../alerts/alert.snackbar";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { 
  KappFormLayout, KappPending, useFormStyle, WrapperPaper, 
} from "../../controls/shared.components";
import { useDappContext } from "../../auth/dapp.context";
import { ContactEditToolbar } from "./contacts.toolbar";
import { Box, Grid, Toolbar, Typography } from "@material-ui/core";
import { CmsAccessModal } from "./details/cms.modal";
import '../../kappform.css';
import { DappEditor } from "../../controls/rich.editor";
import swal from "sweetalert";
import { useDataFromBridge } from "../../controls/custom.hooks";
import { approveContact, cancelApiCall, disableContact, checkNewContactSaveStatus } from "../../apicalls/contact.api";
import { Prompt } from "react-router-dom";
import { LoadingProgress } from "../Loading2";

export const AddEditContact = ({type}) => {
    const { id } = useParams();
    const dappCntx = useDappContext();
    const history = useHistory();
    const location = useLocation();
    const cls = useFormStyle();
    const kForm = useRef();
    const [auth2view, setAuth2View] = useState(true);
    const [user4contact, setUser4Contact] = useState();
    const [userCallParams, setUserCallParams] = useState({});
    const [loadError, setLoadError] = useState(false);
    const [ask2leave, setAsk2Leave] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [showMessage, setShowMessage] = useState({
        open: false, severity:"error", message: "",
        onSnackBarClose: () => {}
    });
    const [showCmsAccess, setShowCmsAccess] = useState({
      open: false, handleClose: () => {},
      handleError: () => {}, handleSave: () => {},
    })

    const handleLoaded = useCallback((form) => {
      kForm.current = form;
    }, [kForm]);

    const handleSaveComplete = useCallback((data, action) => {
      const submissionCheck = {
        "SubmissionId": data.submission.id,
        "SubmissionLabel": data.submission.label,
        "SubmissionHandle": data.submission.handle
      };

      try {
        setIsLoading(true);
        setShowMessage({...showMessage, open: false, severity: '', message: ''});
        setTimeout(() => {
          checkNewContactSaveStatus({
          user: dappCntx.user?.user, data: submissionCheck, timeout: 30
        })
          .then((response) => {
            setIsLoading(false);
            if (response && response.data.length === 0 && response.status === 200) {
              setShowMessage({
                ...showMessage,
                open: true,
                severity: 'success', message: 'Contact is saved'
              });
              setTimeout(() => { history.replace(location.state?.prevPath || "/contacts") }, 1000);
            } else {
              const result = response.data && response.data.length > 0 && JSON.parse(response.data[0]?.data);              
              !result.Success && setShowMessage({
                ...showMessage,
                open: true,
                severity: 'error', message: result?.Message || 'Unable to save new contact',
              });
            }
          }).catch((error) => {
            setIsLoading(false);
            setShowMessage({
              ...showMessage,
              open: true,
              severity: 'error',
              message: `${error?.response?.data?.Message || error?.message}`
            });
          })}, 3000);
      } catch (ex) {
        setIsLoading(false);
        setShowMessage({ 
          ...showMessage,
          open: true,
          severity: 'error',
          message: `${ex.toString()}`
        });
      }
    }, [showMessage, history, location, dappCntx]);

    const handleError = useCallback((props) => {
      setLoadError(true);
      setShowMessage({...showMessage,
        open: true,
        severity: 'error', message: 'An Error Occured',
        onSnackBarClose: () => { setShowMessage({ ...showMessage, open: false, message: 'random'})}
      });
    }, [showMessage]);

    useEffect(() => {
        dappCntx.dappSubtitle(`${(!!id && 'Edit') || 'New'} Contact`, () => {});
        // eslint-disable-next-line
    }, [dappCntx.title]);

    const handleShowDevices = () => history.push(`/contact/devices/${id}`);
    const handleShowRequests = () => history.push(`/contact/requests/${id}`);
    const handleSurveys = () => history.push(`/contact/surveys/${id}`);
  
    const handleAccess = useCallback(() => {
      setShowCmsAccess({
        ...showCmsAccess,
        UserID: user4contact?.CCWebUserID,
        open: true,
        handleClose: () => setShowCmsAccess({ ...showCmsAccess, open: false }),
        handleError: props => handleError(props),
        handleSave: () => {
          setShowMessage({
            open: true,
            message: 'CMS Access changes saved',
            severity: 'success',
            onSnackBarClose: () =>
              setShowMessage({
                ...showMessage,
                open: false,
                message: 'random',
              }),
          });
          setShowCmsAccess({...showCmsAccess, open: false});
        },
      });
    }, [showCmsAccess, showMessage, handleError, user4contact]);
    const handlePreferences = () => history.push(`/contact/preferences/${id}`);

    const handleSave = useCallback(() => {
      swal({
        title: "Save Changes?",
        content: {
          element: "span",
          attributes: {
            innerHTML: `You're about to update contact record`,
          },
        },
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: {
          confirm: true,
          cancel: true,
        },
        dangerMode: false,
      }).then((accepted) => {
        setAsk2Leave(false);
        accepted && kForm.current && kForm.current.submitPage && typeof kForm.current.submitPage === 'function' &&
        kForm.current.submitPage({"timeout": "10"});
      });
    }, [kForm]);

    const handleCancel = useCallback(() => {
      swal({
        title: "Are you sure?",
        text: "If you have unsaved changes, they will be lost.",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: {
          confirm: true,
          cancel: true,
        },
        dangerMode: false,
      }).then((accepted) => {
        if (accepted){
          setAsk2Leave(false);
          history.goBack();
        }        
      });
    }, [history]);

    useEffect(() => {
      setUserCallParams({
        xUser: dappCntx.user?.user,
        apiValues: {"ContactID": id},
        bridgedResourceName: "Contact by ID",
      })
    }, [dappCntx, id])

    const contactUser = useDataFromBridge({...userCallParams});

    useEffect(() => {
      if (!contactUser) return;
      let doShow = true;

      if (!contactUser.success) {
          return;
      }

      if (!!contactUser.data?.error){
        return;
      }

      if (!contactUser.data?.record){
        return;
      }

      console.log(`selected record's CID = ${contactUser?.data?.record?.CID} and current user CID = ${dappCntx?.user?.user.CID}`);
      setAuth2View(contactUser?.data?.record?.CID === dappCntx?.user?.user.CID);
  
      doShow && setUser4Contact(contactUser.data.record);
      return () => doShow = false;
    }, [contactUser, type, dappCntx]);

    const showError = useCallback((errMsg) => {
      swal({
        title: "Error",
        text: (user4contact && `Failed to disable contact for: 
          ${user4contact?.["Last Name"]}${user4contact?.["First Name"] && ", "}${user4contact?.["First Name"]}          
          ${errMsg.message}`) || errMsg.message,
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: {
          confirm: true,
        },
        dangerMode: true,
      })
    }, [user4contact])

    const handleDisableContact = useCallback(() => {
      swal({
        title: "Disable Contact",
        text: "Are you sure you want to disable this contact?",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: {
          confirm: true,
          cancel: true,
        },
        dangerMode: false,
      }).then((accepted) => {
        if (accepted){
          try{
            setIsLoading(true);
            disableContact({user: dappCntx.user?.user, data: id, timeout: 30})
            .then((response) => {
                console.log(`executed `, response);                
                setIsLoading(false);
                history.goBack();
            }).catch((error) => {
              setIsLoading(false);
              showError({message: `${error?.response?.data?.Message || error?.message}`});
            })
            } catch (ex) {
              setIsLoading(false);
              showError({message: `${ex.toString()}`});
            }
            return () => {
                cancelApiCall();
            }
          }        
      });  
    }, [dappCntx, id, history, showError]);

    const showApproveError = useCallback((errMsg) => {
      swal({
        title: "Error",
        text: `Failed to approve contact for: 
          ${user4contact?.["Last Name"]}${user4contact?.["Last Name"] && ", "}${user4contact?.["First Name"]} 
          ${errMsg.message}`,
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: {
          confirm: true,
        },
        dangerMode: true,
      })
    }, [user4contact])

    const handleApproveContact = useCallback(() => {
      swal({
        title: "Approve Contact",
        text: "Are you sure you want to approve this contact?",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: {
          confirm: true,
          cancel: true,
        },
        dangerMode: false,
      }).then((accepted) => {
        if (accepted){
          try{
            setIsLoading(true);
            approveContact({user: dappCntx.user?.user, data: id, timeout: 10})
              .then((response) => {
                // showSuccess({message: `Contact is disabled for: ${response.data.runId}`});                
                setIsLoading(false);
                setAsk2Leave(false);
                history.goBack();
            }).catch((error) => {
              setIsLoading(false);
              showApproveError({message: error?.response?.data?.Message || error?.message});
            })
            } catch (ex) {
              setIsLoading(false);
              showApproveError({message: `${ex.toString()}`});
            }
            return () => {
                cancelApiCall();
            }
          }        
      });  
    }, [dappCntx, id, history, showApproveError]);

    return (
      <Fragment>
        {(auth2view && 
        <ContactEditToolbar
          onShowDevices={handleShowDevices}
          onServiceRequests={handleShowRequests}
          onSurveys={handleSurveys}
          onAccess={handleAccess}
          onPreferences={handlePreferences}
          onSaveChanges={handleSave}
          onCancelChanges={handleCancel}          
          onDisable={handleDisableContact}
          onApprove={handleApproveContact}
          isPending={(!!user4contact?.["Is Pending"] && parseInt(user4contact?.["Is Pending"]) === -1) || false}
          showDetail={false}
          userContact={user4contact}
          type={type}
          loadError={loadError}
        />) || <Toolbar/>}
        <WrapperPaper isForm={true}>
          <Grid container spacing={2}>
            <Grid item xs={6} className={cls.root}>
              {(auth2view && !loadError &&
              <Box component={`div`} name="box-around-core">
                <CoreForm
                  kapp="customer-portal"
                  form={type === "view" ? "view-contact" : (!!id ? 'update-contact' : 'new-contact')}
                  onError={handleError}
                  onCompleted={(data, action) => handleSaveComplete(data, action)}
                  values={(id && { ContactID: `${id}` }) || null}
                  loaded={handleLoaded}
                  components={{
                    Pending: KappPending,
                    Layout: KappFormLayout,
                    Editor: DappEditor,
                  }}
                />
              </Box>) ||
              <Typography component={`div`}>
                You're not authorized to view/edit this contact
            </Typography>}
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </WrapperPaper>
        <CustomAlert {...showMessage} />
        <CmsAccessModal {...showCmsAccess}/>
        <Prompt when={ask2leave && type !== "view"} message={"Are you sure you want to leave?"} />
        <LoadingProgress open={isLoading}/>        
      </Fragment>
    );
};

