import React from "react";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as DappLogoIcon } from "../assets/Dataprise_Logo_Blue_White.svg"
import { ReactComponent as ChatIcon } from "../assets/Chat.svg";
import { ReactComponent as ServiceIcon } from "../assets/ManagedServ.svg";
import { ReactComponent as IncidentIcon} from "../assets/Submit.svg";
import { ReactComponent as RequestIcon} from "../assets/ServiceRequest.svg";
import { ReactComponent as RemoteIcon} from "../assets/RemoteAccess.svg";
import { ReactComponent as PaneIcon} from "../assets/SPG.svg";
import { ReactComponent as ChatIconWhite } from "../assets/ChatWhite.svg";
import { ReactComponent as ServiceIconWhite } from "../assets/ManagedServWhite.svg";
import { ReactComponent as IncidentIconWhite} from "../assets/SubmitWhite.svg";
import { ReactComponent as RequestIconWhite} from "../assets/ServiceRequestWhite.svg";
import { ReactComponent as RemoteIconWhite} from "../assets/RemoteAccessWhite.svg";
import { ReactComponent as PaneIconWhite} from "../assets/SPGWhite.svg";
import { ReactComponent as CustomerServiceRep } from "../assets/CustomerServiceRep.svg";
import { ReactComponent as CustomerServiceRepWhite } from "../assets/CustomerServiceRepWhite.svg";
import { ReactComponent as ITConsultant } from "../assets/ITConsultant.svg";
import { ReactComponent as ITConsultantWhite } from "../assets/ITConsultantWhite.svg";
import { ReactComponent as ServiceDeliveryManager } from "../assets/ServiceDeliveryManager.svg";
import { ReactComponent as ServiceDeliveryManagerWhite } from "../assets/ServiceDeliveryManagerWhite.svg";
import { ReactComponent as Tickets } from "../assets/Tickets.svg";
import { ReactComponent as TicketsWhite } from "../assets/TicketsWhite.svg";
import { ReactComponent as HouseChimneyIcon } from "../assets/house-chimney-solid.svg";
import { ReactComponent as AddressBookIcon } from "../assets/address-book-solid.svg";
import { ReactComponent as ComputerSolidIcon } from "../assets/computer-solid.svg"
import { ReactComponent as AddressCardSolidIcon } from "../assets/address-card.svg";
import { ReactComponent as ListSolidIcon } from "../assets/list.svg";
import { ReactComponent as RectangleSolidListIcon } from "../assets/rectangle-list.svg";
import { ReactComponent as SlantedCornerIcon } from "../assets/SlantedCornerBox.svg";
import { ReactComponent as MyIncidentsImageIcon } from "../assets/MyIncidents.svg";
import { ReactComponent as MyServiceRequestsImageIcon } from "../assets/MyServiceRequests.svg";
import { ReactComponent as CustomerServiceTeamIcon} from "../assets/CustomerServiceTeam.svg";
import { ReactComponent as ITConsultantTeamIcon } from "../assets/ITConsultantTeam.svg";
import { ReactComponent as TechManagerTeamIcon } from "../assets/TechManagerTeam.svg"; 
import { ReactComponent as UserTicketsIcon } from "../assets/TicketsUser.svg"; 
import { ReactComponent as OrgTicketsIcon } from "../assets/TicketsOrg.svg"; 


export const LogoIcon = ({...other}) => {
    return (
        <SvgIcon>
            <DappLogoIcon />
        </SvgIcon>
    );
}

export const DappChatIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><ChatIcon/></SvgIcon>
    );
}

export const DappChatIconWhite = ({...other}) => {
    return (
        <SvgIcon {...other}><ChatIconWhite/></SvgIcon>
    );
}

export const ManageServiceIcon = ({showWhite, ...other}) => {
    return (
        <SvgIcon {...other}><ServiceIcon/></SvgIcon>
    )
}

export const ManageServiceIconWhite = ({showWhite, ...other}) => {
    return (
        <SvgIcon {...other}><ServiceIconWhite/></SvgIcon>
    )
}

export const NewTicketIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><IncidentIcon /></SvgIcon>
    );
}
export const NewTicketIconWhite = ({...other}) => {
    return (
        <SvgIcon {...other}><IncidentIconWhite /></SvgIcon>
    );
}

export const NewRequestIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><RequestIcon/></SvgIcon>
    )
}

export const NewRequestIconWhite = ({...other}) => {
    return (
        <SvgIcon {...other}><RequestIconWhite/></SvgIcon>
    )
}

export const RemoteAccessIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><RemoteIcon /></SvgIcon>
    )
}

export const RemoteAccessIconWhite = ({...other}) => {
    return (
        <SvgIcon {...other}><RemoteIconWhite/></SvgIcon>
    )
}

export const SinglePaneIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><PaneIcon /></SvgIcon>
    )
}

export const SinglePaneIconWhite = ({...other}) => {
    return (
        <SvgIcon {...other}><PaneIconWhite/></SvgIcon>
    )
}

export const CustomerSrvcRepIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><CustomerServiceRep/></SvgIcon>
    )
}

export const CustomerSrvcRepIconWhite = ({...other}) => {
    return (
        <SvgIcon {...other}><CustomerServiceRepWhite/></SvgIcon>
    )
}

export const ITConsultantIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><ITConsultant/></SvgIcon>
    )
}

export const ITConsultantIconWhite = ({...other}) => {
    return (
        <SvgIcon {...other}><ITConsultantWhite/></SvgIcon>
    )
}

export const ServiceDeliveryManagerIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><ServiceDeliveryManager/></SvgIcon>
    )
}

export const ServiceDeliveryManagerIconWhite = ({...other}) => {
    return (
        <SvgIcon {...other}><ServiceDeliveryManagerWhite/></SvgIcon>
    )
}

export const TicketsIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><Tickets/></SvgIcon>
    )
}

export const TicketsIconWhite = ({...other}) => {
    return (
        <SvgIcon {...other}><TicketsWhite/></SvgIcon>
    )
}

export const HomeIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><HouseChimneyIcon/></SvgIcon>
    )
}

export const AddressesIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><AddressBookIcon/></SvgIcon>
    )
}

export const ComputerIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><ComputerSolidIcon/></SvgIcon>
    )
}

export const AddressCardIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><AddressCardSolidIcon/></SvgIcon>
    )
}

export const ListIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><ListSolidIcon/></SvgIcon>
    )
}

export const RectangleListIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><RectangleSolidListIcon/></SvgIcon>
    )
}

export const SlantedCornerBoxIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><SlantedCornerIcon/></SvgIcon>
    )
}

export const MyIncidentsIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><MyIncidentsImageIcon/></SvgIcon>
    )
} 

export const MyServiceRequestIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><MyServiceRequestsImageIcon/></SvgIcon>
    )
}

export const CustSrvcTeamIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><CustomerServiceTeamIcon/></SvgIcon>
    )
}

export const ITConsultTeamIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><ITConsultantTeamIcon/></SvgIcon>
    )
}

export const TechMngrTeamIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><TechManagerTeamIcon/></SvgIcon>
    )
}

export const TicketsUserIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><UserTicketsIcon/></SvgIcon>
    )
}

export const TicketsOrgIcon = ({...other}) => {
    return (
        <SvgIcon {...other}><OrgTicketsIcon/></SvgIcon>
    )
}