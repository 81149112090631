import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react';
import { rowsperpage, WrapperPaper } from '../../controls/shared.components';
import { ServicesToolbar } from './services.toolbar';
import { useGridStyles } from '../../controls/datagrid.striped';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { useServicesColumns, useServicesColumnsShort } from './services.hook';
import { CustomAlert } from '../../alerts/alert.snackbar';
import { useDataFromBridge } from '../../controls/custom.hooks';
import { ModalSelection } from '../modals/modal.selection';
import { useDappContext } from '../../auth/dapp.context';
import clsx from 'clsx';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { SelectedDeviceContext } from '../devices/device.splitview';
import { utils, writeFileXLSX } from 'xlsx';
import swal from 'sweetalert';
import { EXPORTALERTMSG } from '../../controls/dapp.constants';
import { DappCalendar } from './services.calendar';
import { TicketFilterDialog } from './filter/filter.dialog';
import { useServiceRequestContext } from './filter/filter.provider';
import { getDataUniversalExportFromBrdige } from "../../apicalls/universal.api";

export const ServiceRequests = props => {
  const { contactOnly, ...other } = props;
  const [toolbarStats, setToolbarStats] = useState();
  const [ticketType, setTicketType] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [refresh, setRefresh] = useState();
  const [exportData, setExportData] = useState(false);
  const dappCntx = useDappContext();
  const history = useHistory();
  const [calendarView, setCalendarView] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [resetPage, setResetPage] = useState(false);

  const srvcCntx = useServiceRequestContext();

  const [showAlert, setShowAlert] = useState({
    open: false, severity: "error", message: "", onSnackBarClose: () => {}
  });

  const showError = useCallback(({message}) => {
    setShowAlert({ ...showAlert,
      open: true, severity: "error", message: message,
      onSnackBarClose: () => setShowAlert({open: false, message: "random"}),
    })
  }, [showAlert]);

  const handleAdd = () => {
    setTicketType("servicerequest");
    setOpenModal(true);
  }

  const handleModalClose = useCallback((props) => {
    const {ok, selection} = props;
    setOpenModal(false);
    if (!ok) return;
    let type = selection.type;
    let space = (type !== 'primaryContact' && ' ') || '';
    let field = `${type.charAt(0).toUpperCase()}${type.slice(1)}${space}ID`;
    let id = selection.record[`${field}`]
    history.push(`/${ticketType}/add/${id}?type=${type}`);
  }, [ticketType, history]);

  useEffect(() => {
    dappCntx.dappSubtitle("Service Requests");
  }, [dappCntx])  

  const handleRefresh = () => setRefresh(true);
  const finishRefresh = () => setRefresh(false);

  const handleSearch = useCallback((value) => {
    // console.log(`we'll be searching for: `, value);
    setResetPage(true);
    srvcCntx.setFilter({...srvcCntx.filter, SearchValue: (value === '' ? ' ' : value)});
    setTimeout(() => setResetPage(false), 500);
  }, [srvcCntx]);

  const handleExport = useCallback(() => {
    setExportData(true);
  }, []);

  const handleExportComplete = useCallback(() => {
    setExportData(false);
  }, []);

  const handleView = useCallback((props) => {
    setCalendarView(props);
  }, []);

  const handleFilter = () => setShowFilter(true);    

  const handleFilterClose = () => {
    setShowFilter(false);
  }

  const handleOkFilter = useCallback((props) => {
    srvcCntx.setFilter({...srvcCntx.filter, ...props.filterValues});
    setResetPage(true);
    setShowFilter(false);
    setTimeout(() => setResetPage(false), 500);
  }, [srvcCntx]);

  const handleStatusChange = useCallback((props) => {
    setResetPage(true);
    srvcCntx.setFilter({...srvcCntx.filter, Status: props});
    setTimeout(() => setResetPage(false), 500);
  }, [srvcCntx]);

  return (
    <Fragment>
      {/* <TicketsProvider> */}
        <ServicesToolbar 
          onRefresh={handleRefresh} 
          onAdd={handleAdd} 
          onView={handleView}
          handleSearch={handleSearch}
          handleExport={handleExport}                    
          handleOnFilter={handleFilter}
          statusChanged={handleStatusChange}
          {...toolbarStats}
          />
        <WrapperPaper {...other}>
          <ServiceRequestList 
            setToolbarStats={setToolbarStats} 
            handleError={showError} 
            contactOnly={contactOnly}
            refresh={refresh}
            finishRefresh={finishRefresh}
            // searchValue={searchValue}
            exportData={exportData}
            onExportDataComplete={handleExportComplete}
            calendarView={calendarView}
            filterValue={srvcCntx.filter}
            resetPage={resetPage}
          />
        </WrapperPaper>
        <CustomAlert {...showAlert}/>
        <ModalSelection open={openModal} onCloseDialog={handleModalClose} showPrimary={true}/>
        <TicketFilterDialog 
          showDialog={showFilter} 
          handleClose={handleFilterClose} 
          handleOkClick={handleOkFilter}
        />        
      {/* </TicketsProvider> */}
    </Fragment>
  );
};

export const ServiceRequestList = ({
  setToolbarStats, handleError, contactOnly, 
  refresh, finishRefresh, 
  short,  exportData, onExportDataComplete, calendarView,
  filterValue, assignedToMe, resetPage
}) => {
  const [loading, setLoading] = useState(true);
  const [apiparms, setApiparms] = useState();
  // const [exportParams, setExportParams] = useState(undefined);
  const [griddata, setGridData] = useState([]);
  const [xuser, setXuser] = useState();
  const [bridgeName, setBridgeName] = useState();
  const [calendarData, setCalendarData] = useState(); 
  const [calendarApiParams, setCalendarApiParams] = useState(undefined);
  const {id} = useParams();
  const selectedDeviceCntx = useContext(SelectedDeviceContext);
  const [totalRecs, setTotalRecs] = useState(0);
  const [exportedData, setExportedData] = useState([]);
  const [exportDataReady, setExportDataReady] = useState();
  const MAX_ROWS_4_EXPORT = 2000;

  const dappCntx = useDappContext();

  const srvcCntx = useServiceRequestContext();

  const handleDataLoadCallback = useCallback(() => {
    setGridData([]);
    setLoading(true);
  }, []);

  const srvcreqs = useDataFromBridge({ 
    xUser: xuser, apiValues: apiparms,
    bridgedResourceName: bridgeName,
    callback: handleDataLoadCallback,
  });

  // const srvcreqs2export = useDataFromBridge({ 
  //   xUser: xuser, apiValues: exportParams,
  //   bridgedResourceName: "AllServiceRequestsExport",
  // });

  const calendarRecs = useDataFromBridge({ 
    xUser: xuser, apiValues: calendarApiParams,
    bridgedResourceName: "Service Requests by ContactID",
    callback: handleDataLoadCallback,
  });

  useEffect(() => {
    if (!calendarView) return;
    setCalendarApiParams({...apiparms, 
      PageNum: 0, PageSize: 0, 
      CustomerContactID: dappCntx.user?.user?.ContactID || ''
    });
    // eslint-disable-next-line
  }, [calendarView, dappCntx]);

  useEffect(() => {
    if (!id) return;
    if (!apiparms) return;
    let parms = { ...apiparms, CustomerContactID: id};
    if (JSON.stringify(parms) === JSON.stringify(apiparms)) return;
    // setLoading(true);
    setApiparms({...apiparms, ...parms});  
  }, [id, apiparms]);

  useEffect(() => {
    if (calendarView) return;
    // if (!apiparms || !!id) return;
    let parms = (apiparms && {
      ...apiparms, ...filterValue,
      CustomerContactID: (contactOnly && dappCntx.user?.user?.ContactID) || ' ',
      Status: (filterValue?.Status === ' ' ? 'Open' : filterValue?.Status) || 'Open',
      assignedTo: (assignedToMe && `C${dappCntx.user?.user?.CCWebUserID}`) || ((filterValue && filterValue?.assignedTo !== ' ') && filterValue.assignedTo) || ' ',
      CurrentUserID: (assignedToMe && dappCntx.user?.user?.CCWebUserID) || ' '
    }) || {
      ...srvcCntx.initLoadParams, ...filterValue,
      CustomerContactID: (contactOnly && dappCntx.user?.user?.ContactID) || ' ',
      Status: (filterValue?.Status === ' ' ? 'Open' : filterValue?.Status) || 'Open',
      assignedTo: (assignedToMe && `C${dappCntx.user?.user?.CCWebUserID}`) || ' ',
      CurrentUserID: (assignedToMe && dappCntx.user?.user?.CCWebUserID) || ' '
    }
    
    setApiparms({...parms, PageNum: (resetPage && parms.PageNum !== 1) ? 1 : parms.PageNum});  
  }, [contactOnly, dappCntx, calendarView, id, filterValue, assignedToMe, srvcCntx, resetPage]);

  useEffect(() => {
    if (calendarView) return;
    // let brgName = /*((id || contactOnly) && "Service Requests by ContactID") ||*/"All Service Requests Filtered";
    let brgName = "Service Requests Simplified";
    if (bridgeName === brgName) return;
    setBridgeName(brgName);
  }, [bridgeName, id, contactOnly, calendarView]);

  useEffect(() => {
    if (!dappCntx.user) return;
    setXuser(dappCntx.user.user);
  }, [dappCntx]);

  useEffect(() => {
    if (!selectedDeviceCntx) return;
    setBridgeName("Service Requests by DeviceID");    
    if (selectedDeviceCntx.selectedDevice){
      let deviceId = selectedDeviceCntx.selectedDevice["Device ID"];
      if (!apiparms.DeviceID || apiparms.DeviceID !== deviceId){
        setApiparms({...apiparms, DeviceID: deviceId});      
      }
    }
    // eslint-disable-next-line 
  }, [selectedDeviceCntx]);

  const handlePaginationChange = useCallback((model) => {
    setApiparms({
      ...apiparms,
      ...srvcCntx.filter,
      PageNum: model.page + 1,
      PageSize: model.pageSize,
    });
  }, [apiparms, srvcCntx]);

  const showError = useCallback(({message}) => {
    if (handleError && typeof handleError === 'function'){
      handleError({message: message});
    }
  }, [handleError]);

  useEffect(() => {
    if (!refresh) return;
    // setLoading(true);
    setGridData([]);
    setTimeout(() => {
      setApiparms({ ...apiparms, PageToken: 'random' });
      setApiparms({ ...apiparms, PageToken: ' ' });
    }, 800);
  finishRefresh();
}, [refresh, apiparms, finishRefresh]);

  useEffect(() => {
    if (!srvcreqs) return;
    setGridData([]);

    setLoading(false);
    if (srvcreqs.success && !srvcreqs.data){
      showError({message: "No data"});
      return;
    }

    if (!srvcreqs.success) {
      showError({message: srvcreqs.data.Message || srvcreqs.data.message});
      return;
    }

    if (srvcreqs?.data?.error){
      showError({message: `${srvcreqs.data.error.message} for bridge parameters: ${JSON.stringify(apiparms)}` })
      return;
    }  

    let data = srvcreqs.data;

    setTotalRecs(srvcreqs?.data?.metadata?.count || 0);

    setToolbarStats({
      All: data.metadata.all, Calendar: data.metadata.calendar, 
      General: data.metadata.general, OpenIncidents: data.metadata.openIncidents, 
      Scheduled: data.metadata.scheduled, ToDo: data.metadata.toDo
    });

    setLoading(false);
    setGridData(data.records.map((row, indx) => { return { ...row, id: indx }; }) );
    // eslint-disable-next-line
  }, [srvcreqs, apiparms]);

  useEffect(() => {
    if (!calendarRecs) return;

    if (!calendarRecs.success && !calendarRecs.data)
      return;

    if (calendarRecs.success && !calendarRecs.data){
      showError({message: "No data"});
      return;
    }

    if (calendarRecs?.data?.error){
      showError({message: `${calendarRecs.data.error.message} for bridge parameters: ${JSON.stringify(calendarApiParams)}` })
      return;
    }  

    let data = calendarRecs.data;

    setLoading(false);
    setCalendarData(data.records.map((row, indx) => { return { ...row, id: indx }; }) );
    // eslint-disable-next-line
  }, [calendarRecs, calendarApiParams]);  

  useEffect(() => {
    dappCntx.dappSubtitle("Service Requests");
  }, [dappCntx])  

  const handleSortChange = useCallback((sortmodel) => {
    if (!sortmodel) return;
    if (!!sortmodel && sortmodel.length <= 0) return;
    if (apiparms.SortField === sortmodel[0].field && apiparms.AscDesc === sortmodel[0].sort) return;
    setApiparms({
      ...apiparms,
      SortField: sortmodel[0].field,
      AscDesc: sortmodel[0].sort,
    });
  }, [apiparms]);

  const exportAllRecords = useCallback(async () => {
    setLoading(true);
    let pages = Math.round(totalRecs / MAX_ROWS_4_EXPORT);
    pages = (pages <= 0 ? 1 : pages);
    for (var i = 1; i <= pages; i++){
      let p = {
        xUser: xuser,
        apiValues: {...apiparms, PageNum: i, PageSize: MAX_ROWS_4_EXPORT},
        bridgedResourceName: "AllServiceRequestsExport",
      };
      await getDataUniversalExportFromBrdige(p)
        .then(response => {
          // console.log(`inside promise...: ${response?.records?.length}`);
          setExportedData((exportedData || []).push(...response?.records))
        })
        .catch(error => console.error(error?.response?.data));
    }
    setExportDataReady(exportedData);    
  });

  useEffect(() => {
    if (!exportData) return;

    swal({
      title: "Export Service Requests",
      content: {
        element: "span",
        attributes: {
          innerHTML: EXPORTALERTMSG.replace('REPLACE-STRING', 'service requests'),
        },
      },
      icon: undefined,
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: {
        confirm: true,
        cancel: true,
      },
      dangerMode: false,
    }).then((accepted) => {
      accepted && exportAllRecords();
    });

    if (onExportDataComplete && typeof onExportDataComplete === 'function') {
      onExportDataComplete();
    }
  }, [exportData, onExportDataComplete, apiparms, exportAllRecords]);

  useEffect(() => {
    if (!exportDataReady || exportDataReady.length === 0) return;

    const d = new Date();
    const stamp = `${d.getFullYear()}${d.getMonth()}${d.getDay()}-${d.getHours()}${d.getMinutes()}`;
    const fileName = `SERVICEREQUESTS_CMS_${stamp}.xlsx`;

    exportDataReady.map((value) => {
      let dt = new Date(value.LastUpdateDate);
      value.LastUpdateDate = `${dt.toLocaleDateString()}`
      let odt = new Date(value.OpenDate);
      value.OpenDate = `${odt.toLocaleDateString()}`;
      return value;
    });

    const ws = utils.json_to_sheet(exportDataReady);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Service Requests");
    setLoading(false);
    writeFileXLSX(wb, fileName);

    setExportedData([]);
    setExportDataReady([]);

  }, [exportDataReady]);

  return (
    <Fragment>
      {(calendarView &&
        <DappCalendar
          data={calendarData}
          loading={loading}
        />) ||
        <ServiceRequestGrid
          griddata={griddata}
          loading={loading}
          totalCount={totalRecs}
          onPaginationChange={handlePaginationChange}
          sortingMode="server"
          onSortModelChange={handleSortChange}
          contactOnly={contactOnly}
          short={short}
          exportData={exportData}
          onExportDataComplete={onExportDataComplete}
          resetPageNum={resetPage}
        />}
    </Fragment>
  );
};

const ServiceRequestGrid = (props) => {
  const {
    griddata, loading, totalCount,
    onPaginationChange, contactOnly, short, embedStyle, resetPageNum,
    ...other
  } = props;

  // const [pagination, dispatch] = useReducer(pageReducer, {page: 0, pageSize: rowsperpage[0]});
  const gridClasses = useGridStyles();
  const history = useHistory();
  const location = useLocation();
  const apiRef = useGridApiRef();
  const [pageModel, setPageModel] = useState();

  useEffect(() => {
    if (!resetPageNum) return;
    if (pageModel.page === 0) return;
    setPageModel({page: 0, pageSize: pageModel.pageSize});
  }, [resetPageNum, pageModel]);

  const onPageChange = (model) => {
    setPageModel(model);
    if (!!onPaginationChange && typeof onPaginationChange === 'function')
      onPaginationChange(model)
  };

  const srvcColsShort = useServicesColumnsShort({
    handleEdit: ({ row }) => {
      history.push({pathname: `/servicerequest/edit/${row.IncidentID}`, state: {prevPath: location.pathname}});
    },
    handleView: ({ row }) => {
      history.push(`/servicerequest/view/${row.IncidentID}`);
    },
    handleDelete: ({ row }) => {
      alert(`placeholder for delete row with the Incident ID = ${row['IncidentID']}`);
    },
    handleContactView: ({row}) => {
      row?.ContactID && history.push(`/contacts/view/${row.ContactID}`);
    },
  })

  const srvcCols = useServicesColumns({ 
    handleEdit: ({ row }) => {
      history.push({pathname: `/servicerequest/edit/${row.IncidentID}`, state: {prevPath: location.pathname}});
    },
    handleView: ({ row }) => {
      history.push(`/servicerequest/view/${row.IncidentID}`);
    },
    handleDelete: ({ row }) => {
      alert(`placeholder for delete row with the Incident ID = ${row['IncidentID']}`);
    },
    handleContactView: ({row}) => {
      row?.ContactID && history.push(`/contacts/view/${row.AssignedToContactID}`);
    },
  });

  return (
    <Fragment>
        <DataGridPro
          className={clsx(gridClasses[`gridheadfoot${(short && "-home") || ''}`], gridClasses[`gridrows${(short && '-home') || ''}`], embedStyle && gridClasses["data-grid-root"])}        
          rows={griddata}
          columns={(short && srvcColsShort) || srvcCols}
          loading={loading}
          paginationMode="server"
          autoPageSize={true}
          density="compact"
          rowHeight={(short && 32) || 52}
          columnHeaderHeight={(short && 32) || 52}
          apiRef={apiRef}
          rowCount={totalCount || 0}
          paginationModel={pageModel}
          pageSizeOptions={rowsperpage}
          onPaginationModelChange={onPageChange}
          {...other}
          pagination
        />
    </Fragment>
  );
}

// const pageReducer = (state, action) => {
//   switch (action.type) {
//     case "page": 
//       return {...state, page: action.page}
//     case "pageSize": 
//       return {...state, pageSize: action.pageSize}
//     default: 
//       throw new Error();
//   }
// }


export const ServiceRequestByDevice = ({handleError, deviceId, embedStyle}) => {
  const [loading, setLoading] = useState(true);
  const [apiparms, setApiparms] = useState(undefined);
  const [griddata, setGridData] = useState([]);
  const [pageToken, setPageToken] = useState();
  const [xuser, setXuser] = useState();
  const bridgeName = "Service Requests by DeviceID";

  const dappCntx = useDappContext();

  const srvcCntx = useServiceRequestContext();

  const srvcreqs = useDataFromBridge({ 
    xUser: xuser, apiValues: apiparms,
    bridgedResourceName: bridgeName,
  });

  useEffect(() => {
    if (!dappCntx.user) return;
    setXuser(dappCntx.user.user);
  }, [dappCntx])

  useEffect(() => {
    if (!deviceId) return;
    setLoading(true);
    const contactID = dappCntx.user?.user?.UserType !== "Administrator" && dappCntx.user?.user?.ContactID
    setApiparms({...srvcCntx.initLoadParams, DeviceID: deviceId, ContactID: contactID || '', PageNum: 1, PageSize: 10});
  }, [deviceId, dappCntx, srvcCntx]);

  const handlePageSizeChange = useCallback((pageSize) => {
    setLoading(true);
    setApiparms({ ...apiparms, PageSize: pageSize, PageNum: 1,
        PageToken: ' '});
    },
    [apiparms],
  );

  const handlePageChange = useCallback((page) => {
    setLoading(true);
    setPageToken(' ');
    setApiparms({ ...apiparms, PageToken: pageToken, PageNum: page + 1 });
    }, [apiparms, pageToken]);

  const showError = useCallback(({message}) => {
    if (handleError && typeof handleError === 'function'){
      handleError({message: message});
    }
  }, [handleError]);

  useEffect(() => {
    if (!srvcreqs?.data || !deviceId) return;

    setLoading(false);
    if (!srvcreqs.success) {
      showError({message: (srvcreqs.data?.Message || srvcreqs.data?.message) || 'Something failed'});
      return;
    }

    if (srvcreqs.success && !srvcreqs.data){
      return;
    }

    if (srvcreqs?.data?.error){
      showError({message: `${srvcreqs.data.error.message} for bridge parameters: ${JSON.stringify(apiparms)}` })
      return;
    }  

    // setPageToken(srvcreqs.data.metadata?.nextPageToken || srvcreqs.data.NextPage || initLoadParams.PageToken);

    let data = srvcreqs.data;

    setGridData(data.records.map((row, indx) => { return { ...row, id: indx }; }) );
    // eslint-disable-next-line
  }, [srvcreqs, apiparms]);

  return (
    <ServiceRequestGrid 
      griddata={griddata}
      loading={loading}
      totalCount={srvcreqs?.data?.metadata?.count || srvcreqs?.data?.Total || 0}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      embedStyle={embedStyle}
    />
  );
};

