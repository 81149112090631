import React from "react";
import { Box, Link, makeStyles, Typography } from "@material-ui/core";
import { Grid, Tooltip } from "@mui/material";

const DISCLAIM = "The Dataprise Client Management System is provided to you subject to your support services Agreement";

const siteLinks = {
    terms: {link: "https://www.dataprise.com/legal", title: "Terms Of Service"},
    privacy: {link: "https://www.dataprise.com/privacy-policy/", title: "Privacy"},
    legal: {link: "https://www.dataprise.com/legal/", title: "Legal", tooltip: DISCLAIM},
    // sitemap: {link: "http://www.dataprise.com/dataprisesitemap", title: "Sitemap"},
    contact: {link: "http://www.dataprise.com/contactus", title: "Contact"},
    copyright: {link: "http://www.dataprise.com", title: "Copyright © 2023, Dataprise"},
}


const useStyles = makeStyles((theme) => ({
    bottom: {
        bottom: 0,
        backgroundColor: "transparent",
        position: "fixed",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "center",
        color: "window",
        paddingBottom: '.4rem',
    },
    disclaim: {
        fontSize: '.67rem',
        display: "flex",
        justifyContent: "center"
        // '& .MuiTypography-colorPrimary': {
        //     color: "#3f51b5",
        // }
    },
    "bottom-main-div": {
        color: "window",
        margin: '1rem 0 1rem 4.8rem', 
        display: "flex",
        flex: "1 1 auto",
        "& .stretched-box": {
            flexGrow: 1,
            alignContent: "center",
            flexWrap: "wrap",
            display: "flex",
            justifyContent: "center",
        },
        "& .bottom-inner-div": {
            fontSize: "0.65rem",
            alignContent: "center",
            display: "flex",
            flexWrap: "wrap",
            whiteSpace: "nowrap",
        },
    },
}))

export const BottomInfo_old = () => {
    const styles = useStyles();

    return (
        <Typography component={`div`} className={styles.bottom}>
            <Typography component={`div`} className={styles.disclaim}>
                {`${DISCLAIM}`}
                <span style={{ paddingRight: 5 }} />
                <SiteLink detail={siteLinks.terms} />
            </Typography>
            <Typography component={`div`} className={styles.disclaim}>
                <SiteLink detail={siteLinks.privacy} /><span style={{ paddingRight: 20 }} />
                <SiteLink detail={siteLinks.legal} /><span style={{ paddingRight: 20 }} />
                {/* <SiteLink detail={siteLinks.sitemap}/><span style={{paddingRight: 20}}/> */}
                <SiteLink detail={siteLinks.contact} />
            </Typography>
            <Typography component={`div`} className={styles.disclaim}>
                <SiteLink detail={siteLinks.copyright} />
            </Typography>            
        </Typography>
    )
}

export const BottomInfo = () => {
    const stls = useStyles();

    return (
        <Typography component={`div`} className={stls["bottom-main-div"]}>
            <Box className="stretched-box">
                <Typography component={`div`} className="bottom-inner-div">
                    <Grid container spacing={3} direction="row">
                        <Grid item xs={6} style={{ wordWrap: "nowrap" }}>
                            <SiteLink detail={siteLinks.copyright} />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <SiteLink detail={siteLinks.privacy} />
                                </Grid>
                                <Grid item xs={4}>
                                    <SiteLink detail={siteLinks.legal} />
                                </Grid>
                                <Grid item xs={4}>
                                    <SiteLink detail={siteLinks.contact} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Typography>
            </Box>
        </Typography>
    )
}


const SiteLink = (prop) => {
    const {detail} = prop
    return (
        <Tooltip title={detail.tooltip || ''}>
            <Link href={detail.link} target="_blank" rel="noopener noreferrer" style={{color: "#61dafb"}}>
                <span className="lint-text-class">{detail.title}</span>
            </Link>
        </Tooltip>
    )
}

