import axios from 'axios';
import { APP_API_URL, CMSAPIKEY, headers, 
  INVITE_WEBAPI_POST, UNINVITE_WEBAPI_POST,
  DISABLE_CONTACT_WEBAPI_DELETE, APPROVE_CONTACT_WEBAPI_AUTH, APPROVE_CONTACT_WEBAPI_NOAUTH,
  NEW_CONTACT_STATUS_CHECK
} from '../controls/dapp.constants';
import { fetchBridgedResource } from '@kineticdata/react';

export const getContactList = props => {
  const { user, apiParams } = props;

  const check = axios.create({
    cancelToken: axios.CancelToken.source().token,
    headers: {
      'X-User': JSON.stringify(user),
      CmsApiKey: CMSAPIKEY,
    },
  });

  const apiCall = async () => await check.get(
      `${APP_API_URL}/contact`, {params: { ...apiParams }});

  return apiCall();
};

export const getContactsFromBridge = async (props) => {
    const { usr, apiparms} = props;

    if (!usr || !apiparms) return;

    const bridgeCofig = {
      kappSlug: 'customer-portal',
      formSlug: 'shared-resources',
      bridgedResourceName: 'All Contacts',
      values: {
        'X-User': JSON.stringify(usr),
        GetTotal: apiparms.GetTotal, 
        PageToken: apiparms.PageToken || '',
        PageSize: apiparms.PageSize,
        PageNum: apiparms.PageNum,
        OrderBy: apiparms.OrderBy,
      },
    };

    const data = await fetchBridgedResource(bridgeCofig);
    console.log(`right after api call`, data);
    return data;
}

export const sendInvites = props => {
  const { user, data } = props;
  return InvitesWebAPICall({
    user: user, data: data, url: INVITE_WEBAPI_POST
  });
};

export const deleteInvites = props => {
  const { user, data } = props;
  return InvitesWebAPICall({
    user: user, data: data, url: UNINVITE_WEBAPI_POST
  });
};

export const disableContact = async (props) => {
  const {user, data, timeout} = props;
  return KineticWebAPICall({
    user: user, data: data, url: DISABLE_CONTACT_WEBAPI_DELETE, timeout: timeout
  });
}

export const approveContact = props => {
  const {user, data, timeout} = props;
  return KineticWebAPICall({
    user: user, data: data, url: APPROVE_CONTACT_WEBAPI_AUTH, timeout: timeout
  });
}

export const approveContactNoAuth = props => {
  const {data} = props;
  return KineticWebAPICall({
    data: data, url: APPROVE_CONTACT_WEBAPI_NOAUTH
  });
}

export const checkNewContactSaveStatus = props => {
  const {user, data, timeout} = props;
  return KineticWebAPICall({
    user: user, data: data, url: NEW_CONTACT_STATUS_CHECK, timeout: timeout
  });
}

const InvitesWebAPICall = (props) => {
  const { user, data, url } = props;

  const apiCall = async () => await axios.post(url, data, {
    cancelToken: axios.CancelToken.source().token,
    headers: { ...headers,
      'X-User': JSON.stringify(user),
      'CmsApiKey': CMSAPIKEY,
    },
    crossDomain: true,
  });

  return apiCall();
} 

export const KineticWebAPICall = (props) => {
  const { user, data, url, timeout } = props;

  let urlMod = (timeout && `${url}?timeout=${timeout}`) || url; 

  const apiCall = async () => await axios.post(urlMod, data, {
    cancelToken: axios.CancelToken.source().token,
    headers: { ...headers,
      'X-User': JSON.stringify(user),
      'CmsApiKey': CMSAPIKEY,
    },
    crossDomain: true,
  });

  return apiCall();
} 

export const BeaconCall = (props) => {
  const { user, data, url } = props;

  const blb = new Blob([JSON.stringify(data)], { ...headers,
      'X-User': JSON.stringify(user),
      'CmsApiKey': CMSAPIKEY,
    },
  );

  navigator.sendBeacon(url, blb);
} 


export const cancelApiCall = () => {
  axios.CancelToken.source().cancel();
}

export const requestStatusUpdate = props => {
  const {user, data, url} = props;
  return KineticWebAPICall({
    user: user, data: data, url: url
  });
}

export const checkAnyFormSubmitStatus = props => {
  const {user, url, data, timeout} = props;
  return KineticWebAPICall({
    user: user, data: data, url: url, timeout: timeout
  });
}
