import { Grid } from "@material-ui/core";
import React, { useReducer } from "react";
import { Fragment } from "react";
import { CustomCardLeft, CustomCardTop } from "./home.card";
import { DappChatIcon, DappChatIconWhite, ManageServiceIcon, ManageServiceIconWhite, NewRequestIcon, NewRequestIconWhite, NewTicketIcon, NewTicketIconWhite, RemoteAccessIcon, RemoteAccessIconWhite, SinglePaneIcon, SinglePaneIconWhite } from "../../images/dapp.icons";
import { useHomeStyles } from "./home.styles";
import { useCardStyles } from "../../controls/shared.components";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDappContext } from "../../auth/dapp.context";
import clsx from "clsx";

export const HomeCards = (props) => {
    const { newIncident, newRequest, openRemote, openChat } = props;
    const [iconFlag, dispatch] = useReducer(iconFlagReducer, false);
    const homeClasses = useHomeStyles();
    const cls = useCardStyles()
    const history = useHistory();
    const dappCntx = useDappContext();

    const onShowWhite = (id, flag) => {
        dispatch({type: id, flag});
    }
  
    return (
      <Fragment>
        <Grid item xs={4} sm={4} md={4}>
          <CustomCardTop
            id="new-incident"
            title="Submit Incident"
            description="Open Incidents"
            className={cls['card-action-top']}            
            onClick={newIncident}
            onShowWhite={onShowWhite}
            icon={(iconFlag.incFlag && <NewTicketIconWhite className={homeClasses.dappicon} />) || <NewTicketIcon className={homeClasses.dappicon} />}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <CustomCardTop
            id="new-request"
            title="Submit Service Request"
            description="Open Incidents"
            className={cls['card-action-top']}
            onClick={newRequest}
            onShowWhite={onShowWhite}
            icon={(iconFlag.reqFlag && <NewRequestIconWhite className={homeClasses.dappicon} />) || <NewRequestIcon className={homeClasses.dappicon} />}
          />
        </Grid>
        {(!dappCntx?.showChat && 
        <Grid item xs={4} sm={4} md={4}>
          {(!dappCntx?.contactProfile?.IsContactCenterChatEnabled && (
            <CustomCardTop
              id="chat"
              title="Chat with Us"
              description="Start Chat"
              className={cls['card-action-top']}
              onShowWhite={onShowWhite}
              onClick={openChat}
              icon={(iconFlag.chatFlag && <DappChatIconWhite className={homeClasses.dappicon} />) || <DappChatIcon className={homeClasses.dappicon} />}
            />
          )) || (
              <CustomCardTop
                id="chat"
                title="Techy"
                description="Start Chat"
                className={clsx(cls['card-action-top'])}
                onShowWhite={onShowWhite}
                onClick={openChat}
                icon={(iconFlag.chatFlag && <DappChatIconWhite className={homeClasses.dappicon} />) || <DappChatIcon className={homeClasses.dappicon} />}
              />
            )}
        </Grid>) 
        || <Fragment/>}
        {dappCntx?.user?.user?.['UserType'] === 'Administrator' && 
        <Grid item xs={4} sm={4} md={4}>
          {(!dappCntx?.showChat &&
          <CustomCardLeft
            id="jobs"
            title="View Jobs"
            description="Explore Jobs"
            className={clsx(cls['card-action-top'], cls['card-action-left'])}
            onCardClick={() => history.push('/jobs')}
            onShowWhite={onShowWhite}
            icon={(iconFlag.jobFlag && <ManageServiceIconWhite className={homeClasses.dappicon} />) || <ManageServiceIcon className={homeClasses.dappicon} />}
          />) ||   
          <CustomCardTop
            id="jobs"
            title="View Jobs"
            description="Explore Jobs"
            className={clsx(cls['card-action-top'])}
            onCardClick={() => history.push('/jobs')}
            onShowWhite={onShowWhite}
            icon={(iconFlag.jobFlag && <ManageServiceIconWhite className={homeClasses.dappicon} />) || <ManageServiceIcon className={homeClasses.dappicon} />}
          />}          
        </Grid>}
        <Grid item xs={4} sm={4} md={4}>
        {((dappCntx?.user?.user?.['UserType'] === 'Administrator' || !dappCntx?.showChat) &&
          <CustomCardLeft
            id="remote-control"
            title="Live Remote Control"
            description="Remote Control"
            className={clsx(cls['card-action-top'], cls['card-action-left'])}
            onClick={openRemote}
            onShowWhite={onShowWhite}
            icon={(iconFlag.remFlag && <RemoteAccessIconWhite className={homeClasses.dappicon} />) || <RemoteAccessIcon className={homeClasses.dappicon} />}
          />) ||
          <CustomCardTop
            id="remote-control"
            title="Live Remote Control"
            description="Remote Control"
            className={clsx(cls['card-action-top'])}
            onClick={openRemote}
            onShowWhite={onShowWhite}
            icon={(iconFlag.remFlag && <RemoteAccessIconWhite className={homeClasses.dappicon} />) || <RemoteAccessIcon className={homeClasses.dappicon} />}
          />}
        </Grid>
        {dappCntx?.user?.user?.['UserType'] === 'Administrator' && 
        <Grid item xs={4} sm={4} md={4}>
          <CustomCardLeft
            id="report"
            title="Single Pane of Glass Report"
            description="View Report"
            className={clsx(cls['card-action-top'], cls['card-action-left'])}
            onCardClick={() => history.push("/reports/5cd25672-8481-4423-8d43-1adda13e07da")}
            onShowWhite={onShowWhite}
            icon={(iconFlag.rptFlag && <SinglePaneIconWhite className={homeClasses.dappicon} />) || <SinglePaneIcon className={homeClasses.dappicon} />}
          />
        </Grid>}
      </Fragment>
    )
}


const iconFlagReducer = (state, action) => {
    switch (action.type) {
      case "new-incident": {
        return {incFlag: action.flag}; 
      }
      case "new-request": {
        return {reqFlag: action.flag}; 
      }
      case "remote-control": {
        return {remFlag: action.flag}; 
      }
      case "chat": {
        return {chatFlag: action.flag};
      }
      case "jobs": {
        return {jobFlag: action.flag};
      }
      case "report": {
        return {rptFlag: action.flag};
      }
      case "customer-rep": {
        return {cstFlag: action.flag}
      }
      case "it-consultant": {
        return {itcFlag: action.flag}
      }
      case "tech-manager": {
        return {techFlag: action.flag}
      }
      default:
        return false;
    }
  }