import React, { Fragment, useState, useCallback, useEffect } from "react";
import { Typography, Paper } from "@material-ui/core";
import { FormLayout, Pending, useFormStyle, useWrappereStyles } from "../../controls/shared.components";
import { BottomInfo } from "../bottom/bottom.info";
import { CustomAlert } from "../../alerts/alert.snackbar";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CoreForm } from "@kineticdata/react";
// import { LoadCanvasTemplateNoReload, loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha';

export const PasswordRetrieve = ({type}) => {
  const [formvalue, setFormValue] = useState();
  // const [userCaptcha, setUserCaptcha] = useState('');
    const wrapCls = useWrappereStyles();
    const history = useHistory();
    const [showError, setShowError] = useState({
        open: false, message: "",
        severity: "success", 
        onSnackbarClose: () => {}
      });

    const frmCls = useFormStyle();  

    const {gid} = useParams();
    const {search} = useLocation();

    const handleSaveComplete = () => {
      // if (!validateCaptcha(userCaptcha, true)){
      //   setShowError({...showError, open: false, severity: "", message: "kslksdjf"});
      //   setShowError({...showError, open: true, severity: "error", message: "Captcha mismatch"});
      //   return;
      // }
      history.replace("/");
    }
    const handleError = useCallback((parms) => {
      setShowError({...showError, open: true, severity: "error", message: parms.toString(),
      onSnackbarClose: () => setShowError({...showError, open: false, message: "random"})
    });
    }, [showError]);

    useEffect(() => {
      // loadCaptchaEnginge(5);
      (gid && setFormValue(gid)) ||
      (search && search.split("?")[1].split("&").map((value, index) => 
        (value.split("=")[0] === "VGID") && setFormValue(value.split("=")[1])
      ));
    }, [gid, search]);

    const handleUpdated = () => {
      console.log(`this is submit call`);
    };

    // const handleChnage = useCallback((ev) => {
    //   setUserCaptcha(ev.target.value);
    // });
  
    return (
        <Fragment>
            <Typography component={`div`} 
              className={frmCls.root}
              style={{
                minWidth: "50%", minHeight: "50%", margin: "auto", justifyContent: "center", alignItems: "center"
              }}>
              <Paper name="core-form-container" 
              style={{
                minWidth: 350,
                maxWidth:450,
                minHeight: 380,
                backgroundColor: "window",
                borderRadius: 15,
                display: "flex",
                flexFlow: "column",
              }}>
                <CoreForm
                  kapp="customer-portal"
                  form={(type === "retrieve" && "lost-password") || "renew-password"}
                  public={true}
                  onError={handleError}
                  onCompleted={handleSaveComplete}
                  onUpdated={handleUpdated}
                  values={(formvalue && { VGID: `${formvalue}` }) || null}
                  components={{
                    Pending: Pending,
                    Layout: FormLayout,
                  }}
                >                                                                          
                </CoreForm>
            {/* <Typography component={`div`} style={{display: "flex"}}>
              <LoadCanvasTemplateNoReload />
              <TextField
                variant="outlined"
                id="user-captcha"
                label="Enter Captcha Value"
                type="text"
                size="small"
                onChange={handleChnage}
                InputProps={{
                  shrink: "true",
                }}
              />
            </Typography> */}
              </Paper>
              </Typography>
              <Typography component={`div`} className={wrapCls.bottom}>
                <BottomInfo/>
              </Typography>
              <CustomAlert {...showError}/>
        </Fragment>        
    );
}