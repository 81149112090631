import React, { Fragment, useState, useCallback } from "react";
import { 
    CircularProgress, debounce, Grid, List, ListItem, ListItemAvatar, ListItemText, 
    makeStyles, Popover, TablePagination, TextField, Tooltip, Typography, useTheme, 
} from "@material-ui/core";
import { useDappContext } from "../../auth/dapp.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import { useDataFromBridge } from "../../controls/custom.hooks";
import { useSearchContext } from "./search.provider";
import { rowsperpage } from "../../controls/shared.components";
import { AddressesIcon, ManageServiceIcon, NewRequestIcon, NewTicketIcon } from "../../images/dapp.icons";
import { useHistory } from "react-router-dom";

export const GlobalSearch = (props) => {
    const { width, handleOpenSearchClick, onSelected, ...other} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [showResults, setShowResults] = useState(false);
    const dappCntx = useDappContext();
    const [searchParams, setSearchParams] = useState({});

    const triggerSearch = useCallback((value) => {
        setShowResults(true);
        setSearchParams({currentCid: dappCntx.user?.user?.CID, SearchValue: value});
      }, [dappCntx])
  
    const handleSearch = debounce((value) => {
        if (value.trim().length < 3) return;
        triggerSearch(value);
      }, 1200);

    const closeResults = () => {
        setShowResults(false);
    };
    
    const handleSelected = () => {
        closeResults();
        if (onSelected && typeof onSelected === "function"){
            onSelected();
        }
    }

    const handleError = useCallback((message) => {
        console.log(`we're getting error: ${JSON.stringify(message)}`);
    }, []);
  
    return (
        <Typography component={`div`} {...other}>
            <SearchControl 
                placeholder={'TYPE HERE'} 
                onInputChange={(e) => {
                    handleSearch(e.target.value);
                }}
                onFocus={(e) => setAnchorEl(e.target)}
                handleOpenSearchClick={handleOpenSearchClick}
            />
            <SearchResults 
                open={showResults} 
                anchorEl={anchorEl} 
                handleClose={closeResults} 
                searchParams={searchParams}
                handleError={handleError}
                onSelected={handleSelected}
            />
      </Typography>
    )
}

const useSrchListStyles = makeStyles((theme) => ({
    root: {
        width: "520px",
        height: "320px",
        overflowY: "auto",
        padding: "0.4rem",
        '& .MuiTypography-body1': {
            fontSize: '0.7rem !important',
        },
        '& .MuiTypography-body2': {
            fontSize: '0.6rem !important',
        },
        '& .search-overlay': {
            height: "inherit",
            display: "flex",
            flexFlow: "wrap",
            alignContent: "center",
            justifyContent: "center",
        },
    }
}));

const SearchResults = (props) => {
    const {open, anchorEl, handleClose, searchParams, handleError, onSelected, ...other} = props;
    const srchCntx = useSearchContext();
    const stls = useSrchListStyles();
    const theme = useTheme();

    return (
        <Fragment>
            <Popover id='search-results' {...other} 
                open={open}
                onClose={handleClose}
                style={{zIndex: theme.zIndex.drawer + 120}}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
            >
                <Typography component={`div`} className={stls.root}>
                    <ResultList 
                        searchParams={searchParams} 
                        handleError={handleError} 
                        onSelected={onSelected} 
                    />
                </Typography>
                <Typography component={`div`} style={{minHeight: 30}}>
                    <TablePagination component={`div`}
                        count={srchCntx?.srchResult?.length || 0} 
                        page={srchCntx?.page || 0}
                        rowsPerPageOptions={rowsperpage}
                        rowsPerPage={srchCntx?.pageSize || rowsperpage[0]}
                        onPageChange={srchCntx?.handlePageChange}
                        onRowsPerPageChange={srchCntx?.handleRowsPerPageChange}
                    />
                </Typography>
            </Popover>
        </Fragment>
    )
}

const ResultList = (props) => {
    const {searchParams, handleError, onSelected} = props;
    const [loading, setLoading] = useState(false);
    const [xuser, setXuser] = useState();

    const dappCntx = useDappContext();
    const srchCntx = useSearchContext();
    const history = useHistory();

    const searchTypes = ['Contact', 'Incident', 'ServiceRequest', 'Job'];
    const icons = [<AddressesIcon/>, <NewTicketIcon/>, <NewRequestIcon/>, <ManageServiceIcon/>]
    const navigates = ["contacts", "incident", "servicerequest", "jobs"]
    const activeStatuses = ["Active", "Open"];

    useEffect(() => {
        if (!dappCntx?.user?.user) return;
        setXuser(dappCntx?.user?.user);
    }, [dappCntx]);

    const onDataLoad = useCallback(() => {
        srchCntx.setSrchResult([]);
        srchCntx.setPage(0);
        setLoading(true);
        // eslint-disable-next-line
    }, []);

    const srchResults = useDataFromBridge({
        xUser: xuser, apiValues: searchParams,
        bridgedResourceName: 'GlobalSearch',
        callback: onDataLoad
    });

    useEffect(() => {
        if (!srchResults) return;

        setLoading(false);
        if (!srchResults.success){
          handleError({message: srchResults?.data?.Message || srchResults?.data?.message});
          return;
        }
    
        let data = srchResults.data?.records?.map((row, indx) => { 
            return {...row, id: indx}
        });

        srchCntx.setSrchResult(data);
        srchCntx.setPagedResult(data?.slice(srchCntx.page * srchCntx.pageSize, srchCntx.pageSize));
        // eslint-disable-next-line    
    }, [srchResults, handleError]);

    const handleSelected = useCallback((record) => {
        let view = (record.SearchType < 3 && `/view/${record.InternalID}`) || '';
        history.push(`/${navigates[record.SearchType]}${view}`);
        if (onSelected && typeof onSelected === 'function'){
            onSelected();
        }
    }, [history, navigates, onSelected]);

    return (
        <Fragment>
            {(loading && <Typography component={`div`} className="search-overlay"><CircularProgress color="primary"/></Typography>) || 
            (srchCntx?.pagedResult?.length > 0 &&
            <List dense>
                {srchCntx?.pagedResult?.map((record, indx) => {
                    return (
                        <ListItem button selected={false} onClick={(e) => handleSelected(record)} key={record?.id}>
                            <Tooltip title={`${record?.Status}`}>
                                <ListItemAvatar>
                                    <Typography component={`div`}>
                                    {icons[record?.SearchType]}
                                    {(activeStatuses.includes(record?.Status) &&
                                        <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />) ||
                                        <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
                                    }
                                    </Typography>
                                </ListItemAvatar>
                            </Tooltip>
                            <ListItemText
                                primary={
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Typography component={`div`}>
                                                {record?.StringField || `${record?.LastName}, ${record?.FirstName}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography component={`div`}>
                                                {(parseInt(record?.SearchType) === 0 && `${record?.OrganizationName}`) || `${record?.LastName}, ${record?.FirstName}`}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                                secondary={
                                    <Fragment>
                                        <Grid container spacing={1} component={`span`}>
                                            <Grid item xs={6} component={`span`}>
                                                <Typography component={`span`}>
                                                    {searchTypes[record?.SearchType]}
                                                    {record?.DeviceName && ` - ${record?.DeviceName}`}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} component={`span`}>
                                                <Typography component={`span`}>
                                                    {record?.Department}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Fragment>
                                }
                            />
                        </ListItem>
                    )
                })}
            </List>) || <Typography component={'div'} className="search-overlay">No Data</Typography>}
        </Fragment>
    );
}

const SearchControl = (props) => {
    const {placeholder, onInputChange, onFocus, handleOpenSearchClick, ...other} = props;

    return (
        <Autocomplete {...other}
            id="look-ahead-search"
            fullWidth
            size="small"
            clearOnBlur={false}
            clearOnEscape={true}
            disableClearable
            options={[]}
            freeSolo 
            placeholder={placeholder}
            onInputChange={onInputChange}
            onFocus={onFocus}            
            style={{ color: "white" }}
            renderInput={(params) => (
                <TextField {...params}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder={placeholder}
                    autoComplete="off"
                    style={{
                        minWidth: "80px",
                        backgroundColor: "transparent",
                        border: "1px solid #fff",
                        borderRadius: "15px",
                        color: "white",
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <FontAwesomeIcon
                                icon={faSearch}
                                onClick={handleOpenSearchClick}
                                style={{ opacity: "0.8", padding: "0 5px 0 5px", color: '#fff', fontSize: "1rem", cursor: "pointer" }}
                            />),
                        autoComplete: "off", autoCorrect: "off",
                        style: { borderRadius: "15px", color: "window", borderColor: "window", fontSize: "0.7rem" },
                        type: "search",
                    }}
                />
            )}
        />
    )
}

