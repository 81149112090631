import React, { Fragment, useState, useCallback } from "react";
import { Typography, Paper } from "@material-ui/core";
import { FormLayout, Pending, useFormStyle, useWrappereStyles } from "../../controls/shared.components";
import { BottomInfo } from "../bottom/bottom.info";
import { CustomAlert } from "../../alerts/alert.snackbar";
import { useHistory } from "react-router-dom";
import { CoreForm } from "@kineticdata/react";

export const EmailPreferences = () => {
    const wrapCls = useWrappereStyles();
    const history = useHistory();
    const [showError, setShowError] = useState({
        open: false, message: "",
        severity: "success", 
        onSnackbarClose: () => {}
      });

    const frmCls = useFormStyle();  
    
    const handleSaveComplete = () => history.replace("/");
    const handleError = useCallback((parms) => {
      setShowError({...showError, open: true, severity: "error", message: parms.toString(),
      onSnackbarClose: () => setShowError({...showError, open: false, message: "random"})
    });
    }, [showError]);
  
    return (
        <Fragment>
            <Typography component={`div`} 
              className={frmCls.root}
              style={{
                minWidth: "50%", minHeight: "50%", margin: "auto", justifyContent: "center", alignItems: "center"
              }}>
              <Paper name="core-form-container" 
              style={{
                minWidth: 350,
                minHeight: 380,
                backgroundColor: "window",
                borderRadius: 15,
                display: "flex",
                flexFlow: "column",
              }}>
                <CoreForm
                  kapp="customer-portal"
                  form="customer-care-email-preferences"
                  public={true}
                  onError={handleError}
                  onCompleted={handleSaveComplete}
                  // values={values}
                  components={{
                    Pending: Pending,
                    Layout: FormLayout,
                  }}
                />
              </Paper>
              </Typography>
              <Typography component={`div`} className={wrapCls.bottom}>
                <BottomInfo/>
              </Typography>
              <CustomAlert {...showError}/>
        </Fragment>        
    );
}
