import React, { useState, createContext, useCallback } from "react";
import { SplitPane, SplitDivider, SplitPanelTop, SplitPanelLeft } from "../../controls/splitpane/split.pane";
import { Fab, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { useSplitStyle } from "../../controls/shared.components";
import { DeviceList } from "./device.list";
import { DeviceViewForm } from "./device.view";
import { ServiceRequestByDevice } from "../services requests/service.request.main";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { useDeviceContext } from "./devices.main";

export const DeviceSplitView = (props) => {
    const {onError} = props;
    const [selectedDevice, setSelectedDevice] = useState();
    const cls = useSplitStyle();
    const fabCls = useFabStyles();    
    const deviceCntx = useDeviceContext();

    const callAddRequest = useCallback(() => {
        if (!deviceCntx) return;
        if (deviceCntx.NewRequest && typeof deviceCntx.NewRequest === 'function'){
            deviceCntx.NewRequest();
        }
    }, [deviceCntx]);

    return (
        <SelectedDeviceContext.Provider
          value={{
            selectedDevice,
            setSelectedDevice,
          }}
        >
          <SplitPane
            key="main-split-pane"
            name="main-split-pane"
            className={cls.mainPanel}
          >
            <SplitPanelLeft className={cls.leftSplitPanel}>
              <DeviceList onError={onError} />
            </SplitPanelLeft>
            <SplitDivider className={cls.verticalDivider} />
            <Typography component={`div`} className={cls.rightSplitPanel}>
              <SplitPane className={cls.topSplitPanel}>
                <SplitPanelTop style={{ display: "flex", height: "50%" }}>
                  <DeviceViewForm />
                </SplitPanelTop>
                <SplitDivider className={cls.horizontalDivider} />
                <Typography component={`div`} 
                  className={cls.bottomSplitPanel}
                  // style={{border: "2px solid red", width: "inherit", height: "inherit", display: "flex"}}
                >
                  <ServiceRequestByDevice
                    handleError={onError}
                    deviceId={selectedDevice && selectedDevice['Device ID']}
                    embedStyle={true}
                  />
                  <Tooltip title="New Service Request">
                    <Fab className={fabCls.fab} color="primary" onClick={callAddRequest}>
                      <FontAwesomeIcon icon={faAdd} />
                    </Fab>
                  </Tooltip>
                </Typography>
              </SplitPane>
            </Typography>
          </SplitPane>
        </SelectedDeviceContext.Provider>
    );
};

export const SelectedDeviceContext = createContext();

const useFabStyles = makeStyles((theme) => ({
    fab: {
      position: 'absolute',
      bottom: theme.spacing(14),
      right: theme.spacing(6),
      zIndex: theme.zIndex.drawer + 100,
    },
  }));
