import React from "react";
import { TextField } from "@mui/material";
import { useDialogStyles } from "../../modals/dialog.style";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const DropDownField = (props) => {
    const {id, label, onChange, children, ...other} = props;
    const stls = useDialogStyles()

    return (
        <TextField {...other}
            fullWidth
            variant="outlined" 
            label={label}
            id={id}
            name={id}
            onChange={onChange}
            size="small"
            className={stls.dropdown}
            margin="dense"
            select
            SelectProps={{
                MenuProps: {
                    PaperProps: {
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                        },                        
                    },
                    className: stls.dropdownItem,
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "center",                        
                    }
                },
            }}
        >
            {children}
        </TextField>
    )
}
