import React, { Fragment, useCallback, useEffect, useState } from "react";
import { WrapperPaper } from "../../controls/shared.components";
import { IncidentsList } from "./incidents.list";
import { useGridStyles } from "../../controls/datagrid.striped";
import { IncidentToolbar } from "./incidents.toolbar";
import { useHistory, useLocation } from "react-router-dom";
import { ModalSelection } from "../modals/modal.selection";
import { useDappContext } from "../../auth/dapp.context";
import { createRef } from "react";

export const Incidents = ({contactOnly}) => {
    const [openModal, setOpenModal] = useState(false);
    const [ticketType, setTicketType] = useState();
    const [showPrimary, setShowPrimary] = useState(false);
    const [refresh, setRefresh] = useState();
    const [incidentStatus, setIncidentStatus] = useState();
    const [dispositionValue, setDispositionValue] = useState();
    const [searchValue, setSearchValue] = useState();
    const [exportData, setExportData] = useState(false);
    const cls = useGridStyles();
    const history = useHistory();
    const location = useLocation();
    const dappCntx = useDappContext();
    const ref = createRef();

    const handleAdd = () => {
        setTicketType("incident");
        setShowPrimary(false);
        setOpenModal(true);
    }

    useEffect(() => {
        if (!dappCntx) return;
        dappCntx.dappSubtitle("Incidents");
    }, [dappCntx])

    const handleModalClose = useCallback((props) => {
        const {ok, selection} = props;
        setOpenModal(false);
        if (!ok) return;
        let type = selection.type;
        if (type !== 'primaryContact') {
          let field = `${type.charAt(0).toUpperCase()}${type.slice(1)} ID`;
          let id = selection.record[`${field}`];
          history.push(`/${ticketType}/add/${id}?type=${type}`, {prevPath: location.pathname});
        } else {
          let field = `${type.charAt(0).toUpperCase()}${type.slice(1)}ID`;
          let id = selection.record[`${field}`];
          history.push(`/${ticketType || 'servicerequest'}/add/${id}?type=${type}`, {prevPath: location.pathname});
        }
    }, [history, ticketType, location]);

    const handleRefresh = () => {
        setRefresh(true);        
    }

    const finishRefresh = () => setRefresh(false);

    const handleStatusChange = ({status}) => setIncidentStatus(status);
    const handleDispositionChange = ({disposition}) => {
        setDispositionValue(disposition);
    }

    const handleSearch = useCallback((value) => {
        console.log(`we'll be searching for: `, value);
        setSearchValue(value);
    }, []);

    const handleExport = useCallback(() => {
        setExportData(true);
    }, []);

    const handleExportComplete = useCallback(() => {
        setExportData(false);
    }, []);

    return (
        <Fragment>
                <IncidentToolbar onAdd={handleAdd} onRefresh={handleRefresh} 
                    handleStatusChange={handleStatusChange} 
                    handleDispositionChange={handleDispositionChange}
                    handleSearch={handleSearch}
                    handleExport={handleExport}
                    ticketType="Incident"
                />
                <WrapperPaper>
                    <IncidentsList classname={cls.datagrid} 
                        ref={ref}
                        onFinishRefresh={finishRefresh}
                        refresh={refresh} 
                        status={incidentStatus} 
                        disposition={dispositionValue}
                        contactOnly={contactOnly} 
                        searchValue={searchValue}
                        exportData={exportData}
                        onExportComplete={handleExportComplete}
                    />
                </WrapperPaper>
                <ModalSelection open={openModal} onCloseDialog={handleModalClose} showPrimary={showPrimary}/>
        </Fragment>        
    );
}