export const headers = {'Content-Type': 'application/json'};
export const REPORT_API_URL = process.env.REACT_APP_REPORT_API_URL;
export const APP_API_URL = process.env.REACT_APP_API_BASE_URL;
export const CMSAPIKEY = process.env.REACT_APP_CMS_API_KEY;
export const EMBEDURL = process.env.REACT_APP_POWERBI_EMBED_URL;
export const POWERBITOKEN = process.env.REACT_APP_POWERBI_TOKEN;
export const TECHYLINK = process.env.REACT_APP_TECHY_LINK;
export const REMOTECONTROL = process.env.REACT_APP_REMOTE_LINK;
export const CHATLINK = process.env.REACT_APP_CHAT_LINK;
export const LIVEPOWERBI = process.env.REACT_APP_POWERBI_LIVE;
export const LINKTIMEOUT = process.env.REACT_APP_POWERBI_LINK_TIMEOUT;
export const KappBridge = {
    kappSlug: 'customer-portal', 
    formSlug: 'shared-resources'
};

export const INVITE_WEBAPI_POST = "/app/kapps/customer-portal/webApis/send-invite";
export const UNINVITE_WEBAPI_POST = "/app/kapps/customer-portal/webApis/delete-invite";
export const DISABLE_CONTACT_WEBAPI_DELETE = "/app/kapps/customer-portal/webApis/delete-contact";
export const APPROVE_CONTACT_WEBAPI_AUTH = "/app/kapps/customer-portal/webApis/contact-approve-authenticated";
export const APPROVE_CONTACT_WEBAPI_NOAUTH = "/app/kapps/customer-portal/webApis/contact-approve-nonauth";
export const REQUEST_STATUS_UPDATE_WEBAPI_INC = "/app/kapps/customer-portal/webApis/request-status-update-inc";
export const REQUEST_STATUS_UPDATE_WEBAPI_REQ = "/app/kapps/customer-portal/webApis/request-status-update-req";
export const INCIDENT_FILTER_WEBAPI_SAVE = "/app/kapps/customer-portal/webApis/incident-filter-save";
export const CHAT_END_CHAT_BY_ID = "/app/kapps/customer-portal/webApis/chat-end-by-chatid";
export const NEW_CONTACT_STATUS_CHECK = "/app/kapps/customer-portal/webApis/new-contact-activity";

export const INCIDENTROUTE = "/incident/edit/";
export const SRVCREQROUTE = "/servicerequest/edit/";
export const CONTACTROUTE = "/contacts/edit/";
export const JOBROUTE = "/jobs/edit/";


export const TestAuth = {
    user: {
        "CCWebUserID": 3346,
        "ContactID": 237647,
        "UserType": "Administrator",
        "UserName": "kineticadmin1@kinetic.com",
        "FirstName": "Kinetic",
        "LastName": "Admin1",
        "MiddleInitial": "",
        "TimeZoneCode": 35,
        "IsPrimary": false,
        "PrimaryContactID": 104903,
        "CID": "DDX"
    }
}

export const INCONTACTCHATLINK = process.env.REACT_APP_INCONTACT_CHAT_LINK;
export const EXPORTALERTMSG = `Are you certain you wish to export REPLACE-STRING?<br/><span style='font-size: x-small; color: #472453'>(Only the first 2000 records, as sorted, will be exported based on applied filter)</span></br></br>This will take some time, please be patient</br>during export processing.`;
export const EXPORTALERTMSGSHORT = `Are you certain you wish to export REPLACE-STRING?`;
export const ENABLECHAT = process.env.REACT_APP_ENABLE_CHAT;

export const CHATSERVERHOST = process.env.REACT_APP_CHAT_SERVER_HOST;
export const CHATBUSNO = process.env.REACT_APP_CHAT_BUS_NO;
export const CHATPOC = process.env.REACT_APP_CHAT_POC;
export const NICCHATID = process.env.REACT_APP_NIC_CHAT_ID;