import React, { useEffect, useState } from "react";
import { Grid, Link, makeStyles, Paper, Typography } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useDappContext } from "../../auth/dapp.context";
import { useDataFromBridge } from "../../controls/custom.hooks";

const useStatStyles = makeStyles(() => ({
    root: {
        '& .MuiTypography-body1': {
            fontSize: ".8rem",
            lineHeight: "1.3",
        },        
    }
}))

export const ServiceRequestStats = () => {
    const {id} = useParams();
    const [stats, setStats] = useState();
    const [xuser, setXuser] = useState();
    const [apiparams, setApiparams] = useState();
    const dappCntx = useDappContext();
    const history = useHistory();
    const cls = useStatStyles();

    useEffect(() => {
        if (!dappCntx.user) return;
        setXuser(dappCntx.user.user);
        setApiparams({IncidentID: id});
    }, [dappCntx, id]);

    const bridgecall = useDataFromBridge({
        xUser: xuser, apiValues: apiparams,
        bridgedResourceName: "Statistics for Service Requests"
    });

    useEffect(() => {
        if (!bridgecall) return;        
        setStats(bridgecall.data.record);
    }, [bridgecall]);

    return (
        <Paper elevation={3} className={cls.root}>
            <Typography component={`div`} style={{padding:"1rem"}}>
                Your Service Request Statistics
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography component={`div`} style={{textAlign: "end"}}>
                        <Typography component={`span`}>Last Reported Service Request:</Typography>
                    </Typography>
                </Grid>
                <Grid item sx={6}>
                    <Typography component={`div`} style={{textAlign: "center"}}>
                        {stats?.LastReportedServiceRequestNo && 
                        ((dappCntx?.user?.user.UserType !== 'User' &&
                        <Link underline="none" variant="body2" component={`button`}
                        onClick={() => history.push(`/servicerequest/view/${stats?.LastReportedServiceRequestID}`)}>
                            {stats?.LastReportedServiceRequestNo}
                        </Link>) ||
                        <Typography component={`span`} variant="body2">
                            {stats?.LastReportedServiceRequestNo}
                        </Typography>)}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography component={`div`} style={{textAlign: "end"}}>
                        <Typography component={`span`}>
                            Most Commonly Reported Type:
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item sx={6}>
                    <Typography component={`div`} style={{textAlign: "center"}}>
                        {stats?.MostCommonlyReportedRequestType}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography component={`div`} style={{textAlign: "end"}}>
                        <Typography component={`span`}>
                            Qty of Most Commonly Reported Request Type(Over last 12 months)
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item sx={6}>
                    <Typography component={`div`} style={{textAlign: "center"}}>
                        {stats?.QuantityOfMostCommonlyReportedRequestType}
                    </Typography>
                </Grid>
                <Grid item xs={6} style={{textAlign: "end"}}>
                    <Typography component={`div`}>
                        # Distinct Contacts with Open Service Requests:	
                    </Typography>
                </Grid>
                <Grid item sx={6}>
                    <Typography component={`div`} style={{textAlign: "center"}}>
                        {stats?.NumberDistinctCustomerContactsWithOpenIncidents}
                    </Typography>
                </Grid>
                <Grid item xs={6} style={{textAlign: "end"}}>
                    <Typography component={`div`}>
                        # Distinct Devices with Open Service Requests:
                    </Typography>
                </Grid>
                <Grid item sx={6}>
                    <Typography component={`div`} style={{textAlign: "center"}}>
                        {stats?.NumberDistinctCustomerDevicesWithOpenIncidents}
                    </Typography>
                </Grid>
                <Grid item xs={6} style={{textAlign: "end"}}>
                    <Typography component={`div`}>
                        # Distinct Jobs with Open Service Requests:
                    </Typography>
                </Grid>
                <Grid item sx={6}>
                    <Typography component={`div`} style={{textAlign: "center"}}>
                        {stats?.NumberDistinctCustomerJobsWithOpenIncidents}
                    </Typography>
                </Grid>
                <Grid item xs={6} style={{textAlign: "end"}}>
                    <Typography component={`div`}>
                        Total Open Service Requests:
                    </Typography>
                </Grid>
                <Grid item sx={6}>
                    <Typography component={`div`} style={{textAlign: "center"}}>
                        {stats?.TotalOpenServiceRequests}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};
