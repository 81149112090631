import { useEffect } from "react";
import { CHATBUSNO, NICCHATID } from "../../controls/dapp.constants";
import { useState } from "react";

export const useIncontactChat = ({loggedIn, hasProfile, chatEnabled, chatParams, showChat, extraTests}) => {
    const [loaded, setLoaded] = useState(false);

    const busno = CHATBUSNO;
    const nicChat = NICCHATID;

    useEffect(() => {
        let enabled = chatEnabled === '-1';        
        if (window.$ && loggedIn) {
            let chat = window.$('#cxone-guide-container');
            if (chat.length > 0){
                let i = (showChat && enabled && chat?.show()) || chat?.hide();
                console.log(`chat show hide: ${i} ::: ${showChat}`);
            }
        } else {
            let el = window.$ && window.$('#cxone-guide-container');
            if (el){
                el.hide();            
            }
            return;
        }

        if (!loaded && window.brandembassy && enabled){
            window.brandembassy('init', parseInt(busno), nicChat);
            window.brandembassy('showSendButton');
            window.brandembassy('hideChatWindow');
            window.brandembassy('setCaseCustomField', 'user', `${JSON.stringify(extraTests.user)}`);
            window.brandembassy('setCaseCustomField', 'contactprofile', `${JSON.stringify(extraTests.contactProfile)}`);

            window.brandembassy('onAnyPushUpdate', (event) => {
                // console.log(`event type: ${JSON.stringify(event)}`);
                window.brandembassy('sendFirstMessageAutomatically', 'Hello');
            });            

            setLoaded(true);
        }

    }, [chatParams, loggedIn, chatEnabled, hasProfile, loaded, showChat, extraTests, busno, nicChat]);
}

