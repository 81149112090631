import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { rowsperpage, WrapperPaper } from '../../controls/shared.components';
import { SingleJobToobar } from './jobs.toolbar';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useJobsColumns, useJobSortMapper } from './jobs.hook';
import { useGridStyles } from '../../controls/datagrid.striped';
import { CustomAlert } from '../../alerts/alert.snackbar';
import { useDataFromBridge } from '../../controls/custom.hooks';
import { useDappContext } from '../../auth/dapp.context';
import { history } from '@kineticdata/react';
import clsx from "clsx";
import { useReducer } from 'react';
import { JobSummaryModal } from './job.summary';

const initLoadParams = {
  GetTotal: true,
  AscDesc: 'DESC',
  PageNum: 1,
  PageSize: rowsperpage[0],
  PageToken: ' ',
  SortField: 'JOBID',  
};

const bridgedResourceName = "All Jobs";

export const Jobs = props => {
  const { ...other } = props;
  const [loading, setLoading] = useState(true);
  const [apiparms, setApiparms] = useState(initLoadParams);
  const [xuser, setXuser] = useState();
  const [griddata, setGridData] = useState([]);
  const [pageToken, setPageToken] = useState();
  const [showAll, setShowAll] = useState(false);
  const [pagingState, dispatch] = useReducer(pageReducer, initState);
  const [selected, setSelected] = useState();
  const gridClasses = useGridStyles();
  const dappCntx = useDappContext();
  const [selectionModel, setSelectionModel] = useState([]);
  const [jobSummary, setJobSummary] = useState({
    open: false, record: null, handleClose: () => {}
  });
  const sortMapper = useJobSortMapper();

  useEffect(() => {
    dappCntx.dappSubtitle("Jobs", () => {});
    // eslint-disable-next-line
  }, [dappCntx.title])

  const [showAlert, setShowAlert] = useState({
    open: false, severity: 'success', message: '',
    onSnackBarClose: () => {},
  });

  useEffect(() => {
    if (!dappCntx.user) return;    
    if (xuser === dappCntx.user.user) return;
    setXuser(dappCntx.user.user);
    setApiparms({ ...initLoadParams, IncludeInactive: false});
  }, [xuser, dappCntx]);

  const jobs = useDataFromBridge({ 
    xUser: xuser, apiValues: apiparms,
    bridgedResourceName: bridgedResourceName
  });

  const jobCols = useJobsColumns({
    handleEdit: ({row}) => history.push(`/jobs/edit/${row['Job ID']}`),
    handleDelete: ({row}) => setShowAlert({
      open: true, severity: 'warning', 
      message: `Deleting job ${row['Job ID']} ::: ${row['Job Num']} has not been implemeted yet.`
    }), 
    handleViewSummary: ({row}) => {
      setJobSummary({
        record: row,
        open: true,
        handleClose: summaryViewClose
      });
    },
  });

  const summaryViewClose = useCallback(() => {
    setJobSummary({...jobSummary, open: false, record: null});
  }, [jobSummary])

  const handlePagingChange = useCallback((model, details) => {
    if (model.pageSize !== pagingState.pageSize)
      model.page = 0;

    dispatch({type: "page", page: model.page});
    dispatch({type: "pageSize", pageSize: model.pageSize});
    setApiparms({ ...apiparms, 
      PageNum: model.page + 1,
      PageSize: model.pageSize,
      PageToken: pageToken || ' ',
    });
  }, [apiparms, pagingState, pageToken]);

  const showError = useCallback(({message}) => {
    setShowAlert({open: true, 
        severity: "error", message: message,
        onSnackBarClose: () => { setShowAlert({open: false, message: "random"})}
    });
  }, []);

  useEffect(() => {
    if (!jobs) return;

    setLoading(false);
    if (!jobs.success){
      showError({message: jobs.data.Message || jobs.data.message});
      return;
    } else if (jobs.data.error) {
      showError({message: jobs.data.error.message});
      return;
    }
    setPageToken(jobs.data.metadata?.NextPage || jobs.data.NextPage);
    setGridData(
      (jobs.data.records || jobs.data.Data).map((row, indx) => {
        return { ...row, id: indx };
      }),
    );

    // eslint-disable-next-line
  }, [jobs]);

  const onRefresh = useCallback(() => {
    setApiparms({...apiparms, PageToken: 'none'});
    setApiparms({...apiparms, PageToken: ' '});
  }, [apiparms]);

  const handleSelectionChange = useCallback((newSelection) => {
    let sel = new Set(selectionModel);
    let diff = [...newSelection].filter(x => !sel.has(x));
    let indx = newSelection.findIndex(x => x !== diff[0]);
    indx !== -1 && newSelection.splice(indx, 1);
    setSelectionModel(newSelection);
    let row = griddata.find((x) => x.id === newSelection[0]);
    setSelected({row: row});
  }, [griddata, selectionModel])

  const showJobDetails = useCallback((path) => {
    const jobId = selected.row['Job ID'];
    dappCntx.setCurrentRecord(selected.row);
    history.push(`${path}${jobId}`);
  }, [selected, dappCntx]);

  const showAllJobs = useCallback((event) => {
    setShowAll(event.target.checked);
    setApiparms({...apiparms, IncludeInactive: event.target.checked});    
  }, [apiparms])

  const handleSortChange = useCallback((sortmodel) => {
    if (!sortmodel) return;
    if (!!sortmodel && sortmodel.length <= 0) return;   

    const fld = sortMapper.find(x => x.field === sortmodel[0].field)?.sortBy || sortmodel[0].field;

    if (apiparms.SortField === fld && apiparms.AscDesc === sortmodel[0].sort) return;
    setGridData([]);
    setLoading(true);
    setApiparms({
      ...apiparms,
      SortField: fld,
      AscDesc: sortmodel[0].sort,
    });
  }, [apiparms, sortMapper]);

  return (
    <Fragment>
      <SingleJobToobar rowSelected={selected}
        handleAdd={() => history.push("/jobs/add")} 
        handleRefresh={onRefresh}
        handleJobBalances={() => showJobDetails(`/jobs/balances/`)}
        handleJobReports={() => showJobDetails(`/jobs/jobreports/`)}
        handleProducts={() => showJobDetails(`/jobs/products/`)}
        handleManagedServices={() => showJobDetails(`/jobs/services/`)}
        handleFiles={() => showJobDetails(`/jobs/files/`)}
        showAll={showAll}
        handleShowAll={showAllJobs}
      />
      <WrapperPaper {...other}>
        <DataGridPro
          className={clsx(gridClasses.gridheadfoot, gridClasses.gridrows, gridClasses.gridColumnHeaderNoCheckBox)}
          rows={griddata}
          columns={jobCols}
          loading={loading}
          paginationMode="server"
          autoPageSize
          density="compact"
          rowCount={jobs?.data?.metadata?.count || jobs?.Total || 0}
          pageSizeOptions={rowsperpage}
          onRowSelectionModelChange={handleSelectionChange}
          onPaginationModelChange={handlePagingChange}
          checkboxSelection
          selectionModel={selectionModel}
          sortingMode="server"
          onSortModelChange={handleSortChange}
          pagination
        />
      </WrapperPaper>
      <CustomAlert {...showAlert}/>
      <JobSummaryModal {...jobSummary} />
    </Fragment>
  );
};

const initState = {page: 0, pageSize: rowsperpage[0]};

const pageReducer = (state, action) => {
  switch (action.type) {
    case "page": 
      return {...state,  page: action.page};
    case "pageSize":
      return {...state, pageSize: action.pageSize};
    default:
      throw new Error();
  }
}
