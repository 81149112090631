import axios from 'axios';
import { headers, REPORT_API_URL } from '../controls/dapp.constants';

export const getReportEmbedToken = (props) => {
    const {reportId, dataSetId, userName, reportToken} = props;

    const apiCall = async () => await axios.get(
        `${REPORT_API_URL}/${reportId}`,
        {
            cancelToken: axios.CancelToken.source().token,
            withCredentials: false,
            headers: { ...headers, 
                token: reportToken,
                dataset: dataSetId,
                username: userName
            }
        }
    );

    return apiCall();
}
