import React, { createContext, useContext, useState } from "react";

const ServiceRequestContext = createContext();

export const ServiceRequestProvider = ({children}) => {
    const [filter, setFilter] = useState(initLoadParams);
    const [formValues, setFormValues] = useState();

    return (
        <ServiceRequestContext.Provider value={{
            filter, setFilter, initLoadParams,
            formValues, setFormValues
        }}>
            {children}
        </ServiceRequestContext.Provider>
    )    
}

export const useServiceRequestContext = () => useContext(ServiceRequestContext);

const initLoadParams = {
    GetTotal: true,
    AscDesc: 'DESC',
    SortField: 'IncidentID',
    assignedTo: ' ',
    Status: 'Open',
    CustomerContactID: ' ',
    IncidentNo: ' ',
    SearchValue: ' ',
    IsRequestScheduled: ' ',
    PriorityLevel: ' ',
    RequestedStartDate: ' ',
    RequestedStopDate: ' ',
    OpenDateStart: ' ',
    OpenDateStop: ' ',
    RequestPriority: ' ',
    RequestType: ' ',
    TSMDepartmentID: ' ',
    ResponsibleDeptID: ' ', 
    IsAssigneeSharedOperator: ' ', 
    IsVIP: ' ',
    PageToken: ' ',
    PageSize: 20,
    PageNum: 1
  };
  