import React, { Fragment, useCallback, useState } from "react";
import { Toolbar, Box, FormGroup, FormControlLabel, makeStyles, Typography, debounce } from "@material-ui/core";
import { DappToolbarSwitch, ToolbarButton, useToolBarStyle, useToolbarStyle } from "../../controls/shared.components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faAdd, faRefresh, faHardDrive, faContactBook, faLockOpen, faListCheck, 
    faUserCheck, faUserClock, faFileExcel, faSearch, faEdit, faEraser,
    faPersonCircleCheck,
    // , faQuestion, faGears
} from "@fortawesome/free-solid-svg-icons";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { QuickLookup } from "../../controls/search.box";
import { SaveCancelToolbarButtons } from "../../controls/savecancel.buttons";

export const ContactToolbar = (props) => {
    const {
        subClientChanged, onRefresh, newContact, editContact, disableEdit,
        handleContactToggle, handleExport, handleFilter, handleSearchValueChange,
        handlePendingToggle,
        ...other
    } = props;
    const [subclient, setSubClient] = useState(false);
    const [pendingContact, setPendingContact] = useState(false);
    const cls = useToolbarStyle();

    const handleChange = useCallback((event) => {
        setSubClient(event.target.checked);
        if (!!subClientChanged && typeof subClientChanged === 'function'){
            subClientChanged({value: event.target.checked});
        }
    }, [subClientChanged]);

    const handlePendingChange = useCallback((event) => {
        setPendingContact(event.target.checked);
        if (!!handlePendingToggle && typeof handlePendingToggle === 'function'){
            handlePendingToggle({value: event.target.checked});
        }
    }, [handlePendingToggle])

    const handleSearch = debounce((val) => {
        if (handleSearchValueChange && typeof handleSearchValueChange === 'function'){
            handleSearchValueChange(val);
        }
    }, 800);

    return (
        <Toolbar {...other} className={cls.toolbarroot}>
            <ToolbarButton name="New"
                handleClick={newContact}
                tooltip="Add New Contact"                
                icon={<FontAwesomeIcon icon={faAdd}/>}
            />
            <ToolbarButton name="Filter"
                handleClick={handleFilter}
                tooltip="Filter Records"
                style={{display: "none"}}
                icon={<FontAwesomeIcon icon={faSearch}/>}
            />
            <Box sx={{flexGrow: 1}} />
            <Typography component={`div`} style={{minWidth: 320, widh: '40%', padding: "0 0.5rem 0 0"}}>
                <QuickLookup 
                    placeholder="TYPE NAME"
                    onInputChange={(e) => handleSearch(e.target.value)}            
                />
            </Typography>
            <FormGroup row>
                <FormControlLabel className={cls.switchlabel}
                    control={
                    <DappToolbarSwitch
                        checked={subclient} 
                        onChange={handleChange}
                        name="subClient"/>
                }
                    label={`${(!subclient && "No") || ''} Sub Client`}  
                />
            </FormGroup>
            <FormGroup row>
                <FormControlLabel className={cls.switchlabel}
                    control={
                    <DappToolbarSwitch
                        checked={pendingContact} 
                        onChange={handlePendingChange} 
                        name="isPending"/>
                }
                    label={`${(!pendingContact && "Not") || ''} Pending`}  
                />
            </FormGroup>
            <ToolbarButton
                name="Export"
                handleClick={handleExport}
                tooltip="Export to Excel"
                icon={<FontAwesomeIcon icon={faFileExcel}/>}
            />
            <ToolbarButton
                name="Refresh"
                handleClick={onRefresh}
                tooltip="Refresh"
                icon={<FontAwesomeIcon icon={faRefresh}/>}
            />
        </Toolbar>
    );
}

export const ContactEditToolbar = (props) => {    
    const {
        onShowDevices, onServiceRequests, 
        onSurveys, onAccess, onPreferences, 
        onSaveChanges, onCancelChanges, onDisable,
        onApprove, isPending,
        showDetail, type, userContact, loadError,
        ...other
    } = props;
    const cls = useToolbarStyle();
    const history = useHistory();
    const {id} = useParams();
    const location = useLocation();

    return (
        <Toolbar {...other} className={cls.toolbarroot}>
            <ToolbarButton name="Edit" style={(location.pathname.includes("view") && {display: ""}) || {display: "none"}}                
                handleClick={() => history.push(`/contacts/edit/${id}`)}
                tooltip="Edit Contact"
                icon={<FontAwesomeIcon icon={faEdit}/>}
                disabled={loadError}
            />
            <ToolbarButton name="Devices" style={(!id && {display: 'none'}) || {display: ''}}
                handleClick={onShowDevices}
                tooltip="Devices"
                icon={<FontAwesomeIcon icon={faHardDrive}/>}
                disabled={loadError}
            />
            <ToolbarButton style={(!id && {display: 'none'}) || {display: ''}}
                name="Requests"
                handleClick={onServiceRequests}
                tooltip="Service Requests"
                icon={<FontAwesomeIcon icon={faListCheck}/>}
                disabled={loadError}
            />
            <ToolbarButton
                name="Disable"
                handleClick={onDisable}
                tooltip="Disable Contact"
                icon={<FontAwesomeIcon icon={faEraser}/>}
                disabled={loadError}
            />
            <ToolbarButton
                name="Approve"
                handleClick={onApprove}
                tooltip="Approve Contact"
                icon={<FontAwesomeIcon icon={faPersonCircleCheck}/>}
                disabled={loadError}
                style={(isPending && {display: ""}) || {display: "none"}}
            />
            {/* <ToolbarButton
                name="Surveys"
                handleClick={onSurveys}
                tooltip="Surveys"
                icon={<FontAwesomeIcon icon={faQuestion}/>}
            /> */}
            <Box sx={{flexGrow: 1}}/>
            {((location.pathname.includes("add") || location.pathname.includes("edit")) && !loadError &&
            <SaveCancelToolbarButtons 
                handleSave={onSaveChanges}
                handleCancel={onCancelChanges}
            />) || <Fragment/>}            
            <ToolbarButton style={(!userContact && {display: 'none'}) || {display: ''}}
                name="CMS Access"
                handleClick={onAccess}
                tooltip="CMS Access"
                icon={<FontAwesomeIcon icon={faLockOpen}/>}
                disabled={loadError}
            />
            {/* <ToolbarButton
                name="Preferences"
                handleClick={onPreferences}
                tooltip="Preferences"
                icon={<FontAwesomeIcon icon={faGears}/>}
            /> */}
        </Toolbar>
    );
}

export const ContactsSwitchToolbar = ({handleSwitch, ...other}) => {
    const [selectedView, setSelectedView] = useState("contacts");

    const tlbrCls = useToolBarStyle();
    const togglecls = useToggleStyles();

    const handleChangeToggle = useCallback((e) => {
        setSelectedView((e.currentTarget || e.target).value);
        handleSwitch((e.currentTarget || e.target).value);
    }, [handleSwitch]);

    return (
        <Toolbar {...other} className={tlbrCls.toolbarroot}>
            <ToggleButtonGroup orientation="horizontal"
                color="primary" 
                value={selectedView}
                exclusive onChange={handleChangeToggle}
                className={togglecls.dappTabs}
            >
                <ToggleButton value="contacts" style={{borderRadius: "15px 0 0 0"}}>
                    <FontAwesomeIcon icon={faContactBook}/>Contacts
                </ToggleButton>
                <ToggleButton value="new-invite">
                    <FontAwesomeIcon icon={faUserCheck}/>Invite to CMS
                </ToggleButton>
                <ToggleButton value="view-invite" style={{borderRadius: "0 15px 0 0"}}>
                    <FontAwesomeIcon icon={faUserClock}/>View Current CMS Invites
                </ToggleButton>
            </ToggleButtonGroup>
       </Toolbar>
    )
}

const useToggleStyles = makeStyles(() => ({
    dappTabs: {
      '& .MuiToggleButton-root': {
        color: "window",
        backgroundColor: "#55bff2",
        opacity: "0.5",
      },
      '& .Mui-selected': {
        opacity: "1",
        backgroundColor: "#007dba",
      },
    }
  }));
