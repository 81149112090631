import React, { Fragment, useCallback, useEffect, useState } from "react";
import { rowsperpage, WrapperPaper } from "../../../controls/shared.components";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
// import { TablePagination } from "@material-ui/core";
import { useGridStyles } from "../../../controls/datagrid.striped";
import { CustomAlert } from "../../../alerts/alert.snackbar";
import { useDataFromBridge } from "../../../controls/custom.hooks";
import { useDappContext } from "../../../auth/dapp.context";
import clsx from "clsx";
// import { useHistory, useLocation } from "react-router-dom";
import { InviteToolbar } from "./invite.toolbar";
import { useInviteColumns, useSortMapper } from "./invite.hooks";
import { cancelApiCall, sendInvites } from "../../../apicalls/contact.api";
import { DappConfirm } from "../../modals/modal.confirmation";

const initLoadParams = {
    AscDesc: 'ASC',
    GetTotal: true,
    IncludeInactive: false,
    PageNum: 1,
    PageSize: rowsperpage[0],
    PageToken: ' ',
    SortField: 'LastName',
    SubClientContact: false,
    excludeCMSUser: true,
    SearchValue: ' ',
  }

const InvitationRecord = {
    FirstName: "",
    LastName: "",
    EmailAddress: "",
    UserType: "",
    PrimaryContactID: 0,
    ContactID: 0,
}  
  
const bridgedResourceName = "All Invited Contacts"
  
export const Contacts2Invite = (props) => {
    const { ...other } = props;
    const [loading, setLoading] = useState(true);
    const [apiparms, setApiparms] = useState(initLoadParams);
    const [griddata, setGridData] = useState([]);
    const [pageNum, setPageNum] = useState(0);
    const [pageSize, setPageSize] = useState(rowsperpage[0]);
    const [selectedRows, setSelectedRows] = useState();
    const gridClasses = useGridStyles();
    const dappCntx = useDappContext();
    const sortMapper = useSortMapper();

    const apiRef = useGridApiRef();

    const [showConfirmation, setShowConfirmation] = useState({
        open: false, title: '', question: '', handleResult: () => {}
    });

    // const history = useHistory();
    // const location = useLocation();

    const [showAlert, setShowAlert] = useState({
      open: false, severity: 'success', message: '',
      onSnackBarClose: () => {},
    });

    const contacts = useDataFromBridge({
      xUser: dappCntx.user?.user, apiValues: apiparms,
      bridgedResourceName: bridgedResourceName 
    });

    const handleRowUserTypeChange = useCallback((props) => {
        const {userType, row} = props;
        let modified = griddata.slice();
        modified.find(x => x.id === row.id).UserType = userType;
        setGridData(modified);
    }, [griddata]);

    const contactColumns = useInviteColumns({
        handleViewContact: ({ContactID}) => console.log(`navigating to ${ContactID}`),
        handleTypeChange: (props) => handleRowUserTypeChange(props),
    });

    const handlePageChange = useCallback((model, details) => {
      setGridData([]);
      if (model.pageSize !== pageSize)
        model.page = 0;
      setPageNum(model.page);
      setPageSize(model.pageSize);
      setLoading(true);
      setApiparms({...apiparms, PageNum: model.page + 1, PageSize: model.pageSize});
    }, [apiparms, pageSize]);

    const showError = useCallback(({message}) => {
      setShowAlert({...showAlert, open: true, 
          severity: "error", message: message,
          onSnackBarClose: () => { setShowAlert({open: false, message: "random"})}
      });
    }, [showAlert])

    const showSuccess = useCallback(({message}) => {
        setShowAlert({...showAlert, open: true, 
            severity: "success", message: message,
            onSnackBarClose: () => { setShowAlert({open: false, message: "random"})}
        });
      }, [showAlert])
      
    useEffect(() => {
      if (!contacts) return;
      let doShow = true;

      setLoading(false);
      if (!contacts.success) {
          showError({message: contacts.data.Message || contacts.data.message});
          return;
      }

      if (!!contacts.data?.error){
        showError({message: contacts.data?.error?.message});
        return;
      }
  
      doShow && setGridData((contacts.data.records || contacts.data.Data).map((row, indx) => { 
        return {...row, id: indx, "User Type": row["User Type"] === "" && "User"}
    }));

    return () => doShow = false;

      // eslint-disable-next-line
    }, [contacts]);

    const handleRefresh = useCallback(() => {
      setLoading(true);
      setGridData([]);
      setApiparms({...apiparms, PageToken: 'random'});
      setApiparms({...apiparms, PageToken: ' '});
    }, [apiparms]);

    const handleSelectionChange = useCallback((selection) => {
      setSelectedRows(selection.map(x => griddata.find(rec => x === rec.id)));
    }, [griddata]);

    const handleSearchValueChange = useCallback((val) => {
      setGridData([]);
      setLoading(true);
      setApiparms({...apiparms, SearchValue: (!!val && val) || ' '})
    }, [apiparms])

    useEffect(() => {
      dappCntx.dappSubtitle("Invite");
    }, [dappCntx]);

    const handleResult = (props) => {
        const {success, message} = props;
        success && showSuccess({message: message});
        !success && showError({message: message});
        handleRefresh();
    }

    const handleSendInvite = useCallback(({accepted}) => {
        setShowConfirmation({...showConfirmation, 
            open: false, title: 'random', 
            question: 'lksd', handleResult: () => {}});
        
        if (!accepted) return;

        const inviteData = selectedRows.map(x => { return {...InvitationRecord, 
            FirstName: x["First Name"],
            LastName: x["Last Name"],
            EmailAddress: x["Email Address"],
            UserType: x["User Type"],
            PrimaryContactID: x["Primary Contact ID"],
            ContactID: x["Contact ID"]
        }});

        try{
            sendInvites({user: dappCntx.user?.user, data: inviteData}).then((response) => {
                handleResult({data: response.data, success: true, 
                    message: `Invitations sent successfully to ${selectedRows.length} contacts with runid: ${response.data.runId}`
                });
            }).catch((error) => {
                handleResult({data: [], sucess: false, message: `Failed to send invites with: ${error.toString()}`});
            });
        } catch (exp){
            handleResult({data: [], success: false, message: `Failed to send invites with: ${exp.toString()}`});
        }
        return () => {
            cancelApiCall();
        }
        //eslint-disable-next-line        
        }, [dappCntx, selectedRows]);
    
    const handleInvite = useCallback(() => {
        setShowConfirmation({ ...showConfirmation, 
            open: true,
            title: `Sending Invitation${(selectedRows.length > 1 && 's') || ''}`,
            question: `Are you sure you want to send invitations to ${selectedRows.length} contacts?`,
            handleResult: handleSendInvite,
        })
    }, [showConfirmation, selectedRows, handleSendInvite]);

    const handleSortChange = useCallback((sortmodel) => {
      if (!sortmodel) return;
      if (!!sortmodel && sortmodel.length <= 0) return;

      const mapped = sortMapper.find(x => x.field === sortmodel[0].field);
      if (!mapped) return;

      if (apiparms.SortField === mapped.sortBy && apiparms.AscDesc === sortmodel[0].sort){
        return;
      }

      setGridData([]);
      setLoading(true);
      
      setApiparms({
        ...apiparms, 
        SortField: mapped.sortBy,
        AscDesc: sortmodel[0].sort,
      });
    }, [apiparms, sortMapper]);

    const handleExport = useCallback(() => {
      const d = new Date();
      const stamp = `${d.getFullYear()}${d.getMonth()}${d.getDay()}-${d.getHours()}${d.getMinutes()}`;

      apiRef.current.exportDataAsCsv({
        fileName: `INVITES_CMS_${stamp}-pg(${pageNum + 1})-sz(${pageSize}).csv`
      });
    }, [pageNum, pageSize, apiRef]);

    return (
      <Fragment {...other}>
        <InviteToolbar 
          onRefresh={handleRefresh}
          handleInvite={handleInvite}
          disableInvite={(selectedRows?.length || 0) <= 0}
          handleSearch={handleSearchValueChange}
          handleExport={handleExport}
        />
        <WrapperPaper>
          <DataGridPro className={clsx(gridClasses.gridheadfoot, gridClasses.gridrows)}
            columns={contactColumns}
            //page={pageNum}
            density="compact"
            paginationMode="server"
            autoPageSize
            isRowSelectable={(params) => params.row["Is Invited CMS"] !== '-1' && params.row["Is Active CMS"] !== '-1'}
            rows={griddata}
            loading={loading}
            apiRef={apiRef}
            rowCount={contacts?.data?.metadata?.count || 0}
            //pageSize={pageSize}            
            pageSizeOptions={rowsperpage}
            checkboxSelection 
            disableSelectionOnClick
            onRowSelectionModelChange={handleSelectionChange}
            onPaginationModelChange={handlePageChange}
            sortingMode="server"
            onSortModelChange={handleSortChange}
            pagination
            initialState={{
              pagination: {
                paginationModel: {page: pageNum, pageSize: pageSize}
              }
            }}
          />
        </WrapperPaper>
        <CustomAlert {...showAlert}/>
        <DappConfirm {...showConfirmation}/>
      </Fragment>
    );
}

