import React, { Fragment, useState, useCallback, useEffect } from "react";
import { Typography, Paper, } from "@material-ui/core";
import { FormLayout, Pending, useFormStyle, useWrappereStyles } from "../../controls/shared.components";
import { BottomInfo } from "../bottom/bottom.info";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CustomAlert } from "../../alerts/alert.snackbar";
import { CoreForm } from "@kineticdata/react";

export const RegisterVerifyForm = () => {
  const[formvalue, setFormValue] = useState();
  const [showError, setShowError] = useState({
    open: false, message: "",
    severity: "success", 
    onSnackbarClose: () => {}
  });
  const wrapCls = useWrappereStyles();
  const history = useHistory();
  const frmCls = useFormStyle();
  const {gid} = useParams();
  const {search} = useLocation();

  const handleSaveComplete = () => history.replace("/");
  const handleError = useCallback((parms) => {
    setShowError({...showError, open: true, severity: "error", message: parms.toString(),
    onSnackbarClose: () => setShowError({...showError, open: false, message: "random"})
  });
  }, [showError]);


  useEffect(() => {
    (gid && setFormValue(gid)) ||
    (search && search.split("?")[1].split("&").map((value, index) => 
      (value.split("=")[0] === "VGID") && setFormValue(value.split("=")[1])
    ));
  }, [gid, search]);

  return (
      <Fragment>
          <Typography component={`div`}
            className={frmCls.root}
            style={{
              minWidth: "50%", minHeight: "50%", margin: "auto", justifyContent: "center", alignItems: "center"
            }}>
            <Paper name="core-form-container" elevation={6}
              style={{
                minWidth: 350,
                minHeight: 380,
                backgroundColor: "window",
                borderRadius: 15,
                display: "flex",
                flexFlow: "column",
                overflowY: "auto",
              }}>
              <CoreForm 
                kapp="customer-portal"
                form={"verify-registration"}
                public={true}
                onError={handleError}
                onCompleted={handleSaveComplete}
                values={(formvalue && { VGID: `${formvalue}` }) || null}
                components={{
                  Pending: Pending,
                  Layout: FormLayout,
                }}
              />
            </Paper>
            </Typography>
            <Typography component={`div`} className={wrapCls.bottom}>
              <BottomInfo/>
            </Typography>
            <CustomAlert {...showError}/>
      </Fragment>
  )
}