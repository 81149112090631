import React from "react";
import { 
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, 
} from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";
import { CoreForm } from "@kineticdata/react";
import { DappDialogTitle, KappFormLayout, KappPending, useDialogButtonStyles, useFormStyle } from "../../controls/shared.components";
import { useEffect } from "react";
import { convertFromHTML, ContentState } from "draft-js";
import { useState } from "react";

export const ActionDetailModalTest = (props) => {
    const {actionrow, open, onCloseModal} = props;
    const [longDescription, setLongDescription] = useState();

    const btnCls = useDialogButtonStyles();

    useEffect(() => {
        if (!actionrow) return;
        const blocksFromHTML = convertFromHTML(actionrow['IncidentDescription']);
        const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        );
        state && setLongDescription(state.getPlainText());        
    }, [actionrow]);

    return (
        <Dialog open={open || false} onClose={onCloseModal}>
            <DialogTitle style={{fontSize: '0.6rem'}}>
                Action for Incident #: <span style={{color: indigo[700]}}>{actionrow && actionrow['IncidentNo']}</span> {(actionrow?.Status && `(${actionrow.Status})`) || ''} 
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            id="start-date-time"
                            label="Start Time"
                            defaultValue={(actionrow && actionrow['Start Date']) || ''}
                            type="datetime-local"
                            size="small"
                            InputProps={{
                                shrink: "true",
                                readOnly: true,
                            }}
                        />                        
                    </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField variant="outlined" 
                            id='incident-tier'
                            label="TIER"
                            value={(actionrow && actionrow['Incident Priority']) || ''}
                            size="small"
                            InputProps={{
                                readOnly: true,
                            }}
                        />                        
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                            id="action-rep"
                            label="Engineer"
                            value={(actionrow && actionrow['User Name']) || ''}
                            size="small"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField fullWidth variant="outlined"
                            label="Action Taken"
                            helperText="Read Only"
                            multiline
                            minRows={8}
                            onChange={() => {}}
                            value={(actionrow && actionrow['Action Desc']) || ''}
                            InputProps={{
                                readOnly: true,
                            }}
                        />                        
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField fullWidth variant="outlined"
                            label={`Incident Description`}
                            multiline
                            minRows={8}
                            value={longDescription || ''}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onCloseModal} className={btnCls.closebutton}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const ActionDetailModal = (props) => {
    const {actionrow, open, onCloseModal} = props;
    const cls = useFormStyle();
    const btnCls = useDialogButtonStyles();

    return (
        <Dialog open={open || false} onClose={onCloseModal}>
            <DappDialogTitle 
                title={`Action for Incident #: 
                    ${actionrow && actionrow['IncidentNo']} ${(actionrow?.Status && `(${actionrow.Status})`) || ''}`}
                handleClose={onCloseModal}
            />
            <DialogContent className={cls.root} style={{minWidth: 600, overflowY: "hidden"}}>
                <ViewActionForm actionID={actionrow?.["Action ID"]}/>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onCloseModal} className={btnCls.closebutton}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const ViewActionForm = ({actionID}) => {
    return (
      <CoreForm
        kapp="customer-portal"
        form="view-action"
        values={{ActionID: `${actionID}`}}
        components={{
          Pending: KappPending,
          Layout: KappFormLayout,
        }}
      />
    );
  };
  
