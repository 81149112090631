import React, { Fragment, useState, useEffect, useCallback, useReducer } from 'react';
import { rowsperpage, WrapperPaper } from '../../controls/shared.components';
import { useManagedColumns } from './managed.hooks';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useGridStyles } from '../../controls/datagrid.striped';
import { useDataFromBridge } from '../../controls/custom.hooks';
import { CustomAlert } from '../../alerts/alert.snackbar';
import { useDappContext } from '../../auth/dapp.context';
import { useParams } from 'react-router-dom';
import { JobDetailsToolbar } from '../jobs/jobs.toolbar';
import clsx from "clsx";
import swal from 'sweetalert';
import { utils, writeFileXLSX } from 'xlsx';
import { EXPORTALERTMSG } from '../../controls/dapp.constants';

const initLoadParams = {
  AscDesc: 'desc',
  GetTotal: true,
  IncludeClosedJobManagedServices: false,
  OkToInvoiceOnly: true,
  PageSize: rowsperpage[0],
  PageNum: 1,
  PageToken: ' ',
  SortField: 'POServiceDetailID',
};

export const ManagedServices = props => {
  const { ...other } = props;
  const [apiparms, setApiparms] = useState();
  const [paging, dispatch] = useReducer(pageReducer, {page: 0, pageSize: rowsperpage[0]});
  const [griddata, setGridData] = useState([]);
  const [xuser, setXuser] = useState();
  const [jobbyid, setJobbyid] = useState();
  const [bridgeName, setBridgeName] = useState();
  const [toolbarTitle, setToolbarTitle] = useState();
  const gridClasses = useGridStyles();
  const [exportParams, setExportParams] = useState();
  const [showAlert, setShowAlert] = useState({
    open: false, severity:"error", message: "",
    onSnackBarClose: () => {},
  });

  const { jobid } = useParams();

  const dappCntx = useDappContext();
  useEffect(() => {
    dappCntx.dappSubtitle("Managed Services");
  }, [dappCntx])

  useEffect(() => {
    if (!dappCntx.user) return;
    dispatch({type: "loading", loading: true}); 
    setXuser(dappCntx.user.user);
    if (jobid){
      setApiparms({...initLoadParams, JobID: jobid})
      setJobbyid({JobID: jobid});
      setBridgeName('Managed Service by ID');
    } else {
      setApiparms({...initLoadParams})
      setBridgeName('All Managed Services');
      setToolbarTitle([` All Jobs`, ``]);    
    }
  }, [dappCntx, jobid])

  useEffect(() => {
    if (!dappCntx.currentRecord) return;
    setToolbarTitle([`${dappCntx.currentRecord["Job Num"]}`, `${dappCntx.currentRecord?.InternalShortDesc || ''}`]);
  }, [dappCntx.currentRecord])

  const jobRecord = useDataFromBridge({
    xUser: xuser, apiValues: jobbyid,
    bridgedResourceName: "Job by ID"
  });

  useEffect(() => {
    if (dappCntx.currentRecord) return;
    if (!jobRecord?.data) return;
    dappCntx.currentRecord = jobRecord?.data.record;
  }, [dappCntx.currentRecord, jobRecord]);

  const managedsrvcs = useDataFromBridge({
    xUser: xuser, apiValues: apiparms,
    bridgedResourceName: bridgeName
  });

  const managedsrvcsExport = useDataFromBridge({
    xUser: xuser, apiValues: exportParams,
    bridgedResourceName: "AllManagedServicesExport"
  });

  const managedCols = useManagedColumns();

  const handlePaginationChange = useCallback((model, details) => {
    if (model.pageSize !== paging.pageSize)
      model.page = 0;

    dispatch({ type: "page", page: model.page });
    dispatch({ type: "pageSize", pageSize: model.pageSize });
    dispatch({ type: "loading", loading: true });
    setApiparms({
      ...apiparms,
      PageNum: model.page + 1,
      PageSize: model.pageSize,
    });
  }, [apiparms, paging]);

  const showError = useCallback(({message}) => {
     setShowAlert({...showAlert, open: true,  message: message, 
      onSnackBarClose: () => { setShowAlert({...showAlert, open: false, message: "random"})},
    });
  }, [showAlert]);

  useEffect(() => {
    if (!managedsrvcs) return;

    dispatch({type: "loading", loading: false});
    if (!managedsrvcs.success) {
      showError({message: managedsrvcs.data.Message })
      return;
    }

    if (managedsrvcs.success && !managedsrvcs.data){
      showError({message: `No data returned from bridge call`});
      return;
    } else if (managedsrvcs.data.error) {
      showError({message: managedsrvcs.data.error.message});
      return;
    }

    // setPageToken(managedsrvcs.data.metadata?.nextPageToken || managedsrvcs.data.NextPage || '');
    let data = managedsrvcs.data.records || managedsrvcs.data.Data;
    setGridData(data.map((row, indx) => { return { ...row, id: indx };}),
    );
    // eslint-disable-next-line
  }, [managedsrvcs]);

  useEffect(() => {
    if (!managedsrvcsExport || !managedsrvcsExport?.data?.records) return;

    const createExcel = (exportData) => {
      const d = new Date();
      const stamp = `${d.getFullYear()}${d.getMonth()}${d.getDay()}-${d.getHours()}${d.getMinutes()}`;
      const fileName = `MANAGED_SERVICES_CMS_${stamp}.xlsx`;

      const ws = utils.json_to_sheet(exportData);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, `Services-${dappCntx.currentRecord["Job Num"]}`);
      writeFileXLSX(wb, fileName);
    }
    createExcel(managedsrvcsExport?.data?.records);

  }, [managedsrvcsExport, dappCntx])

  const handleSortChange = useCallback((sortmodel) => {
    if (!sortmodel) return;
    if (!!sortmodel && sortmodel.length <= 0) return;
    if (apiparms.SortField === sortmodel[0].field && apiparms.AscDesc === sortmodel[0].sort) return;

    setGridData([]);
    dispatch({type: "loading", loading: true});
    
    setApiparms({
      ...apiparms, 
      SortField: sortmodel[0].field,
      AscDesc: sortmodel[0].sort,
    });
  }, [apiparms]);

  const export2excel = useCallback(() => {
    swal({
      title: "Export Manged Services List",
      content: {
        element: "span",
        attributes: {
          innerHTML: EXPORTALERTMSG.replace('REPLACE-STRING', 'a List of Managed Services'),
          },
      },
      icon: undefined,
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: {
        confirm: true,
        cancel: true,
      },
      dangerMode: false,
    }).then((accepted) => {
      accepted && setExportParams({
        ...apiparms,
        PageNum: 1,
        PageSize: 2000,
      });
    });
  }, [apiparms]);
  
  return (
    <Fragment>
      <JobDetailsToolbar
        title1={toolbarTitle?.[0]}
        title2={toolbarTitle?.[1]}
        handleExport={export2excel}
        showExport={true}
      />
      <WrapperPaper {...other}>
        <DataGridPro
          className={clsx(gridClasses.gridheadfoot, gridClasses.gridrows)}
          rows={griddata}
          columns={managedCols}
          loading={paging.loading}
          paginationMode="server"
          autoPageSize
          density="compact"          
          rowCount={managedsrvcs?.data?.metadata?.count || 0}
          // page={paging.page}
          // pageSize={paging.pageSize}
          pageSizeOptions={rowsperpage}
          // onPageSizeChange={handlePageSizeChange}
          onPaginationModelChange={handlePaginationChange}
          sortingMode="server"
          onSortModelChange={handleSortChange}
          pagination
          initialState={{
            pagination: {
              paginationModel: {
                page: paging.page,
                pageSize: paging.pageSize,
              }
            }
          }}
        />
      </WrapperPaper>
      <CustomAlert {...showAlert} />
    </Fragment>
  );
};

const pageReducer = (state, action) => {
  switch (action.type) {
    case "page": 
      return {...state, page: action.page}
    case "pageSize": 
      return {...state, pageSize: action.pageSize}
    case "loading": 
      return {...state, loading: action.loading}
    default: 
      throw new Error();
  }
}
