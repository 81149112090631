import React, { useCallback, useEffect, useState } from 'react';
import { CoreForm, fetchForm } from '@kineticdata/react';
import {
  useLocation,
  // useHistory,
  // useLocation,
  useParams,
} from 'react-router-dom';
import qs from "qs";
import { WrapperPaper, useFormStyle } from '../../controls/shared.components';
import { Grid } from '@material-ui/core';

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

export const Form = props => {
  // const history = useHistory();
  // const query = useQuery();
  const urlParams = useLocation();
  const { kappSlug, formSlug, id } = useParams();

  // fetch and set form
  const [form, setForm] = useState();
  useEffect(() => {
    async function fetchFormRequest() {
      let response = await fetchForm({
        kappSlug,
        formSlug,
        include: 'kapp',
      });
      setForm(response.form);
    }
    fetchFormRequest();
  }, [kappSlug, formSlug]);

  // set navigation breadcrumbs using fetched form info
  useEffect(() => {
    if (!!form && !!props) return;
    props.setCrumbs([
      {
        path: '/kapps',
        name: 'Kapps',
      },
      {
        path: `/kapps/${kappSlug}/forms`,
        name: `${form ? form.kapp.name : 'Forms'}`,
      },
      {
        path: `/kapps/${kappSlug}/forms/${formSlug}/submissions`,
        name: `${form ? form.name : 'Form'}`,
      },
    ]);
  }, [form, props, kappSlug, formSlug]);

  const handleCreated = useCallback(
    ({ submission, history }) => {
      const { coreState, currentPage, displayedPage, id } = submission;
      if (
        coreState === 'Draft' ||
        (currentPage &&
          (!displayedPage || displayedPage.type === 'confirmation'))
      ) {
        // For Multipage forms
        history.push(`/kapps/${kappSlug}/forms/${formSlug}/submissions/${id}`);
      } else {
        history.push(`/kapps/${kappSlug}/forms/${formSlug}/submissions`);
      }
    },
    [kappSlug, formSlug],
  );

  // Form Saves
  const handleCompleted = useCallback(
    ({ history }) => {
      history.push(`/kapps/${kappSlug}/forms/${formSlug}/submissions`);
    },
    [kappSlug, formSlug],
  );

  const handleUpdate = useCallback(
    ({ history }) => {
      history.push(`/kapps/${kappSlug}/forms/${formSlug}/submissions`);
    },
    [kappSlug, formSlug],
  );

  //// original function that throws and exception (values expecting an object, not function)
  // const valuesFromQueryParams = () => {
  //   const params = qs.parse(urlParams.search);
  //   return Object.entries(params).reduce((values, [key, value]) => {
  //     if (key.startsWith('values[')) {
  //       const vk = key.match(/values\[(.*?)\]/)[1];
  //       return { ...values, [vk]: value };
  //     }
  //     return values;
  //   }, {});
  // };

  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    const params = qs.parse(urlParams.search.replace('?', ''));
    let parms = Object.entries(params).reduce((values, [key, value]) => {
      if (key.startsWith('values[')) {
        const vk = key.match(/values\[(.*?)\]/)[1];
        return { ...values, [vk]: value };
      }
      return {...values, ...value};
    }, {});
    setFormValues(parms);
  }, [urlParams]);

  const cls = useFormStyle();

  return (
    <WrapperPaper isForm={true}>
      <Grid item xs={8} className={cls.root}>
      <h1>{form && form.name}</h1>
      {id ? (
        <CoreForm
          submission={id}
          onCompleted={handleCompleted}
          onUpdated={handleUpdate}
          review={true}
          // // review={query.get('mode') === 'review'} <--- not implemented
          values={formValues}
        />
      ) : (
        <CoreForm 
          kapp={kappSlug} 
          form={formSlug} 
          onCreated={handleCreated} 
          values={formValues} 
        />
      )}
      </Grid>
    </WrapperPaper>
  );
};

