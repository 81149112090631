import { Dialog, DialogContent, DialogTitle, IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { CHATLINK } from "../../controls/dapp.constants";
import React from "react";

export const ChatModal = (props) => {
    const {open, handleClose, ...other} = props;
    const theme = useTheme();
    const screen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            fullScreen={screen}
            open={open} 
            onClose={handleClose}             
            {...other} 
            style={{display: "flex", flexFlow: "column"}}
        >
            <DialogTitle style={{backgroundColor: '#007dba', color: 'window'}}>
                Chat with Us
                <IconButton 
                    onClick={handleClose}
                    style={{position: 'absolute', right: 8, top: 8, color: 'inherit', backgroundColor: '#bc202f'}}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent style={{minWidth: 600, minHeight: 600, display: "flex", padding: "unset"}}>
                <iframe
                      title="Live-Remote-01"
                      id="remCtrlId"
                      src={CHATLINK}
                      style={{                        
                        width: '100%', 
                        minHeight: "inherit",                       
                        height: "inherit",
                        border: "none",
                        overflow: "hidden"
                      }}
                    />
            </DialogContent>
        </Dialog>
    )
}