import React, { createContext, useCallback, useContext, useState } from "react";
// import { rowsperpage } from "../../controls/shared.components";

const TicketsContext = createContext();

export const TicketsProvider = ({children}) => {
    const [srch, setSrch] = useState({...baseApiParams, ...filterApiParams});
    const [showSearch, setShowSearch] = useState();
    const [savedFilter, setSavedFilter] = useState();
    const [showAlert, setShowAlert] = useState({
        open: false, severity: 'success', message: '',
        onSnackBarClose: () => {},
      });
    

    const updateSearch = (values) => {
        setSrch({...srch, ...values});
    };

    const openSearchDialog = useCallback(() => {
        setShowSearch({...showSearch, open: true, values: srch});        
    }, [showSearch, srch])

    const closeSearchDialog = useCallback(() => {
        setShowSearch({...showSearch, open: false});
    }, [showSearch])

    const value = {
        openSearchDialog, closeSearchDialog, showSearch, srch, setSrch,
        defaultFilter: filterApiParams, updateSearch,
        savedFilter, setSavedFilter,
        showAlert, setShowAlert,
    }

    return (
        <TicketsContext.Provider value={value}>
            {children}
        </TicketsContext.Provider>
    )
}

export const useTicketsContext = () => useContext(TicketsContext);

const baseApiParams = {
    AscDesc: 'DESC',
    GetTotal: true,
    PageNum: 1,
    PageSize: 1,
    PageToken: ' ',
    SortField: 'IncidentID',
    showClosed: true,
    CustomerContactID: ' ',
    IsVIP: false,
    Status: ' ',
    Disposition: ' ',
  }

const filterApiParams = {
    SearchValue: ' ',
    SeverityShort: ' ',
    IncidentPriority: ' ',
    IncidentClass: ' ',
    IncidentSubClass: ' ',
    assignedTo: ' ',
    DepartmentID: ' ',
    ReAssignmentTemplateID: ' ',
    OpenDateStart: ' ',
    OpenDateStop: ' ',
    IsRequestScheduled: ' ',
    RequestedStartDate: ' ',
    RequestedStopDate: ' ',
}

