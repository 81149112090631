import React, { Fragment, useCallback, useState, useEffect } from "react";
import { Toolbar } from "@material-ui/core";
import { CoreForm } from "@kineticdata/react";
import { CustomAlert } from "../../alerts/alert.snackbar";
import { useParams } from "react-router-dom";
import { KappFormLayout, KappPending, WrapperPaper } from "../../controls/shared.components";
import { useDappContext } from "../../auth/dapp.context";
import { SingleJobToobar } from "./jobs.toolbar";
import { useHistory } from "react-router-dom";

export const AddEditJobs = () => {
    const { jobid } = useParams();
    const dappCntx = useDappContext();
    const history = useHistory();

    const [showMessage, setShowMessage] = useState({
        open: false, severity:"error", message: "",
        onSnackBarClose: () => {}
    });

    const handleSaveComplete = useCallback((params) => {
        console.log(`parameters coming from core form ::: `, params);
        setShowMessage({...showMessage, 
            open: true,
            severity: 'success', message: 'Job is saved',
            onSnackBarClose: () => { setShowMessage({ ...showMessage, open: false, message: 'rnadom'})}
        });
    }, [showMessage]);

    const handleError = useCallback((props) => {
        console.log(`this is on error form event ::`, props);
        setShowMessage({...showMessage, open: true, })        
    }, [showMessage]);

    useEffect(() => {
      dappCntx.dappSubtitle(`${(!!jobid && 'Edit') || 'New'} Job`, () => {});
      // eslint-disable-next-line
    }, [dappCntx.title]);

    return (
        <Fragment>
            {(!!jobid && 
            <SingleJobToobar 
                handleJobBalances={() => history.push(`/jobs/balances/${jobid}`)}
                handleJobReports={() => history.push(`/jobs/jobreports/${jobid}`)}
                handleProducts={() => history.push(`/jobs/products/${jobid}`)}
                handleManagedServices={() => history.push(`/jobs/services/${jobid}`)}
                handleFiles={() => history.push(`/jobs/files/${jobid}`)}
            />) || <Toolbar />}
            <WrapperPaper>
                <JobEditForm id={jobid} 
                  formType={(jobid && "update-job") || "new-job"}
                  handleError={handleError} 
                  handleSaveComplete={handleSaveComplete}/>
            </WrapperPaper>
            <CustomAlert {...showMessage}/>
        </Fragment>
    )
};

const JobEditForm = (props) => {
  const {id, handleError, handleSaveComplete, formType} = props;

  const values = (id && { 'Job ID': `${id}` }) || '';
  console.log(`jobid is not supported by the form: ${JSON.stringify(values)}`);

  return (
    <CoreForm
      kapp="customer-portal"
      form={formType}
      onError={handleError}
      onCompleted={handleSaveComplete}
      // values={values}
      components={{
        Pending: KappPending,
        Layout: KappFormLayout,
      }}
    />
  );
};

