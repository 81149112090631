import React, { useCallback, useEffect, useState } from "react";
import { Button, Checkbox, FormControlLabel, Popover, TextField, Typography } from "@material-ui/core";
import { useFilterStyles } from "../../modals/filter.styles";
import { useDialogStyles } from "../../modals/dialog.style";
import { PropTypes } from "prop-types";
import { useTicketsContext } from "../incident.provider";

export const SaveFilter = (props) => {
    const {
        parentEl, showSave, handleClose, handleSave
    } = props;
    const [filterName, setFilterName] = useState();
    const [isPublic, setIsPublic] = useState();

    const filterStyles = useFilterStyles();
    const diagStyles = useDialogStyles();    
    const srchCntx = useTicketsContext();

    useEffect(() => {
        if (!!srchCntx?.savedFilter){
            setFilterName(srchCntx.savedFilter.SearchName);
            setIsPublic(srchCntx.savedFilter.IsPublic)
        }
    }, [srchCntx]);

    const handleChange = useCallback((event) => {
        setFilterName(event.target.value);
    }, []);

    const handlePublicChange = useCallback((event) => {
        setIsPublic((event.target.checked && '-1') || '0');
    }, [])

    const onSaveFilter = useCallback(() => {
        let filter2save = {...srchCntx.savedFilter, SearchName: filterName, IsPublic: isPublic};
        if (!!handleSave && typeof handleSave === 'function'){
            handleSave(filter2save);
        }
    }, [srchCntx, filterName, handleSave, isPublic]);

    return (
        <Popover 
            open={showSave} 
            anchorEl={parentEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            transitionDuration={800}
        >
            <Typography component={`div`} className={filterStyles["wrapper-root"]}>
                <Typography component={'div'} style={{
                    width: parentEl?.offsetWidth || 0, height: 120,
                    display: "flex", justifyContent: "center", alignItems: "center", flexFlow: "row", 
                }}>
                    <Typography component={'div'} style={{flexGrow: 1, textAlign: "end"}}>
                        Save As:
                    </Typography>
                    <Typography component={'div'} style={{flexGrow: 1, padding: "0 1.5rem 0 1.5rem"}}>
                        <TextField 
                            variant="outlined"
                            size="small"
                            fullWidth={true}
                            value={filterName || ''}
                            onChange={handleChange}
                            required
                        />
                    </Typography>
                    <Typography component={'div'}>
                        <FormControlLabel control={
                            <Checkbox checked={parseInt(isPublic) === -1} onChange={handlePublicChange} />                            
                        } label="Public"/>
                    </Typography>
                </Typography>
                <Typography component={`div`} className={diagStyles["filter-actions"]}>
                        <Button title="Save" 
                            onClick={onSaveFilter} 
                            color="primary" 
                            disabled={!srchCntx?.savedFilter || filterName?.length <= 0}
                        >
                            Save
                        </Button>
                        <Button title="Cancel" onClick={handleClose} color="secondary">Cancel</Button>
                </Typography>
            </Typography>
        </Popover>
    )
}

SaveFilter.prototype = {
    parentEl: PropTypes.element.isRequired,
    showSave: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
};