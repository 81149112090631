import axios from 'axios';
import { APP_API_URL, CMSAPIKEY, KappBridge } from '../controls/dapp.constants';
import PropTypes from "prop-types";
//overwrite lib function with original modified copy
//import { fetchBridgedResource } from '@kineticdata/react';
// this is original modified copy per Ani...
import { fetchBridgedResource_Ex } from './kinetic.bridge';

const cmstypes = ['contact, job', 'device', 'incident', 'product', 'jobreport', 'action']

export const getDataUniversal = (props) => {
    const { user, apiparams, cmstype } = props;

    const instance = axios.create({
        cancelToken: axios.CancelToken.source().token,
        headers: { 
            'X-User': JSON.stringify(user),
            CmsApiKey:CMSAPIKEY
        },
    });

    const apiCall = async () => await instance.get(        
        `${APP_API_URL}/${cmstype}`, {params: { ...apiparams }});

    return apiCall();
}

getDataUniversal.PropTypes = {
    user: PropTypes.object.isRequired,
    apiParams: PropTypes.object.isRequired,
    cmstype: PropTypes.oneOf(cmstypes),
}

export const getDataUniversalFromBrdige = async (props) => {
    const { xUser, apiValues, bridgedResourceName } = props;

    if (!xUser || !apiValues) return;

    const bridgeConfig = {
        ...KappBridge, bridgedResourceName: bridgedResourceName,
      values: { ...apiValues, 'X-User': JSON.stringify(xUser) },
    };

    const data = await fetchBridgedResource_Ex(bridgeConfig);
    return data;
}

export const getDataUniversalExportFromBrdige = async (props) => {
    const { xUser, apiValues, bridgedResourceName } = props;

    if (!xUser || !apiValues) return;

    const bridgeConfig = {
        ...KappBridge, bridgedResourceName: bridgedResourceName,
      values: { ...apiValues, 'X-User': JSON.stringify(xUser) },
    };

    const data = await fetchBridgedResource_Ex(bridgeConfig);
    return data;
}


getDataUniversalFromBrdige.PropTypes = {
    xUser: PropTypes.object.isRequired,
    apiValues: PropTypes.object.isRequired,
    bridgedResourceName: PropTypes.string.isRequired,
}
