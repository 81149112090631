import React from "react";
import { Toolbar, Divider, Box, Grid, Typography, MenuItem, FormLabel, Select, FormControl, debounce, Tooltip } from "@material-ui/core";
import { useToolbarStyle } from "../../controls/shared.components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faExclamation, faChartBar, faFolder, faAdd, faRefresh, faSearch, faEdit, faFileExcel, faStar,
} from "@fortawesome/free-solid-svg-icons";
import { ToolbarButton } from "../../controls/shared.components";
import { useCallback, useState } from "react";
import { blue } from "@material-ui/core/colors";
import { useTicketsContext } from "./incident.provider";
import { SaveCancelToolbarButtons } from "../../controls/savecancel.buttons";
import { useHistory, useLocation } from "react-router-dom";
import { Fragment } from "react";
import { QuickLookup } from "../../controls/search.box";
import { useDappContext } from "../../auth/dapp.context";

export const IncidentEditToolbar = (props) => {
    const { 
        handleActions, handleStats, handleFiles, isNew, title1, title2,
        handleSaveForm, handleCancelChanges, type, editEnabled,
        handleStatusUpdate,
    } = props;

    const cls = useToolbarStyle();
    const location = useLocation();
    const history = useHistory();
    const dappCntx = useDappContext();

    return (
        <Toolbar className={cls.toolbarroot}>
            {(editEnabled &&
            <ToolbarButton name="Edit" style={(location.pathname.includes("view") && {display: ""}) || {display: "none"}}                
                handleClick={() => history.push(location.pathname.replace("view", "edit"))}
                tooltip="Edit Ticket"
                icon={<FontAwesomeIcon icon={faEdit}/>}
            />) || <Fragment/>}
            <ToolbarButton name="Actions"
                handleClick={(event) => {
                    handleActions(event);
                }}
                icon={<FontAwesomeIcon icon={faExclamation}/>}
                tooltip="Actions"
                style={(isNew && {display: "none"}) || {display: ""}}
            />
            <ToolbarButton name="Stats"
                handleClick={handleStats}
                icon={<FontAwesomeIcon icon={faChartBar}/>}
                tooltip="Stats"
                style={{display: "none"}}
            />
            <Divider orientation="vertical" style={{display: "none"}}/>
            <ToolbarButton name="Files"
                handleClick={handleFiles}
                icon={<FontAwesomeIcon icon={faFolder}/>}
                tooltip="Files"
                style={{display: "none"}}
            />
            <ToolbarButton name="Status"
                handleClick={handleStatusUpdate}
                icon={<FontAwesomeIcon icon={faStar}/>}
                tooltip="Request Status Update"
                style={(((isNew || (parseInt(dappCntx?.currentRecord?.AssignedCCWebUserID || 0 ) > 0)) && {display: "none"})) || {display: ''}}
            />
            <Box sx={{flexGrow: 1}}>
                <Grid container >
                    <Grid item xs={12}>
                        <Typography component={`div`} className="ticket-subtitle-body">
                            {(!isNew && `Ticket #: ${title1}`) || 'New Ticket'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {(!isNew &&
                        <Tooltip title={title2 || ''}>
                        <Typography component={`div`} variant="body2" className="ticket-subtitle-body">
                            {title2}
                        </Typography>
                        </Tooltip>) || <></>}
                    </Grid>
                </Grid>
            </Box>
            {(type === 'edit' && 
            <SaveCancelToolbarButtons 
                handleSave={handleSaveForm}
                handleCancel={handleCancelChanges}
            />) || <Fragment/>}
        </Toolbar>
    )
} 

export const IncidentToolbar = (props) => {
    const {
        onAdd, onRefresh, ticketType, 
        handleStatusChange, handleDispositionChange,
        handleSearch, handleExport, ...other
    } = props;
    const [statusvalue, setStatusValue] = useState("Open");
    const [dispositionValue, setDispositionValue] = useState(" ");
    const cls = useToolbarStyle();
    const ticketsCntx = useTicketsContext();
    const dappCntx = useDappContext();

    const onStatusChange = useCallback((e) => {
        setStatusValue(e.target.value);
        if (!!handleStatusChange && typeof handleStatusChange === 'function'){
            handleStatusChange({status: e.target.value});
        }
    }, [handleStatusChange]);

    const onDispositionChange = useCallback((e) => {
        setDispositionValue(e.target.value);
        if (!!handleDispositionChange && typeof handleDispositionChange === 'function'){
            handleDispositionChange({disposition: e.target.value});
        }
    }, [handleDispositionChange]);

    const onFilter = useCallback(() => {
        ticketsCntx.openSearchDialog();
    }, [ticketsCntx]);

    const onSearchChange = debounce((val) => {
        if (handleSearch && typeof handleSearch === 'function'){
            handleSearch(val);
        }
    }, 800);

    return (
        <Toolbar {...other} className={cls.toolbarroot}>
            <ToolbarButton
                name="New"
                handleClick={onAdd}
                tooltip={`New ${ticketType}`}
                icon={<FontAwesomeIcon icon={faAdd} />}
            />
            <Box sx={{flexGrow: 0.5, display: "flex"}}>
                <Typography component={`div`} style={{width: "50%", margin: "auto", color: "white"}}>
                    <FormControl size="small">
                    <FormLabel component="span" style={{color: "white", fontSize: "0.75em"}}
                        id="invoice-status-label"
                    >Status</FormLabel>
                    <Select id="invStatus" value={statusvalue}
                        variant="outlined"
                        labelId="invoice-status-label"
                        size="small"
                        style={{background: blue[50], minWidth: 120}}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                            getContentAnchorEl: null,
                        }}
                        onChange={onStatusChange}>
                        <MenuItem value="<ALL>">(All)</MenuItem>    
                        <MenuItem value="Open">Open</MenuItem>
                        <MenuItem value="Closed">Closed</MenuItem>
                        <MenuItem value="Escalation">Escalation</MenuItem>
                        <MenuItem value="Paused">Paused</MenuItem>
                        <MenuItem value="On Hold">On Hold</MenuItem>
                    </Select>
                    </FormControl>
                </Typography>
                <Typography component={`div`} style={{ width: "50%", margin: "auto", color: "white" }}>
                    <FormControl size="small">
                        <FormLabel component="span" style={{ color: "white", fontSize: "0.75em"}}
                            id="invoice-disposition-label"
                        >Disposition</FormLabel>
                        <Select id="invDisposition" 
                            value={dispositionValue}
                            variant="outlined"
                            labelId="invoice-disposition-label"
                            size="small"
                            style={{ background: blue[50], minWidth: 120 }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            onChange={onDispositionChange}>
                            <MenuItem value=" ">(None)</MenuItem>
                            <MenuItem value="Resolved">Resolved</MenuItem>
                            <MenuItem value="Unresolved">Unresolved</MenuItem>
                        </Select>
                    </FormControl>
                </Typography>
            </Box>
            <Box style={{flexGrow: 0.5}} />
            {(dappCntx?.user?.user.UserType !== "User" &&
            <Typography component={`div`} style={{minWidth: 320, widh: '40%', padding: "0 0.5rem 0 0"}}>
                <QuickLookup 
                    placeholder="SEARCH INCIDENT"
                    onInputChange={(e) => onSearchChange(e.target.value)}            
                />
            </Typography>) || <Fragment/>}
            <ToolbarButton 
                name="filter"                
                handleClick={onFilter}
                tooltip="Filter Tickets"
                icon={<FontAwesomeIcon icon={faSearch} />}
            />
            <ToolbarButton
                name="Export"
                handleClick={handleExport}
                tooltip="Export to Excel"
                icon={<FontAwesomeIcon icon={faFileExcel}/>}
            />
            <ToolbarButton
                name="Refresh"
                handleClick={onRefresh}
                tooltip="Services Settings"
                icon={<FontAwesomeIcon icon={faRefresh} />}
            />
        </Toolbar>
    )    
}