import { makeStyles } from "@material-ui/core";

export const useDialogStyles = makeStyles(() => ({    
    title: {
        padding: "8px 8px",
    },
    closeCross: {
        color: 'inherit', 
        backgroundColor: '#bc202f',
        borderRadius: "unset",
    },
    dropdown: {
        '& .MuiInputBase-input': {
            fontSize: '0.8rem',             
            '& .MuiAutocomplete-poppover .MuiAutocomplete-listbox .MuiAutocomplete-option .MuiAutocomplete-input': {
                fontSize: "0.8rem",
            }
        },
        '&  .MuiTextField-root': {
            fontSize: '0.8rem', 
        }
    },
    autoCpmleteList: {
        '& .MuiAutocomplete-listbox': {
            fontSize: "0.8rem"
        }
    },
    dropdownItem: {
        '& .MuiMenuItem-root': {
            fontSize: '0.8rem',
        },
    },
    "title-wrapper": { 
        display: "flex", 
        flexFlow: "row",    
        backgroundColor: "#007dba",
        color: "window",        
    },
    "filter-wrapper": {
        width: 500, 
        minHeight: 280, 
        display: "flex", 
        flexFlow: "column",
    },
    "filter-selection": {
        flexGrow: "0.9",
        alignItems: "center",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
    },
    "filter-actions": {
        flexGrow: "0.1",
        display: 'flex',
        alignItems: "center",
        justifyContent: "right",
    },
    "my-switch": {
        "& .MuiFormControlLabel-label": {
            fontSize: "0.6rem",
        }                
    }
}));

export const useAutoStyles = makeStyles(() => ({
    paper: {
        fontSize: "0.8rem",
    }
}));
