import { useMemo } from "react";

export const useInvoiceDetailsColumns = (props) => {
    return useMemo(() => [
        {
            field: 'Spacer-1', headerName: ' ', disableColumnMenu: true, width: 50,
        },
        {
            field: "Item", headerName: "Item", disableColumnMenu: true, width: 240,
        },
        {
            field: "Description", headerName: "Description", disableColumnMenu: true, flex: 2,
        },
        {
            field: "Quantity", headerName: "Qty", width: "120", align: "right", disableColumnMenu: true,
            headerAlign: "right", type: "number",
            valueFormatter: (props) => {
                Number((isNaN(props.value) && 0) || props.value).toFixed(2)
            },
        },
        {
            field: "Price", headerName: "Price", width: "160", align: "right", disableColumnMenu: true,
            headerAlign: "right", type: "number",
            valueFormatter: (props) => `$${props.value}`,
        },
        {
            field: "Total", headerName: "Total", width: "160", align: "right", disableColumnMenu: true,
            headerAlign: "right", type: "number",
            valueFormatter: (props) => {
                let row = props.api.getRow(props.id);
                return `$${((Number((isNaN(row.Quantity) && 0) || row.Quantity)) * Number(row.Price)).toFixed(2)}`
            },
        },
        {
            field: 'Spacer-2', headerName: ' ', disableColumnMenu: true, width: 30,
        },
    ], []);
}