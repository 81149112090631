import React, { useCallback, useState } from 'react';
import { Fragment } from 'react';
import { history } from '../../index';
import { 
  Typography, Paper, Grid, Button, InputAdornment, Divider,
  TextField, IconButton, Dialog, DialogContent, DialogTitle, Tooltip,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { Visibility, VisibilityOff, EmailOutlined } from '@material-ui/icons';
import { useEffect } from 'react';
import { CustomAlert } from '../../alerts/alert.snackbar';
import { useTheme } from "@material-ui/core/styles";
import { useLocation } from 'react-router-dom';

export const KLogin = ({
  error,
  onChangePassword,
  onChangeUsername,
  onLogin,
  password,
  pending,
  redirect,
  username,
}) => {

  console.log(`the login is here...`);
  const redirectCallback = redirect ? () => history.push('/') : null;

  const onSubmit = useCallback(event => onLogin(event, redirectCallback), [
    onLogin,
    redirectCallback,
  ]);
  return (
    <div className="login__wrapper">
      <span>
        <h1>Login</h1>
        <form>
          {error && <div style={{ color: 'red' }}>{error}</div>}
          <input
            type="text"
            name="username"
            placeholder="Username"
            onChange={onChangeUsername}
            value={username}
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            onChange={onChangePassword}
            value={password}
          />
          <button disabled={pending} type="submit" onClick={onSubmit}>
            Login
          </button>
        </form>
      </span>
    </div>
  );
};

export const DLogin = ({
  error, onChangePassword, onChangeUsername, onLogin, 
  password, pending, redirect, username,
}) => {

  const [showPass, setShowPass] = useState(false);
  const [showAlert, setShowAlert] = useState({
    open: false, severity: 'success', message: '',
    onSnackBarClose: () => {},
  });

  const currentLocation = useLocation();

  //// if we have any field validators we can add it here and try 
  //// to validate fields before submitting them like email format and or strong password.
  // const [fieldErrors, setFieldErrors] = useState({
  //   email: false, password: false
  // });

  const theme = useTheme();

  // const redirectCallback = redirect ? () => history.push('/') : null;
  const redirectCallback = useCallback(() => {
    history.replace(currentLocation.pathname);
  }, [currentLocation]);

  const handleSubmit = (event) => {    
    onLogin(event, redirectCallback)
  };

  const handleShowPass = useCallback(() => {
    setShowPass(!showPass)
  }, [showPass])

  useEffect(() => {
    if (!error) return;
    setShowAlert({ open: true, severity: 'error', message: error, 
    onSnackBarClose: () => setShowAlert({ open: false, severity: 'error', message: 'random'})
  });
  }, [error])

  const handleNewUser = () =>  history.replace("/register");

  const handleReset = () => history.replace("/retrieve");

  return (
    <Fragment>
      <Typography
        component={`div`}
        variant="h4"
        style={{
          display: 'flex',
          margin: 'auto',
          justifyContent: 'center',
        }}
      >
        <Paper
          elevation={4}
          style={{
            minHeight: 470,
            minWidth: 420,
            height: 360,
            width: 360,
            borderRadius: 15,
          }}
        >
          <form
            onSubmit={handleSubmit}
            style={{ display: 'flex', flexFlow: 'column', height: '100%' }}
          >
            <Grid container style={{ height: 'inherit' }}>
              <Grid item xs={12}
                style={{
                  height: '10%',
                  borderTopLeftRadius: 15,
                  borderTopRightRadius: 15,
                  backgroundColor: 'Highlight',
                }}
              >
                <Typography
                  component={`div`}
                  style={{ 
                    textAlign: 'center', fontSize: '1.4rem',
                    color: theme.palette.background.paper,
                  }}
                >
                  Login
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ height: '40%' }}>
                <Typography
                  component={`div`}
                   color="primary"
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexFlow: 'column',
                    justifyContent: 'center',
                    fontSize: '4.5rem',
                    color: theme.palette.primary.light
                  }}
                >
                  <FontAwesomeIcon icon={faRightToBracket} />
                </Typography>
              </Grid>
              <Grid item xs={4}
                style={{
                  height: '15%',
                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography component={`div`} style={{ textAlign: 'end' }}>
                  Email Address:
                </Typography>
              </Grid>
              <Grid item xs={8}
                style={{
                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                  paddingRight: '.6rem',
                }}
              >
                <TextField
                  name="email"
                  variant='outlined'
                  value={username}
                  onChange={onChangeUsername}
                  size="small"
                  required
                  autoComplete="off"
                  onBlur={() => {}}
                  // error={fieldErrors.email}
                  // helperText={fieldErrors.emailHelper}
                  label="Email Address"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ color: 'InactiveCaptionText' }}
                      >
                        <EmailOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}
                style={{
                  height: '15%',
                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography component={`div`} style={{ textAlign: 'end' }}>
                  Password:
                </Typography>
              </Grid>
              <Grid item xs={8}
                style={{
                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                  paddingRight: '.6rem',
                }}
              >
                <TextField variant='outlined'
                  type={showPass ? `text` : `password`}
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  size="small"
                  label="Password"
                  required
                  autoComplete="off"
                  onBlur={() => {}}
                  // error={fieldErrors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ color: 'InactiveCaptionText' }}
                      >
                        <IconButton onClick={handleShowPass}>
                          {showPass ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Divider orientation="horizontal" />
              <Grid item xs={12} style={{ height: '20%', justifyItems: 'center' }}>
                <Grid container spacing={2} style={{ marginTop: '0.1rem' }}>
                  <Grid item xs={12}
                    style={{
                      display: 'flex',
                      flexFlow: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleSubmit}
                      type="submit"
                      disabled={pending}                      
                    >
                      Login
                    </Button>
                  </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} style={{display: 'flex', flexFlow: 'column', alignItems: 'end', }}>
                          <Typography style={{fontSize: "0.75rem"}}>
                            <Tooltip title="New User Registration">
                              <Button variant='text' color='primary' onClick={handleNewUser}>Register</Button> 
                            </Tooltip>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} style={{display: 'flex', flexFlow: 'column', alignItems: 'start', }}>
                          <Typography style={{fontSize: "0.75rem"}}>
                            <Tooltip title="Forgot Password">
                              <Button variant='text' color='secondary' onClick={handleReset}>Forgot Password</Button>
                            </Tooltip>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
          </form>
        </Paper>
      </Typography>
      <CustomAlert
        open={showAlert.open}
        severity={showAlert.severity}
        location={showAlert.location}
        message={showAlert.message}
        onSnackbarClose={showAlert.onSnackBarClose}
      />
    </Fragment>
  );
}

export const LoginDialog = ({children, open}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Your login has expired. Please, enter your credentials</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  )
}