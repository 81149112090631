import React, { useMemo } from "react";
import { IconButton, Link, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { lightBlue, blue} from "@material-ui/core/colors";
import { ContentState, convertFromHTML } from "draft-js";

export const useActionColumns = ({handleEdit, selectedRowIndex, handleContact, userType}) => {

    return useMemo(() => [
        {field: "Spacer", headerName: " ", width: 20, disableColumnMenu: true},
        {
            field: "Start Date", headerName: "Date", flex: .5, disableColumnMenu: true,
            renderCell: (params) => {
                let date = new Date(params.value);
                return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
            }
        },        
        {
            field: "Display Name", headerName: "Staff", flex: .6, disableColumnMenu: true,
            renderCell: (params) => {
                return ((userType && userType === "Administrator" && params.row["ActionContactID"] &&
                    <Link component={`button`} variant="body2" underline="none"
                    onClick={() => handleContact({contactID: params.row["ActionContactID"]})}>
                        {params.row['User Name'] || ''}
                    </Link>) || params.row['User Name'] || ''
                )
            },            
        },
        {
            field: "Action Desc", headerName: "Action Taken", flex: 1.6, disableColumnMenu: true,
            valueGetter: (params) => {
                const content = convertFromHTML(params.row?.['Action Desc']);
                const state = ContentState.createFromBlockArray(
                    content.contentBlocks, content.entityMap
                );
                const textValue = state.getPlainText();
                return textValue;
            },
        },
        // {
        //     field: "Status", headerName: "Status", flex: 0.3, disableColumnMenu: true,
        // },
        {
            field: "Incident Priority", headerName: "Tier", flex: 0.2, disableColumnMenu: true,
        },
        {
            field: "Actions", headerName: " ", width: 40, disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <React.Fragment>
                        <Tooltip title="View Action">
                            <IconButton id="editRecord" 
                            style={
                                (selectedRowIndex === params.row.id && {fontSize: '1.2rem', color: blue[500]}) || {fontSize: '.90rem', color: lightBlue[300]}
                            }
                                onClick={(event) => {
                                    handleEdit({event: event, row: params.row});
                                    event.preventDefault();
                                }}>
                                <FontAwesomeIcon icon={faEdit}/>
                            </IconButton>
                        </Tooltip>
                    </React.Fragment>
                );
            },
        }
    ], [handleEdit, selectedRowIndex, handleContact, userType]);
}