import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { rowsperpage, WrapperPaper } from '../../controls/shared.components';
import { SortMapper, useBalancesColumns } from './balances.hooks';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useGridStyles } from '../../controls/datagrid.striped';
import { useDataFromBridge } from '../../controls/custom.hooks';
import { CustomAlert } from '../../alerts/alert.snackbar';
import { useDappContext } from '../../auth/dapp.context';
import { useHistory, useParams } from 'react-router-dom';
import { useReducer } from 'react';
import { JobDetailsToolbar } from '../jobs/jobs.toolbar';
import clsx from "clsx";
import { TextField, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { DappGridFooter } from '../../controls/grid.footer';
import swal from 'sweetalert';
import { utils, writeFileXLSX } from 'xlsx';
import { EXPORTALERTMSG } from '../../controls/dapp.constants';

const initLoadParams = {
  AscDesc: 'desc',
  GetTotal: true,  
  OrderBy: 'TransID',
  PageNum: ' ',
  asOfDate: ' ',
  PageSize: ' ',
};

const bridgedResourceName = "All Customer Balances";
const bridgedResourceNameExport = "AllCustomerBalancesExport";

export const CustomerBalances = props => {
  const { ...other } = props;
  const [loading, setLoading] = useState(true);
  const [apiparms, setApiparms] = useState(initLoadParams);
  const [griddata, setGridData] = useState([]);
  const [toolbarTitle, setToolbarTitle] = useState();
  const [jobbyid, setJobbyid] = useState();
  const gridClasses = useGridStyles();
  const [asOfDate, setAsOfDate] = useState(new Date(new Date().getFullYear() - 1, 0, 1).toLocaleDateString());
  const [total, setTotal] = useState(0);
  const [balanceForward, setBalanceForward] = useState(0);
  const [showAlert, setShowAlert] = useState({
    open: false, message: '', severity: "error", 
    onSnackBarClose: () => {},
  });
  
  const [exportParams, setExportParams] = useState();

  const [pagingState, dispatch] = useReducer(pageReducer, initState);
  const [xuser, setXuser] = useState();

  const { jobid } = useParams();
  const dappCntx = useDappContext();
  const history = useHistory();

  useEffect(() => {
    if(!dappCntx.user) return;
    setXuser(dappCntx.user.user);
  }, [dappCntx, jobid, apiparms])

  useEffect(() => {
    setApiparms({
      ...initLoadParams, 
      JobID: jobid,
     asOfDate: asOfDate,
    });
    setJobbyid({JobID: jobid});    
  }, [jobid, asOfDate])

  useEffect(() => {
    dappCntx.dappSubtitle("Customer Balances");
  }, [dappCntx])

  useEffect(() => {
    if (!dappCntx.currentRecord) return;
    setToolbarTitle([`${dappCntx.currentRecord["Job Num"]}`, `${dappCntx.currentRecord?.InternalShortDesc || ''}`]);
  }, [dappCntx.currentRecord])

  const jobRecord = useDataFromBridge({
    xUser: xuser, apiValues: jobbyid,
    bridgedResourceName: "Job by ID"
  });

  useEffect(() => {
    if (!jobRecord || !jobRecord?.data) return;
    dappCntx.setCurrentRecord(jobRecord?.data.record);
  }, [dappCntx, jobRecord]);

  const balances = useDataFromBridge({ 
    xUser: xuser, apiValues: apiparms,
    bridgedResourceName: bridgedResourceName
  });

  const balancesExport = useDataFromBridge({ 
    xUser: xuser, apiValues: exportParams,
    bridgedResourceName: bridgedResourceNameExport
  });

  const gridcols = useBalancesColumns({
    handleViewDetails: ({invoiceRecord}) => {
      dappCntx.setCurrentRecord(invoiceRecord);
      history.push(`/jobs/balances/details/${invoiceRecord["Transaction ID"]}`);
    },
    handleViewTime: ({invoiceID}) => {
      history.push(`/jobs/jobreports/${jobbyid.JobID}/${invoiceID}`);
    }
  });

  const handlePaginationChange = useCallback((model, details) => {
    if (model.pageSize !== pagingState.pageSize)
      model.page = 0;

    dispatch({type: "page", page: model.page});
    dispatch({type: "pageSize", pageSize: model.pageSize});
    }, [pagingState],
  );

  const showError = useCallback(({message}) => {
    setShowAlert({...showAlert, open: true, 
        severity: "error", message: message,
        onSnackBarClose: () => { setShowAlert({open: false, message: "random"})}
    });
  }, [showAlert])

  useEffect(() => {
    if (!balances || !balances?.data) return;

    setLoading(false);
    if (!balances.success) {
      showError({message: balances.data.Message || balances.data.message});
      return;
    } else if (balances.data.error) {
      showError({message: balances.data.error.message});
      return;
    }

    const balanceForward = balances.data.records.find(x => x["Transaction Type"] === "Balance Forward");
    if (balanceForward){
      const indexOf = balances.data.records.indexOf(balanceForward);
      balances.data.records.splice(indexOf, 1);
    }
    setBalanceForward(balanceForward?.Amount || 0);

    const trxTotal = balances.data.records.find(x => x["Transaction Type"] === "Total");
    if (trxTotal){
      const indexOftotal = balances.data.records.indexOf(trxTotal);
      balances.data.records.splice(indexOftotal, 1);
    }
    setTotal((balanceForward?.Amount || 0) + balances.data.records.reduce((tot, rec) => tot + (((rec["Transaction Type"] !== "INVOICE" && 1) || -1) * parseFloat(rec.Amount, 2)), total));

    const data2show = balances.data.records.map((row, indx) => { 
      return {...row, id: indx}
    }).sort((a, b) => { 
      let frst = ((Number(a["InvoiceAppliedID"]) < 0 && Number(a["Transaction ID"])) || Number(a["InvoiceAppliedID"]));
      let scnd = ((Number(b["InvoiceAppliedID"]) < 0 && Number(b["Transaction ID"])) || Number(b["InvoiceAppliedID"]));
      return (frst - scnd); 
    });

    setGridData(data2show);
    // eslint-disable-next-line
  }, [balances]);

  const handleSortChange = useCallback((props) => {
    if (!props) return;
    const fld = SortMapper.find(x => x.field === props[0]?.field)?.sortBy;
    if (apiparms.OrderBy === fld && apiparms.AscDesc === props[0]?.sort) {
      return;
    }
    setApiparms({...apiparms, AscDesc: `${props[0].sort}`, OrderBy: `${fld}`});
  }, [apiparms]);

  const handleAsOfDateChange = (e) => {    
    setAsOfDate(e.target.value)
  };

  useEffect(() => {
    if (!balancesExport || !balancesExport.data?.records) return;

    const export2excel = (exportData) => {
      const d = new Date();
      const stamp = `${d.getFullYear()}${d.getMonth()}${d.getDay()}-${d.getHours()}${d.getMinutes()}`;
      const fileName = `INVOICES_CMS_${stamp}.xlsx`;
  
      const ws = utils.json_to_sheet(exportData);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Invoices");
      writeFileXLSX(wb, fileName);
    }
    export2excel(balancesExport?.data?.records);
    // eslint-disable-next-line
  }, [balancesExport]);

  const handleExport = useCallback(() => {
    swal({
      title: "Export Invoices",
      content: {
        element: "span",
        attributes: {
          innerHTML: EXPORTALERTMSG.replace('REPLACE-STRING', 'Inovices for this Job'),
        },
      },
      icon: undefined,
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: {
        confirm: true,
        cancel: true,
      },
      dangerMode: false,
    }).then((accepted) => {
      accepted && setExportParams({...apiparms, PageNum: 1, PageSize: 2000});
    });
  }, [apiparms]);

  return (
    <Fragment>
      <JobDetailsToolbar 
        title1={toolbarTitle?.[0]} 
        title2={toolbarTitle?.[1]}
        handleExport={handleExport}
        showExport={true}
      />
      <WrapperPaper {...other}>
        <BalanceHeader 
          asOfDate={asOfDate}
          balanceForward={balanceForward} 
          handleDateChange={handleAsOfDateChange}
        />
        <Typography component={`div`} style={{overflowY: "auto", flex: 1}} className='grid-wrapper-div'>
        <DataGridPro
          className={clsx(gridClasses.gridheadfoot, gridClasses.gridrows)}
          rows={griddata}          
          columns={gridcols}
          density="compact"
          loading={loading}
          paginationMode="client"
          autoPageSize
          rowCount={balances?.data?.records?.length}
          pageSizeOptions={rowsperpage}
          onPaginationModelChange={handlePaginationChange}
          sortingMode="client"
          onSortModelChange={handleSortChange}
          pagination
          initialState={{
            pagination: {
              paginationModel: {
                page: pagingState.page,
                pageSize: pagingState.pageSize
              }
            }
          }}
          slots={{
            pagination: DappGridFooter,
          }}
          slotProps={{
            pagination: {total: parseFloat(total, 2).toFixed(2)}
          }}
        />
        </Typography>
      </WrapperPaper>
      <CustomAlert {...showAlert}/>
    </Fragment>
  );
};


const initState = {page: 0, pageSize: rowsperpage[0]};

const pageReducer = (state, action) => {
  switch (action.type) {
    case "page": 
      return {page: action.page};
    case "pageSize":
      return {pageSize: action.pageSize};
    default:
      throw new Error();
  }
}

const useBalanceHeaderStyles = makeStyles((theme) => ({
  balanceHeader: {
    display: "flex", 
    flexFlow: "row", 
    margin: "0.8rem !important",
    '& .spacer': {
      flex: 0.5
    },
    '& .header-label': {
      flexWrap: "wrap", 
      display: "flex", 
      alignContent: "center", 
      marginRight: "1rem",
      fontWeight: 600, 
      color: "#7788BE",     
    },
    '& .header-forward': {
      flex: 1, 
      display: "flex", 
      justifyContent: "flex-end",
    },
  },
  'date-field': {
    color: "#7788BE !important",
    fontWeight: "700 !important",
  },
  "total-field": {
    color: "#7788BE !important",
    textAlign: "end",
    fontWeight: "700 !important",
  },
}));

const BalanceHeader = (props) => {
  const {asOfDate, handleDateChange, balanceForward} = props;
  const stls = useBalanceHeaderStyles();
  return (
    <Typography component={`div`} className={stls.balanceHeader}>
      <Typography component={`div`} className='spacer'>
      </Typography>
      <Typography component={'span'} className='header-label'>As of Date:</Typography>
      <Typography component={`div`}>
          <TextField
            id="AsOfDate"
            variant="outlined"
            type="date"
            size='small'
            value={new Date(asOfDate)?.toJSON()?.split("T")[0]}
            onChange={handleDateChange}
            InputLabelProps={{
              shrink: true,              
            }}
            inputProps={{
              className: stls['date-field'],              
            }}
          />
      </Typography>
      <Typography component={`div`} className='header-forward'>
        <Typography component={`span`} className='header-label'>Balance Forward:</Typography>
          <TextField 
            id="balance-forward"
            variant='outlined'
            type="text"
            size='small'
            value={parseFloat(balanceForward, 2).toFixed(2)}
            className={stls['total-field']}
            InputProps={{
              startAdornment: <FontAwesomeIcon icon={faDollarSign}/>
            }}
            inputProps={{
              className: stls['total-field'],
            }}
          />
      </Typography>
    </Typography>
  )
}