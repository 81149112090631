import React, { Fragment } from "react";
import { useEffect } from "react";
import { useDappContext } from "../../auth/dapp.context";
import { useDataFromBridge } from "../../controls/custom.hooks";
import { useState } from "react";
import { useCallback } from "react";
import { CustomerRepCard, ITConsultantCard, TechManagerCard } from "./team.cards";
import { Grid } from "@material-ui/core";
import { CustomAlert } from "../../alerts/alert.snackbar";

export const TeamList = ({onShowWhite, ...other}) => {
    const [xuser, setXuser] = useState(null);
    const [data2show, setData2Show] = useState([]);
    const [showAlert, setShowAlert] = useState({
        open: false, severity: "error", message: '',
        onSnackBarClose: () => {}
    });

    const dappCntx = useDappContext();

    const handleError = useCallback(({message}) => {
        setShowAlert({
            open: true, severity: "error", message: message,
            onSnackBarClose: () => {setShowAlert({open: false, message: "random"})},
        });
    }, [])

    const team = useDataFromBridge({
        xUser: xuser, apiValues: ' ',
        bridgedResourceName: "DPTeam",
    });

    useEffect(() => {
        if (!dappCntx.user) return;
        setXuser(dappCntx.user.user);
    }, [dappCntx]);

    useEffect(() => {
        if (!team) return;
        let doShow = true;

        if (!team.success){
          handleError({message: team.data.Message || team.data.message});
          return;
        }
    
        doShow && setData2Show(team.data?.records?.map((row, indx) => { 
          return {...row, id: indx}
        }));
        return () => {
          doShow = false;
        }
        // eslint-disable-next-line        
    }, [team])

    return (
      <Fragment>
        {data2show && data2show?.[0]?.["CustSvcRepFullName"] !== '' &&
        <Grid item xs={4} {...other}>
          <CustomerRepCard teamRecord={data2show} 
            // onShowWhite={onShowWhite}
          />
        </Grid>}
        <Grid item xs={4} {...other}>
          <ITConsultantCard teamRecord={data2show} 
            // onShowWhite={onShowWhite}
          />
        </Grid>
        <Grid item xs={4} {...other}>
          <TechManagerCard teamRecord={data2show} 
            // onShowWhite={onShowWhite}
          />
        </Grid>
        <CustomAlert {...showAlert}/>
      </Fragment>
    );
}
