import React from "react";
import { Box, Button, Grid, Tooltip } from "@material-ui/core";
import { useDialogButtonStyles } from "./shared.components";
import { faCancel, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SaveCancelToolbarButtons = (props) => {
    const {handleSave, handleCancel} = props
    const btnCls = useDialogButtonStyles();

    return (
        <Box sx={{ flexGrow: .1 }}>
            <Grid container spacing={2}>
                <Grid item xs={6} style={{
                    display: "flex",
                    flexFlow: "column",
                    alignItems: "end"
                }}>
                    <Tooltip title="Save Changes">
                    <Button 
                        startIcon={<FontAwesomeIcon icon={faSave} />}
                        onClick={handleSave}
                        variant="contained"
                        fullWidth
                        className={btnCls.okbutton}
                    >Save
                    </Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={6}>
                    <Tooltip title="Cancel">
                    <Button 
                        startIcon={<FontAwesomeIcon icon={faCancel} />}
                        onClick={handleCancel}
                        variant="contained"
                        fullWidth
                        className={btnCls.closebutton}
                    >
                        Cancel
                    </Button>
                    </Tooltip>
                </Grid>
            </Grid>
        </Box>
    )
}