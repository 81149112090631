import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { rowsperpage, WrapperPaper } from '../../../controls/shared.components';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useGridStyles } from '../../../controls/datagrid.striped';
import { useDataFromBridge } from '../../../controls/custom.hooks';
import { CustomAlert } from '../../../alerts/alert.snackbar';
import { useDappContext } from '../../../auth/dapp.context';
import { useParams } from 'react-router-dom';
import { useReducer } from 'react';
import clsx from "clsx";
import { useInvoiceDetailsColumns } from './invoicedetails.hook';
import { DetailsToolbar } from './invoicedetails.toolbar';
import { DappGridFooter } from '../../../controls/grid.footer';
import swal from 'sweetalert';
import { utils, writeFileXLSX } from 'xlsx';
import { EXPORTALERTMSGSHORT } from '../../../controls/dapp.constants';

const bridgedResourceName = "InvoiceDetails";

export const InvoiceDetails = props => {
  const { ...other } = props;
  const [loading, setLoading] = useState(true);
  const [apiparms, setApiparms] = useState({});
  const [griddata, setGridData] = useState();
  const [toolbarTitle, setToolbarTitle] = useState();
  const gridClasses = useGridStyles();
  const [total, setTotal] = useState(0);
  const [showAlert, setShowAlert] = useState({
    open: false, message: '', severity: "error", 
    onSnackBarClose: () => {},
  });  

  const [pagingState, dispatch] = useReducer(pageReducer, initState);
  const [xuser, setXuser] = useState();

  const { id } = useParams();
  const dappCntx = useDappContext();

  useEffect(() => {
    if(!dappCntx.user) return;
    setXuser(dappCntx.user.user);
    setApiparms({InvoiceID: id});
  }, [dappCntx, id])

  useEffect(() => {
    dappCntx.dappSubtitle("Invoice Details");
  }, [dappCntx])

  useEffect(() => {
    if (!dappCntx.currentRecord) return;
    setToolbarTitle([`Inovice Num: ${dappCntx.currentRecord?.["Transaction Number"]}`, `Job Number: ${dappCntx.currentRecord?.["Job Number"] || ''}`]);
  }, [dappCntx.currentRecord])

// this is when navigating directly to the link we need api call invoice by invoice ID
// all this is just to show toolbar title what invoice number and for what job number
  // const invoiceRecord = useDataFromBridge({
  //   xUser: xuser, apiValues: id,
  //   bridgedResourceName: "Invoice by ID"
  // });

  // useEffect(() => {
  //   if (dappCntx.currentRecord) return;
  //   if (!invoiceRecord?.data) return;
  //   dappCntx.currentRecord = invoiceRecord?.data.record;
  // }, [dappCntx.currentRecord, invoiceRecord]);

  const details = useDataFromBridge({ 
    xUser: xuser, apiValues: apiparms,
    bridgedResourceName: bridgedResourceName
  });

  const gridcols = useInvoiceDetailsColumns();

  const handlePageChange = (model, details) => {
    if (model.pageSize !== pagingState.pageSize)
      model.page = 0;

    dispatch({type: "page", page: model.page});
    dispatch({type: "pageSize", pageSize: model.pageSize});
  }

  const showError = useCallback(({message}) => {
    setShowAlert({...showAlert, open: true, 
        severity: "error", message: message,
        onSnackBarClose: () => { setShowAlert({open: false, message: "random"})}
    });
  }, [showAlert])

  useEffect(() => {
    if (!!griddata) return;

    if (!details) return;

    setLoading(false);
    if (!details.success) {
      showError({message: details.data.Message || details.data.message});
      return;
    } else if (details.data.error) {
      showError({message: details.data.error.message});
      return;
    }

    setGridData((details.data.records).map((row, indx) => { return {...row, id: indx}}));
    setTotal(details.data.records.reduce((tot, rec) => tot + parseFloat(rec.Price, 2) * parseFloat(rec.Quantity, 2), total));

    // eslint-disable-next-line
  }, [details]);

  const export2excel = useCallback(() => {
    const d = new Date();
    const stamp = `${d.getFullYear()}${d.getMonth()}${d.getDay()}-${d.getHours()}${d.getMinutes()}`;
    const fileName = `INVOICE_DETAILS_CMS_${dappCntx.currentRecord?.["Transaction Number"]}_${stamp}.xlsx`;

    const ws = utils.json_to_sheet(details.data.records);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, `${dappCntx.currentRecord?.["Transaction Number"]}` || "Invoice Details");
    writeFileXLSX(wb, fileName);
  }, [details, dappCntx]);

  const handleExport = useCallback(() => {
    swal({
      title: "Export Invoice Details",
      content: {
        element: "span",
        attributes: {
          innerHTML: EXPORTALERTMSGSHORT.replace('REPLACE-STRING', 'Invoice Details'),
        },
      },
      icon: undefined,
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: {
        confirm: true,
        cancel: true,
      },
      dangerMode: false,
    }).then((accepted) => {
      accepted && export2excel();
    });
  }, [export2excel]);

  return (
    <Fragment>
      <DetailsToolbar 
        title1={toolbarTitle?.[0]} 
        title2={toolbarTitle?.[1]}
        handleExport={handleExport}
      />
      <WrapperPaper {...other}>
        <DataGridPro sx={{width: "100%"}}
          className={clsx(gridClasses.gridheadfoot, gridClasses.gridrows)}
          rows={griddata || []}          
          columns={gridcols}
          density="compact"
          loading={loading}
          paginationMode="client"
          autoPageSize
          rowCount={details?.data?.records?.length}
          pageSizeOptions={rowsperpage}
          onPaginationModelChange={handlePageChange}
          pagination
          slots={{
            pagination: DappGridFooter,
          }}
          slotProps={{
            pagination: {total: total}
          }}
          initialState={{
            pagination: {
              paginationModel: {
                page: pagingState.page,
                pageSize: pagingState.pageSize
              }
            }
          }}
        />
      </WrapperPaper>
      <CustomAlert {...showAlert}/>
    </Fragment>
  );
};

const initState = {page: 0, pageSize: rowsperpage[0]};

const pageReducer = (state, action) => {
  switch (action.type) {
    case "page": 
      return {page: action.page};
    case "pageSize":
      return {pageSize: action.pageSize};
    default:
      throw new Error();
  }
}