import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '20rem',
    },
}));

export const SurveyCharts = (props) => {
    const classes = useStyles();

    return (
        <Typography component={`div`} className={classes.root}>
            Survey Charts will be shown here
        </Typography>
    )
}