import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardActionArea, CardContent, Grid, Typography } from "@material-ui/core";
import React, { useState, useCallback } from "react";

export const CustomCardLeft = (props) => {
    const {id, title, description, onCardClick, icon, children, onShowWhite, ...other} = props;
    const [showWhite, setShowWhite] = useState(false);

    const handleMouseOver = useCallback((e) => {
      if (showWhite) return;
      setShowWhite(true);
      if (!!onShowWhite && typeof onShowWhite === 'function'){
        onShowWhite(id, true);
      }
    }, [showWhite, onShowWhite, id]);

    const handleMouseLeave = useCallback((e) => {
      if (!showWhite) return;
      setShowWhite(false);
      if (!!onShowWhite && typeof onShowWhite === 'function'){
        onShowWhite(id, false);
      }
    }, [showWhite, onShowWhite, id]);

    return (
        <Card {...other}>
            <CardActionArea onClick={onCardClick} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={3}>
                            <Typography>{icon}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={9}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography component={`div`}>
                                        {title}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography component={`div`} style={{ border: "1px solid #55bff2" }}></Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography component={`div`} className="card-description">
                                        {description}<FontAwesomeIcon icon={faArrowRight} style={{paddingLeft: "10px"}}/>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {children}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    )
};

export const CustomCardTop = (props) => {
    const {id, title, description, onCardClick, icon, children, onShowWhite, ...other} = props;
    const [showWhite, setShowWhite] = useState(false);

    const handleMouseOver = useCallback((e) => {
      if (showWhite) return;
      setShowWhite(true);
      if (!!onShowWhite && typeof onShowWhite === 'function'){
        onShowWhite(id, true);
      }
    }, [showWhite, onShowWhite, id]);

    const handleMouseLeave = useCallback((e) => {
      if (!showWhite) return;
      setShowWhite(false);
      if (!!onShowWhite && typeof onShowWhite === 'function'){
        onShowWhite(id, false);
      }
    }, [showWhite, onShowWhite, id]);

    return (
        <Card {...other}>
            <CardActionArea onClick={onCardClick} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography>{icon}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component={`div`}>
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component={`div`} style={{border: "1px solid #55bff2"}}></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component={`div`} className="card-description">
                                {description}<FontAwesomeIcon icon={faArrowRight} style={{paddingLeft: "10px"}}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {children}
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    )
};
