import React, { Fragment } from "react";
import { 
    Typography, DialogTitle, Box, IconButton, Button, TextField
} from "@material-ui/core";
import { FolderOpen, Close, Save } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser } from "@fortawesome/free-solid-svg-icons";

export const FilterDialogTitle = (props) => {
    const {
        diagStyles, handleSave, handleClear, handleClose, handleLoadFilter, titleEl
    } = props;

    return (
        <Typography component={`div`} className={diagStyles["title-wrapper"]} ref={titleEl}>
            <DialogTitle className={diagStyles.title}>
                Search Tickets
            </DialogTitle>
            <Box style={{ flexGrow: 1 }}>
            </Box>
            <IconButton title="Load Saved Filter(s)" onClick={handleLoadFilter}>
                <FolderOpen />
            </IconButton>
            <IconButton title="Save Filter" onClick={handleSave}>
                <Save />
            </IconButton>
            <IconButton title="Clear Filter" onClick={handleClear} /*disabled={!ticketCntx.savedFilter}*/>
                <FontAwesomeIcon icon={faEraser} />
            </IconButton>
            <IconButton onClick={handleClose} title="Close" className={diagStyles.closeCross}>
                <Close />
            </IconButton>
        </Typography>
    );
}

export const FilterOKCancelButtons = (props) => {
    const { OkButtonClick, CancelButtonClick, ClearButtonClick, formValues } = props;
    return (
        <Fragment>
            <Button title="Clear Filter" onClick={ClearButtonClick} color="default">Clear</Button>
            <Box style={{ display: "flex", flexGrow: 1 }} />
            <Button onClick={OkButtonClick} color="primary" disabled={!formValues}>
                OK
            </Button>
            <Button onClick={CancelButtonClick} color="secondary">
                Cancel
            </Button>
        </Fragment>
    );
}

export const FilterDate = (props) => {
    const {id, label, value, onChange, ...other} = props;
    return (
        <TextField {...other}
            fullWidth
            variant="outlined"
            id={id}
            label={label}
            type="date"
            value={value}
            onChange={onChange}
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
}


export const FilterDef = 
{
    SearchCriteriaID: null,
    SearchPage: "",
    SearchName: "",
    UserID: 0,
    IsPublic: 0,
    SearchType: "",
    DateCreated: "",
    DateUpdated: "",
    CCWebUserID: 0,
    PrimaryContactID: 0,
    Fields: []
}

export const FilterFieldDef = {
    WebControlName: "",
    WebControlValue: "",
    IsEnabled: 0,
    IsVisible: 0,
    DynamicDateType: "",
    IsStartDate: 0,
    IsViewMode: 0,
    IsPrimaryContactIDField: 0,
    FieldName: ""
}
