import axios from 'axios';
import { APP_API_URL, CMSAPIKEY } from '../controls/dapp.constants';
import { fetchBridgedResource } from '@kineticdata/react';

export const getJobs = (props) => {
    const { user, apiParams } = props;

    const instance = axios.create({
        cancelToken: axios.CancelToken.source().token,
        headers: { 
            'X-User': JSON.stringify(user),
            CmsApiKey:CMSAPIKEY
        },
    });

    const apiCall = async () => await instance.get(        
        `${APP_API_URL}/job`, {params: { ...apiParams }});

    return apiCall();
}

export const getJobsFromBridge = async (props) => {
    const { usr, apiparms} = props;

    if (!usr || !apiparms) return;

    const bridgeCofig = {
      kappSlug: 'customer-portal',
      formSlug: 'shared-resources',
      bridgedResourceName: 'All Jobs',
      values: {
        GetTotal: apiparms.GetTotal, 
        PageToken: apiparms.PageToken || ' ',
        PageSize: apiparms.PageSize,
        PageNum: apiparms.PageNum,
        'X-User': JSON.stringify(usr),
      },
    };

    const data = await fetchBridgedResource(bridgeCofig);
    console.log(`right after api call`, data);
    return data;
}

