import React, { useState, useCallback } from "react";
import { Grid, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { useDataFromBridge } from "../../controls/custom.hooks";
import { rowsperpage } from "../../controls/shared.components";
import { useDappContext } from "../../auth/dapp.context";
import { Fragment } from "react";

const contactCallParams = {
  bridgeParams: {
    AscDesc: 'ASC',
    GetTotal: true,
    IncludeInactive: false,
    PageNum: 1,
    PageSize: rowsperpage[0],
    PageToken: ' ',
    SortField: 'LastName',
    SubClientContact: false,
    IsPending: false,
    SearchValue: ' ',
  },
  bridgedResourceName: 'All Contacts',
};  

export const ContactList = props => {
  const {onLoading, setContactCount, page, pageSize, onContactSelect, searchVal} = props;
  const [selected, setSelected] = useState();
  const [contactparms, setContactparms] = useState(contactCallParams.bridgeParams);
  const [bridgeName, setBridgeName] = useState(contactCallParams.bridgedResourceName);
  const [contactlist, setContactList] = useState();
  const dappCntx = useDappContext();
  const [xuser, setXuser] = useState(null);

  const contacts = useDataFromBridge({
    xUser: xuser,
    apiValues: contactparms,
    bridgedResourceName: bridgeName,
  });

  const onSelect = useCallback(({record}) => {
    setSelected(record);
    onContactSelect(record);
  }, [onContactSelect]);

  useEffect(() => {
    if (!dappCntx?.user) return;
    setXuser(dappCntx.user.user);
    if (dappCntx.user.user.UserType === "User"){
      setBridgeName("Contact by ID");      
      setContactparms({...contactparms, ContactID: dappCntx.user.user.ContactID});
    }
    // eslint-disable-next-line
  }, [dappCntx, bridgeName]);

  useEffect(() => {
    if (contactparms.SearchValue === searchVal) return;
    onLoading(true);
    setContactparms({...contactparms, SearchValue: (searchVal?.length === 0 ? ' ' : searchVal)});
    // eslint-disable-next-line
  }, [searchVal]);

  useEffect(() => {
    if (!page || page === 0) return;
    setContactparms({...contactparms, PageNum: page});  
    // eslint-disable-next-line  
  }, [page]);

  useEffect(() => {
    if (!pageSize) return;
    setContactparms({...contactparms, PageSize: pageSize,
      // ContactID: dappCntx.user.user.ContactID,
    });
    // eslint-disable-next-line
  }, [pageSize])

  useEffect(() => {
    onLoading(true);
    if (!contacts) return;
    onLoading(false);
    setContactCount(contacts?.data?.metadata?.count || (contacts?.data?.record && 1) || 0);
    setContactList(contacts?.data?.records || [contacts?.data?.record] || []);
    // eslint-disable-next-line
  }, [contacts]);

  return (
    <React.Fragment>
        <Typography component={`div`} style={{flex: '1 1 auto', overflow: 'auto'}}>
      <List dense>
        {contactlist?.map((record, indx) => {
          return (
            <ListItem
              key={`contact-key-${indx}`}
              button
              disableGutters
              record={record}
              selected={
                record?.['Contact ID'] === (selected && selected['Contact ID'])
              }
              onClick={() => onSelect({ record: record })}
            >
              <ListItemText style={{fontSize: ".75rem"}} 
                primary={
                  <Typography component={`div`}>
                    <Grid container>
                      <Grid item xs={10}>
                        <Typography component={`span`} style={{fontSize: "0.75rem"}}>
                          {`${(record?.["Last Name"] || '')}${(record?.["First Name"] && `, ${record?.["First Name"]}`)}${(record?.["Middle Initial"] && ` ${record["Middle Initial"]}.`)}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography component={`div`} style={{textAlign: "end", fontSize: "0.75rem"}}>
                          {record?.['CID']}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                }
                secondary={<Typography component={`div`} style={{fontSize: "0.7rem"}}>{record?.['Organization Name']}</Typography>}
              />
            </ListItem>
          );
        })}
      </List>
      </Typography>
    </React.Fragment>
  );
};

const deviceCallParams = {
  bridgeParams: {
    GetTotal: true,
    PageSize: rowsperpage[0],
    PageNum: 1,
    PageToken: ' ',
    AscDesc: 'ASC',
    SortField: 'DeviceName',
    DeviceName: ' '
  },
  bridgedResourceName: 'All Devices',
};

export const DeviceList = props => {
  const {onLoading, setDeviceCount, page, pageSize, onDeviceSelect, searchVal} = props;
  const [selected, setSelected] = useState();
  const [deviceparms, setDeviceparms] = useState(deviceCallParams.bridgeParams);
  const [devicelist, setDeviceList] = useState();
  const [xuser, setXuser] = useState();
  const [bridgeName, setBridgeName] = useState();
  const dappCntx = useDappContext();

  const devices = useDataFromBridge({
    xUser: xuser,
    apiValues: deviceparms,
    bridgedResourceName: bridgeName,
  });

  useEffect(() => {
    if (!dappCntx) return;
    if (!dappCntx?.user) return;
    setXuser(dappCntx.user.user);
    setBridgeName((dappCntx.user.user.UserType === "User" && "Devices by ContactID") || deviceCallParams.bridgedResourceName);
  }, [bridgeName, dappCntx, searchVal]);

  useEffect(() => {
    if (deviceCallParams.DeviceName === searchVal) return;
    onLoading(true);
    setDeviceparms({
      ...deviceCallParams.bridgeParams, 
      PageNum: 1,
      DeviceName: searchVal || ' '
    });
    // eslint-disable-next-line
  }, [searchVal]);

  const onSelect = useCallback(({record}) => {
    setSelected(record);    
    onDeviceSelect(record);
  }, [onDeviceSelect]);

  useEffect(() => {
    if (!page || page === 0) return;
    setDeviceparms({...deviceparms, PageNum: page});    
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (!pageSize) return;
    setDeviceparms({...deviceparms, PageSize: pageSize,       
      ContactID: dappCntx.user.user.ContactID,
    });
    // eslint-disable-next-line
  }, [pageSize])

  useEffect(() => {
    onLoading(true);
    if (!devices) return;
    onLoading(false);
    setDeviceCount(devices.data?.metadata?.count || (devices.data?.record && 1) || 0);
    setDeviceList((devices?.data?.records || (devices?.data?.record && [devicelist?.data?.record])) || null);
    // eslint-disable-next-line
  }, [devices]);

  return (
    <React.Fragment>
        <Typography component={`div`} style={{flex: '1 1 auto', overflow: 'auto'}}>
      <List dense>
        {(devicelist && devicelist?.map((record, indx) => {
          return (
            <ListItem
              key={`device-item-${indx}`}
              button
              disableGutters
              record={record}
              selected={
                selected && ((record["Device ID"] === ((selected["Device ID"] === '-1' && -2) || selected["Device ID"])) 
                  || (record["Kaseya Machine Group ID"] === (((selected["Kaseya Machine Group ID"] === '' || selected["Kaseya Machine Group ID"] === '-1') && -2) ||  selected["Kaseya Machine Group ID"]))
                  || (record["NCentral Device ID"] === (((selected["NCentral Device ID"] === '' || selected["NCentral Device ID"] === '-1') && -2) || selected["NCentral Device ID"])))
              }
              onClick={() => onSelect({ record: record })}
            >
              <ListItemText
                primary={
                  <Typography component={`div`}>
                    <Grid container>
                      <Grid item xs={8}>
                        <Typography component={`span`} style={{fontSize: "0.75rem"}}>
                          {record?.['Device Name']}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography component={`span`} style={{fontSize: ".75rem"}}>
                          {record?.['Device Type']}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                }
                secondary={
                <Typography component={`span`} style={{fontSize: ".7rem"}}>
                  {record?.['Description']}
                </Typography>
                } 
              />
            </ListItem>
          );
        })) || <Fragment/>}
      </List>
      </Typography>
    </React.Fragment>
  );
};
