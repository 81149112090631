import React, { useCallback, useContext } from "react";
import { Toolbar, Box, debounce } from "@material-ui/core";
import { ToolbarButton, useToolbarStyle } from "../../controls/shared.components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { DeviceSearchContext, useDeviceContext } from "./devices.main";
import { QuickLookup } from "../../controls/search.box";

export const DevicesToolbar = (props) => {
    const {handleAdd, handleRefresh, ...other} = props;
    const cls = useToolbarStyle();
    const deviceCntx = useDeviceContext();
    const deviceSearchCntx = useContext(DeviceSearchContext);
    
    // const callAddRequest = useCallback(() => {
    //     if (!deviceCntx) return;
    //     if (deviceCntx.NewRequest && typeof deviceCntx.NewRequest === 'function'){
    //         deviceCntx.NewRequest();
    //     }
    // }, [deviceCntx]);

    const callRefreshList = useCallback(() => {
        if (!deviceCntx) return;
        if (deviceCntx.RefreshDeviceList && typeof deviceCntx.RefreshDeviceList === 'function'){
            deviceCntx.RefreshDeviceList();
        }
    }, [deviceCntx]);

    const handleSearch = debounce((val) => {
        deviceSearchCntx && deviceSearchCntx.setSearchValue(val || ' ');
    }, 800);

    return (
        <Toolbar {...other} className={cls.toolbarroot}>
            {/* <ToolbarButton
                name="Add"
                handleClick={callAddRequest}
                tooltip="Add New Service Request"
                icon={<FontAwesomeIcon icon={faAdd}/>}
                disabled={!deviceCntx?.isSelected}
            /> */}
            <Box sx={{flexGrow: 1}} />
            <QuickLookup style={{width: "30%"}}
              placeholder="TYPE NAME"
              onInputChange={(e) => handleSearch(e.target.value)}            
            />
            <ToolbarButton
                name="Refresh"
                handleClick={callRefreshList}
                tooltip="Refresh"
                icon={<FontAwesomeIcon icon={faRefresh}/>}
            />
        </Toolbar>
    );
}
