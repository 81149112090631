import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { rowsperpage, WrapperPaper } from '../../controls/shared.components';
import { SurveyToolbar } from './survey.toolbar';
import { useSurveyColumns } from './survey.hook';
import { DataGrid } from '@material-ui/data-grid';
import { TablePagination } from '@material-ui/core';
import { useGridStyles } from '../../controls/datagrid.striped';
import { SurveyCharts } from './survey.charts';
import { CustomAlert } from '../../alerts/alert.snackbar';
import { useDappContext } from '../../auth/dapp.context';
import { useDataFromBridge } from '../../controls/custom.hooks';

const initLoadParams = {
  GetTotal: true,
  OrderBy: 'SurveyInvitationID DESC',
  // PageNum: 1,
  PageSize: rowsperpage[0],
};

export const Surveys = props => {
  const { ...other } = props;
  const [loading, setLoading] = useState(true);
  const [apiparms, setApiparms] = useState(initLoadParams);
  const [griddata, setGridData] = useState([]);
  const [pageToken, setPageToken] = useState();
  const [xuser, setXuser] = useState();
  const gridClasses = useGridStyles();

  const [showAlert, setShowAlert] = useState({
    open: false, severity: 'success', message: '',
    onSnackBarClose: () => {},
  });

  const dappCntx = useDappContext();
  useEffect(() => dappCntx.dappSubtitle("Surveys", () => {}), 
  // eslint-disable-next-line
  [dappCntx.title]);

  const surveys = useDataFromBridge({usr: xuser, apiparms,});

  useEffect(() => {
    if (!dappCntx.user) return;
    setXuser(dappCntx.user.user);
  }, [dappCntx])

  const gridColumns = useSurveyColumns();

  const handlePageSizeChange = useCallback(
    newSize => {
      console.log(`page size change to: ${newSize}`);
      setApiparms({ ...apiparms, PageSize: newSize, PageNum: 1 });
    },
    [apiparms],
  );

  const handlePageChange = useCallback(
    newPage => {
      console.log(`switching to new page: ${newPage}`);
      setApiparms({ ...apiparms, PageToken: pageToken });
    },
    [apiparms, pageToken],
  );

  const showError = useCallback(({message}) => {
    setShowAlert({...showAlert, open: true, 
        severity: "error", message: message,
        onSnackBarClose: () => { setShowAlert({open: false, message: "random"})}
    });
  }, [showAlert])

  useEffect(() => {
    if (!surveys) return;

    setLoading(false);
    if (!surveys.success) {
        showError({message: surveys.data.Message});
        return;
    }

    setPageToken(surveys.data.NextPage);
    setGridData(
      surveys.data.Data.map((row, indx) => {
        return { ...row, id: indx };
      }),
    );
    // eslint-disable-next-line
  }, [surveys]);

  return (
    <Fragment>
        <SurveyToolbar />
      <WrapperPaper {...other}>
        <SurveyCharts />
        <DataGrid
          className={gridClasses.datagrid}
          rows={griddata}
          columns={gridColumns}
          loading={loading}
          paginationMode="server"
          density="compact"
          rowCount={surveys?.Total || 0}
          pageSize={rowsperpage[0]}
          rowsPerPageOptions={rowsperpage}
          conmponents={{
            Pagination: TablePagination,
          }}
          onPageSizeChange={handlePageSizeChange}
          onPageChange={handlePageChange}
        />
      </WrapperPaper>
      <CustomAlert {...showAlert}/>
    </Fragment>
  );
};



