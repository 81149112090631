import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { useDialogStyles } from "../../modals/dialog.style";


export const AssigneeDropDown = (props) => {
    const {formValues, handleAutoCompleteChange, assigneeData} = props;
    const diagStyles = useDialogStyles();

    return (
    <Autocomplete
        id="assignedTo"
        size="small"
        fullWidth
        className={diagStyles.autoCpmleteList}
        options={assigneeData || []}
        disableClearable={false}
        getOptionLabel={(option) => option?.UserName || ' '}
        onChange={handleAutoCompleteChange}
        value={(formValues?.assignedTo && (assigneeData?.find(x => x.UserID === formValues.assignedTo) || null)) || null}
        renderInput={(params) =>
            <TextField {...params}
                label="Assigned To"
                variant="outlined"
                margin="dense"
                SelectProps={{
                    MenuProps:{
                        className: diagStyles.dropdownItem
                    }
                }}
            />
        }
    />
    )
}