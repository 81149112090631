import React, { Fragment, useCallback, useState } from "react";
import { 
    Toolbar, Box, Tooltip, Badge, debounce, Typography,
    FormControl, FormLabel, MenuItem, Select
} from "@material-ui/core";
import { blue } from "@mui/material/colors";
import { ToolbarButton } from "../../controls/shared.components";
import { useToolbarStyle } from "../../controls/shared.components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faPlus, faRefresh, faClipboardList, faCalendarWeek, faFilter,
    faCarBurst, faCircleQuestion, faCalendarDay, faFileCircleCheck, faFileExcel, faList
} from "@fortawesome/free-solid-svg-icons";
import { QuickLookup } from "../../controls/search.box";

export const ServicesToolbar = (props) => {
    const {
        onRefresh, onAdd, onView, handleSearch, handleExport, handleOnFilter,
        statusChanged, All, Calendar, General, OpenIncidents, Scheduled, ToDo, 
        ...other
    } = props;

    const cls = useToolbarStyle();
    const [status, setStatus] = useState("Open");

    const onSearchChange = debounce((val) => {
        if (handleSearch && typeof handleSearch === 'function'){
            handleSearch(val);
        }
    }, 800);

    const onFilter = () => {
        if (handleOnFilter && typeof handleOnFilter === 'function'){
            handleOnFilter();
        }
    };

    const [calView, setCalView] = useState(false);

    const onStatusChange = useCallback((event) => {
        setStatus(event.target.value);
        if (statusChanged && typeof statusChanged === 'function'){
            statusChanged(event.target.value);
        }
    }, [statusChanged]);

    const handleCalView = useCallback(() => {
        setCalView(!calView);
        onView(!calView);
    }, [calView, onView]);

    // enable calendar view on 10.16.2023
    return (
        <Toolbar className={cls.toolbarroot} {...other}>
            <ToolbarButton
                name=""
                handleClick={onAdd}
                tooltip="New Service Request"
                icon={<FontAwesomeIcon icon={faPlus} />}
            />
            <ToolbarButton 
                name=""//{(calView && "Grid") || "Calendar"}
                handleClick={handleCalView}
                icon={<FontAwesomeIcon icon={(calView && faList) || faCalendarWeek} />}
                tooltip={(calView && "Grid") || "Calendar"}
            />
            <Box style={{flexGrow: 0.5, display: "flex"}}>
                <Typography component={`div`} style={{width: "50%", margin: "auto", color: "white"}}>
                    <FormControl size="small">
                    <FormLabel component="span" style={{color: "white", fontSize: "0.75em"}}
                        id="request-status-label"
                    >Status</FormLabel>
                    <Select id="reqStatus" value={status}
                        variant="outlined"
                        labelId="request-status-label"
                        size="small"
                        style={{background: blue[50], minWidth: 120}}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                            getContentAnchorEl: null,
                        }}
                        onChange={onStatusChange}>
                        <MenuItem value="<ALL>">(All)</MenuItem>    
                        <MenuItem value="Open">Open</MenuItem>
                        <MenuItem value="Close">Close</MenuItem>
                    </Select>
                    </FormControl>
                </Typography>
            </Box>
            <Box style={{flexGrow: 1}} />
            <StatIcons All={All} Calendar={Calendar} General={General} OpenIncidents={OpenIncidents} Scheduled={Scheduled} ToDo={ToDo} {...other}/>
            <Typography component={`div`} style={{minWidth: 280, widh: '35%', padding: "0 0.5rem 0 0"}}>
                <QuickLookup
                    placeholder="SEARCH REQUEST"
                    onInputChange={(e) => onSearchChange(e.target.value)}   
                    // this should make it to state         
                />
            </Typography>
            <ToolbarButton 
                name=""
                handleClick={onFilter}
                tooltip="Filter Tickets"
                icon={<FontAwesomeIcon icon={faFilter} />}
                // and this should make it to stage
            />
            <ToolbarButton
                name=""
                handleClick={handleExport}
                tooltip="Export to Excel"
                icon={<FontAwesomeIcon icon={faFileExcel}/>}
            />
            <ToolbarButton
                name=""
                handleClick={onRefresh}
                tooltip="Services Settings"
                icon={<FontAwesomeIcon icon={faRefresh} />}
            />
        </Toolbar>
    );
}

const StatIcons = (props) => {
    const {
        All, Calendar, General, OpenIncidents, Scheduled, ToDo, ...other
    } = props;

    const cls = useToolbarStyle();

    return (
        <Fragment>
            <Box component={`div`} style={{ fontSize: 'xx-large', minWidth: 50 }} {...other}>
                <Tooltip title={`All - ${All}`}>
                    <Badge badgeContent={All} color={All > 100 ? "secondary" : "primary"} overlap="circular">
                        <FontAwesomeIcon icon={faClipboardList} className={cls.switchlabel} />
                    </Badge>
                </Tooltip>
            </Box>
            <Box component={`div`} style={{ fontSize: 'xx-large', minWidth: 50 }} >
                <Tooltip title={`Calendar - ${Calendar}`}>
                    <Badge badgeContent={Calendar} color={Calendar > 100 ? "secondary" : "primary"} overlap="circular">
                        <FontAwesomeIcon icon={faCalendarWeek} className={cls.switchlabel} />
                    </Badge>
                </Tooltip>
            </Box>
            <Box component={`div`} style={{ fontSize: 'xx-large', minWidth: 50 }} >
                <Tooltip title={`General - ${General}`}>
                    <Badge badgeContent={General} color={General > 100 ? "secondary" : "primary"} overlap="circular">
                        <FontAwesomeIcon icon={faCircleQuestion} className={cls.switchlabel} />
                    </Badge>
                </Tooltip>
            </Box>
            <Box component={`div`} style={{ fontSize: 'xx-large', minWidth: 50 }} >
                <Tooltip title={`OpenIncidents - ${OpenIncidents}`}>
                    <Badge badgeContent={OpenIncidents} color={OpenIncidents > 100 ? "secondary" : "primary"} overlap="circular">
                        <FontAwesomeIcon icon={faCarBurst} className={cls.switchlabel} />
                    </Badge>
                </Tooltip>
            </Box>
            <Box component={`div`} style={{ fontSize: 'xx-large', minWidth: 50 }} >
                <Tooltip title={`Scheduled - ${Scheduled}`}>
                    <Badge badgeContent={Scheduled} color={Scheduled > 100 ? "secondary" : "primary"} overlap="circular">
                        <FontAwesomeIcon icon={faCalendarDay} className={cls.switchlabel} />
                    </Badge>
                </Tooltip>
            </Box>
            <Box component={`div`} style={{ fontSize: 'xx-large', minWidth: 50 }} >
                <Tooltip title={`ToDo - ${ToDo}`}>
                    <Badge badgeContent={ToDo} color={ToDo > 100 ? "secondary" : "primary"} overlap="circular">
                        <FontAwesomeIcon icon={faFileCircleCheck} className={cls.switchlabel} />
                    </Badge>
                </Tooltip>
            </Box>
        </Fragment>
    );
}
