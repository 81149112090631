import React, {Fragment, useEffect, useState, useCallback, useReducer} from "react";
import { rowsperpage, WrapperPaper } from "../../controls/shared.components";
import { useParams } from "react-router-dom";
import { useDappContext } from "../../auth/dapp.context";
import { useDataFromBridge } from "../../controls/custom.hooks";
import { useGridStyles } from "../../controls/datagrid.striped";
import { CustomAlert } from "../../alerts/alert.snackbar";
import clsx from "clsx";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useProductColumns } from "./product.columns";
import { JobDetailsToolbar } from "../jobs/jobs.toolbar";
import swal from "sweetalert";
import { utils, writeFileXLSX } from "xlsx";
import { EXPORTALERTMSG } from "../../controls/dapp.constants";

const formTitle = "Job Products"

const initLoadParams = {
  PageSize: rowsperpage[0],
  PageNum: 1,
  GetTotal: true,
  // AscDesc: 'desc',
  // PageToken: ' ',
  // SortField: "POID"    
};

const bridgedResourceName = "All Products";
const bridgedExportName = "AllProductsExport";

export const ProductList = () => {
    const {jobid} = useParams();
    const [xuser, setXuser] = useState();
    const [apiparams, setApiparams] = useState();
    const [gridData, setGridData] = useState([]);
    const [pagingState, dispatch] = useReducer(pageReducer, initState);
    const [toolbarTitle, setToolbarTitle] = useState();
    const [jobbyid, setJobbyid] = useState();
    const [exportParams, setExportParams] = useState(null);
    const dappCntx = useDappContext();
    const cls = useGridStyles();
    const [showAlert, setShowAlert] = useState({
      open: false,
      severity: 'error',
      message: '',
      onSnackBarClose: () => {},
    });
  
    useEffect(() => {
      dappCntx.dappSubtitle(formTitle);
    }, [dappCntx]);

    const prods = useDataFromBridge({
      xUser: xuser,
      apiValues: apiparams,
      bridgedResourceName: bridgedResourceName,
    });

    const data2export = useDataFromBridge({
      xUser: xuser,
      apiValues: exportParams,
      bridgedResourceName: bridgedExportName,
    });
 
    const gridcols = useProductColumns({
      handleView: ({row}) => { alert(`trying to view product details for: `, row["POID"])}
    });

    useEffect(() => {
      if (!dappCntx.user) return;
      dispatch({type: "loading", loading: true});
      setXuser(dappCntx.user.user);      
      setApiparams({...initLoadParams, JobID: jobid});
      setJobbyid({JobID: jobid});    
    }, [dappCntx, jobid]);

    useEffect(() => {
      if (!dappCntx.currentRecord) return;
      setToolbarTitle([`${dappCntx.currentRecord["Job Num"]}`, `${dappCntx.currentRecord?.InternalShortDesc || ''}`]);
    }, [dappCntx.currentRecord])
  
    const jobRecord = useDataFromBridge({
      xUser: xuser, apiValues: jobbyid,
      bridgedResourceName: "Job by ID"
    });
  
    useEffect(() => {
      if (dappCntx.currentRecord) return;
      if (!jobRecord?.data) return;
      dappCntx.currentRecord = jobRecord?.data.record;
    }, [dappCntx.currentRecord, jobRecord]);
  
    const handlePaginationChange = useCallback((model, details) => {
      if (model.pageSize !== pagingState.pageSize)
        model.page = 0;

      dispatch({type: "page", page: model.page});
      dispatch({type: "pageSize", pageSize: model.pageSize});
      // setApiparams({...apiparams, 
      //   PageNum: model.page + 1,
      //   PageSize: model.pageSize,
      // });      
    }, [pagingState]);
  
    const showError = useCallback(
      ({ message }) => {
        setShowAlert({
          ...showAlert,
          open: true,
          severity: 'error',
          message: message,
          onSnackBarClose: () => {
            setShowAlert({ ...showAlert, open: false, message: 'random' });
          },
        });
      },
      [showAlert],
    );
  
    useEffect(() => {
      if (!prods) return;
  
      dispatch({type: "loading", loading: false});
      if (!prods.success) {
        showError({
          message: prods.data.Message || prods.data.message,
        });
        return;
      } else if (prods.data.error) {
        showError({message: prods.data.error.message});
        return;
      }
  
      setGridData(
        (prods.data.records).map((row, indx) => {
          return { ...row, id: indx };
        }),
      );
      // eslint-disable-next-line
    }, [prods]);

    useEffect(() => {
      if (!data2export || !data2export?.data?.records) return;
  
      const createExcel = (exportData) => {
        const d = new Date();
        const stamp = `${d.getFullYear()}${d.getMonth()}${d.getDay()}-${d.getHours()}${d.getMinutes()}`;
        const fileName = `PRODUCTS_CMS_${stamp}.xlsx`;
    
        const ws = utils.json_to_sheet(exportData);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, `Products-${dappCntx.currentRecord["Job Num"]}`);
        writeFileXLSX(wb, fileName);
      }
      createExcel(data2export?.data?.records);
    }, [data2export, dappCntx]);

    const export2excel = useCallback(() => {
      swal({
        title: "Export List of Products",
        content: {
          element: "span",
          attributes: {
            innerHTML: EXPORTALERTMSG.replace('REPLACE-STRING', 'a List of Products'),
          },
        },
        icon: undefined,
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: {
          confirm: true,
          cancel: true,
        },
        dangerMode: false,
      }).then((accepted) => {
        accepted && setExportParams({
          ...apiparams,
          PageNum: 1,
          PageSize: 2000,
        });
      });
    }, [apiparams]);
  
      
    return (
      <Fragment>
        <JobDetailsToolbar 
          title1={toolbarTitle?.[0]} 
          title2={toolbarTitle?.[1]} 
          handleExport={export2excel}
          showExport={true}
        />
        <WrapperPaper>
          <DataGridPro
            className={clsx(cls.gridheadfoot, cls.gridrows)}
            rows={gridData}
            columns={gridcols}
            loading={pagingState.loading}
            density="compact"
            paginationMode="client"
            autoPageSize
            rowCount={prods?.data?.records.length || 0}
            pageSizeOptions={rowsperpage}
            onPaginationModelChange={handlePaginationChange}
            pagination
            initialState={{
              pagination: {
                paginationModel: {
                  page: pagingState.page,
                  pageSize: pagingState.pageSize,
                }
              }
            }}
          />
        </WrapperPaper>
        <CustomAlert {...showAlert} />
      </Fragment>
    );
}

const initState = {page: 0, pageSize: rowsperpage[0]};

const pageReducer = (state, action) => {
  switch (action.type) {
    case "page": 
      return {...state,  page: action.page};
    case "pageSize":
      return {...state, pageSize: action.pageSize};
    case "loading": {
      return {...state, loading: action.loading};
    }
    default:
      throw new Error();
  }
}