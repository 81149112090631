import React, { useState, useContext, useEffect, useRef } from "react";
import { SplitPaneContext } from "./split.context";
import { Typography } from "@material-ui/core";

export const SplitPane = ({children, ...props}) => {
    const [clientHeight, setClientHeight] = useState(null);
    const [clientWidth, setClientWidth] = useState(null);
    const yDividerPos = useRef(null);
    const xDivederPos = useRef(null);

    const onMouseHoldDown = (e) => {
        yDividerPos.current = e.clientY;
        xDivederPos.current = e.clientX;
    };

    const onMouseHoldUp = () => {
        yDividerPos.current = null;
        xDivederPos.current = null;
    };

    const onMouseHoldMove = (e) => {
        if (!yDividerPos.current && !xDivederPos.current) return;

        setClientHeight(clientHeight + e.clientY - yDividerPos.current);
        setClientWidth(clientWidth + e.clientX - xDivederPos.current);

        yDividerPos.current = e.clientY;
        xDivederPos.current = e.clientX;
    };

    useEffect(() => {
        document.addEventListener("mouseup", onMouseHoldUp);
        document.addEventListener("mousemove", onMouseHoldMove);

        return () => {
            document.removeEventListener("mouseup", onMouseHoldUp);
            document.removeEventListener("mousemove", onMouseHoldMove);
        }
    });

    return (
        <div {...props}>
            <SplitPaneContext.Provider value={{
                clientHeight, setClientHeight,
                clientWidth, setClientWidth,
                onMouseHoldDown,
            }}>
                {children}
            </SplitPaneContext.Provider>
        </div>
    );
};

export const SplitDivider = (props) => {
    const { onMouseHoldDown } = useContext(SplitPaneContext);
    return (<div {...props} onMouseDown={onMouseHoldDown}/>);
}

export const SplitPanelLeft = ({children, ...props}) => {
    const topRef = useRef();
    const {clientWidth, setClientWidth} = useContext(SplitPaneContext);  

    useEffect(() => {
        if (!clientWidth) {
            setClientWidth(topRef.current.clientWidth / 2);
            return;
        }

        topRef.current.style.minWidth = clientWidth + 'px';
        topRef.current.style.maxWidth = clientWidth + 'px';
    }, [clientWidth, setClientWidth])

    return (
        <Typography {...props} component={`div`} ref={topRef}>
            {children}
        </Typography>
    );
};

export const SplitPanelTop = ({children, ...props}) => {
    const topRef = useRef();
    const {clientHeight, setClientHeight} = useContext(SplitPaneContext);

    useEffect(() => {
        if (!clientHeight){
            setClientHeight(topRef.current.clientHeight);
            return;
        }

        topRef.current.style.minHeight = `${clientHeight}px`;
        topRef.current.style.maxHeight = `${clientHeight}px`;
    }, [clientHeight, setClientHeight])

    return (
        <Typography component={`div`} {...props} ref={topRef}>
            {children}
        </Typography>
    );
};