import React, { 
  Fragment, useState, useEffect, useCallback, createContext, useContext 
} from 'react';
import { WrapperPaper } from '../../controls/shared.components';
import { DevicesToolbar } from './devices.toolbar';
import { CustomAlert } from '../../alerts/alert.snackbar';
import { useDappContext } from '../../auth/dapp.context';
import { DeviceSplitView } from './device.splitview';

export const Devices = props => {
  const { ...other } = props;
  const [showAlert, setShowAlert] = useState({
    open: false, severity: 'success', message: '',
    onSnackBarClose: () => {},
  });
  const dappCntx = useDappContext();

  const [searchValue, setSearchValue] = useState();

  useEffect(() => {
    dappCntx.dappSubtitle("Devices", () => {});
  }, [dappCntx]);

  const handleError = useCallback(({message}) => {
    setShowAlert({
      ...showAlert, open: true, message: message, severity: "error",
      onSnackBarClose: {...showAlert, open: false, message: "random"}
    })
  }, [showAlert])

  return (
    <Fragment>
      <DeviceContextProvider>
        <DeviceSearchContext.Provider value={{searchValue, setSearchValue}}>
        <DevicesToolbar/>
        <WrapperPaper {...other}>
          <DeviceSplitView onError={handleError} />
        </WrapperPaper>
        <CustomAlert {...showAlert} />
        </DeviceSearchContext.Provider>
      </DeviceContextProvider>
    </Fragment>
  );
};

const DeviceContext = createContext();

const DeviceContextProvider = ({children}) => {
  const [isSelected, setIsSelected] = useState(false);
  const [searchVal, setSearchVal] = useState();
  
  const NewRequest = undefined;

  const RefreshDeviceList = undefined;

  return (
    <DeviceContext.Provider value={{
      isSelected, setIsSelected, 
      NewRequest, RefreshDeviceList,
      searchVal, setSearchVal
    }}>
      {children}
    </DeviceContext.Provider>
  )
}

export const useDeviceContext = () => useContext(DeviceContext);
// this is device search context that holds the value that user types in
export const DeviceSearchContext = createContext();