import React, { Fragment, useState, useCallback, useEffect, useRef } from "react";
import { CoreForm } from "@kineticdata/react";
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Toolbar } from "@material-ui/core";
import { CustomAlert } from "../../alerts/alert.snackbar";
import { useDappContext } from "../../auth/dapp.context";
import { DappDialogTitle, KappFormLayout, KappPending, useDialogButtonStyles, useFormStyle, WrapperPaper } from "../../controls/shared.components";
// import { useParams } from "react-router-dom";

export const NewAction = (props) => {
    const cls = useFormStyle();
    const [showMessage, setShowMessage] = useState({
        open: false, severity: "error", message: "", onSnackBarClose: () => {}
    });
    // const {id} = useParams();

    const kForm = useRef();

    const dappCntx = useDappContext();
    useEffect(() => {
        dappCntx.dappSubtitle("New Action");
    }, [dappCntx])

    const closeAlert = useCallback(() => {
        setShowMessage({...showMessage, open: false, message: "random"});
    }, [showMessage]);

    const handleError = useCallback(() => {
        setShowMessage({ ...showMessage, 
            open: true, message: `something failed on form`, severity: "error",
            onSnackBarClose: closeAlert,
        });
    }, [showMessage, closeAlert]);

    const handleSaveComplete = useCallback(() => {
        console.log(`something saved...`);
        setShowMessage({
            ...showMessage, open: true, message: "Action is saved",
            severity: "success", onSnackBarClose: closeAlert
        })
    }, [showMessage, closeAlert]);    

    const handleLoaded = useCallback((form) => {
      kForm.current = form;
    }, [kForm]);

    return (
      <Fragment>
        <Toolbar />
        <WrapperPaper style={{ overflowY: 'auto' }}>
          <Grid container>
            <Grid item xs={6} className={cls.root}>
              <Box component={`div`} name="box-around-core">
                <NewActionForm 
                    onError={handleError}
                    onSave={handleSaveComplete}
                    onLoaded={handleLoaded}
                />
              </Box>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </WrapperPaper>
        <CustomAlert {...showMessage} />
      </Fragment>
    );
}

export const NewActionForm = ({onSave, onError, incidentID, onLoaded}) => {
  return (
    <CoreForm
      kapp="customer-portal"
      form="new-action"
      onError={onError}
      onCompleted={onSave}
      values={{IncidentID: `${incidentID}`}}
      loaded={onLoaded}
      components={{
        Pending: KappPending,
        Layout: KappFormLayout,
      }}
    />
  );
};

export const NewActionModal = (props) => {
    const { open, handleClose, handleError, handleSave, incidentID } = props;
    const cls = useFormStyle();
    const kForm = useRef();  
    const btnCls = useDialogButtonStyles();
    
    const handleLoaded = useCallback((form) => {
      kForm.current = form;
    }, [kForm]);

    const onSave = useCallback(() => {
      kForm.current.submitPage();
      if (handleSave &&  typeof handleSave === 'function')
        handleSave();
    }, [kForm, handleSave]);

    return (
        <Dialog onClose={handleClose} open={open}>
            <DappDialogTitle title="New Action" handleClose={handleClose}/>
            <DialogContent className={cls.root} style={{maxHeight: "520px", overflowY: "auto"}}>
                <NewActionForm 
                  onError={handleError} 
                  onSave={onSave} 
                  onLoaded={handleLoaded}
                  incidentID={incidentID}/>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" className={btnCls.okbutton} onClick={onSave}>Update</Button>
              <Button variant="contained" className={btnCls.closebutton} onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}