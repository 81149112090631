import React, { Fragment, useEffect, useState, useMemo } from "react";
import { getJobs, getJobsFromBridge } from "../../apicalls/jobs.api";
import PropTypes from 'prop-types';
import { getDataFromBridge } from "../../apicalls/kapp.bridge";
import { DappBooleanAsIcon } from "../../controls/shared.components";
import { Tooltip, IconButton, Link } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircleInfo, faXmark } from "@fortawesome/free-solid-svg-icons";

export const useJobsData = (props) => {
    const { usr, apiparms } = props;
    const [jobs, setJobs] = useState(null);

    useEffect(() => {
      try {
        getJobs({ user: usr, apiParams: apiparms })
          .then(response => {
            setJobs({ success: true, data: response.data });
          })
          .catch(error => {
            console.error(`jobs api call failed with: ${error.toString()}`);
            setJobs({ success: false, data: error.response.data });
          });
      } catch (exp) {
        console.error(`jobs api call failed with: ${exp.Message}`);
        setJobs({
          success: false,
          data: `jobs api call failed with: ${exp.toString()}`,
        });
      }
    }, [apiparms, usr]);

    return jobs;
}

useJobsData.PropTypes = {
    usr: PropTypes.object.isRequired,
    apiParams: PropTypes.object.isRequired,
}

export const useJobsDataBridge = props => {
    const { usr, apiparms } = props;
    const [jobs, setJobs] = useState(null);
  
    useEffect(() => {
      try {
        getJobsFromBridge({ usr, apiparms })
          .then(response => {
            setJobs({
              success: !!response.records,
              data: response.error || response,
            });
          })
          .catch(error => {
            setJobs({ success: false, data: error.data });
          });
      } catch (exp) {
        setJobs({ success: false, data: exp.Message });
      }
    }, [usr, apiparms]);
    return jobs;
  };

export const useJobsDataFromUniversalBridge = props => {
    const { usr, apiparms } = props;
    const [jobs, setJobs] = useState(null);
  
    useEffect(() => {
      try {
        getDataFromBridge({ 
          usr: usr, 
          apiparms: apiparms, 
          bridgedResourceName: 'All Jobs'
        })
          .then(response => {
            setJobs({ success: true, data: response });
          })
          .catch(error => {
            setJobs({ success: false, data: error.response.data });
          });
      } catch (exp) {
        setJobs({ success: false, data: exp.Message });
      }
    }, [usr, apiparms]);
    return jobs;
 };


export const useJobsColumns = ({handleViewSummary}) => {
    return useMemo(() => [
        {
            field: "Job Num", headerName: "Job #", flex: .5, disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <Tooltip title="Job Summary">
                  <Link component={`button`} underline="none" variant="body2" 
                    onClick={() => handleViewSummary({row: params.row})}>
                    {params.row['Job Num']}
                  </Link>
                </Tooltip>)
            }
        },
        {
            field: "InternalShortDesc", headerName: "Description", flex: 2, disableColumnMenu: true,
        },
        {
            field: "POStatus", headerName: "Status", width: 160, disableColumnMenu: true,
        },
        {
            field: "Is Fixed Job", headerName: "Fixed", width: 120, disableColumnMenu: true, sortable: false,
            align: "center", renderCell: (params) => <DappBooleanAsIcon value={params.value}/>
        },
        {
          field: "Actions", headerName: " ", width: 100, disableColumnMenu: true, sortable: false,
          // renderCell: (params) => {
          //     return (
          //         <Fragment>
          //             <Tooltip title="Edit Contact">
          //             <IconButton id="editRecord" onClick={() => handleEdit({row: params.row})} 
          //                 color="primary" style={{fontSize: '.9rem'}}>
          //                 <FontAwesomeIcon icon={faEdit} />
          //             </IconButton>
          //             </Tooltip>
          //             <Tooltip title="Delete Contact">
          //             <IconButton id="deleteRecord" onClick={() => handleDelete({row: params.row})} 
          //                 color="secondary" style={{fontSize: '.9rem'}}>
          //                 <FontAwesomeIcon icon={faEraser} />
          //             </IconButton>
          //             </Tooltip>
          //         </Fragment>
          //     );
          // },
      },
  ], [handleViewSummary]);
}

export const useJobReportColumns = ({ handleView }) => {
  return useMemo(
    () => [
      {
        field: 'F Name',
        headerName: 'Staff Name',
        flex: 0.3,
        disableColumnMenu: true,
        renderCell: params => {
          return `${params.row['L Name']}, ${params.row['F Name']}`;
        },
      },
      {
        field: 'Activity Type',
        headerName: 'Type',
        headerAlign: "center",
        flex: .2,
        align: "center",
        disableColumnMenu: true,
      },
      {
        field: 'Rate Desc',
        headerName: 'Rate',
        flex: .4,
        disableColumnMenu: true,
      },
      {
        field: "Invoice ID",
        headerName: "Invoiced",
        flex: .2, align: "center",
        disableColumnMenu: true,
        renderCell: (params) => {
          return <FontAwesomeIcon 
            icon={(Number(params.row["Invoice ID"]) === -1 && faXmark) || faCheck} 
            style={(Number(params.row["Invoice ID"]) === -1 && {color: '#eba9ff'}) || {color: '#007dba'}}
          />
        }
      },
      {
        field: "TimeIn",
        headerName: "Time In",
        headerAlign: "center",
        flex: .4,
        align: "center",
        renderCell: (params) => {
          const dt = new Date(params.value);
          return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`
        },
        disableColumnMenu: true,
      },
      {
        field: "TimeOut",
        headerName: "Time Out",
        headerAlign: "center",
        flex: .4, align: "center",
        renderCell: (params) => {
          const dt = new Date(params.value);
          return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`
        },
        disableColumnMenu: true,
      },
      {
        field: "Duration",
        headerName: "Hours",
        flex: .2,
        headerAlign: "right",
        disableColumnMenu: true,
        align: "right",
      },
      {
        field: 'Actions',
        headerName: ' ',
        width: 40,
        disableColumnMenu: true,
        renderCell: params => {
          return (
            <Fragment>
              <Tooltip title="View Details">
                <IconButton
                  id="editRecord"
                  onClick={() => handleView({ row: params.row })}
                  color="primary"
                  style={{ fontSize: '.9rem' }}
                >
                  <FontAwesomeIcon icon={faCircleInfo} />
                </IconButton>
              </Tooltip>
            </Fragment>
          );
        },
      },
    ],
    [handleView],
  );
};

export const useJobSortMapper = () => {
  return useMemo(() => [
    {field: "Job Num", sortBy: "JobNum"},
  ], []);
}

export const useJobReportSortMapper = () => {
  return useMemo(() => [
    {field: "Job Num", sortBy: "JobNum"},
    {field: 'F Name', sortBy: "LName"},
    {field: "Activity Type", sortBy: "ActivityType"},
    {field: "Rate Desc", sortBy: "RateDesc"},
  ], []);
}