import React, { useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';

const defaultProps = {
    open: false, 
    severity: 'success', 
    message: '',
    location: {horizontal: 'center', vertical: 'top'},
    onSnackbarClose: () => {},
};

export const CustomAlert = (props) => {
    const {open, severity, message, location, onSnackbarClose} = props;
    const [prps, setPrps] = useState();

    useEffect(() => {
        setPrps({
            open: (!!open && open) || defaultProps.open,
            severity: (!!severity && severity) || defaultProps.severity,
            message: (!!message && message) || defaultProps.message,
            location: (!!location && location) || defaultProps.location,
            onSnackbarClose: (!!onSnackbarClose && onSnackbarClose) || defaultProps.onSnackbarClose
        })
    }, [open, severity, message, onSnackbarClose, location]);

    const handleClose = (reason) => {
        if (reason === 'clickaway'){
            return;
        }
        setPrps({...prps, open: false, message: 'closed it'});
        if (!!onSnackbarClose && typeof onSnackbarClose === 'function')
            onSnackbarClose();
    }

    return (
        <Snackbar open={(!!prps && prps.open) || defaultProps.open} 
            anchorOrigin={(!!prps && prps.location) || defaultProps.location }
            autoHideDuration={5000} onClose={handleClose}>
            <MyAlert onClose={handleClose} 
                severity={(!!prps && prps.severity) || defaultProps.severity}>
                {(!!prps && prps.message) || defaultProps.message}
            </MyAlert>
        </Snackbar>
    );
}

const MyAlert = React.forwardRef((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props}></MuiAlert>
});

CustomAlert.propTypes = {
    open: PropTypes.bool.isRequired,
    severity: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onSnackbarClose: PropTypes.func,
    location: PropTypes.object,
}

export const SimpifiedAlert = (props) => {
    const {alertProps} = (props);
    const {open, severity, message, location, onSnackbarClose, } = alertProps;

    const handleClose = (reason) => {
        if (reason === 'clickaway'){
            return;
        }
        if (!!onSnackbarClose && typeof onSnackbarClose === 'function')
            onSnackbarClose();
    }

    return (
        <Snackbar open={open} 
            anchorOrigin={location || defaultProps.location }
            autoHideDuration={2000} onClose={handleClose}>
            <MyAlert onClose={handleClose} severity={severity}>
                {message}
            </MyAlert>
        </Snackbar>
    );
}
