import { CoreForm } from "@kineticdata/react";
import { Box, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useReducer, } from "react";
import { KappFormLayout, KappPending, useDeviceViewStyle } from "../../controls/shared.components";
import { SelectedDeviceContext } from "./device.splitview";

export const DeviceViewForm = (props) => {
    const {onError} = props;
    const selectedDeviceCntx = useContext(SelectedDeviceContext);
    const [formParams, dispatch] = useReducer(paramsReducer, null);
    const cls = useDeviceViewStyle();

    useEffect(() => {
        if (!selectedDeviceCntx || !selectedDeviceCntx.selectedDevice) return;

        const getRightType = () => {
          let devid = String(selectedDeviceCntx.selectedDevice["Device ID"]).trim();
          let kasid = String(selectedDeviceCntx.selectedDevice["Kaseya Machine Group ID"]).trim();
          let cntid = String(selectedDeviceCntx.selectedDevice["NCentral Device ID"]).trim();

          if (parseInt(devid) > 0)
            return {type: "device", id: selectedDeviceCntx.selectedDevice["Device ID"]};

          if (kasid.length > 0 && kasid !== '-1')
            return {type: "kaseya", id: selectedDeviceCntx.selectedDevice["Kaseya Machine Group ID"]}; 
  
          if (cntid.length > 0 && cntid !== '-1')
            return {type: "ncentral", id: selectedDeviceCntx.selectedDevice["NCentral Device ID"]};
        }

        dispatch(getRightType());

    }, [selectedDeviceCntx])

    return (
      <Typography component={`div`} 
        name="device-form-wrapper" 
        className={cls["device-form-wrapper"]}
        style={(!formParams && {justifyContent: "center"}) || {}}
      >
        {(formParams &&
        <Box className={cls.root}>
        <CoreForm
          kapp="customer-portal"
          form={'view-device'}
          onError={onError}
          values={(formParams.DeviceID && {DeviceID: `${formParams.DeviceID}`}) 
          || (formParams.KaseyaMachineGroupID && {KaseyaMachineGroupID: `${formParams.KaseyaMachineGroupID}`})
          || (formParams.NCentralDeviceID && {NCentralDeviceID: `${formParams.NCentralDeviceID}`})
        }
          components={{
            Pending: KappPending,
            Layout: KappFormLayout,
          }}
        /></Box>) || 
        <Typography component={`div`} style={{
            height: "inherit", width: "inherit",
            display: "flex",
            justifyContent: "center",
          }}>
          No Device Selected
        </Typography>}
      </Typography>
    );
}

const paramsReducer = (state, action) => {
  switch (action.type) {
    case "device": {
      return {DeviceID: `${action.id}`}
    }
    case "kaseya": {
      return {KaseyaMachineGroupID: action.id}
    }
    case "ncentral": {
      return {NCentralDeviceID: action.id}
    }
    default: 
      throw new Error("Invalid type/state for devices form");
  }
}
