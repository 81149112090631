import { Grid, Link, Paper, Tab, Tabs, Typography, makeStyles } from "@material-ui/core";
import React, { Fragment, useCallback, useState, useEffect } from "react";
import { useBigNumberStyles, useHomeStyles, useMyIncidentsStyles } from "./home.styles";
import { rowsperpage, useWrappereStyles } from "../../controls/shared.components";
import { BottomInfo } from "../bottom/bottom.info";
import { useDappContext } from "../../auth/dapp.context";
import { SearchProvider } from "./search.provider";
import { GlobalSearch } from "./global.search";
import { useTicketsContext } from "../incidents/incident.provider";
import { IncidentsList } from "../incidents/incidents.list";
import { TabContext, TabPanel } from "@material-ui/lab";
import { ServiceRequestList } from "../services requests/service.request.main";
import { TeamList } from "../team/team.main";
import { SlantedCornerBoxIcon } from "../../images/dapp.icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { HomeCards } from "./home.v2.cards";
import { ModalSelection } from "../modals/modal.selection";
// import { RemoteControlModal } from "../modals/modal.remote";
import { ChatModal } from "../modals/modal.chat";
import { useDataFromBridge } from "../../controls/custom.hooks";
import { REMOTECONTROL } from "../../controls/dapp.constants";
import clsx from "clsx";
import { useServiceRequestContext } from "../services requests/filter/filter.provider";

export const HomeV2 = (props) => {
    const {...other} = props;
    const dappCntx = useDappContext();
    const ticketCntx = useTicketsContext();
    const history = useHistory();

    const [ticketType, setTicketType] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [showPrimary, setShowPrimary] = useState(false);
    const [srch, setSrch] = useState();
    const [openIncCount, setOpenIncCount] = useState(0);
    const [openReqCount, setOpenReqCount] = useState(0);
    const [requestsBridge, setRequestsBridge] = useState("Service Requests Simplified");
    const [requestsParams, setRequestsParams] = useState();

    // const [modalRemote, setModalRemote] = useState({open: false, handleClose: () => {}});
    const [chatModal, setChatModal] = useState({open: false, handleClose: () => {}});

    const homeClasses = useHomeStyles();
    const wrapperClasses = useWrappereStyles();
    const nbrs = useBigNumberStyles();

    const srvcCntx = useServiceRequestContext();

    const newIncident = () => {
        setTicketType("incident");
        setShowPrimary(false);
        setOpenModal(true);
    }
    const newServiceRequest = () => {
        setTicketType("servicerequest");
        setShowPrimary(true);
        setOpenModal(true);
    } 

    useEffect(() => {
        dappCntx.dappSubtitle("Home");
        // eslint-disable-next-line
    }, [dappCntx.title]);

    useEffect(() => {
        if (!dappCntx?.user) return;
        setSrch({
            ...ticketCntx?.srch, 
            PageSize: rowsperpage[0],
            CustomerContactID: (dappCntx.user?.user.UserType === "User" && dappCntx.user?.user.ContactID) || ' ',
            Status: "Open",
            assignedTo: ' ',
        });
    }, [dappCntx, ticketCntx]);
    
    const getRightType = useCallback((deviceRecord) => {
        let devid = String(deviceRecord["Device ID"]).trim();
        let kasid = String(deviceRecord["Kaseya Machine Group ID"]).trim();
        let cntid = String(deviceRecord["NCentral Device ID"]).trim();

        if (parseInt(devid) > 0)
          return {type: "device", id: deviceRecord["Device ID"]};

        if (kasid.length > 0 && kasid !== '-1')
          return {type: "kaseya", id: deviceRecord["Kaseya Machine Group ID"]}; 

        if (cntid.length > 0 && cntid !== '-1')
          return {type: "ncentral", id: deviceRecord["NCentral Device ID"]};
      }, []);

    const handleModalClose = useCallback((props) => {
        const {ok, selection} = props;
        setOpenModal(false);
        if (!ok) return;
        let type = selection.type;
        if (type !== 'primaryContact') {
          let field = `${type.charAt(0).toUpperCase()}${type.slice(1)} ID`;
          if (type !== 'contact'){
            let devType = getRightType(selection.record);
            history.push(`/${ticketType}/add/${devType.id}?type=${type}&devicetype=${devType.type}`);
          } else {
            let id = selection.record[`${field}`];
            history.push(`/${ticketType}/add/${id}?type=${type}`);
          }
        } else {
          let field = `${type.charAt(0).toUpperCase()}${type.slice(1)}ID`;
          let id = selection.record[`${field}`];
          history.push(`/${ticketType || 'servicerequest'}/add/${id}?type=${type}`);
        }

    }, [history, ticketType, getRightType]);

    // const showModalRemote = useCallback(() => {
    //     setModalRemote({ ...modalRemote, open: true, handleClose: () => {
    //       setModalRemote({...modalRemote, open: false, handleClose: () => {}});
    //     }})
    // });

    const showModalRemote = () => window.open(REMOTECONTROL, "_blank");

    const showModalChat = useCallback(() => {
        setChatModal({...chatModal, open: true, handleClose: () => {
          setChatModal({...chatModal, open: false, handleClose: () => {}});
        }})
    }, [chatModal]);

    const incidents = useDataFromBridge({ 
        xUser: dappCntx?.user?.user, 
        apiValues: srch,
        bridgedResourceName: "All Incidents Filtered",
    });

    useEffect(() => {
        setRequestsBridge("Service Requests Simplified");
    }, [dappCntx])

    useEffect(() => {
        if (!dappCntx?.user) return;
        setRequestsParams({...srvcCntx.initLoadParams, CustomerContactID: (dappCntx.user?.user?.UserType === "User" && dappCntx.user.user.ContactID) || ' '});
    }, [srvcCntx, dappCntx])

    const requests = useDataFromBridge({ 
        xUser: dappCntx?.user?.user, 
        apiValues: requestsParams,
        bridgedResourceName: requestsBridge,
    });

    useEffect(() => {
        if (!incidents) return;
        setOpenIncCount(incidents?.data?.metadata?.count || 0);
    }, [incidents]);

    useEffect(() => {
        if (!requests) return;
        setOpenReqCount(requests?.data?.metadata?.count || 0);
    }, [requests]);
    
    return (
        <Fragment>
            <Paper {...other} elevation={0} className={homeClasses.homeroot}>
                <Grid container spacing={2} direction="row" style={{paddingBottom: "1.8rem"}} wrap="wrap">
                    <Grid item xs={12} sm={4}>
                        <GlobalSearchMain />
                    </Grid>
                    <Grid item xs={12} sm={1}></Grid>
                    <Grid item xs={12} style={{display: "flex"}} sm={7}>
                        {dappCntx.user?.user?.["UserType"] !== "User" && <TeamList/>}
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="home-action-grids">
                    <Grid item xs={12} sm={6} md={6} lg={6} className={nbrs.numbersRoot}>
                        <BigNumberBoxes incidentsCount={openIncCount} requestCount={openReqCount}/>
                    </Grid>
                    <Grid item xs={12} style={{display: "flex", flexFlow: "column"}} sm={6} md={6} lg={6}>
                        <Typography component={`div`} elevation={2} className="home-ticket-grid">
                            <TicketTabs/>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3} direction="row" className="home-action-spacer">
                    <Grid item xs={12} sm={12} md={12} style={{height: "4rem"}}>
                        <Typography component={`div`}></Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="home-action-cards" wrap="wrap">
                    <HomeCards 
                        newIncident={newIncident} 
                        newRequest={newServiceRequest} 
                        openRemote={showModalRemote}
                        openChat={showModalChat}
                    />
                </Grid>
            </Paper>
            <Typography component={`div`} className={wrapperClasses.bottom}>
                {/* <BottomInfowithChat popupChat={showModalChat}/> */}
                <BottomInfo/>
            </Typography>
            <ModalSelection
              open={openModal}
              onCloseDialog={handleModalClose}
              showPrimary={showPrimary}
            />
            {/* <RemoteControlModal {...modalRemote}/> */}
            <ChatModal {...chatModal}/>
        </Fragment>
    )
}

const useSearchStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: '3rem',
        color: "window",
        paddingTop: "1.2rem",
    },
    txtLine: {
        paddingBottom: ".5rem",
    }

}));

const GlobalSearchMain = () => {
    const stls = useSearchStyles();
    const dappCntx = useDappContext();

    return (
        <Typography component={`div`} className={stls.root}>
            <Typography component={`div`} variant="h5" className={stls.txtLine}>
                {`Hello, ${dappCntx?.user?.user?.FirstName || ''}`}
            </Typography>
            <Typography component={`div`} variant="subtitle1" className={stls.txtLine}>
                What would you like to do today?
            </Typography>
            <SearchProvider>
                <GlobalSearch />
            </SearchProvider>
        </Typography>
    )    
}

export const TicketTabs = (props) => {
    const [value, setValue] = useState("inc");
    const stls = useMyIncidentsStyles();
    
    const handleTabChange = useCallback((event, newValue) => {
        setValue(newValue);
    }, []);

    return (
        <Typography component={`div`} className={stls["tabs-grid-wrapper"]}>
            <TabContext value={value}>
                <Tabs
                    value={value}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="inherit"
                >
                    <Tab label="My Incidents" value="inc" className="home-tab" />
                    <Tab label="My Requests" value="req" className="home-tab" />
                    <Tab label="My Assigned Incs" value="my_inc" className="home-tab"/>
                    <Tab label="My Assigned Reqs" value="my_req" className="home-tab"/>
                </Tabs>
                    <Typography component={`div`} className={clsx(stls.outer, stls["outer-layout"])}>
                        <TabPanel value="inc" index={0} className={stls["outer-tab-panel"]}>
                            <IncidentsList contactOnly={true} short={true} name="incident-list" />
                        </TabPanel>
                        <TabPanel value="req" index={1}>
                            <ServiceRequestList contactOnly={true} short={true} 
                                setToolbarStats={() => { }}
                                handleError={() => { console.log(`some error happened here`) }}
                            />
                        </TabPanel>
                        <TabPanel value="my_inc" index={2} className={stls["outer-tab-panel"]}>
                            <IncidentsList contactOnly={false} short={true} assignedToMe={true} name="my-incident-list" />
                        </TabPanel>
                        <TabPanel value="my_req" index={3}>
                            <ServiceRequestList contactOnly={false} short={true} assignedToMe={true}
                                setToolbarStats={() => { }}
                                handleError={() => { console.log(`some error happened here`) }}
                            />
                        </TabPanel>
                    </Typography>
            </TabContext>
        </Typography>
    );
}

export const BigNumberBoxes = (props) => {
    const {incidentsCount, requestCount} = props;
    const history = useHistory();
    const dappCntx = useDappContext();

    return (
        <Typography component={`div`} className="wrapper-slanted-boxes">
            <Grid container spacing={2} direction="row">
                <Grid item xs={1} />
                <Grid item xs={5} sm={5} className="big-number-content">
                    <SlantedCornerBoxIcon className="big-number-frame"/>
                    <Typography component={`div`} className="lines-wrapper">
                        <Typography component={`div`} className="big-number-line-1">
                            {incidentsCount}
                        </Typography>
                        <Typography component={`div`} className="big-number-line-2">Open Incidents</Typography>
                        <Link 
                            component={'button'} 
                            className="big-number-line-3" 
                            onClick={() => history.push("/incidents")}
                        >
                            View All
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={5} sm={5} className="big-number-content">
                    <SlantedCornerBoxIcon  className="big-number-frame"/>
                    <Typography component={`div`} className="lines-wrapper-2">
                        <Typography component={`div`} className="big-number-line-1">
                            {requestCount}
                        </Typography>
                        <Typography component={`div`} className="big-number-line-2">Open Service Requests</Typography>
                        <Link 
                            component={'button'} 
                            className="big-number-line-3" 
                            onClick={() => history.push((dappCntx.user.user.UserType === "User" && "/myrequests") || "/requests")}
                        >
                            View All
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={1} />
            </Grid>
        </Typography>
    )
}
