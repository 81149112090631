import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createHashHistory } from 'history';
import { App } from './App';
import './index.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

// import { bundle } from "react-kinetic-core";
// import { Alert } from "reactstrap";
// import 'bootstrap/dist/css/bootstrap.css';

// Asynchronously import the global dependencies that are used in the embedded
// forms. Note that we deliberately do this as a const so that it should start
// immediately without making the application wait but it will likely be ready
// before users nagivate to the actual forms.
const globals = import('./globals');

export const history = createHashHistory();

ReactDOM.render(
  <Router history={history}>
    <App globals={globals} />
  </Router>,
  document.getElementById('root'),
);


