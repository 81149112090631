import React, { Fragment, useCallback, useEffect, useState } from "react";
import { ContactToolbar } from "./contacts.toolbar";
import { useContactColumns, useSortMapper } from "./columns.contacts";
import { rowsperpage } from "../../controls/shared.components";
import { WrapperPaper } from "../../controls/shared.components";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { useGridStyles } from "../../controls/datagrid.striped";
import { CustomAlert } from "../../alerts/alert.snackbar";
import { useDataFromBridge } from "../../controls/custom.hooks";
import { useDappContext } from "../../auth/dapp.context";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";
import { utils, writeFileXLSX } from "xlsx";
import swal from "sweetalert";
import { EXPORTALERTMSG } from "../../controls/dapp.constants";

const initLoadParams = {
  AscDesc: 'DESC',
  GetTotal: true,
  IncludeInactive: false,
  PageNum: 1,
  PageSize: rowsperpage[0],
  PageToken: ' ',
  SortField: 'ContactID',
  SubClientContact: false,
  SearchValue: ' ',
  IsPending: false,
}

const bridgedResourceName = "All Contacts"
const exportBridge = "AllContactsExport"

export const Contacts = (props) => {
    const { ...other } = props;
    const [loading, setLoading] = useState(true);
    const [apiparms, setApiparms] = useState(initLoadParams);
    const [griddata, setGridData] = useState([]);
    const [pageNum, setPageNum] = useState(0);
    const [pageSize, setPageSize] = useState(rowsperpage[0]);
    const [selected, setSelected] = useState();
    const [exportParams, setExportParams] = useState(undefined);
    const gridClasses = useGridStyles();
    const dappCntx = useDappContext();
    const history = useHistory();
    const location = useLocation();
    const sortMapper = useSortMapper();

    const apiRef = useGridApiRef();

    const [showAlert, setShowAlert] = useState({
      open: false, severity: 'success', message: '',
      onSnackBarClose: () => {},
    });

    const contacts = useDataFromBridge({
      xUser: dappCntx.user?.user, apiValues: apiparms,
      bridgedResourceName: bridgedResourceName 
    });

    const contacts2export = useDataFromBridge({
      xUser: dappCntx.user?.user, apiValues: exportParams,
      bridgedResourceName: exportBridge, 
    });

    const handleEditContact = useCallback(({row}) => {
      history.push({pathname: `/contacts/edit/${row["Contact ID"]}`, state: {prevPath: location.pathname}});
    }, [history, location]);
    
    const handleViewContact = ({row}) => history.push(`/contacts/view/${row["Contact ID"]}`);

    const handleDeleteContact = useCallback(({row}) => {
      let msg = `Delete for ${row['Contact ID']} - ${row['Display Name']} is not implemented yet.`;
      setShowAlert({
        open: true, severity: "warning", message: msg,
        onSnackBarClose: () => setShowAlert({open: false, severity: "error", message: "random"}),
    });
    }, [])
    
    const contactColumns = useContactColumns({
      handleEdit: handleEditContact,      
      handleDelete: handleDeleteContact,
      handleView: handleViewContact,
    });

    const handlePageChange = useCallback((model, details) => {      
      setGridData([]);
      if (model.pageSize !== pageSize)
        model.page = 0;
      setPageNum(model.page);
      setPageSize(model.pageSize);
      setLoading(true);
      setApiparms({...apiparms, PageNum: model.page + 1, PageSize: model.pageSize/*, PageToken: pagetoken*/});
    }, [apiparms, pageSize]);

    const showError = useCallback(({message}) => {
      setShowAlert({...showAlert, open: true, 
          severity: "error", message: message,
          onSnackBarClose: () => { setShowAlert({open: false, message: "random"})}
      });
    }, [showAlert])
  
    useEffect(() => {
      if (!contacts) return;
      let doShow = true;

      setLoading(false);
      if (!contacts.success) {
          showError({message: contacts.data.Message || contacts.data.message});
          return;
      }

      if (!!contacts.data?.error){
        showError({message: contacts.data?.error?.message});
        return;
      }
  
      // setPageToken(contacts.data.metadata?.nextPageToken || contacts.data.NextPage || initLoadParams.PageToken);
      doShow && setGridData((contacts.data.records || contacts.data.Data)?.map((row, indx) => { return {...row, id: indx}}));
      return () => doShow = false;
      // eslint-disable-next-line
    }, [contacts]);

    const handleSubClientChanged = useCallback(({value}) => {
      setLoading(true);
      setPageNum(0);
      setApiparms({...apiparms, PageNum: 1, PageToken: ' ', SubClientContact: value});
    }, [apiparms]);

    const handlePendingToggle = useCallback(({value}) => {
      setLoading(true);
      setPageNum(0);
      setApiparms({...apiparms, PageNum: 1, PageToken: ' ', IsPending: value});
    }, [apiparms]);

    const handleRefresh = useCallback(() => {
      setLoading(true);
      setGridData([]);
      setApiparms({...apiparms, PageToken: 'random'});
      setApiparms({...apiparms, PageToken: ' '});
    }, [apiparms]);

    const handleRowSelected = useCallback((event) => {
      setSelected(event.row);
    }, []);

    const handleNewContact = () => history.push(`/contacts/add`);

    useEffect(() => {
      dappCntx.dappSubtitle("Contacts", () => {});
    }, [dappCntx]);

    const handleSortChange = useCallback((sort) => {
      if (!sort || sort?.length === 0) return;
      const fld = sortMapper.find(x => x.field === sort[0]?.field)?.sortBy;
      if (apiparms.SortField === fld && apiparms.AscDesc === sort[0]?.sort) {
        return;
      }
      setGridData([]);
      setLoading(true);

      setApiparms({...apiparms, AscDesc: `${sort[0]?.sort}`, SortField: `${fld}`});
    }, [apiparms, sortMapper]);

    const handleExport = useCallback(() => {
      swal({
        title: "Export Contacts",
        content: {
          element: "span",
          attributes: {
            innerHTML: EXPORTALERTMSG.replace('REPLACE-STRING', 'Contacts'),
          },
        },
        icon: undefined,
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: {
          confirm: true,
          cancel: true,
        },
        dangerMode: false,
      }).then((accepted) => {
        accepted && setExportParams({
          ...apiparms,
          PageNum: 1,
          PageSize: 2000,
        });
      });
    }, [apiparms]);

    const handleSearchValueChange = useCallback((val) => {
      setGridData([]);
      setLoading(true);
      setApiparms({...apiparms, SearchValue: (!!val && val) || ' '})
    }, [apiparms])

    useEffect(() => {
      if (!contacts2export) return;
      if (!contacts2export?.data) return;

      const d = new Date();
      const stamp = `${d.getFullYear()}${d.getMonth()}${d.getDay()}-${d.getHours()}${d.getMinutes()}`;
      const fileName = `CONTACT_CMS_${stamp}.xlsx`;

      const ws = utils.json_to_sheet(contacts2export.data.records);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Contacts");
      writeFileXLSX(wb, fileName);

    }, [contacts2export])

    const handleFilter = useCallback(() => {
      console.log(`we'll be showing filter here`);
    }, []);

    return (
      <Fragment {...other}>
        <ContactToolbar 
          subClientChanged={handleSubClientChanged} 
          onRefresh={handleRefresh}
          disableEdit={!selected}
          newContact={handleNewContact}
          editContact={handleEditContact}
          handleExport={handleExport}
          handleFilter={handleFilter}
          handleSearchValueChange={handleSearchValueChange}
          handlePendingToggle={handlePendingToggle}
        />
        <WrapperPaper>
          <DataGridPro className={clsx(gridClasses.gridheadfoot, gridClasses.gridrows)}
            columns={contactColumns}
            page={pageNum}
            density="compact"
            paginationMode="server"
            autoPageSize
            rows={griddata}
            loading={loading}
            apiRef={apiRef}
            rowCount={contacts?.data.metadata?.count || contacts?.data.Total || 0}
            pageSizeOptions={rowsperpage}
            onRowClick={handleRowSelected}
            onPaginationModelChange={handlePageChange}
            sortingMode="server"
            onSortModelChange={handleSortChange}
            pagination
            initialState={{
              pagination: {
                paginationModel: {pageNum: pageNum, pageSize: pageSize}
              }
            }}
          />
        </WrapperPaper>
        <CustomAlert {...showAlert}/>
      </Fragment>
    );
}

