import React, { Fragment, useEffect, useState } from "react";
import { Paper, Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useCallback } from "react";
import { CONTACTROUTE, INCIDENTROUTE, JOBROUTE, SRVCREQROUTE } from "../../controls/dapp.constants";
import { useWrappereStyles } from "../../controls/shared.components";
import { BottomInfo } from "../bottom/bottom.info";
import { useNavigationContext } from "../../navigation/navigation.provider";

const routeMapping = [
    {key: "IncidentID", route: INCIDENTROUTE},
    {key: "ContactID", route: CONTACTROUTE},
    {key: "PID", route: CONTACTROUTE},
    {key: "ServiceRequestID", route: SRVCREQROUTE},
    {key: "JOBID", route: JOBROUTE},
    {key: "VGID", route: "/reset/"},    
    {key: "approved", route: "/pendingcontact"},
    {key: "approvercontactid", route: "/pendingcontact"},
    {key: "ac", route: "/pendingcontact"},
]

export const NomatchRedirects = () => {
    const [success, setSuccess] = useState(true);
    const location = useLocation();
    const history = useHistory();
    const wrapCls = useWrappereStyles();
    const [showRedirect, setShowRedirect] = useState(false);
    const [redirectTo, setRedirectTo] = useState(null)
    const navCntx = useNavigationContext();

    const tryRedirect = useCallback((keys) => {
        let check = keys.map(x => {
         return routeMapping.find(y => y.key === x.key);
        });
        check = check.filter(x => !!x && x)?.[0];
        setSuccess(!!check);

        if (!!check && !!check.route){
            setShowRedirect(false);
            setRedirectTo(`${check.route}${keys.find(x => x.key === check.key)?.value}`)
        } else {
            setSuccess(false);
            setShowRedirect(true);
        }
    }, []);

    const findMatchRoute = useCallback((arr) => {
        let rte = "";
        let cnt = 1;

        let x =  arr.map(element => {
            if (!rte || rte !== element.route) {
              rte = element.route;
              cnt = 1;
            } else {
              ++cnt;
            }  
            return { ...element, cnt };  
          });
          
          return x.reduce((prev, curr, indx, arr) => {
            return ((prev.cnt > curr.cnt && prev) || curr)
          }, {});

    }, []);

    const tryRedirectWithBody = useCallback((keys) => {
        let check = keys.map(x => {
            return routeMapping.find(y => y.key === x.key);
        }).filter(x => !!x && x);
        
        let redir2 = findMatchRoute(check);
        let route = redir2.route;
        let params = check.map(x => {
            return keys.find(y => y.key === x.key)
        }).map(x => {
            return {[`${x.key}`]: x.value};
        });
        let single = {};
        params.map(x => single = {...single, ...x});
        // console.log(route, single);
        if (!!route){
            setShowRedirect(false);
            navCntx.setBodyParams(single);
            setRedirectTo(`${route}`);
        } else {
            setSuccess(false);
            setShowRedirect(true);
        }
    }, [findMatchRoute, navCntx]);

    useEffect(() => {
        if (!redirectTo) return;
        history.replace(redirectTo);
    }, [history, redirectTo]);
 
    useEffect(() => {
        if (!location) return;
        var chk = location.search.replace('?', '').split('&').map(x => {
            let splt = x.split('=');
            return {key: splt[0], value: splt[1]}
        });
        let filtered = chk.filter(x => x?.value && x);
        // console.log(JSON.stringify(filtered));   
        if (filtered.length <= 0) return;
        
        if (filtered.length > 1){
            tryRedirectWithBody(filtered);  
        } else {
            tryRedirect(filtered);  
        } 

    }, [location, tryRedirect, tryRedirectWithBody]);

    return (
        <Fragment>
                {(showRedirect && 
            <Typography component={`div`} 
            style={{
                minWidth: "50%", minHeight: "50%", margin: "auto", justifyContent: "center", alignItems: "center"
              }}>
              <Paper name="core-form-container" elevation={6}
                style={{
                  minWidth: 380,
                  minHeight: 380,
                  backgroundColor: "window",
                  borderRadius: 15,
                  display: "flex",
                  flexFlow: "column",
                  overflowY: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",                                    
                }}>
                    <Typography style={{color: (!success  && "red") || "inherit", fontSize: "1.8rem", fontWeight: "bold", wordWrap: true}}>
                        {(success && `URL ${location.search} is being redirected`) || `The URL ${location.search} has no match to redirect`}
                    </Typography>
              </Paper>
              </Typography>) || null }
              <Typography component={`div`} className={wrapCls.bottom}>
                <BottomInfo/>
              </Typography>
        </Fragment>
    )
}

