import { useState, useEffect } from 'react';
import { getReportEmbedToken } from '../../apicalls/report.api';
import { POWERBITOKEN } from '../../controls/dapp.constants';

export const useReportToken = props => {
  const { reportId, dataSetId, userName } = props;
  const [result, setResult] = useState({
    success: false,
    reportToken: null,
    errorMessage: '',
  });

  useEffect(() => {
    if (!reportId || !dataSetId || !userName){
      return;
    } 
      try {
        getReportEmbedToken({
          reportId: reportId,
          dataSetId: dataSetId,
          userName: userName,
          reportToken: POWERBITOKEN,
        })
          .then(response => {
            setResult({
              success: true,
              reportToken: response.data,
              errorMessage: '',
            });
          })
          .catch(error => {
            setResult({
              success: false,
              reportToken: null,
              errorMessage: `API call to get report embed token has failed with ${error.message}`,
            });
          });
      } catch (ex) {
        setResult({
          success: false,
          reportToken: null,
          errorMessage: `Failed to get report embed token with: ${ex.Message}`,
        });
      }
  }, [reportId, dataSetId, userName]);

  return result;
};
