import { useEffect, useState } from 'react';
import { getDataUniversalExportFromBrdige, getDataUniversalFromBrdige } from '../apicalls/universal.api';
import PropTypes from 'prop-types';

export const useDataFromBridge = props => {
  const { xUser, apiValues, bridgedResourceName, callback } = props;
  const [contactlist, setContactList] = useState(null);

  useEffect(() => {
    if (!xUser) return;
    let isSubscribedToApi = true;
    try {
      if (callback && typeof callback === 'function') {
        callback();
      }

      getDataUniversalFromBrdige({
        xUser: xUser,
        apiValues: apiValues,
        bridgedResourceName: bridgedResourceName,
      })
        .then(response => {          
          isSubscribedToApi && setContactList({ success: !!response, data: response });
        })
        .catch(error => {
          isSubscribedToApi && setContactList({ success: false, data: error?.message || error?.response?.data });
        });
    } catch (exp) {
      isSubscribedToApi && setContactList({ success: false, data: exp.Message });
    }

    return () => {
      isSubscribedToApi = false;
    }
  }, [xUser, apiValues, bridgedResourceName, callback]);

  return contactlist;
};

useDataFromBridge.PropTypes = {
    xUser: PropTypes.object.isRequired,
    apiValues: PropTypes.object.isRequired,
    bridgedResourceName: PropTypes.string.isRequired    
}

export const useData4Export2Excel = props => {
  const { xUser, apiValues, bridgedResourceName } = props;
  const [data2export, setData2Export] = useState(null);

  useEffect(() => {
    if (!xUser) return;
    let isSubscribedToApi = true;

    try{
      getDataUniversalExportFromBrdige({
        xUser: xUser,
        apiValues: apiValues,
        bridgedResourceName: bridgedResourceName,
      }).then((response) => {
        isSubscribedToApi && setData2Export({success: true, data: response});
      }).catch(error => {
        isSubscribedToApi && setData2Export({success: false, data: error.response.data});
      })
    } catch (ex) {
      isSubscribedToApi && setData2Export({success: false, data: ex.Message});
    }

    return () => {
      isSubscribedToApi = false;
    }

  }, [xUser, apiValues, bridgedResourceName]);
  
  return data2export;
}
