import React, {useMemo} from "react";
import { DappBooleanAsIcon } from "../../controls/shared.components";
import { Tooltip, IconButton, Link } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, /*faEraser*/ } from "@fortawesome/free-solid-svg-icons";

export const useSortMapper = () => {
    return useMemo(() => [
        {field: "Display Name", sortBy: "DisplayName"},
        {field: "Open Incidents", sortBy: "OpenIncidentsCount"},
        {field: "Organization Name", sortBy: "OrganizationName"},
        {field: "Open Service Requests", sortBy: "OpenRequestsCount"},
    ], [])
};

export const useContactColumns = (props) => {
const {handleEdit, handleView, /*handleDelete*/} = props;

    const columns = useMemo(() => [
        {field: 'beginSpacer', headerName: ' ', width: 40, disableColumnMenu: true, sortable: false},
        {
            field: "Display Name", headerName: "Name", width: 180, disableColumnMenu: true,
            resizable: true,
            renderCell: (params) => { return (
                <Tooltip title="View Contact">
                    <Link component={`button`} underline="none" variant="body2"
                        onClick={() => handleView({row: params.row})}>
                            {params.row['Display Name']}
                    </Link>
                </Tooltip>
            )}
        },
        {
            field: "Is Active CMS", headerName: "CMS Access", flex: 0.3, 
            disableColumnMenu: true, align: "center", sortable: false,
            renderCell: (params) => <DappBooleanAsIcon value={params.value}/>
        },
        {
            field: "Primary Contact", headerName: "Primary", flex: 0.3,
            disableColumnMenu: true, align: "center", sortable: false, headerAlign: "center",
            renderCell: (params) => <DappBooleanAsIcon value={params.value}/>,
        },
        {
            field: "Organization Name", headerName: "Organization", flex: .8, disableColumnMenu: true,            
            resizable: true,
        },
        {
            field: "POC", headerName: "POC", width: 100, disableColumnMenu: true,
            renderCell: (params) => <DappBooleanAsIcon value={params.value}/>, 
            align: "center", type: "number", headerAlign: "center", sortable: false,
        },
        {
            field: "Is Help Desk Authorized", width: 100, align: "center",
            headerName: "Help Desk Auth", headerAlign: "center",
            disableColumnMenu: true, sortable: false,
            renderCell: (params) => <DappBooleanAsIcon value={params.value}/>, 
        },
        {
            field: "Open Incidents", flex: 0.4, type: "number", 
            disableColumnMenu: true, align: "right",
            filterable: true,
        },
        {
            field: "Open Service Requests", flex: .4, type: 'number',
            disableColumnMenu: true, align: "right",
            filterable: true,
        },
        {
            field: "Actions", headerName: " ", width: 80, disableColumnMenu: true, 
            sortable: false, align: "right",
            renderCell: (params) => {
                return (
                    <React.Fragment>
                        <Tooltip title="Edit Contact">
                        <IconButton id="editRecord" onClick={() => handleEdit({row: params.row})} 
                            color="primary" style={{fontSize: '.9rem'}}>
                            <FontAwesomeIcon icon={faEdit} />
                        </IconButton>
                        </Tooltip>
                        {/* <Tooltip title="Delete Contact">
                        <IconButton id="deleteRecord" onClick={() => handleDelete({row: params.row})} 
                            color="secondary" style={{fontSize: '.9rem'}}>
                            <FontAwesomeIcon icon={faEraser} />
                        </IconButton>
                        </Tooltip> */}
                    </React.Fragment>
                );
            },
        },
    ], [handleEdit, handleView]);
    return columns;
}