import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardContent, Grid, Link, Tooltip, Typography } from "@material-ui/core";
import { faPhone, faFax, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useCardStyles } from "../../controls/shared.components";
import { useHomeStyles } from "../home/home.styles";
import { CustSrvcTeamIcon, TechMngrTeamIcon, ITConsultTeamIcon } from "../../images/dapp.icons";

export const CustomerRepCard = ({teamRecord, onShowWhite, ...other}) => {
    const [contact2show, setContact2Show] = useState();
    const iconCls = useHomeStyles();

    useEffect(() => {
        if (!teamRecord) return;
        setContact2Show({
            key: "cust-rep",
            title: "Account Manager", 
            fullName: teamRecord?.[0]?.["CustSvcRepFullName"],
            ext: teamRecord?.[0]?.["CustRepExt"],
            phone: teamRecord?.[0]?.["Phone"],
            fax: teamRecord?.[0]?.["Fax"],
            email: teamRecord?.[0]?.["CustSvcRepEmail"],            
            icon: <CustSrvcTeamIcon className={iconCls.dappiconTeam}/>,
        });
    }, [teamRecord, iconCls])

    return (contact2show && contact2show?.fullName && 
        <ContactCard id="customer-rep" {...contact2show} {...other}/>
    ) || null
}

export const ITConsultantCard = ({teamRecord, onShowWhite, ...other}) => {
    const [contact2show, setContact2Show] = useState();
    const iconCls = useHomeStyles();

    useEffect(() => {
        if (!teamRecord) return;
        setContact2Show({
            key:"it-consult",
            title: "IT Consultant", 
            fullName: teamRecord?.[0]?.["ITConsultantFullName"],
            ext: teamRecord?.[0]?.["ITConsultantExt"],
            phone: teamRecord?.[0]?.["Phone"],
            fax: teamRecord?.[0]?.["Fax"],
            email: teamRecord?.[0]?.["ITConsultantEmail"],
            icon: <ITConsultTeamIcon className={iconCls.dappiconTeam}/>,
        });
    }, [teamRecord, iconCls])

    return (contact2show && contact2show?.fullName && 
        <ContactCard id="it-consultant" {...contact2show} {...other}/>
    ) || null
}

export const TechManagerCard = ({teamRecord, onShowWhite, ...other}) => {
    const [contact2show, setContact2Show] = useState();
    const iconCls = useHomeStyles();

    useEffect(() => {
        if (!teamRecord) return;
        setContact2Show({
            key: "tech-mngr",
            title: "Technical Manager", 
            fullName: teamRecord?.[0]?.["TechMgrFullName"],
            ext: teamRecord?.[0]?.["TechMgrExt"],
            phone: teamRecord?.[0]?.["Phone"],
            fax: teamRecord?.[0]?.["Fax"],
            email: teamRecord?.[0]?.["TechMgrEmail"],
            icon: <TechMngrTeamIcon className={iconCls.dappiconTeam}/>,
        });
    }, [teamRecord, iconCls])

    return (contact2show && contact2show?.fullName && 
        <ContactCard id="tech-manager" {...contact2show} {...other} />
    ) || null
}

const ContactCard = ({id, title, fullName, ext, phone, fax, email, icon, iconWhite, onShowWhite, ...other}) => {
    const cls = useCardStyles();

    return (
        <Card 
            style={{backgroundColor: "transparent"}} 
            {...other} 
            component="div" name="card-root"
            className={cls.teamCard}>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {icon}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component={`div`} className="team-title">
                                {title}
                            </Typography>
                            <Typography component={`div`} className={'team-name'}>
                                <Tooltip title={email}>
                                    <Link href={`mailto:${email}`} underline="none" style={{paddingRight: 10}}>
                                        {fullName}
                                    </Link>
                                </Tooltip>
                                <FontAwesomeIcon icon={faEnvelope}/>
                            </Typography>
                            <Grid container spacing={0}>
                                <Grid item xs={1}>
                                    <Typography variant="body2" component={`div`}>
                                        <FontAwesomeIcon icon={faPhone}/>
                                    </Typography> 
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="body2" component={`div`}>
                                        {phone}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" component={`div`}>
                                        {ext && `ext: (${ext})`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0}>
                                <Grid item xs={1}>
                                    <Typography variant="body2" component={`div`}>
                                        <FontAwesomeIcon icon={faFax}/>
                                    </Typography> 
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography component={`div`} variant="body2">
                                        {fax}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}></Grid>
                            </Grid>                           
                        </Grid>
                    </Grid>
                </CardContent>
        </Card>
    )
};