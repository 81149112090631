import React, { Fragment, useState, useEffect, useCallback } from "react";
import { rowsperpage, WrapperPaper } from "../../../controls/shared.components";
import { useParams } from "react-router-dom";
import { useDappContext } from "../../../auth/dapp.context";
import { useDataFromBridge } from "../../../controls/custom.hooks";
import { CustomAlert } from "../../../alerts/alert.snackbar";
import { useGridStyles } from "../../../controls/datagrid.striped";
import clsx from "clsx";
import { useReducer } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useJobReportColumns, useJobReportSortMapper } from "../jobs.hook";
import { JobDetailsToolbar } from "../jobs.toolbar";
import { JobReportModal } from "./job.reportview";
import swal from "sweetalert";
import { utils, writeFileXLSX } from "xlsx";
import { EXPORTALERTMSG } from "../../../controls/dapp.constants";

const initLoadParams = {
    PageSize: rowsperpage[0],
    PageNum: 1,
    ShowAll: true,
    GetTotal: true,
    AscDesc: 'desc',
    SortField: "TimeDataID",
    PageToken: ' ',
    InvoiceID: ' ',
};
  
const bridgedResourceName = "Job Report by Job ID";

export const JobReportList = () => {
  const { jobid, invid } = useParams();
  const [loading, setLoading] = useState(true);
  const [apiparms, setApiparms] = useState(null);
  const [gridData, setGridData] = useState([]);
  const [xUser, setXuser] = useState(null);
  const [jobRecord, setJobRecord] = useState(null);
  const [toolbarTitle, setToolbarTitle] = useState();
  const [pagingState, dispatch] = useReducer(pageReducer, initState);
  const [singleVal, setSingleVal] = useState();
  const [exportParams, setExportParams] = useState(undefined);
  
  const [jobReport, setJobReport] = useState({
    record: null, open: false, handleClose: () => {}
  });

  const dappCntx = useDappContext();
  const [showAlert, setShowAlert] = useState({
    open: false,
    severity: 'error',
    message: '',
    onSnackBarClose: () => {},
  });
  const cls = useGridStyles();
  const sortMapper = useJobReportSortMapper();

  const onDataLoad = useCallback(() => {
    setGridData([]);
    setLoading(true);
  }, []);

  const jobReports = useDataFromBridge({
    xUser: xUser, apiValues: apiparms,
    bridgedResourceName: bridgedResourceName,
    callback: onDataLoad,
  });

  const jobReportExport = useDataFromBridge({
    xUser: xUser, apiValues: exportParams,
    bridgedResourceName: "JobReportsExport",
  });

  const singleJob = useDataFromBridge({
    xUser: xUser, apiValues: singleVal,
    bridgedResourceName: "Job by ID"
  })

  useEffect(() => {
    if (!jobid) return;
    setSingleVal({JobID: jobid});
  }, [jobid, invid])

  useEffect(() => {
    if (!singleJob) return;
    setJobRecord(singleJob.data.record);        
  }, [singleJob]);

  useEffect(() => {
    if (!dappCntx.user) return;
    setXuser(dappCntx.user.user);
    dappCntx.dappSubtitle("Job Reports");
  }, [dappCntx]);

  useEffect(() => {
    if (!jobid) return;
    setApiparms({ 
      ...initLoadParams, 
      JobID: jobid, 
      InvoiceID: invid || ' '
    });
  }, [jobid, invid]);

  useEffect(() => {
    if (!dappCntx.currentRecord) return;
    setToolbarTitle([`${dappCntx.currentRecord["Job Num"]}`, `${dappCntx.currentRecord.InternalShortDesc}`]);
  }, [dappCntx]);

  useEffect(() => {
    if (!jobRecord) return;
    if (!!jobRecord && !dappCntx.currentRecord){
      setToolbarTitle([`${jobRecord["Job Num"]}`, `${jobRecord["InternalShortDesc"]}`])
    }
  }, [jobRecord, dappCntx]);

  const closeJobReport = useCallback(() => {
    setJobReport({ ...jobReport, record: null, open: false, });
  }, [jobReport])

  const gridcols = useJobReportColumns({
    handleView: ({row}) => { 
      setJobReport({
        record: row, open: true, 
        handleClose: closeJobReport
      })
    }
  });

  const showError = useCallback(
    ({ message }) => {
      setShowAlert({
        ...showAlert,
        open: true,
        severity: 'error',
        message: message,
        onSnackBarClose: () => {
          setShowAlert({ ...showAlert, open: false, message: 'random' });
        },
      });
    },
    [showAlert],
  );

  useEffect(() => {
    if (!jobReports) return;

    setLoading(false);
    if (!jobReports.success) {
      showError({
        message: jobReports.data.Message || jobReports.data.message,
      });
      return;
    } else if (jobReports.data.error) {
      showError({message: jobReports.data.error.message});
      return;
    }

    setGridData(
      (jobReports.data.records || jobReports.data.Data).map((row, indx) => {
        return { ...row, id: indx };
      }),
    );
    // eslint-disable-next-line
  }, [jobReports]);

  const handlePaginationChange = useCallback((model, details) => {
    if (model.pageSize !== pagingState.pageSize)
      model.page = 0;

    dispatch({type: "page", page: model.page});
    dispatch({type: "pageSize", pageSize: model.pageSize});
    setApiparms({...apiparms, 
      PageNum: model.page + 1,
      PageSize: model.pageSize,
    });
  }, [apiparms, pagingState]);

  const handleSortChange = useCallback((sortmodel) => {
    if (!sortmodel) return;
    if (!!sortmodel && sortmodel.length <= 0) return;   

    const fld = sortMapper.find(x => x.field === sortmodel[0].field)?.sortBy || sortmodel[0].field;

    if (apiparms.SortField === fld && apiparms.AscDesc === sortmodel[0].sort) return;
    setGridData([]);
    setLoading(true);
    setApiparms({
      ...apiparms,
      SortField: fld,
      AscDesc: sortmodel[0].sort,
    });
  }, [apiparms, sortMapper]);

  const handleExport = useCallback(() => {
    swal({
      title: "Export Time Data",
      content: {
        element: "span",
        attributes: {
          innerHTML: EXPORTALERTMSG.replace('REPLACE-STRING', 'Time Data'),
        },
      },
      icon: undefined,
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: {
        confirm: true,
        cancel: true,
      },
      dangerMode: false,
    }).then((accepted) => {
      accepted && setExportParams({
        ...apiparms,
        PageNum: 1,
        PageSize: 2000,
      });
    });
  }, [apiparms]);

  useEffect(() => {
    if (!jobReportExport) return;
    if (!jobReportExport?.data) return;

    const d = new Date();
    const stamp = `${d.getFullYear()}${d.getMonth()}${d.getDay()}-${d.getHours()}${d.getMinutes()}`;
    const fileName = `JOB_REPORTS_CMS_${stamp}.xlsx`;

    const ws = utils.json_to_sheet(jobReportExport.data.records);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Time");
    writeFileXLSX(wb, fileName);

  }, [jobReportExport])

  return (
    <Fragment>
      <JobDetailsToolbar 
        title1={toolbarTitle && toolbarTitle[0]} 
        title2={toolbarTitle && toolbarTitle[1]}
        handleExport={handleExport}
        showExport={true}
      />
      <WrapperPaper>
          <DataGridPro className={clsx(cls.gridheadfoot, cls.gridrows)}           
            rows={gridData}            
            columns={gridcols}
            loading={loading}
            density="compact"
            paginationMode="server" 
            autoPageSize           
            rowCount={jobReports?.data?.metadata?.count || 0}
            pageSizeOptions={rowsperpage}
            onPaginationModelChange={handlePaginationChange}
            sortingMode="server"
            onSortModelChange={handleSortChange}
            pagination
            initialState={{
              pagination: {
                paginationModel: {
                  page: pagingState.page,
                  pageSize: pagingState.pageSize
                }
              }
            }}
          />
      </WrapperPaper>
      <CustomAlert {...showAlert} />
      <JobReportModal {...jobReport}/>
    </Fragment>
  );
};

const initState = {page: 0, pageSize: rowsperpage[0]};

const pageReducer = (state, action) => {
  switch (action.type) {
    case "page": 
      return {...state,  page: action.page};
    case "pageSize":
      return {...state, pageSize: action.pageSize};
    default:
      throw new Error();
  }
}