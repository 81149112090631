import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Backdrop, CircularProgress } from "@mui/material";

export const DappCalendar = (props) => {
    const {data, loading, ...moreprops} = props;    
    const localizer = momentLocalizer(moment);
    const [currentView, setCurrentView] = useState();
    const [eventData, setEventData] = useState([]);
    const [dateRange, setDateRange] = useState();

    useEffect(() => {
        if (!data) return;

        let evtData = data.filter(rsd => rsd?.RequestedStartDate.length > 0)
            .map(sr => {
            let stDt = new Date(sr.RequestedStartDate);
            stDt = new Date(stDt.getFullYear(), stDt.getMonth(), stDt.getDate(),  
            stDt.getHours(), stDt.getMinutes(), 0);

            let etDt = new Date(sr?.RequestedEndDate || sr.RequestStartDate);
            let isEOD = !sr.RequestEndDate; 
            etDt = new Date(etDt.getFullYear(), etDt.getMonth(), etDt.getDate(),  
            (isEOD && 16) || etDt.getHours(), (isEOD && 0) ||etDt.getMinutes(), 0);

            return {
                id: sr.IncidentID,
                title: `${sr.IncidentNo} - ${sr.ShortDescription}`,
                start: stDt,
                end: etDt,
                desc: sr.Description
            }
        });
        console.log(`the data size we're using: ${evtData.length}`);
        setEventData(evtData)

    }, [data])

    const handleRangeChange = useCallback((view) => {
        if (currentView === 'month'){
            setDateRange({start: view.start, end: view.end});
        } 
        if (currentView === "week"){
            setDateRange({start: view[0], end: view[view.length]});
        }
    }, [currentView]);

    const handleOnView = useCallback((view) => {
        setCurrentView(view);        
    }, []);

    return (
        <Fragment>
            {(loading && <DappBackDrop open={loading} {...dateRange}/>) ||
            <Calendar {...moreprops}
                localizer={localizer}
                events={eventData}
                startAccessor="start"
                endAccessor="end"
                defaultView="month"
                onRangeChange={handleRangeChange}
                onView={handleOnView}
                views={{
                    month: true,
                    week: true,
                    day: true,
                    agenda: false,
                }}
            />}
        </Fragment>
    );
}

const DappBackDrop = (props) => {
    return (
        <Backdrop sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}} {...props}>
            <CircularProgress color="inherit"/>
        </Backdrop>
    );
}

