import React, { useState, createContext, useContext, useEffect } from "react";
import { CHAT_END_CHAT_BY_ID, CMSAPIKEY, TestAuth, headers } from "../controls/dapp.constants";
import { fetchSpace, fetchProfile } from "@kineticdata/react";
import { useDataFromBridge } from "../controls/custom.hooks";
import { useIncontactChat } from "../components/bottom/chat.live";
import { ENABLECHAT } from "../controls/dapp.constants";

const DappContext = createContext();

export const DappProvider = (props) => {
    const {loggedIn, children} = props;
    const [user, setUser] = useState();
    const [title, setTitle] = useState();
    const [space, setSpace] = useState();
    const [profile, setProfile] = useState();
    const [reset, setReset] = useState(true);
    const [showChat, setShowChat] = useState(true);
    const [contactProfile, setContactProfile] = useState();
    const [currentRecord, setCurrentRecord] = useState();
    const [contProfParams, setContProfParams] = useState();
    const [userProps, setUserProps] = useState({
        CID: '',
        Department: '',
        Manager: '',
        Organization: '',
        Site: '',
        UserType: '',
    });

    const [sharedOperator, setSharedOperator] = useState(false);
    const [sharedOpParms, setSharedOpParms] = useState();

    const [alertsCount, setAlertsCount] = useState();
    const [customerAlerts, setCustomerAlerts] = useState();

    const isChatEnabled = ENABLECHAT;

    useEffect(() => {
      if (!loggedIn) return;
      let spaceCall = true;
      const fetchSpaceRequest = async () => {
        let response = await fetchSpace();
        if (!response?.space){
          setShowChat(false);
        }        
        spaceCall && setSpace(response.space);
      };
      (!space || (!!space && reset)) && fetchSpaceRequest();

      return () => {
        spaceCall = false;
      }
    }, [space, loggedIn, reset]);

    useEffect(() => {
      if (!loggedIn) return;
      let makeCall = true;

      const fetchProfileRequest = async () => {
        let response = await fetchProfile({
          include: 'authorization,attributesMap',
        });
        if (!response?.profile) {
          setProfile(undefined);
          setUserProps(undefined);
          setUser(undefined);
          setShowChat(false);          
        } else {
          if (makeCall){
          setProfile(response.profile);
          let props = response.profile.attributesMap;
          setUserProps({
            CID: props?.CID[0] || '',
            Department: props?.Department[0] || '',
            Manager: props?.Manager[0] || '',
            Organization: props?.Organization[0] || '',
            Site: props?.Site[0] || '',
            UserType: props?.userType[0] || '',
          });
          setUser({ user: JSON.parse(props?.ccData[0])});
          setShowChat(true);
        }
          setReset(false);
        }
      }

      (!profile || (!!profile && reset)) && fetchProfileRequest();

    return () => {
      makeCall = false;
    }    
    }, [profile, loggedIn, reset]);

    const contactProfileCall = useDataFromBridge({
      xUser: user?.user, apiValues: contProfParams,
      bridgedResourceName: "Contact Profile by ID",
    });

    useEffect(() => {
      if (!user?.user) return;
      setContProfParams({ContactID: user.user.PrimaryContactID});
      setSharedOpParms({PrimaryContactID: user.user.PrimaryContactID});
    }, [user])

    useEffect(() => {
      if (!contactProfileCall?.data) return;
      setContactProfile(contactProfileCall.data?.record);
    }, [contactProfileCall]);

    const signIn = (profile, callback) => {        
        setUser(profile?.user || TestAuth);
        setReset(false);
        callback();
    }

    const signOut = (callback) => {
      setReset(true);
      callback();
    }

    const dappSubtitle = (title, callback) => {
        setTitle(title);
        if (!!callback && typeof callback === "function"){
            callback();    
        }
    }

    /// getting alerts 
    const alerts = useDataFromBridge({
      xUser: user?.user,
      apiValues: ' ',
      bridgedResourceName: "Customer Alerts",
    });

  useEffect(() => {
    if (!alerts) return;
    let flag = true;

    flag && setCustomerAlerts((alerts.data?.records)?.map((row, indx) => { return { ...row, id: indx } }));
    setAlertsCount(alerts.data?.records?.length || 0);

    return () => {
      flag = false;
    }
    // eslint-disable-next-line
  }, [alerts]);  

  useIncontactChat({loggedIn: !!user, 
    hasProfile: !!contactProfile,
    chatEnabled: (isChatEnabled === 'true' && contactProfile?.IsContactCenterChatEnabled) || 0,    
    chatParams: [
    !!user?.user && JSON.stringify(user?.user),
    !!contactProfile && JSON.stringify(contactProfile),
    ],
    extraTests: {
      user: user?.user,
      contactProfile: contactProfile
    },
    showChat: showChat,
  });

  const sharedOperatorCall = useDataFromBridge({
    xUser: user?.user,
    apiValues: sharedOpParms,
    bridgedResourceName: "GetPrimaryContactInformation",
  });

  useEffect(() => {
    if (!sharedOperatorCall) return;
    let flag = true;

    flag && setSharedOperator(sharedOperatorCall.data?.record);

    return () => {
      flag = false;
    }
    // eslint-disable-next-line
  }, [sharedOperatorCall]);  

  useEffect(() => {
    // this is recommended approach vs. unload event
    if (!user?.user) return;
    document.onvisibilitychange = () => {
      if (document.visibilityState === "hidden"){
        const blb = new Blob([JSON.stringify(user.user)], { ...headers,
            'X-User': JSON.stringify(user.user),
            'CmsApiKey': CMSAPIKEY,
          },
        );
        navigator.sendBeacon(CHAT_END_CHAT_BY_ID, blb); 
        const none = () => {};
        // set up a bit of delay, so the beacon has time to fire
        for (let i = 0; i < 10000000; i++) { none(); }
        // just to make sure we're reaching this point
        console.log(`exiting an app and calling chat end Web API, visibility status: ${document.visibilityState}`);
      }
    }
  }, [user]);

    const value = {
      user, title, signIn, signOut, dappSubtitle,
      userProps, contactProfile, 
      currentRecord, setCurrentRecord,
      alertsCount, setAlertsCount,
      customerAlerts, setCustomerAlerts,
      sharedOperator, showChat
    }

    return (
        <DappContext.Provider value={value}>
            {children}
        </DappContext.Provider>
    )
}

export const useDappContext = () => useContext(DappContext);