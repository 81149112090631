import React from "react";
import { 
    Toolbar, Box, Divider, FormGroup, FormControlLabel
    , Typography, Grid
} from "@material-ui/core";
import { DappToolbarSwitch, ToolbarButton, useToolbarStyle } from "../../controls/shared.components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faAdd, faRefresh, faBalanceScale, faClock, faShoppingCart,
    faListCheck,//, faFilePen
    faFileExcel
} from "@fortawesome/free-solid-svg-icons";

export const JobsToolbar = (props) => {
    const {handleAdd, handleRefresh, ...other} = props;
    const cls = useToolbarStyle();

    return (
        <Toolbar {...other} className={cls.toolbarroot}>
            <ToolbarButton
                name="Add"
                handleClick={handleAdd}
                tooltip="Add New Job"
                icon={<FontAwesomeIcon icon={faAdd} />}
            />
            <Box sx={{flexGrow: 1}} />
            <ToolbarButton
                name="Refresh"
                handleClick={handleRefresh}
                tooltip="Refresh"
                icon={<FontAwesomeIcon icon={faRefresh}/>}
            />
        </Toolbar>
    );
}

export const SingleJobToobar = (props) => {
    const {
        handleJobBalances, handleJobReports, handleProducts, handleRefresh,
        handleManagedServices, /*handleFiles,*/ rowSelected, showAll, handleShowAll,
    } = props;
    const cls = useToolbarStyle();

    const promptMsg = "Please, select a job record to view "

    return (
        <Toolbar className={cls.toolbarroot}>
            <ToolbarButton name="Balances"
                handleClick={handleJobBalances}
                icon={<FontAwesomeIcon icon={faBalanceScale}/>}
                tooltip={`${(!rowSelected?.row && promptMsg) || ''} ${'Balances'}`}
                disabled={!rowSelected?.row}
            />
            <ToolbarButton name="Job Reports"
                handleClick={handleJobReports}
                icon={<FontAwesomeIcon icon={faClock}/>}
                tooltip={`${(!rowSelected?.row && promptMsg) || ''} ${"Job Reports"}`} 
                disabled={!rowSelected?.row}
            />
            <Divider orientation="vertical"/>
            <ToolbarButton name="Products"
                handleClick={handleProducts}
                icon={<FontAwesomeIcon icon={faShoppingCart}/>}
                tooltip={`${(!rowSelected?.row && promptMsg) || ''} ${"Products"}`} 
                disabled={!rowSelected?.row}
            />
            <ToolbarButton name="Services"
                handleClick={handleManagedServices}
                icon={<FontAwesomeIcon icon={faListCheck}/>}
                tooltip={`${(!rowSelected?.row && promptMsg) || ''} ${"Managed Services"}`} 
                disabled={!rowSelected?.row}
            />
            <Box sx={{flexGrow: 1}}/>
            {/* <ToolbarButton name="Files"
                handleClick={handleFiles}
                icon={<FontAwesomeIcon icon={faFilePen}/>}
                tooltip="Files"
                disabled={!rowSelected}
            /> */}
            <FormGroup row>
                <FormControlLabel className={cls.switchlabel}
                    control={
                    <DappToolbarSwitch 
                        checked={showAll} 
                        onChange={handleShowAll} 
                        name="showAll"/>
                }
                    label={(showAll && "Show All Jobs") || "Active Only"}
                />
            </FormGroup>
            {/* <ToolbarButton name={(showAll && "Show All Jobs") || "Active Only"}
                handleClick={handleShowAll}
                variant="outlined"
                color={(showAll && "primary") || "secondary"}
            >
            </ToolbarButton> */}
            <ToolbarButton
                name="Refresh"
                handleClick={handleRefresh}
                tooltip="Refresh"
                icon={<FontAwesomeIcon icon={faRefresh}/>}
            />
        </Toolbar>
    );
}


export const JobDetailsToolbar = (props) => {
    const {title1, title2, handleExport, showExport} = props;
    const cls = useToolbarStyle();

    return (
        <Toolbar className={cls.toolbarroot}>
            <Box sx={{flexGrow: 1}}>
                <Grid container style={{width: "60%", margin: "auto"}}>
                    <Grid item xs={12}>
                        <Typography component={`div`}>
                            Job #: {title1}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component={`div`} variant="body2" >
                            {title2}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            {(showExport &&
            <ToolbarButton
                name="Export"
                handleClick={handleExport}
                tooltip="Export to Excel"
                icon={<FontAwesomeIcon icon={faFileExcel}/>}
            />) || <></>}
        </Toolbar>
    )
}