import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "12rem",
    flexDirection: "column",
    "& .cls-1": {
      fill: theme.palette.primary.light,
    },
    "& .cls-2": {
      fill: theme.palette.background.paper,
    },
    "& .st0": {fill:"#007DBA;"},
    "& .st1": {fill:"#FFFFFF;"}
  }
}));

export const DatapriseLogo_old = () => {
  const classes = useStyles();
    
    return (
      <Typography component={`div`} className={classes.root} >
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 181 25.71"
        >
          <title>Dataprise-Main-Logo-300dpi</title>
          <polygon
            className="cls-1"
            points="13.71 25.71 0 25.71 0.56 22.82 14.26 22.82 13.71 25.71"
          />
          <polygon
            className="cls-1"
            points="17.08 8.14 13.71 25.71 10.86 25.71 14.24 8.14 17.08 8.14"
          />
          <polygon
            className="cls-1"
            points="0 25.71 2.65 11.89 5.58 11.89 2.92 25.71 0 25.71"
          />
          <polygon
            className="cls-1"
            points="2.74 11.32 16.47 11.32 15.92 14.21 2.21 14.21 2.74 11.32"
          />
          <polygon
            className="cls-1"
            points="18.67 0 17.29 7.22 14.39 7.22 15.78 0 18.67 0"
          />
          <polygon
            className="cls-1"
            points="29.58 3.03 15.87 3.03 16.45 0 30.16 0 29.58 3.03"
          />
          <polygon
            className="cls-1"
            points="24.52 14.21 27.24 0.04 30.15 0.04 27.43 14.21 24.52 14.21"
          />
          <polygon
            className="cls-1"
            points="17.35 11.32 27.88 11.32 27.33 14.21 16.8 14.21 17.35 11.32"
          />
          <path
            className="cls-2"
            d="M49.59,17.1a9.86,9.86,0,0,0,1.3-3.38,7.91,7.91,0,0,0-.06-3.33,6.52,6.52,0,0,0-3.69-4.5,7.58,7.58,0,0,0-3.22-.67H36.17L33,22.45h7.51A9.49,9.49,0,0,0,44,21.77a11.41,11.41,0,0,0,5.55-4.67ZM47.07,14A6.09,6.09,0,0,1,46.27,16a6.86,6.86,0,0,1-1.43,1.68A6.75,6.75,0,0,1,43,18.83a5.92,5.92,0,0,1-2.18.42H37.14l2-10.82h3.39a4.94,4.94,0,0,1,2.08.43,4.36,4.36,0,0,1,1.56,1.2A4.43,4.43,0,0,1,47,11.83,5.1,5.1,0,0,1,47.07,14Z"
            transform="translate(-0.5 -0.57)"
          />
          <path
            className="cls-2"
            d="M62.56,19.9,63,22.45H67L63.74,5.22H60.3L50.76,22.45H54.5l1.42-2.56ZM61.26,9l.9,7.86H57.44Z"
            transform="translate(-0.5 -0.57)"
          />
          <polygon
            className="cls-2"
            points="83.81 7.93 83.84 7.9 84.44 4.65 69.88 4.65 69.28 7.93 74.7 7.93 72.14 21.88 75.86 21.88 78.41 7.93 83.81 7.93"
          />
          <path
            className="cls-2"
            d="M94.36,19.9l.49,2.56h3.9L95.54,5.22H92.1L82.56,22.45H86.3l1.42-2.56ZM93.06,9l.9,7.86H89.25Z"
            transform="translate(-0.5 -0.57)"
          />
          <path
            className="cls-2"
            d="M110.74,16.69a6.69,6.69,0,0,0,2.43-.47,8.43,8.43,0,0,0,4-3.25,6.36,6.36,0,0,0,.94-2.34,4.63,4.63,0,0,0-.08-2.2,4.11,4.11,0,0,0-1-1.69,4.9,4.9,0,0,0-1.72-1.12,6.08,6.08,0,0,0-2.24-.41h-7.79l-3.16,17.23h3.64l1.06-5.76ZM108.53,8H112a2.6,2.6,0,0,1,1,.21,2.26,2.26,0,0,1,1.3,1.48,2.36,2.36,0,0,1,0,1.08,2.78,2.78,0,0,1-.41,1,3.39,3.39,0,0,1-.7.78,3.34,3.34,0,0,1-.93.53,3,3,0,0,1-1.07.2h-3.68Z"
            transform="translate(-0.5 -0.57)"
          />
          <path
            className="cls-2"
            d="M132.6,14.91a7,7,0,0,0,2-1.84,5.68,5.68,0,0,0,1-2.31,5.89,5.89,0,0,0,.06-1.58,3.79,3.79,0,0,0-.37-1.38,4.85,4.85,0,0,0-.65-.94,5.59,5.59,0,0,0-.91-.84,4.61,4.61,0,0,0-1.06-.59,3,3,0,0,0-1.08-.21h-8.75l-3.16,17.23h3.78l1-5.74h4.13l2.17,5.74h4.16l-2.8-7.26Zm-.86-4.09a2.77,2.77,0,0,1-.41,1,3.39,3.39,0,0,1-.7.78,3.35,3.35,0,0,1-.93.53,3,3,0,0,1-1.07.2H125l1-5.3h3.44a2.6,2.6,0,0,1,1,.21,2.26,2.26,0,0,1,1.3,1.48A2.36,2.36,0,0,1,131.74,10.81Z"
            transform="translate(-0.5 -0.57)"
          />
          <polygon
            className="cls-2"
            points="140.97 4.65 137.81 21.88 141.47 21.88 144.63 4.65 140.97 4.65"
          />
          <path
            className="cls-2"
            d="M158.54,21.87a6.53,6.53,0,0,0,2.07-1.68,5.86,5.86,0,0,0,1.15-2.69,7.9,7.9,0,0,0,.12-1.16,3.09,3.09,0,0,0-.12-1,2.49,2.49,0,0,0-.54-1,4.12,4.12,0,0,0-1-.8,7.24,7.24,0,0,0-1.31-.61c-.5-.18-1-.34-1.5-.48s-1-.26-1.49-.36-1-.21-1.39-.33a6.18,6.18,0,0,1-1.14-.41,1.69,1.69,0,0,1-.83-.78,1.65,1.65,0,0,1-.09-1A2,2,0,0,1,153,8.54a2.42,2.42,0,0,1,.86-.57,3.36,3.36,0,0,1,.94-.22c.28,0,.56,0,.82,0a8.9,8.9,0,0,1,2.95.47,18.62,18.62,0,0,1,2.08.88l1.17-1.54,1.09-1.42a9.09,9.09,0,0,0-2.36-1A14.08,14.08,0,0,0,155,4.85a10.21,10.21,0,0,0-2.11.54,7.71,7.71,0,0,0-1.9,1,6.5,6.5,0,0,0-1.54,1.65,5.86,5.86,0,0,0-.86,2.13,4.48,4.48,0,0,0,.07,2.4A2.92,2.92,0,0,0,149.74,14a4.62,4.62,0,0,0,.87.47c.35.15.72.28,1.1.4s.76.23,1.18.32l1.32.32,1,.21a5.79,5.79,0,0,1,1.1.32,3.48,3.48,0,0,1,.49.22,3.39,3.39,0,0,1,.51.34,1.85,1.85,0,0,1,.45.56,1.34,1.34,0,0,1,.12.89,2.18,2.18,0,0,1-.59,1.2,2.57,2.57,0,0,1-1,.59,4.1,4.1,0,0,1-1,.21c-.32,0-.64,0-.93,0a10.7,10.7,0,0,1-2.87-.37,12,12,0,0,1-2.55-1l0,0-.33.43-1,1.27L147,21l-.43.56,0,0a14.86,14.86,0,0,0,2.87,1,15.3,15.3,0,0,0,6.4.16A9.45,9.45,0,0,0,158.54,21.87Z"
            transform="translate(-0.5 -0.57)"
          />
          <polygon
            className="cls-2"
            points="167.54 4.65 164.38 21.88 177.84 21.88 178.41 18.8 168.44 18.8 169.17 14.83 179.18 14.83 179.76 11.73 169.74 11.72 170.47 7.76 180.43 7.76 181 4.65 167.54 4.65"
          />
        </svg>
      </Typography>
    );
}

export const DatapriseLogo = () => {
  const classes = useStyles();
  return (
    <Typography className={classes.root} component={`div`}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 667.9 94.9"
        // style="enable-background:new 0 0 667.9 94.9;"
        // xml:space="preserve"
      >
        <g>
          <polygon className="st0" points="50.6,94.9 0,94.9 2,84.2 52.6,84.2 	" />
          <polygon className="st0" points="63,30 50.6,94.9 40.1,94.9 52.5,30 	" />
          <polygon className="st0" points="0,94.9 9.8,43.8 20.6,43.8 10.8,94.9 	" />
          <polygon
            className="st0"
            points="10.1,41.8 60.8,41.8 58.7,52.4 8.1,52.4 	"
          />
          <polygon className="st0" points="68.9,0 63.8,26.7 53.1,26.7 58.2,0 	" />
          <polygon className="st0" points="109.1,11.2 58.5,11.2 60.7,0 111.3,0 	" />
          <polygon
            className="st0"
            points="90.4,52.4 100.5,0.1 111.2,0.1 101.2,52.4 	"
          />
          <polygon
            className="st0"
            points="64,41.8 102.9,41.8 100.8,52.4 62,52.4 	"
          />
        </g>
        <g>
          <path
            className="st1"
            d="M181.1,61c2.4-3.9,4-8.1,4.8-12.5c0.8-4.3,0.7-8.5-0.2-12.3c-0.9-3.8-2.6-7.1-4.9-9.9c-2.3-2.8-5.3-5-8.7-6.7
		c-3.5-1.6-7.5-2.5-11.9-2.5h-28.6l-11.7,63.6h27.7c4.4,0,8.8-0.8,13-2.5c4.2-1.7,8.1-4,11.6-6.9C175.7,68.4,178.7,64.9,181.1,61z
		 M171.8,49.4c-0.5,2.7-1.5,5.3-2.9,7.7c-1.4,2.4-3.2,4.4-5.3,6.2c-2.1,1.8-4.4,3.1-7,4.1c-2.6,1-5.3,1.5-8.1,1.5h-13.4l7.3-39.9
		H155c2.8,0,5.4,0.5,7.7,1.6c2.3,1.1,4.2,2.5,5.8,4.4c1.5,1.9,2.6,4.1,3.2,6.6C172.2,44,172.3,46.6,171.8,49.4z"
          />
          <path
            className="st1"
            d="M229,71.3l1.8,9.4h14.4l-11.9-63.6h-12.7l-35.2,63.6h13.8l5.3-9.4H229z M224.2,31.2l3.3,29h-17.4L224.2,31.2z"
          />
          <polygon
            className="st1"
            points="309.2,29.2 309.3,29.1 311.5,17.2 257.8,17.2 255.6,29.2 275.6,29.2 266.1,80.7 279.8,80.7 
		289.3,29.2 	"
          />
          <path
            className="st1"
            d="M346.3,71.3l1.8,9.4h14.4l-11.9-63.6h-12.7l-35.2,63.6h13.8l5.3-9.4H346.3z M341.5,31.2l3.3,29h-17.4
		L341.5,31.2z"
          />
          <path
            className="st1"
            d="M406.7,59.5c3,0,6-0.6,9-1.7c3-1.2,5.8-2.8,8.3-4.8c2.5-2,4.7-4.4,6.5-7.2c1.7-2.7,2.9-5.6,3.5-8.6
		c0.5-2.9,0.4-5.7-0.3-8.1c-0.7-2.4-2-4.5-3.6-6.2c-1.7-1.7-3.8-3.1-6.3-4.1c-2.5-1-5.3-1.5-8.3-1.5h-28.8l-11.7,63.6h13.4l3.9-21.2
		H406.7z M398.5,27.6h12.7c1.4,0,2.7,0.3,3.9,0.8c1.2,0.5,2.2,1.3,3,2.2c0.8,0.9,1.4,2,1.7,3.2c0.4,1.2,0.4,2.6,0.1,4
		c-0.2,1.4-0.8,2.6-1.5,3.8c-0.7,1.1-1.6,2.1-2.6,2.9c-1,0.8-2.2,1.5-3.4,2c-1.3,0.5-2.6,0.7-4,0.7H395L398.5,27.6z"
          />
          <path
            className="st1"
            d="M487.3,52.9c2.9-1.8,5.4-4.1,7.3-6.8c1.9-2.6,3.2-5.5,3.7-8.5c0.3-1.8,0.4-3.8,0.2-5.8
		c-0.2-1.9-0.6-3.7-1.3-5.1c-0.6-1.2-1.4-2.3-2.4-3.5c-1-1.1-2.1-2.2-3.4-3.1c-1.2-0.9-2.5-1.6-3.9-2.2c-1.3-0.5-2.6-0.8-4-0.8
		h-32.3l-11.7,63.6h13.9l3.8-21.2h15.2l8,21.2H496l-10.3-26.8L487.3,52.9z M484.2,37.8c-0.2,1.4-0.8,2.6-1.5,3.8
		c-0.7,1.1-1.6,2.1-2.6,2.9c-1,0.8-2.2,1.5-3.4,2c-1.3,0.5-2.6,0.7-4,0.7h-13.6l3.6-19.5h12.7c1.4,0,2.7,0.3,3.9,0.8
		c1.2,0.5,2.2,1.3,3,2.2c0.8,0.9,1.4,2,1.7,3.2C484.4,35,484.4,36.4,484.2,37.8z"
          />
          <polygon
            className="st1"
            points="520.1,17.2 508.4,80.7 521.9,80.7 533.6,17.2 	"
          />
          <path
            className="st1"
            d="M583,78.6c2.9-1.5,5.5-3.6,7.6-6.2c2.1-2.5,3.5-5.9,4.2-9.9c0.3-1.4,0.4-2.8,0.5-4.3c0-1.3-0.1-2.5-0.4-3.6
		c-0.4-1.5-1.1-2.8-2-3.8c-1-1.1-2.2-2.1-3.6-3c-1.5-0.9-3.1-1.6-4.8-2.3c-1.9-0.7-3.7-1.2-5.6-1.8c-1.9-0.5-3.7-1-5.5-1.3
		c-1.8-0.4-3.6-0.8-5.1-1.2c-1.7-0.5-3.1-1-4.2-1.5c-1.5-0.8-2.5-1.7-3.1-2.9c-0.5-1.1-0.6-2.3-0.3-3.5c0.3-1.6,0.9-2.9,1.9-3.9
		c0.9-0.9,2-1.6,3.2-2.1c1.1-0.4,2.3-0.7,3.5-0.8c1-0.1,2.1-0.1,3-0.1c4,0,7.6,0.6,10.9,1.7c2.5,0.9,5.1,2,7.7,3.3
		c1.4-1.9,2.9-3.8,4.3-5.7c1.3-1.7,2.7-3.5,4-5.2c-2.7-1.6-5.6-2.8-8.7-3.6c-6.4-1.7-13.4-2-20.5-1c-2.6,0.4-5.3,1.1-7.8,2
		c-2.5,0.9-4.8,2.2-7,3.8c-2.1,1.6-4.1,3.6-5.7,6.1c-0.8,1.2-1.5,2.5-2,3.8c-0.5,1.4-0.9,2.7-1.2,4c-0.7,3.8-0.6,6.7,0.2,8.9
		c0.9,2.1,2.2,3.8,4,5c1,0.7,2,1.3,3.2,1.8c1.3,0.5,2.7,1,4,1.5c1.4,0.5,2.8,0.8,4.3,1.2c1.6,0.4,3.2,0.7,4.9,1.2
		c1.2,0.3,2.4,0.5,3.6,0.8c1.4,0.3,2.8,0.7,4.1,1.2c0.5,0.2,1.2,0.5,1.8,0.8c0.7,0.4,1.3,0.8,1.9,1.3c0.7,0.6,1.3,1.3,1.7,2.1
		c0.5,1,0.7,2.1,0.4,3.3c-0.3,1.9-1.1,3.3-2.2,4.4c-1,1-2.2,1.7-3.5,2.2c-1.2,0.4-2.5,0.7-3.9,0.8c-1.2,0.1-2.4,0.1-3.4,0.1
		c-3.8,0-7.3-0.5-10.6-1.4c-3.2-0.9-6.4-2.2-9.4-3.9l-0.1-0.1c-0.3,0.5-0.8,1-1.2,1.6c-1.2,1.5-2.4,3-3.7,4.7
		c-0.6,0.8-1.2,1.6-1.8,2.4c-0.6,0.8-1.1,1.5-1.6,2.1l-0.1,0.1c3.3,1.6,6.9,2.9,10.6,3.8c7.4,1.9,15.4,2,23.6,0.6
		C576.7,81.2,580,80.1,583,78.6z"
          />
          <polygon
            className="st1"
            points="618.1,17.2 606.4,80.7 656.1,80.7 658.2,69.3 621.4,69.3 624.1,54.7 661,54.7 663.2,43.3 626.2,43.2 
		628.9,28.6 665.6,28.6 667.7,17.2 	"
          />
        </g>
      </svg>
    </Typography>
  );
};