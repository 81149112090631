import React from "react"
import { useMemo } from "react"
import { IconButton, Link, Tooltip, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faEdit, faXmark } from "@fortawesome/free-solid-svg-icons"
import { Fragment } from "react";
import { DappBooleanAsIcon } from "../../controls/shared.components";

export const useIncidentSortMapper = () => {
    return useMemo(() => [
        {field: "IncidentNo", sortBy: "IncidentNo"},
        {field: "Status", sortBy: "Status"},
        {field: "AssignedToName", sortBy: "AssignedToName"},
        {field: "ShortDescription", sortBy: "ShortDescription"},
        {field: "IncidentPriority", sortBy: "IncidentPriority"},
        {field: "Severity", sortBy: "SevertityShort"},
        {field: "OpenDate", sortBy: "OpenDate"},
        {field: "IncidentClass", sortBy: "IncidentClass"},
        {field: "IncidentSubClass", sortBy: "IncidentSubClass"},
        {field: "TargetName", sortBy: "TargetName"}
    ], []);
}

export const useIncidentsColumns = (props) => {
    const {handleEdit, handleView, handleContactView, userType} = props;

    return useMemo(() => [
        {field: "Spacer", headerName: " ", width: 10, disableColumnMenu: true},
        {
            field: "IncidentNo", headerName: "Incident No", flex: .2, disableColumnMenu: true, resizable: true,
            renderCell: (params) => {
                
                return (                    
                  <Tooltip title="View Ticket">
                    <Link
                      underline="none"
                      component="button"
                      onClick={() => handleView({ row: params.row })}
                      variant="body2"
                    >
                      {params.row.IncidentNo}
                    </Link>
                  </Tooltip>
                );
            }
        },
        {
            field: "AssignedToName", headerName: "Assigned To", flex: .15, disableColumnMenu: true, resizable: true,
            sortable: true,
            renderCell: (params) => {

                return (
                    <Fragment>
                        {(userType && userType === "Administrator" && params.row.AssignedToContactID && 
                        <Tooltip title="View Contact">
                            <Link
                                underline="none"
                                component={'button'}
                                onClick={() => handleContactView({row: params.row})}
                                variant="body2"
                            >
                                {params.row.AssignedToName}
                            </Link>                        
                        </Tooltip>) || 
                        <Typography component={`span`} variant="body2">
                            {params.row.AssignedToName}
                        </Typography>}
                    </Fragment>
                )
            }
        },
        {
            field: "IncidentClass", headerName: "Type", flex: .1, disableColumnMenu: true, resizable: true,            
            sortable: true,
        },
        {
            field: "IncidentSubClass", headerName: "Sub Type", flex: .1, disableColumnMenu: true, resizable: true,
            sortable: true,
        },
        {
            field: "Status", headerName: "Status", flex: .1, disableColumnMenu: true, align: "center", resizable: true,
        },
        {
            field: "Disposition", headerName: "Disposition", flex: .1, disableColumnMenu: true, align: "center", resizable: true,
            sortable: false,
        },
        {
            field: "ShortDescription", headerName: "Short Description", flex: .3, disableColumnMenu: true, resizable: true,
        },
        {
            field: "TargetName", headerName: "Target", flex: .15, disableColumnMenu: true, resizable: true,
            sortable: true,
            renderCell: (params) => {
                return (
                    <Fragment>
                        {(userType && userType === "Administrator" && params.row.TargetContactID && 
                        <Tooltip title="Target">
                            <Link
                                underline="none"
                                component={'button'}
                                onClick={() => handleContactView({row: params.row})}
                                variant="body2"
                            >
                                {params.row.TargetName}
                            </Link>                        
                        </Tooltip>) || 
                        <Typography component={`span`} variant="body2">
                            {params.row.TargetName}
                        </Typography>}
                    </Fragment>
                )
            }
        },
        {
            field: "IncidentPriority", headerName: "Assigned Lvl", flex: .1, disableColumnMenu: true, resizable: true,
            renderCell: (params) => {
                return (
                    <Typography component={`div`} style={{fontSize: "inherit"}}>
                        {params.row.IncidentPriority}
                    </Typography>
                )
            }
        },
        {
            field: "Severity", headerName: "Severity", flex: .1, disableColumnMenu: true, resizable: true,
            renderCell: (params) => {
                return (
                    <Tooltip 
                        title={params.row.Severity} 
                        style={{textOverflow: "ellipsis", overflow: "hidden", maxWidth: 80}}
                        arrow={true}
                    >
                        <Typography component={'div'} style={{fontSize: "inherit"}}>
                            {params.row.SeverityShort}
                        </Typography>
                    </Tooltip>
                )
            }
        },
        {
            field: "IsVIP", headerName: "VIP", width: 40, disableColumnMenu: true, reszable: false,
            align: "center", sortable: false, headerAlign: "center",
            renderCell: (params) => <DappBooleanAsIcon value={params.value}/>
        },
        {
            field: "SubClientOrganizationName", headerName: "Sub Client", flex: 0.15, disableColumnMenu: true,
            resizable: true, align: "left", sortable: false, headerAlign: "center",
            renderCell: (params) => {
                return (
                    <Tooltip 
                        title={params.row.SubClientOrganizationName} 
                        style={{textOverflow: "ellipsis", overflow: "hidden", maxWidth: 150}}
                        arrow={true}
                    >
                        <Typography component={'div'} style={{fontSize: "inherit"}}>
                            {params.row.SubClientOrganizationName}
                        </Typography>
                    </Tooltip>
                )
            }
        }, 
        {
            field: "OpenDate", headerName: "Opened", type: "date", flex: .3, 
            disableColumnMenu: true, align: "center", headerAlign: "center",
            valueFormatter: (params) => {
                let dt = new Date(params.value);
                return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`
            },
        },
        {
            field: "Actions", headerName: " ", width: 40, disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                return (
                    <React.Fragment>
                        {(userType !== "User" && 
                        // (params.row.Status === 'Open') && 
                        <Tooltip title="Edit Incident">
                        <IconButton id="editRecord" color="primary" style={{fontSize: '.90rem'}}
                            onClick={() => handleEdit({row: params.row})}>
                            <FontAwesomeIcon icon={faEdit}/>
                        </IconButton>
                        </Tooltip>) || <Fragment/>}
                    </React.Fragment>
                );
            },
        },
    ], [handleEdit, handleView, handleContactView, userType]);
}

export const useIncidentsColumnsShort = (props) => {
    const {handleEdit, handleView, handleContactView, userType} = props;

    return useMemo(() => [
        {
            field: "IncidentNo", headerName: "Incident No", flex: 2, disableColumnMenu: true, resizable: false,
            renderCell: (params) => {
                
                return (                    
                  <Tooltip title="View Ticket">
                    <Link
                      underline="none"
                      component="button"
                      onClick={() => handleView({ row: params.row })}
                      variant="body2"
                    >
                      {params.row.IncidentNo}
                    </Link>
                  </Tooltip>
                );
            }
        },
        {
            field: "Status", headerName: "Status", flex: 2, disableColumnMenu: true, align: "center", resizable: false,
            headerAlign: "center",
        },
        {
            field: "ShortDescription", headerName: "Short Description", flex: 6, disableColumnMenu: true, resizable: false,
        },
        {
            field: "TargetName", headerName: "Target", flex: 2, disableColumnMenu: true, resizable: false,
            renderCell: (params) => {
                return (
                    <Fragment>
                        {(userType && userType === "Administrator" && params.row.TargetContactID && 
                        <Tooltip title="Target">
                            <Link
                                underline="none"
                                component={'button'}
                                onClick={() => handleContactView({row: params.row})}
                                variant="body2"
                            >
                                {params.row.TargetName}
                            </Link>                        
                        </Tooltip>) || 
                        <Typography component={`span`} variant="body2">
                            {params.row.TargetName}
                        </Typography>}
                    </Fragment>
                )
            }
        },
        {
            field: "Actions", headerName: " ", width: 40, disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                return (
                    <React.Fragment>
                        {(userType !== "User" && 
                        // (params.row.Status === 'Open') && 
                        <Tooltip title="Edit Incident">
                        <IconButton id="editRecord" color="primary" style={{fontSize: '.90rem'}}
                            onClick={() => handleEdit({row: params.row})}>
                            <FontAwesomeIcon icon={faEdit}/>
                        </IconButton>
                        </Tooltip>) || <Fragment/>}
                    </React.Fragment>
                );
            },
        },
    ], [handleEdit, handleView, handleContactView, userType]);
}

export const useSavedFilterColumns = (props) => {
    return useMemo(() => [
        {
            field: "SearchName", headerName: "Name", flex: 1, disableColumnMenu: true, resizable: true,
            sortable: false,
        },
        {
            field: "OwnerName", headerName: "Owner", width: 80, disableColumnMenu: true, resizable: false,
        },
        {
            field: "IsPublic", headerName: "Public", width: 60, disableColumnMenu: true, resizable: false,
            align: "center", headerAlign: "center", renderCell: (params) => {
                return (
                    <FontAwesomeIcon icon={(parseInt(params.row.IsPublic) === -1 && faCheck) || faXmark}
                        style={{color: (parseInt(params.row.IsPublic) === -1 && 'blue') || 'red'}}
                    />
                )
            }
        },
        {
            field: "DateUpdated", headerName: "Updated", width: 160, disableColumnMenu: true, resizable: false,
            align: "center", headerAlign: "center", renderCell: (params) => {
                return (
                    new Date(params.row.DateUpdated || params.row.DateCreated).toJSON().split('T')[0]
                )
            }
        }
    ], []);
}