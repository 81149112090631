import React, { Fragment, useState } from 'react';
import axios from "axios";
import './App.css';
import { KineticLib, logout, } from '@kineticdata/react';
import { history } from './index';
import { WallySpinner } from './components/KAppComponents/Loading';
import { DLogin, LoginDialog } from './components/KAppComponents/Login';
// import { Header } from './components/Header';
import * as TableComponents from './components/KAppComponents/TableComponents';
// import { OriginalNav } from './navigation/kinetic.navigation';
import { TopNavBar } from './navigation/top.navbar';
import { LoadingProgress } from './components/Loading2';
import { /*KAppRoutes,*/ DAppRoutes } from './navigation/app.routes';
import { DappProvider } from './auth/dapp.context';
import { CMSAPIKEY } from './controls/dapp.constants';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { LicenseInfo} from '@mui/x-license-pro';
// import { ChatScript, RemoveChatScript } from './components/bottom/chat.live';
import { NavigationProvider } from './navigation/navigation.provider';
import { TicketsProvider } from './components/incidents/incident.provider';
import { ServiceRequestProvider } from './components/services requests/filter/filter.provider';

LicenseInfo.setLicenseKey('78b4c61b76aaf176232f22fbf082c61bTz04Nzc1NyxFPTE3NDM3NzkwNzUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

export const appLogout = () => logout(() => {  
   history.replace('/');
});

// use Wally for empty app
export const EmptyBodyRow = () => <WallySpinner />;

export const App = () => {
  // breadcrumbs for navigation
  // eslint-disable-next-line
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const [showLogin, setShowLogin] = useState(false);
  const path = useLocation();

  useEffect(() => {
    let re = new RegExp(/\/register|\/reset|\/retrieve|\/preferences|\/verify|\/pendingcontact/gi);
    let pathCheck = path.pathname.match(re);
    setShowLogin((pathCheck?.length | path?.search.length) <= 0);
  }, [path]);
  
  const handleDappNavigation = ({pathto}) => {
    if (pathto === window.location.pathname){
      history.replace(pathto, "/");
    }    
    history.push(pathto);
  };

  useEffect(() => {
    window.onbeforeunload = (event) => {
      event.preventDefault();
      event.returnValue = "something here to show message";
    };
  }, []);

  return (
    <KineticLib components={{ ...TableComponents, EmptyBodyRow }} locale="en">
      {({ initialized, loggedIn, loginProps, timedOut }) => (
        <Fragment>
          <DappProvider loggedIn={loggedIn}>
          <TopNavBar onMenuClick={handleDappNavigation} loggedIn={loggedIn} onLogout={appLogout}/>
          {(!initialized && <LoadingProgress open={!initialized} />) 
          || (showLogin && (!loggedIn && <DLogin {...loginProps} />))}

          <NavigationProvider>
            <TicketsProvider>
              <ServiceRequestProvider>
                <DAppRoutes loggedIn={loggedIn} setBreadcrumbs={setBreadcrumbs} profile={undefined}/>
              </ServiceRequestProvider>
            </TicketsProvider>
          </NavigationProvider>

          {/* {(loggedIn && <ChatScript/>) || <RemoveChatScript/>} */}

          {/* {(timedOut || tout) && (<dialog open><DLogin {...loginProps} oncloseDialog={onCloseLoginDialog}/></dialog>)} */}

          {timedOut && (
            <LoginDialog open>
              <DLogin  {...loginProps}/>
            </LoginDialog>
          )}
          </DappProvider>
        </Fragment>
      )}
    </KineticLib>
  );
};

axios.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers, 
    CmsApiKey: CMSAPIKEY
  }
  return config;
}, async (error) => {
  console.log(`something happens here...`);
  return Promise.reject(error);
});
