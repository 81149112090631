import React, {useState, useCallback, useEffect, Fragment, useRef} from "react";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { rowsperpage } from "../../controls/shared.components";
import { useIncidentsColumns, useIncidentsColumnsShort, useIncidentSortMapper } from "./columns.incidents";
import { SimpifiedAlert } from "../../alerts/alert.snackbar";
import { useDataFromBridge } from "../../controls/custom.hooks";
import { useDappContext } from "../../auth/dapp.context";
import clsx from "clsx";
import { useGridStyles } from "../../controls/datagrid.striped";
import { useHistory, useLocation } from "react-router-dom";
import { TicketSearchDialog } from "./ticket.search";
import { useTicketsContext } from "./incident.provider";
import { forwardRef } from "react";
import { utils, writeFileXLSX } from "xlsx";
import swal from "sweetalert";
import { EXPORTALERTMSG } from '../../controls/dapp.constants';
import { getDataUniversalExportFromBrdige } from "../../apicalls/universal.api";

const bridgedResourceName = "All Incidents Filtered";
const exportBridge = "AllIncidentsExport";
const MAX_ROWS_4_EXPORT = 2000;

export const IncidentsList = forwardRef((props, ref) => {
  const { 
    contactOnly, refresh, status, disposition, onFinishRefresh, 
    searchValue, short, exportData, onExportComplete, assignedToMe, ...other 
  } = props;
  const [loading, setLoading] = useState(true);
  const [xuser, setXuser] = useState();
  const [griddata, setGridData] = useState([]);
  // const [exportParams, setExportParams] = useState(undefined);
  const [totalRecs, setTotalRecs] = useState(0);
  const [exportedData, setExportedData] = useState([]);
  const [exportDataReady, setExportDataReady] = useState();
  const gridcls = useGridStyles();
  const dappCntx = useDappContext();  
  const history = useHistory();
  const location = useLocation();
  const snackRef = useRef();

  const apiRef = useGridApiRef();

  const sortMapper = useIncidentSortMapper();

  const ticketCntx = useTicketsContext();

  useEffect(() => {
    if (!dappCntx || !dappCntx.user) return;
    setXuser(dappCntx.user?.user);
  }, [dappCntx]);

  const onDataLoad = useCallback(() => {
    setGridData([]);
    setLoading(true);
  }, []);

  // const incidents2export = useDataFromBridge({ 
  //   xUser: xuser, apiValues: exportParams,
  //   bridgedResourceName: exportBridge,
  // });

  const shortColumns = useIncidentsColumnsShort({
    handleEdit: ({row}) => {
      history.push({pathname: `/incident/edit/${row.IncidentID}`, state: {prevPath: location.pathname}});
    }, handleDelete: ({row}) => {
      alert(`placeholder for delete row with the Incident ID = ${row["IncidentID"]}`);
    }, handleView: (({row}) => {
      history.push(`/incident/view/${row.IncidentID}`)
    }), handleContactView: ({row}) => {
      history.push(`/contacts/view/${row.ContactID}`)
    }, userType: dappCntx?.user?.user.UserType,
  });

  const columns = useIncidentsColumns({
    handleEdit: ({row}) => {
      history.push({pathname: `/incident/edit/${row.IncidentID}`, state: {prevPath: location.pathname}});
    }, handleDelete: ({row}) => {
      alert(`placeholder for delete row with the Incident ID = ${row["IncidentID"]}`);
    }, handleView: (({row}) => {
      history.push(`/incident/view/${row.IncidentID}`)
    }), handleContactView: ({row}) => {
      history.push(`/contacts/view/${row.ContactID}`)
    }, userType: dappCntx?.user?.user.UserType,
  });

  useEffect(() => {
    if (!refresh) return;
    ticketCntx.setSrch({...ticketCntx.srch, PageToken: "random"});
    ticketCntx.setSrch({...ticketCntx.srch, PageToken: ' '});
    onFinishRefresh();
    // eslint-disable-next-line
  }, [refresh, ticketCntx]);

  const handlePageChange = useCallback((model, details) => {
      ticketCntx.updateSearch({        
        PageNum: model.page + 1,
        PageSize: model.pageSize,        
      });
    }, [ticketCntx],
  );

  const showError = useCallback(({message}) => {
    ticketCntx.setShowAlert({...ticketCntx.showAlert, open: true, 
        severity: "error", message: message,
        onSnackBarClose: () => { ticketCntx.setShowAlert({open: false, message: "random"})}
    });
  }, [ticketCntx])

  const incidents = useDataFromBridge({ 
    xUser: xuser, apiValues: ticketCntx?.srch,
    bridgedResourceName: bridgedResourceName,
    callback: onDataLoad
  });

  useEffect(() => {
    if (!incidents) return;

    setLoading(false);
    if (!incidents.success){      
      (incidents.data?.Message || incidents.data?.message) && showError({message: incidents.data?.Message || incidents.data?.message});
      return;
    }

    setTotalRecs(incidents.data?.metadata?.count || 0);

    setGridData((incidents.data?.records || incidents.data?.Data)?.map((row, indx) => { 
      return {...row, id: indx}
    }));
    // eslint-disable-next-line
}, [incidents]);

const handleSortChange = useCallback((sortmodel) => {
  if (!sortmodel) return;
  if (sortmodel && sortmodel.length <= 0) return;
  const fld = sortMapper.find(x => x.field === sortmodel[0].field).sortBy;
  if (!fld) return;
  if (ticketCntx.srch.SortField === fld && ticketCntx.srch.AscDesc === sortmodel[0].sort) return;

  ticketCntx.setSrch({
    ...ticketCntx.srch, SortField: fld,
    AscDesc: sortmodel[0].sort
    });
  }, [sortMapper, ticketCntx]);

  useEffect(() => {
    if (!dappCntx?.user) return;
    if (!ticketCntx?.srch) return;
    let changes = {
      ...ticketCntx.srch, 
      CustomerContactID: (contactOnly && dappCntx.user?.user?.ContactID) || ' ',
      SearchValue: searchValue || ' ',
      Status: status || 'Open',
      Disposition: disposition || ' ',
      assignedTo: (assignedToMe && `C${dappCntx.user?.user?.CCWebUserID}`) || ' '
    };
    if (JSON.stringify(changes) !== JSON.stringify(ticketCntx.srch)){
      ticketCntx.setSrch({...changes});
    }

    // eslint-disable-next-line
  }, [status, dappCntx, searchValue, contactOnly, disposition]);

  const exportAllRecords = async () => {
    setLoading(true);
    let pages = Math.round(totalRecs / MAX_ROWS_4_EXPORT);
    pages = (pages <= 0 ? 1 : pages);
    for (var i = 1; i <= pages; i++){
      let p = {
        xUser: xuser,
        apiValues: {...ticketCntx.srch, PageNum: i, PageSize: MAX_ROWS_4_EXPORT},
        bridgedResourceName: exportBridge,
      };
      await getDataUniversalExportFromBrdige(p)
        .then(response => {
          // console.log(`inside promise...: ${response?.records?.length}`);
          setExportedData((exportedData || []).push(...response?.records))
        })
        .catch(error => console.error(error?.response?.data));
    }
    setExportDataReady(exportedData);    
  }

  useEffect(() => {
    if (!exportData) return;

    swal({
      title: "Export Incidents",
      content: {
        element: "span",
        attributes: {
          innerHTML: EXPORTALERTMSG.replace('REPLACE-STRING', 'incidents'),
        },
      },
      icon: undefined,
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: {
        confirm: true,
        cancel: true,
      },
      dangerMode: false,
    }).then((accepted) => {
      accepted && exportAllRecords();      
    });

    if (onExportComplete && typeof onExportComplete === 'function') {
      onExportComplete();
    }
  }, [exportData, onExportComplete, ticketCntx, exportAllRecords]);

  // useEffect(() => {
  //   if (!incidents2export || !incidents2export?.data) return;

  //   const d = new Date();
  //   const stamp = `${d.getFullYear()}${d.getMonth()}${d.getDay()}-${d.getHours()}${d.getMinutes()}`;
  //   const fileName = `INCIDENTS_CMS_${stamp}.xlsx`;

  //   const ws = utils.json_to_sheet(incidents2export.data.records);
  //   const wb = utils.book_new();
  //   utils.book_append_sheet(wb, ws, "Incidents");
  //   writeFileXLSX(wb, fileName);

  // }, [incidents2export]);

  useEffect(() => {
    if (!exportDataReady || exportDataReady.length === 0) return;

    const d = new Date();
    const stamp = `${d.getFullYear()}${d.getMonth()}${d.getDay()}-${d.getHours()}${d.getMinutes()}`;
    const fileName = `INCIDENTS_CMS_${stamp}.xlsx`;

    exportDataReady.map((value) => {
      let dt = new Date(value.LastUpdateDate);
      value.LastUpdateDate = `${dt.toLocaleDateString()}`
      let odt = new Date(value.OpenDate);
      value.OpenDate = `${odt.toLocaleDateString()}`
    });

    const ws = utils.json_to_sheet(exportDataReady);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Incidents");
    setLoading(false);
    writeFileXLSX(wb, fileName);

    setExportedData([]);
    setExportDataReady([]);

  }, [exportDataReady]);

  return (
        <Fragment>
          <DataGridPro 
            className={clsx(gridcls[`gridheadfoot${(short && "-home") || ''}`], gridcls[`gridrows${(short && '-home') || ''}`])} {...other}
            columns={(short && shortColumns) || columns}
            density="compact"
            paginationMode="server"
            ref={snackRef}
            pagination
            autoPageSize
            rows={griddata || []}
            loading={loading}
            rowHeight={(short && 32) || 52}
            columnHeaderHeight={(short && 32) || 52}
            rowCount={totalRecs}
            pageSizeOptions={rowsperpage}
            onPaginationModelChange={handlePageChange}
            sortingMode="server"
            // scrollEndThreshold={80}
            // ref={ref}
            apiRef={apiRef}
            onSortModelChange={handleSortChange}
            // initialState={{
            //   pagination: {
            //     paginationModel: {
            //       pageSize: pageSize,
            //       page: currpage,
            //     },
            //   },
            // }}
          />
          <SimpifiedAlert alertProps = {ticketCntx.showAlert} />
          <TicketSearchDialog open={ticketCntx?.showSearch} values={ticketCntx?.srch}/>
        </Fragment>
  );
});


