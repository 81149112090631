import React, { Fragment } from "react";
import { GridPagination } from "@mui/x-data-grid";
import { Typography, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core";

const useFooterStyles = makeStyles((theme) => ({
    'grid-pagination-footer': {
        flex: 1,
    },
    'total-label': {
        marginRight: "1rem !important",
    },
    'footer-total-wrapper': {
        flex: 0.18,
        display: "flex",
        marginRight: "1rem !important",
        '& .footer-total-field': {
            '& .dollar-sign': {
                color: "window",
            },
            '& .total-text-field': {
                textAlign: "end",
                color: "window",
            },
        },
    },
}));

export const DappGridFooter = (props) => {    
    const {total, ...other} = props;
    const stls = useFooterStyles();
    return (
      <Fragment>
      <GridPagination className={stls["grid-pagination-footer"]}/>
      <Typography component={`span`} className={stls["total-label"]}>Total:</Typography>
      <Typography component={`div`} className={stls["footer-total-wrapper"]} {...other}>
        <TextField 
          value={parseFloat(total, 2)} 
          size='small'
          className='footer-total-field' 
          InputProps={{
            startAdornment: <FontAwesomeIcon icon={faDollarSign} className='dollar-sign'/>,
          }}
          inputProps={{
            className: 'total-text-field',
          }}
        />
      </Typography>
      </Fragment>
    );
  }
  