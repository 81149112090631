import { CoreForm } from "@kineticdata/react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useCallback, useRef } from "react";
import { KappFormLayout, KappPending, useDialogButtonStyles, useFormStyle, useTabStyles } from "../../../controls/shared.components";

export const CmsAccessModal = (props) => {
    const {UserID, open, handleClose, handleSave, handleError} = props;

    const tabcls = useTabStyles();
    const fmrcls = useFormStyle();
    const btnCls = useDialogButtonStyles();

    const kForm = useRef();

    const handleLoaded = useCallback((form) => {
      kForm.current = form;
    }, [kForm]);

    const handleSaveChanges = useCallback(() => {
      kForm.current.submitPage();
      handleClose();
    }, [kForm, handleClose]);

    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle style={{ padding: 'unset', backgroundColor: '#007dba', color: 'window' }}>
          <Typography component={`div`} className={tabcls.dappTitle}>
            CMS Access
            <IconButton onClick={handleClose} className={tabcls.closeIcon}>
              <Close />
            </IconButton>
          </Typography>
        </DialogTitle>
        <DialogContent className={fmrcls.root} style={{ width: "520px" }}>
          <CmsAccessForm
            UserID={UserID}
            onSave={handleSaveChanges}
            onError={handleError}
            onLoadComplete={handleLoaded}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className={btnCls.okbutton} defaultChecked onClick={handleSave}>Update</Button>
          <Button variant="contained" className={btnCls.closebutton} onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    )
}

export const CmsAccessForm = ({UserID,  onSave, onError, onLoadComplete }) => {
  return (
    <CoreForm
      kapp="customer-portal"
      form="cms-access"
      onError={onError}
      onCompleted={onSave}
      values={{UserID: UserID}}
      loaded={onLoadComplete}
      components={{
        Pending: KappPending,
        Layout: KappFormLayout,
      }}
    />
  );
};
