import React, { useCallback, useRef, useState } from "react";
import { 
    Dialog, DialogActions, DialogContent, DialogTitle, IconButton, 
    TextField, useMediaQuery, useTheme, Grid, MenuItem, Divider, 
    makeStyles, Typography, Box, 
} from "@material-ui/core";
import { Close, FolderOpen, Save } from "@material-ui/icons";
import { useTicketsContext } from "./incident.provider";
import { useDialogStyles } from "../modals/dialog.style";
import { useDataFromBridge } from "../../controls/custom.hooks";
import { useDappContext } from "../../auth/dapp.context";
import { useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import { Fragment } from "react";
import { LoadFilter } from "./filter/filter.load";
import { SaveFilter } from "./filter/filter.save";
import { KineticWebAPICall } from "../../apicalls/contact.api";
import { INCIDENT_FILTER_WEBAPI_SAVE } from "../../controls/dapp.constants";
import { useFilterStyles } from "../modals/filter.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser } from "@fortawesome/free-solid-svg-icons";
import { DappToolbarSwitch } from "../../controls/shared.components";
import { FormControlLabel } from "@mui/material";
import { FilterOKCancelButtons } from "../services requests/filter/filter.components";

const useAutoStyles = makeStyles(() => ({
    paper: {
        fontSize: "0.8rem",
    }
}));

const filterMapping = [
    {oldField: "rcbHDAssignedTo", newField: "assignedTo"},
    {oldField: "FilterStartDate", newField: "OpenDateStart"},
    {oldField: "FilterEndDate", newField: "OpenDateStop"},
    {oldField: "rcbHDFilterSeverity", newField: "SeverityShort"},
    {oldField: "rcbHDFilterClass", newField: "IncidentClass"},
    {oldField: "rcbHDFilterSubClass", newField: "IncidentSubClass"},
    {oldField: "rcbdepartmentforIncident", newField: "DepartmentID"},
    {oldField: "rcbHDFilterTier", newField: "IncidentPriority"},
];

const defaultFilter = {
    assignedTo: ' ',
    IncidentClass: ' ',
    IncidentClassID: ' ',
    IncidentSubClass: ' ',
    SeverityShort: ' ',
    DepartmentID: ' ',
    IncidentPriority: ' ',
    IsVIP: false,
    OpenDateStart: new Date(new Date().setFullYear(new Date().getFullYear() - 3)).toJSON().split('T')[0],
    OpenDateStop: new Date().toJSON().split('T')[0],
};

export const TicketSearchDialog = (props) => {
    const {open, values} = {...props?.open};
    const [formValues, setFormValues] = useState();
    const ticketCntx = useTicketsContext();
    const diagStyles = useDialogStyles();
    const theme = useTheme();
    const diagSize = useMediaQuery(theme.breakpoints.down('sm'));
    const dappCntx = useDappContext();
    const [xuser, setXuser] = useState();
    const [severityData, setSeverityData] = useState();
    const [priorityData, setPriorityData] = useState();
    const [emptyVals, setEmptyVals] = useState();
    const [sevbridgeName, setSevBridgeName] = useState('');
    const [priorbridgeName, setPriorBridge] = useState('');
    const [hdClassBrdige, setHdClassBridge] = useState('');
    const [assigneeBridge, setAssigneeBridge] = useState('');
    const [hdClassData, setHdClassData] = useState();
    const [assigneeData, setAssigneeData] = useState();
    const [deptsData, setDeptsData] = useState();
    const [ticketDeptsBridge, setTicketDeptsBridge] = useState('');
    const [showAssigneeLkp, setShowAssigneeLkp] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [showSave, setShowSave] = useState(false);
    const [hdsubclass, setHdSubClass] = useState();
    const [subclassBridge, setSubclassBrige] = useState('');
    const [subclassVals, setSubClassVals] = useState();
    
    const titleEl = useRef();
    const filterStyles = useFilterStyles();

    const handleClose = useCallback(() => {
        ticketCntx.closeSearchDialog();
    }, [ticketCntx]);

    useEffect(() => {
        if (!emptyVals){
            setEmptyVals({random: 0});
            setSevBridgeName("TicketSeverity");
            setPriorBridge("TicketPriority");
            setHdClassBridge("TicketClass");
            setAssigneeBridge("TicketAssignee");
            setTicketDeptsBridge("TicketDepartments");
            setSubclassBrige("TicketSubClassByClassId");
        }
    }, [emptyVals, sevbridgeName]);

    const handleOk = useCallback((e) => {
        e.preventDefault();
        formValues.DepartmentID = formValues.DepartmentID || ' ';
        formValues.ReAssignmentTemplateID = formValues.ReAssignmentTemplateID || ' ';
        ticketCntx.setSrch({...ticketCntx.srch, ...formValues});
        if (!!formValues?.OpenDateStart && !formValues?.OpenDateStop){
            ticketCntx.setSrch({...ticketCntx.srch, ...{...formValues, "OpenDateStop": new Date().toJSON().split('T')[0]}});
        }
        ticketCntx.closeSearchDialog();
    }, [ticketCntx, formValues]);

    const autoStls = useAutoStyles();

    const subclass = useDataFromBridge({
        xUser: xuser, apiValues: subclassVals,
        bridgedResourceName: subclassBridge
    });
    
    const handleChange = useCallback((e) => {
        setFormValues({...formValues, [`${e.target.id || e.target.name}`]: e.target.value});
    }, [formValues]);

    const handleClassChange = useCallback((e) => {
        let className = hdClassData.find(x => x["Incident Class ID"] === e.target.value)?.["Incident Class Name"];
        setFormValues({
            ...formValues, [`${e.target.id || e.target.name}`]: e.target.value,
            IncidentClass: className || ' ',
            IncidentSubClass: ' ', 
        });
        setSubClassVals({...subclassVals, ClassID: e.target.value});
    }, [hdClassData]);

    const handleDeptChange = useCallback((e) => {
        let type = deptsData.find(d => d["Department ID"] === e.target.value)?.["Department Type"];

        if (type === 'Client'){
            setFormValues({...formValues, "ReAssignmentTemplateID" : e.target.value, "DepartmentID" : undefined});
        } else {
            setFormValues({...formValues, "DepartmentID" : e.target.value, "ReAssignmentTemplateID" : undefined});            
        }

    }, [formValues, deptsData]);

    // const resetSubclass = useCallback((e) => {
    //     setSubClassVals({ ...subclassVals, ClassID: e.target.value === ' ' ? -1 : e.target.value});
    // }, [subclassVals, ticketCntx.srch]);

    const handleSubClassChange = useCallback((e, {filterValue}) => {
        setFormValues({
            ...formValues, 
            [`${e.target.id || e.target.name}`]: e.target.value,
            IncidentSubClass: filterValue,
        });
    }, [formValues]);


    const handleCheckChange = useCallback((e) => {
        setFormValues({ ...formValues, [`${e.target.id || e.target.name}`]: e.target.checked});
    }, [formValues]);

    useEffect(() => {
        if (!dappCntx?.user) return;
        setXuser(dappCntx.user.user);
    }, [dappCntx])

    const severity = useDataFromBridge({
        xUser: xuser, apiValues: emptyVals,
        bridgedResourceName: sevbridgeName
    });

    useEffect(() => {
        if (!severity) return;
        setSeverityData(severity?.data?.records);
    }, [severity]);

    const priority = useDataFromBridge({
        xUser: xuser, apiValues: emptyVals,
        bridgedResourceName: priorbridgeName
    });

    useEffect(() => {
        if (!priority) return;
        setPriorityData(priority?.data?.records);
    }, [priority]);

    const hdClass = useDataFromBridge({
        xUser: xuser, apiValues: emptyVals,
        bridgedResourceName: hdClassBrdige,
    });

    useEffect(() => {
        if (!hdClass) return;
        setHdClassData(hdClass?.data?.records);
    }, [hdClass]);

    const assignees = useDataFromBridge({
        xUser: xuser, apiValues: emptyVals,
        bridgedResourceName: assigneeBridge,
    });

    useEffect(() => {
        if (!assignees) return;
        let simplified = assignees?.data?.records?.map((option) => {
            return { UserID: option?.["Assignee ID"],
                UserName: option?.["Assignee DisplayName"]}
        });
        setAssigneeData(simplified);
    }, [assignees]);

    const departments = useDataFromBridge({
        xUser: xuser, apiValues: emptyVals,
        bridgedResourceName: ticketDeptsBridge,
    });

    useEffect(() => {
        if (!departments) return;
        setDeptsData(departments?.data?.records);
    }, [departments]);

    const handleAutoCompleteChange = useCallback((e, value) => {
        setFormValues({...formValues, assignedTo: value?.UserID || ' '});        
    }, [formValues]);

    useEffect(() => {
        if (!dappCntx.sharedOperator) return;
        setShowAssigneeLkp( 
            (dappCntx?.user?.user?.UserType !== "User") &&
            (dappCntx.sharedOperator && Number(dappCntx.sharedOperator.IsHDSharedOperator) === -1)
        );
    }, [dappCntx]);

    const hideFilter = useCallback(() => {
        setShowFilter(false);
    }, []);

    const showMessage = useCallback((props) => {
        const {severity, message} = props;
        ticketCntx.setShowAlert({...ticketCntx.showAlert, 
          open: true, severity: severity, message: message,
          onSnackbarClose: () => { 
            ticketCntx.setShowAlert({...ticketCntx.showAlert, open: false, message: "random"})
        },
        });
      }, [ticketCntx]);

    const handleSaveFilter = useCallback((f2s) => {
        try {
            KineticWebAPICall({
                user: xuser, data: {Filter: f2s}, url: INCIDENT_FILTER_WEBAPI_SAVE
            }).then((response) => {
                showMessage({severity: "success", message: "Filter saved"});
                ticketCntx.setSavedFilter(f2s);
                setShowSave(false);
            }).catch((error) => {
                showMessage({severity: "error", message: `Save filter failed: ${error}`});
            })
        } catch (exp) {
            showMessage({severity: "error", message: `Save filter failed with: ${exp}`});
        }

    }, [xuser, showMessage, ticketCntx]);

    const handleCloseFilter = useCallback(() => {
        setShowSave(false);
    }, []);

    const applyFilterValues = useCallback((fields) => {
        let newFields = {};
        fields.map(fld => {
            let fieldName = !!fld && filterMapping.find(fm => fm.oldField === fld.WebControlName)?.newField;

            if (fieldName && fld?.WebControlValue !== '-1'){
                newFields = {
                    ...newFields, 
                    [`${fieldName}`]: (fld.WebControlValue === '<ALL>' && ' ') ||  
                    (fieldName.match(/Date/gi)?.length > 0 ? new Date(fld.WebControlValue).toJSON().split('T')[0] : fld.WebControlValue)
                };  
            }
                
            return fieldName;
        });
        setFormValues({...formValues, ...newFields});
        setSubClassVals({ ...subclassVals, ClassID: newFields["IncidentClass"]});
    }, [formValues, subclassVals]);

    const applyValues2Filter = useCallback(() => {
        let flds = [];
        Object.entries(formValues).map((fld) => {
            let fieldName = filterMapping.find(fm => fm.newField === fld[0])?.oldField;

            if (fieldName){
                flds.push({
                    ...FilterFieldDef, 
                    WebControlName: `${fieldName}`,
                    WebControlValue: fld[1],
                });  
            }                
            return fieldName;
        });
        return flds;
    }, [formValues]);

    const onSaveFilter = useCallback(() => {
        let flt = { 
            ...((!!ticketCntx.savedFilter && ticketCntx.savedFilter) || FilterDef), 
            SearchPage: "Incident Grid",
            SearchType: "Incident",
            CCWebUserID: dappCntx.user.user.CCWebUserID,
            PrimaryContactID: dappCntx.user.user.PrimaryContactID,
            Fields: applyValues2Filter()
        };
        ticketCntx.setSavedFilter(flt);

        setShowSave(!showSave);
    }, [ticketCntx, showSave, applyValues2Filter, dappCntx]);

    const handleApplyFilter = useCallback((filterRecord) => {
        ticketCntx.setSavedFilter(filterRecord);
        applyFilterValues(JSON.parse(filterRecord.Fields));
        hideFilter();
    }, [hideFilter, applyFilterValues, ticketCntx]);

    const onClearFilter = useCallback(() => {
        ticketCntx.setSavedFilter(null);
        setFormValues(defaultFilter);
    }, [ticketCntx]);

    useEffect(() => {
        if (!!formValues) return;
        setFormValues(defaultFilter);        
    }, [formValues]);

    useEffect(() => {
        if (!subclass || !subclass.data || !subclass.success) return;
        setHdSubClass(subclass?.data?.records);
    }, [subclass])

    useEffect(() => {
        if (!open) return;
        if (values?.IncidentClassID && values.IncidentClassID !== ' '){
            setSubClassVals({ ...subclassVals, ClassID: values?.IncidentClassID});
        }
        // setFormValues(f => ({ ...values}));
    }, [open, values]);

    const handleClearFilter = useCallback((e) => {
        ticketCntx.setSrch({
            ...ticketCntx.srch, 
            ...defaultFilter,
            OpenDateStart: ' ',
            OpenDateStop: ' ',
        });
        ticketCntx.closeSearchDialog();
    }, [handleOk, defaultFilter]);

    // enable filter buttons on 10.16.2023
    return (
        <Dialog open={open || false} onClose={handleClose} fullScreen={diagSize}>
            <Typography component={`div`} className={diagStyles["title-wrapper"]} ref={titleEl}>
                <DialogTitle className={diagStyles.title}>
                    Search Tickets
                </DialogTitle>
                <Box style={{ flexGrow: 1 }}>
                </Box>
                <IconButton title="Load Saved Filter(s)" onClick={(e) => {
                    setShowFilter(!showFilter);
                }}>
                    <FolderOpen />
                </IconButton>
                <IconButton title="Save Filter" onClick={onSaveFilter}>
                    <Save />
                </IconButton>
                <IconButton title="Clear Filter" onClick={onClearFilter} disabled={!ticketCntx.savedFilter}>
                    <FontAwesomeIcon icon={faEraser} />
                </IconButton>
                <IconButton onClick={handleClose} title="Close" className={diagStyles.closeCross}>
                    <Close />
                </IconButton>
            </Typography>
            <DialogContent className={diagStyles.root}>
                <form onSubmit={handleOk}>
                    <Typography component={'div'} className={filterStyles["wrapper-root"]}>
                        <Typography component={'div'} className="title-wrap">
                            <Typography component={'div'} className="filter-prompt">
                                Selected Filter:
                            </Typography> 
                            <Typography component={'div'} className="filter-name">
                                {ticketCntx?.savedFilter?.["SearchName"] || ''}
                            </Typography>
                        </Typography>
                    </Typography>                    
                    <Grid container spacing={3}>
                        {(showAssigneeLkp &&
                        <Grid item xs={12}>
                            <Autocomplete 
                                id="assignedTo"
                                size="small"
                                fullWidth
                                className={diagStyles.dropdown}
                                options={assigneeData || []}
                                disableClearable={false}
                                getOptionLabel={(option) => option?.UserName || ' '}
                                onChange={handleAutoCompleteChange}
                                classes={{ paper: autoStls.paper }}
                                value={(formValues?.assignedTo && (assigneeData?.find(x => x.UserID === formValues.assignedTo) || null)) || null}
                                getOptionSelected={(option, value) => {
                                    return (value?.UserID && option.UserID === value?.UserID) || false;
                                }}
                                renderInput={(params) => 
                                    <TextField {...params} 
                                        label="Assigned To" 
                                        variant="outlined"
                                        SelectProps={{
                                            MenuProps: {
                                                className: diagStyles.dropdownItem
                                            }
                                        }}
                                    />
                                }
                            />
                        </Grid>) || <Fragment/>}
                        <Divider orientation="horizontal"/>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <DropDownField
                                        id="IncidentClassID"
                                        name="IncidentClassID"
                                        label="Type"
                                        value={formValues?.["IncidentClassID"] || ' '}
                                        // onChange={(e) => {
                                        //     let className = hdClassData.find(x => x["Incident Class ID"] === e.target.value)?.["Incident Class Name"];
                                        //     handleClassChange(e, {filterValue: className});
                                        // }}
                                        onChange={handleClassChange}
                                    >
                                        <MenuItem key="blank" value=' ' style={{opacity: "0.8"}}>{`(None)`}</MenuItem>
                                        {hdClassData?.map((value, indx) => {
                                            return (
                                                <MenuItem key={`class-${indx}`} value={value["Incident Class ID"]}>
                                                    {value["Incident Class Name"]}
                                                </MenuItem>
                                            )
                                        })}
                                    </DropDownField>
                                </Grid>

                                <Grid item xs={6}>
                                    <DropDownField
                                        id="SeverityShort" name="SeverityShort"
                                        value={formValues?.SeverityShort || ' '}
                                        label="Severity"
                                        onChange={handleChange}
                                    >
                                        <MenuItem key="blank" value=' ' style={{opacity: "0.8"}}>{`(None)`}</MenuItem>
                                        {severityData?.map((value, indx) => {
                                            return (
                                                <MenuItem key={`sev-${indx}`} value={value["SeverityShort"]}>
                                                    {value["Severity Name"]}
                                                </MenuItem>
                                            )
                                        })}
                                    </DropDownField>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{paddingTop: "0.8rem"}}>
                        <Grid item xs={8}>
                            <DropDownField
                                id="IncidentSubclassID"
                                name="IncidentSubclassID"
                                label="Sub Type"
                                value={formValues?.["IncidentSubclassID"] || ' '}
                                onChange={(e) => {
                                    let filterValue = hdsubclass.find(x => x["IncidentSubclassID"] === e.target.value)?.["IncidentSubclassName"] || ' ';
                                    handleSubClassChange(e, {filterValue: filterValue || ' '});
                                }}
                            >
                                <MenuItem key="blank" value=' ' style={{opacity: "0.8"}}>{`(None)`}</MenuItem>
                                {hdsubclass?.map((value, index) => {
                                    return (
                                        <MenuItem key={`sub-class-${index}`}  value={value["IncidentSubclassID"]}>
                                            {value["IncidentSubclassName"]}
                                        </MenuItem>
                                    )
                                })}
                            </DropDownField>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={
                                    <DappToolbarSwitch
                                        checked={formValues?.IsVIP}
                                        onChange={handleCheckChange}
                                        name="IsVIP" />
                                }
                                label={`${(!formValues?.IsVIP && "No") || ''} VIP`}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{ paddingTop: "0.8rem" }}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5}>
                            <DropDownField
                                id="DepartmentID" name="DepartmentID"
                                label="Department"
                                value={(Number.isInteger(Number.parseInt(formValues?.ReAssignmentTemplateID)) && formValues?.ReAssignmentTemplateID)
                                || (Number.isInteger(Number.parseInt(formValues?.DepartmentID)) && formValues?.DepartmentID) || ' '}
                                onChange={handleDeptChange}
                            >
                                <MenuItem key="blank" value=' ' style={{opacity: "0.8"}}>{`(None)`}</MenuItem>
                                {deptsData?.map((value, indx) => {
                                    return (
                                        <MenuItem key={`dept-${indx}`} value={value["Department ID"]}>
                                            {value["Department Name"]}
                                        </MenuItem>
                                    )
                                })}
                            </DropDownField>
                        </Grid>
                        <Grid item xs={5}>
                            <DropDownField
                                id="IncidentPriority" name="IncidentPriority"
                                label="Assigned Lvl"
                                value={formValues?.IncidentPriority || ' '}
                                onChange={handleChange}
                            >
                                <MenuItem key="blank" value=' ' style={{opacity: "0.8"}}>{`(None)`}</MenuItem>
                                {priorityData?.map((value, indx) => {
                                    return (
                                        <MenuItem key={`prior-${indx}`} value={value["Priority Name"]}>
                                            {value["Priority Name"]}
                                        </MenuItem>
                                    )
                                })}
                            </DropDownField>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                    <Grid container spacing={3} style={{paddingTop: "0.8rem"}}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="OpenDateStart"
                                label="Open Date Start"
                                type="date"
                                value={formValues?.OpenDateStart || 
                                    new Date(new Date().setFullYear(new Date().getFullYear() - 3)).toJSON()
                                    .split('T')[0]
                                }
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                id="OpenDateStop"
                                label="Open Date Stop"
                                fullWidth
                                variant="outlined"
                                type="date"                                
                                value={formValues?.OpenDateStop || new Date().toJSON().split('T')[0]}
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </form>                
            </DialogContent>
            <DialogActions>
                <FilterOKCancelButtons 
                        OkButtonClick={handleOk} 
                        CancelButtonClick={handleClose}
                        ClearButtonClick={handleClearFilter} 
                        formValues={formValues}                        
                />
            </DialogActions>
            <LoadFilter 
                showFilter={showFilter} 
                hideFilter={hideFilter} 
                parentEl={titleEl.current}
                applyFilter={handleApplyFilter}
                currentFilter={ticketCntx?.savedFilter}
                searchType='incident'
            />
            <SaveFilter
                showSave={showSave}
                handleClose={handleCloseFilter}
                parentEl={titleEl.current}
                handleSave={handleSaveFilter}
            />
        </Dialog>
    )
}

const DropDownField = (props) => {
    const {id, label, onChange, children, ...other} = props;
    const stls = useDialogStyles();

    return (
        <TextField {...other}
            fullWidth
            variant="outlined" 
            label={label}
            id={id}
            name={id}
            onChange={onChange}
            size="small"
            className={stls.dropdown}
            select
            SelectProps={{
                MenuProps: {
                    PaperProps: {
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                        },                        
                    },
                    className: stls.dropdownItem,
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left",                        
                    },
                    getContentAnchorEl: null,
                },
            }}
        >
            {children}
        </TextField>
    )
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const FilterDef = 
{
    SearchCriteriaID: null,
    SearchPage: "",
    SearchName: "",
    UserID: 0,
    IsPublic: 0,
    SearchType: "",
    DateCreated: "",
    DateUpdated: "",
    CCWebUserID: 0,
    PrimaryContactID: 0,
    Fields: []
}

const FilterFieldDef = {
    WebControlName: "",
    WebControlValue: "",
    IsEnabled: 0,
    IsVisible: 0,
    DynamicDateType: "",
    IsStartDate: 0,
    IsViewMode: 0,
    IsPrimaryContactIDField: 0,
    FieldName: ""
}

