import React, { Fragment, useState, useEffect, useCallback, useReducer, useContext } from 'react';
import { LinearWait, rowsperpage } from '../../controls/shared.components';
import { List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles, TablePagination, Tooltip, Typography } from '@material-ui/core';
import { useDataFromBridge } from '../../controls/custom.hooks';
import { useDappContext } from '../../auth/dapp.context';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComputer, /*faCircleInfo*/ } from "@fortawesome/free-solid-svg-icons";
import { SelectedDeviceContext } from './device.splitview';
import { useHistory, useParams } from 'react-router-dom';
import { DeviceSearchContext, useDeviceContext } from './devices.main';
// import { Badge } from '@material-ui/core/Badge';
import { indigo } from '@material-ui/core/colors';

const initLoadParams = {
    GetTotal: true,
    PageSize: rowsperpage[0],
    PageNum: 1,
    PageToken: ' ',
    AscDesc: 'Asc',
    SortField: 'DeviceName',
    DeviceName: ' ',
  };
  
const initState = {page: 0, pageSize: rowsperpage[0], loading: true};

const pageReducer = (state, action) => {
  switch (action.type) {
    case "page": 
      return {...state,  page: action.page};
    case "pageSize":
      return {...state, pageSize: action.pageSize};
    case "pageToken": {
      return {...state, pageToken: action.pageToken};
    }
    case "deviceList": {
      return {...state, deviceList: action.deviceList};
    }
    case "loading": {
      return {...state, loading: action.loading};
    }
    default:
      throw new Error();
  }
}

export const DeviceList = (props) => {
  const {onError} = props;
  const [pagination, dispach] = useReducer(pageReducer, initState);
  const [xuser, setXuser] = useState();  
  const [apiparms, setApiparms] = useState();
  const dappCntx = useDappContext();
  const selectedDeviceCntx = useContext(SelectedDeviceContext);
  const deviceSearchCntx = useContext(DeviceSearchContext);
  const [bridgeName, setBridgeName] = useState();
  const cls = useListStyles();
  const {id} = useParams();  
  const deviceCntx = useDeviceContext();
  const history = useHistory();

  const devices = useDataFromBridge({ 
    xUser: xuser, apiValues: apiparms,
    bridgedResourceName: bridgeName,
  });

  const handleSelect = useCallback(({record}) => {
    selectedDeviceCntx.setSelectedDevice(record);
    deviceCntx.setIsSelected(true);
  }, [deviceCntx, selectedDeviceCntx]);

  useEffect(() => {
    if (!!id || dappCntx.user?.user?.UserType === 'User'){
      setApiparms({...initLoadParams, ContactID: id || dappCntx.user?.user?.ContactID});
      setBridgeName("Devices by ContactID");
    } else {
      setApiparms({...initLoadParams});
      setBridgeName("All Devices");
    }
  }, [id, dappCntx])

  const handlePageSizeChange = useCallback((pageSize) => {
    dispach({type: "loading", loading: true});
    setApiparms({
      ...apiparms,
      PageSize: pageSize,
      PageNum: 1,
      PageToken: ' ' /*pagetoken*/,
    });
  },
  [apiparms],
);

  const handlePageChange = useCallback((page) => {
    dispach({type: "loading", loading: true});
    setApiparms({ ...apiparms, PageNum: ++page, PageToken: ' ' /*pagetoken*/ });
  },
  [apiparms],
);

  useEffect(() => {
    if (!dappCntx.user) return;
    setXuser(dappCntx.user.user);
  }, [dappCntx])

  useEffect(() => {
    if (!devices) return;
    dispach({type: "loading", loading: false});

    if (!devices.success){
      onError({message: devices.data.Message || devices.data.message})
    }

    if (!!devices.data?.error) {
      onError({message: devices.data?.error?.message});
      return;
    }

    let list = devices.data.records.map((row, indx) => {
      return {...row, id: indx, "Device ID": (row["Device ID"] === "-1" && (-900 -indx)) || row["Device ID"]}
    });
    dispach({type: 'pageToken', pageToken: devices.data.metadata?.nextPageToken || initLoadParams.PageToken});
    dispach({type: 'deviceList', deviceList: list});

    // eslint-disable-next-line
  }, [devices]);

  useEffect(() => {
    if (!pagination.deviceList) return;
    handleSelect({record: pagination.deviceList[0]});
    // eslint-disable-next-line
  }, [pagination.deviceList]);

  const handleNewRequest = useCallback(() => { 
    let selectedId = selectedDeviceCntx.selectedDevice && selectedDeviceCntx.selectedDevice["Device ID"];
    selectedId &&
    history.push(`/servicerequest/add/${selectedId}?type=device`);
  }, [history, selectedDeviceCntx.selectedDevice]);

  const handleRefresh = useCallback(() => {
    dispach({type: "loading", loading: true});
    dispach({type: "page", page: 0});
    setApiparms({...apiparms, PageNum: 1, PageToken: " "});
  }, [apiparms]);

  useEffect(() => {
    if (!deviceCntx) return;
    if (!deviceCntx.NewRequest){
        deviceCntx.NewRequest = handleNewRequest;
    }
    if (!deviceCntx.RefreshDeviceList){
        deviceCntx.RefreshDeviceList = handleRefresh;
    }
  // eslint-disable-next-line
  }, [deviceCntx])

  useEffect(() => {
    if (!deviceSearchCntx) return;
    if (!apiparms || !apiparms?.PageSize) return;
    if (!deviceSearchCntx?.searchValue) return;
    dispach({type: "loading", loading: true});
    dispach({type: "page", page: 0});
    setApiparms({
      ...apiparms, 
      DeviceName: deviceSearchCntx?.searchValue || ' ',
      PageNum: 1
    });
    //eslint-disable-next-line
  }, [deviceSearchCntx]);

  return (
    <Fragment>
      <Typography
        component={`div`}
        style={{ flex: '1 1 auto', overflow: 'auto', height: "100%" }}
      >
        {(pagination.loading && <LinearWait />) ||
        ((pagination.deviceList?.length > 0 && <List dense style={{maxHeight: "50px"}}>
          {pagination.deviceList?.map((dev, indx) => {
            return (
              <ListItem classes={{root: cls.listitem}}
                button
                key={`device-key-${indx}`}
                onClick={() => handleSelect({ record: dev })}
                selected={dev["Device ID"] === (selectedDeviceCntx?.selectedDevice && selectedDeviceCntx?.selectedDevice["Device ID"])}
              >
                <ListItemIcon style={{color: "#55bff2"}}>
                  <FontAwesomeIcon icon={faComputer}/>
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography component={`div`}>
                      {dev['Device Name']}
                    </Typography>
                  }
                  secondary={dev['Device Type']}
                />
                <ListItemSecondaryAction>
                  <Tooltip title={`Details are populated from Dataprise's records of the customer's environment`}>
                    <span style={{color: indigo[600]}}>
                  {/* <Badge edge="end">
                    <FontAwesomeIcon icon={faCircleInfo}/>
                  </Badge> */}
                  </span>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>) || 
        <Typography component={`div`} style={{height: "inherit"}}>
          <Typography component={`div`} className={cls.nodata}>
          No Devices Found
          </Typography>
        </Typography>)}
      </Typography>
      <TablePagination className={cls.footer}
        component={`div`}
        rowsPerPageOptions={rowsperpage}
        labelRowsPerPage="Devices:"
        count={devices?.data?.metadata?.count || 0}
        rowsPerPage={pagination.pageSize}
        page={pagination.page}
        style={{
          marginTop: 'auto',
          flex: '1 0 auto',
          maxHeight: '60px',
        }}
        onRowsPerPageChange={(e) => {
          dispach({type: "pageSize", pageSize: e.target.value});
          dispach({type: "page", page: 0});
          handlePageSizeChange(e.target.value);
        }}
        onPageChange={(e, page) => {
          dispach({type: "page", page: page});
          handlePageChange(page);
        }}
      />
    </Fragment>
  );
}

const useListStyles = makeStyles(() => ({
  footer: {
    '& .MuiSvgIcon-root': {fontSize: ".7rem !important"},
    '& .MuiTablePagination-caption': {fontSize: ".7rem !important"},
    '& .MuiTablePagination-input': {fontSize: ".7rem !important"},
    '& .MuiTablePagination-toolbar': {
        backgroundColor: '#55bff2', //'#7788BE',
        color: 'window',
        '& .MuiSelect-icon': {
          color: 'window',
        }
      },
  },
  listitem: {
    '& .MuiTypography-body2':{
        fontSize: "0.775rem",
        lineHeight: 1
    },
    '& .MuiTypography-body1':{
        fontSize: "0.875rem",
        lineHeight: 1
    },
    // '&:nth-child(2n)': {
    //   backgroundColor: '#7788BE',
    //   color: 'window',
    // },    
  },
  nodata: {
    display: "flex",
    textAlign: "center",
    width: "inherit",
    height: "inherit",
    margin: "auto",
    alignItems: "center",
    justifyContent: "center",
  }
}));

