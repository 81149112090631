import React, { useState, useCallback } from "react";
import { makeStyles} from "@material-ui/core/styles";
import { DataGrid, GridOverlay } from "@material-ui/data-grid";
import { rowsperpage } from "./shared.components";
import { Backdrop, CircularProgress, TablePagination } from "@material-ui/core";

export const useGridStyles = makeStyles(theme => ({
  gridrows: {
    '& .MuiDataGrid-virtualScrollerRenderZone': {
      '& .MuiDataGrid-row': {
        '&:nth-child(2n)': {
          backgroundColor: '#E4E4E5', //'#7788BE',
          // color: 'window',
          '& .MuiTypography-body2': {
            // color: 'window',
          },
          '& .MuiIconButton-colorPrimary': {
            // color: 'window',
          },
          '& .MuiSelect-select.MuiSelect-select': {
            // color: 'window',
          }
        },
      },
    },
    '& .MuiDataGrid-row': {
      lineHeight: 1,
    },
    '& .MuiDataGrid-cell': {
      fontSize: ".75rem",
    },
    '& .MuiTypography-body2': {
      lineHeight: 1,
      fontSize: ".75rem",
    },
    '& .cell-total-amount': {
      fontWeight: 600,
      color: '#7788BE',
    },
    '& .cell-total-amount-negative': {
      color: "red"
    },
    '& .cell-total-amount-negative::before': {
      content: "'(' ",
    },
    '& .cell-total-amount-negative::after': {
      content: "')'",
    },
  },
  'data-grid-root': {
    width: "100px",
    '& .MuiDataGrid-virtualScroller': {
      overflowX: "hidden",
      overflowY: "auto",
    }
  },
  gridheadfoot: {
    '& .MuiDataGrid-columnHeaders': {
      color: 'window',
      backgroundColor: '#55bff2', // '#7788BE',
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 700,
        fontSize: '0.9rem',
      },
    },
    '& .MuiDataGrid-footerContainer': {
      color: 'window',
      backgroundColor: '#55bff2', // '#7788BE',
    },
    '& .MuiTablePagination-root' : {
      color: 'window',
      '& .MuiSelect-icon': {
        color: 'window',
      },
    }
  },
  gridoverlay: {
    zIndex: theme.zIndex.drawer + 250,
  },
  gridColumnHeaderNoCheckBox: {
    '& .MuiDataGrid-columnHeaderTitleContainer .MuiCheckbox-root .MuiIconButton-label': {
      display: "none",
    },
  },
  "gridrows-home": {
    '& .MuiDataGrid-virtualScroller': {
    '& .MuiDataGrid-virtualScrollerRenderZone': {
      '& .MuiDataGrid-row': {
        '&:nth-child(2n)': {
          backgroundColor: '#E4E4E5',
          color: 'inherit',
          '& .MuiTypography-body2': {
            color: 'inherit',
          },
          '& .MuiIconButton-colorPrimary': {
            color: 'inherit',
          },
          '& .MuiSelect-select.MuiSelect-select': {
            color: 'inherit',
          }
        },
      },
    }},
    '& .MuiDataGrid-cell': {
      fontSize: ".75vw",
    },
    '& .MuiTypography-body2': {
      lineHeight: 0.8,
      fontSize: ".75vw",
    },
    '& .Mui-selected': {
      fontWeight: 600,
      backgroundColor: "lightyellow !important"
    },
  },
  "gridheadfoot-home": {
    '& .MuiDataGrid-columnHeaders': {
      color: 'inherit',
      backgroundColor: '#E4E4E5',
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 400,
        fontSize: '.75vw',
      },
    },
    '& .MuiDataGrid-footerContainer': {
      color: 'inherit',
      backgroundColor: '#E4E4E5',
      fontSize: "0.75vw",
      height: "32px",
      minHeight: "32px",
      '& .MuiToolbar-root': {
        fontSize: ".75vw",
      },
      '& .MuiToolbar-root > p': {
        fontSize: ".75vw",
      }
  },
    '& .MuiTablePagination-root' : {
      color: 'inherit',
      '& .MuiSelect-icon': {
        color: 'inherit',
      },
    }
  },
}));

export const DappDataGrid = props => {
  const {rows, columns, loading, rowCount, onPageChange, onPageSizeChange, ...other} = props;
  const [pagesize, setPageSize] = useState(rowsperpage[0]);
  const [pageNum, setPageNum] = useState(1);
  const gridClasses = useGridStyles();

  const handlePageSizeChange = useCallback(
    size => {
      setPageSize(size);
      setPageNum(1);
      onPageSizeChange(size);
    },
    [onPageSizeChange],
  );

  const handlePageChange = useCallback(
    pagenum => {
      setPageNum(pagenum);
      onPageChange(++pagenum, pagesize);
    },
    [onPageChange, pagesize],
  );

  return (
    <DataGrid
      {...other}
      className={gridClasses.datagrid}
      rows={rows}
      columns={columns}
      loading={loading}
      pageSize={pagesize}
      density="compact"
      rowCount={rowCount}
      rowsPerPageOptions={rowsperpage}
      page={pageNum}
    //   rowHeight={45}
    //   page={pageNum}
    //   getRowClassName={params =>
    //     params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
    //   }
      components={{
        // NoRowsOverlay: CustomNoRowsOverlay,
        Pagination: TablePagination,
        LoadingOverlay: CustomGridLoadingOverlay,
      }}
      onPageSizeChange={handlePageSizeChange}
      onPageChange={handlePageChange}
    />
  );
};

export const CustomGridLoadingOverlay = () => {
  const classes = useGridStyles();

  return (
    <GridOverlay>
      <Backdrop open className={classes.gridoverlay}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </GridOverlay>
  );
};
  