import React from "react";
import { Box, debounce, Toolbar, Typography } from "@material-ui/core";
import { ToolbarButton, useToolBarStyle } from "../../../controls/shared.components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh, faIdCard, faRemove, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { QuickLookup } from "../../../controls/search.box";


export const InviteToolbar = (props) => {
    const { 
        handleInvite, disableInvite, disableDelete, onRefresh, handleSearch, handleExport,
        ...other
    } = props;

    const cls = useToolBarStyle();

    const onSearchChange = debounce((val) => {
        if (handleSearch && typeof handleSearch === 'function'){
            handleSearch(val);
        }
    }, 800);
    
    return (
        <Toolbar className={cls.toolbarroot} {...other}>
            <ToolbarButton 
                name="Send Invite"
                handleClick={handleInvite}
                disabled={disableInvite}
                tooltip="Send Invite"
                icon={<FontAwesomeIcon icon={faIdCard}/>}
            />
            <Box sx={{flexGrow: 1}} />
            <Typography component={`div`} style={{minWidth: 320, widh: '40%', padding: "0 0.5rem 0 0"}}>
                <QuickLookup 
                    placeholder="TYPE NAME"
                    onInputChange={(e) => onSearchChange(e.target.value)}            
                />
            </Typography>
            <ToolbarButton 
                name="Export"
                handleClick={handleExport}
                tooltip="Export to Excel"
                icon={<FontAwesomeIcon icon={faFileExcel}/>}
            />
            <ToolbarButton
                name="Refresh"
                handleClick={onRefresh}
                tooltip="Refresh"
                icon={<FontAwesomeIcon icon={faRefresh}/>}
            />
        </Toolbar>
    )
} 

export const ViewInviteToolbar = (props) => {
    const { 
        handleInvite, disableInvite, handleDelete, disableDelete, onRefresh, handleSearch,
        ...other
    } = props;

    const cls = useToolBarStyle();

    const onSearchChange = debounce((val) => {
        if (handleSearch && typeof handleSearch === 'function'){
            handleSearch(val);
        }
    }, 800);
    
    return (
        <Toolbar className={cls.toolbarroot} {...other}>
            <ToolbarButton 
                name="Re-send Invite"
                handleClick={handleInvite}
                disabled={disableInvite}
                tooltip="Re-send Invite"
                icon={<FontAwesomeIcon icon={faIdCard}/>}
            />
            <ToolbarButton
                name="Delete"
                tooltip="Delete Invitation"
                disabled={disableDelete}
                icon={<FontAwesomeIcon icon={faRemove}/>}
                handleClick={handleDelete}
            />
            <Box sx={{flexGrow: 1}} />
            <Typography component={`div`} style={{minWidth: 320, widh: '40%', padding: "0 0.5rem 0 0"}}>
                <QuickLookup 
                    placeholder="TYPE NAME"
                    onInputChange={(e) => onSearchChange(e.target.value)}            
                />
            </Typography>
            <ToolbarButton
                name="Refresh"
                handleClick={onRefresh}
                tooltip="Refresh"
                icon={<FontAwesomeIcon icon={faRefresh}/>}
            />
        </Toolbar>
    )
} 
