import React, { createContext, useContext, useState } from "react";
import { useCallback } from "react";
import { rowsperpage } from "../../controls/shared.components";

const SearchContext = createContext();

export const SearchProvider = ({children}) => {
    const [srchResult, setSrchResult] = useState();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(rowsperpage[0]);
    const [pagedResult, setPagedResult] = useState([]);

    const handlePageChange = useCallback((event, value) => {
        setPage(value);        
        setPagedResult(srchResult.slice((value * pageSize), ((value + 1) * pageSize)));
    }, [pageSize, srchResult]);

    const handleRowsPerPageChange = useCallback((event) => {
        setPageSize(parseInt(event.target.value));
        setPage(0);
        setPagedResult(srchResult.slice(0, pageSize));
    }, [pageSize, srchResult]);

    const value = {
        srchResult, setSrchResult, 
        handlePageChange, handleRowsPerPageChange, 
        page, pageSize, setPage, pagedResult, setPagedResult,
    }

    return (
        <SearchContext.Provider value={value}>
            {children}
        </SearchContext.Provider>
    )
}

export const useSearchContext = () => useContext(SearchContext);
