import { Toolbar, Box } from "@material-ui/core";
import React, { useCallback } from "react";
import { 
    FileCopyOutlined as FileIcon, 
    SettingsApplicationsOutlined as SettingIcon 
} from "@material-ui/icons";
import { ToolbarButton } from "../../controls/shared.components";

export const SurveyToolbar = (props) => {
    const {...other} = props;

    const onFileClick = useCallback((event) => {
        console.log(`clicked on ${event.target}`)
    }, [])

    return (
        <Toolbar {...other}>
            <ToolbarButton
                name="File"
                handleClick={onFileClick}
                tooltip="Choose your file"
                icon={<FileIcon />}
            />
            <Box sx={{flexGrow: 1}} />
            <ToolbarButton
                name="Settings"
                handleClick={(event) => console.log(`clicked on ${event.target}`)}
                tooltip="Survey Settings"
                icon={<SettingIcon />}
            />
        </Toolbar>
    );
}
