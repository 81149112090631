import { FormControl, Link, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useCallback } from "react";
import { DappBooleanAsIcon } from "../../../controls/shared.components";

export const useSortMapper = () => { 
    return useMemo(() => [
        {field: "Display Name", sortBy: "LastName"},
        {field: "Email Address", sortBy: "EmailAddress"},
        {field: "User Type", sortBy: "UserType"}
    ], []);
}

export const useInviteColumns = (params) => {
    const {handleViewContact, handleTypeChange} = params;

    return useMemo(() => [
        {
            field: "Display Name", headerName: "Name", flex: 0.3, disableColumnMenu: true,
            resizable: true,
            renderCell: (params) => {
                return (
                    <Link component={`button`} underline="none" variant="body2"
                        onClick={() => handleViewContact({ContactID: params.row.ContactID})}
                    >
                    {`${params.row["Display Name"]}`}
                    </Link>
                )
            } 
        },
        {
            field: "Email Address", headerName: "Email", flex: 0.8, disableColumnMenu: true,
            resizable: true,
        },
        {
            field: "User Type", headerName: "Type", flex: 0.2, disableColumnMenu: true, resizable: false,
            renderCell: (params) => {
                return (
                    <UserTypeSelect currentValue={params.row.UserType} sharedOperator={params.row.IsHDSharedOperator}
                        disabled={params.row["Is Invited CMS"] === '-1' || params.row["Is Active CMS"] === '-1'}
                        handleTypeChange={({userType}) => {
                            handleTypeChange({userType: userType, row: params.row});
                        }}
                    />
                )
            }
        },
        {
            field: "Is Invited CMS", headerName: "Invited?", width: 140, align: "center",
            headerAlign: "center", sortable: false, disableColumnMenu: true, resizable: false,
            renderCell: (params) => <DappBooleanAsIcon value={params.row["Is Invited CMS"]}/>
        },
        {
            field: "Is Active CMS", headerName: "CMS Active", width: 140, align: "center",
            headerAlign: "center", sortable: false, disableColumnMenu: true, resizable: false,
            renderCell: (params) => <DappBooleanAsIcon value={params.row["Is Active CMS"]}/>
        },
    ], [handleViewContact, handleTypeChange]);
} 

const UserTypeSelect = ({currentValue, sharedOperator, handleTypeChange, ...other}) => {
    const [userType, setUserType] = useState();

    useEffect(() => {
        if (!currentValue) return;
        setUserType(currentValue);
    }, [currentValue]);

    const handleChange = useCallback((e) => {
        setUserType(e.target.value);
        if (!!handleTypeChange && typeof handleTypeChange === 'function'){
            handleTypeChange({userType: e.target.value});
        }
    }, [handleTypeChange]);

    return (
        <FormControl size="small" fullWidth>
            <Select id="user-type" value={userType || "User"} variant="outlined" {...other}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "left",
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                    },
                    getContentAnchorEl: null,
                }}
                onChange={handleChange}
            >
                <MenuItem value="User">User</MenuItem>
                <MenuItem value="Operator" style={sharedOperator !== -1 && {display: 'none'}} >Operator</MenuItem>
                <MenuItem value="Administrator">Administrator</MenuItem>
            </Select>
        </FormControl>
    )
}

export const useViewInviteColumns = (params) => {
    const {handleViewContact} = params;
    return useMemo(() => [
        {
            field: "LastName", headerName: "Name", flex: 0.3, disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <Link component={`button`} underline="none" variant="body2"
                        onClick={() => handleViewContact({ContactID: params.row.ContactID})}
                    >
                    {`${params.row.FirstName}, ${params.row.LastName}`}
                    </Link>
                )
            } 
        },
        {
            field: "EmailAddress", headerName: "Email", flex: 0.8, disableColumnMenu: true,
        },
        {
            field: "UserType", headerName: "Type", flex: 0.3, disableColumnMenu: true,
        },
        {
            field: "IsEmailVerified", headerName: "Verified", width: 140, align: "center",
            headerAlign: "center", disableColumnMenu: true, sortable: false, 
            renderCell: (params) => <DappBooleanAsIcon value={params.row.IsEmailVerified}/>
        },
    ], [handleViewContact]);
} 
