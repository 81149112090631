import { CoreForm } from "@kineticdata/react";
import { Button, Dialog, DialogActions, DialogContent, Typography } from "@material-ui/core";
import React from "react";
import { DappDialogTitle, KappFormLayout, KappPending, useDialogButtonStyles, useFormStyle } from "../../controls/shared.components";

export const JobSummaryModal = (props) => {
    const {record, open, handleClose} = props;
    const cls = useFormStyle();
    const btnCls = useDialogButtonStyles();
    return (
      <Dialog open={open} onClose={handleClose}>
        <DappDialogTitle title="Job Summary" handleClose={handleClose}/>
        <DialogContent className={cls.root} style={{width: "520px", maxHeight: '480px', overflowY: "auto"}}>
            <Typography component={`div`} variant="body1">
                {record?.["Job Num"]}
            </Typography>
            <Typography component={`div`} variant="body2">
                {record?.["InternalShortDesc"]}
            </Typography>
            <JobSummaryViewForm jobid={record?.["Job ID"]}/>                  
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} className={btnCls.closebutton}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
}

export const JobSummaryViewForm = ({jobid}) => {
  return (
    <CoreForm
      kapp="customer-portal"
      form="view-jobs"
      values={{JobID: jobid}}
      components={{
        Pending: KappPending,
        Layout: KappFormLayout,
      }}
    />
  );
};
