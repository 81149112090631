import React, { useState, useEffect } from "react";
import { 
    ContentState, convertFromHTML, convertToRaw, EditorState, AtomicBlockUtils, SelectionState,
} from "draft-js";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactDOM from 'react-dom';
import { bundle } from '@kineticdata/react';
import { useCallback } from "react";
import draftjsToHtml from "draftjs-to-html";

export const DappEditor = (props) => {
    const { rawContent, onChange } = props;
    const [editorState, setEditorState] = useState(() => {EditorState.createEmpty()});

    useEffect(() => {
        if (!rawContent) return;
        const fromHtml = convertFromHTML(rawContent);
        const contentState = ContentState.createFromBlockArray(
            fromHtml.contentBlocks,
            fromHtml.entityMap
        );

        let st = EditorState.createWithContent(contentState);

        contentState.getBlocksAsArray().forEach(block => {
            const blockKey = block.getKey();
            const entityKey = contentState.getBlockForKey(blockKey).getEntityAt(0);
            const hasImage = entityKey && contentState.getEntity(entityKey).getType() === 'IMAGE';
            if (hasImage){
                st = EditorState.acceptSelection(
                    st, SelectionState.createEmpty(blockKey)
                );
                st = AtomicBlockUtils.insertAtomicBlock(st, entityKey, ' ');
            }
        });

        setEditorState(st);
    }, [rawContent]);

    const insertImage = (url) => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', { src: url },);
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set( editorState, { currentContent: contentStateWithEntity });
        return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
    };    

    const handleChange = useCallback((e) => {
        setEditorState(e);
        if (!!onChange && typeof onChange === 'function'){
            const currContent = e.getCurrentContent(); 
            let rawCnt = convertToRaw(currContent);
            onChange(draftjsToHtml(rawCnt));
        }
    }, [onChange]);

    const handlePastedFiles = (files) => {
        var file = files[0];        
        if (file.kind !== 'file' && file.type !== 'image/png') return;

        let reader = new FileReader();
        reader.onload = function(event){
            setEditorState(insertImage(event.target.result));
        }
        reader.readAsDataURL(file);    
     }    

    return (
        <Editor 
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            // editorStyle={{minHeight: 240}}
            editorState={editorState} 
            onEditorStateChange={handleChange}
            handlePastedFiles={handlePastedFiles}
        />
    );
}

bundle.helpers = {
    dappEditor: (element, rawHtml, onChange) => {
        ReactDOM.render(<DappEditor rawContent={rawHtml} onChange={onChange} />, element, onChange);
    }
}

