import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const useLookupStyles = makeStyles(() => ({
    searchText: {
        fontSize: "0.85rem",        
    },
    iconStyle: {
        opacity: ".3",
        paddingRight: "10px",
    }
}));

export const QuickLookup = (props) => {
    const {onInputChange, placeholder, ...other} = props;
    const stls = useLookupStyles();
  
    return (
      <Autocomplete {...other}
        id="look-ahead-search"
        fullWidth
        clearOnBlur={false}
        clearOnEscape={true}
        disableClearable
        options={[]}
        freeSolo
        placeholder={placeholder}
        onInputChange={onInputChange}
        renderInput={(params) => (
          <TextField {...params}
            fullWidth 
            variant="outlined"
            size="small"
            placeholder={placeholder}          
            className={stls.searchText}
            InputProps={{            
              startAdornment: (<FontAwesomeIcon icon={faSearch} className={stls.iconStyle} />),
              autoComplete: "off", autoCorrect: "off", 
              style:{borderRadius: "15px"},
              type: "search",
            }}
          />
        )}      
      />    
    )
  }
  