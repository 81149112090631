import { makeStyles } from "@material-ui/core/styles";

export const useHomeStyles = makeStyles((theme) => ({
    height100: {
        height: '100%',
    },
    width100: {
        width: '100%'
    },
    homeroot: {
        margin: '.6em 0 .1rem .4rem',
        display: 'flex',
        flexFlow: 'column',
        height: 'inherit',
        backgroundColor: "transparent",
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingLeft: '4.2rem',
        paddingRight: '.2rem',
        '& .MuiCardActionArea-root': {
            height: 'inherit'
        },
        '& .home-action-grids': {
            backgroundColor: "window", 
            display: "flex",
            marginLeft: "0.1rem",
            padding: ".8rem 0.8rem 3rem 0",
            flex: "1 1 auto",
        },
        '& .home-action-cards': {
            display: "flex",
            flex: 1,
            backgroundColor: 'window',
            textAlign: "center",
            marginLeft: '0.1rem',
            paddingRight: "0.8rem",
        },
        '& .home-action-spacer': {
            display: "flex",
            //flex: 1,
            backgroundColor: "window",
            marginLeft: "0.1rem",
            paddingRight: "0.8rem",
        },
        '& .card-action-top': {
            borderRadius: "18px",
            border: "2px solid grey",
        },
        '& .home-ticket-grid': {
            display: "flex", 
            flexFlow: "column",
            flex: "1 1 auto",
            height: "100px",
        },
    },
    subincident: {
        color: 'lightblue', 
        fontSize: '3.5rem',
    },
    datalistpaper: {
        display: "flex", 
        flexFlow: "column", 
        height: "100%", 
        fontSize: 'xx-small',
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%', 
        height: '100%',
        alignItems: 'center', 
        margin: 'auto', 
        border: '1px solid',
        borderColor: 'lightgrey',
        textAlign: 'center',        
    },
    dappicon: {
        fontSize: '3.2rem',
    },
    dappiconTeam: {
        fontSize: '2.0rem',
    },
}));

export const useBigNumberStyles = makeStyles((theme) => ({
    numbersRoot: {
        alignContent: "flex-end",
        display: "flex",
        flexWrap: "wrap",
        flex: "1 1 auto",
        // marginTop: "3rem",
        '& .wrapper-slanted-boxes': {
            display: "flex",
            textAlign: "center",
            flex: 1,            
        },
        '& .big-number-content': {
            display: "flex",
            flexFlow: "column",
            flexWrap: "wrap",
            flex: 1,
            justifyContent: "center",
            position: "relative",
        },
        '& .big-number-frame': {
            fontSize: "13rem",
            position: "relative",
            width: "100%",
            height: "auto",
        },
        '& .big-number-line-1': {
            fontSize: "3.2vw",
            fontWeight: 900,
            color: "#55bff2",
            fontFamily: "system-ui",
            lineHeight: 1.5,
            flex: "1 1 auto",
        },
        '& .lines-wrapper': {
            position: "absolute",
            left: "6vw",
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "column",
        },
        '& .lines-wrapper-2': {
            position: "absolute",
            left: "4vw",
            flex: "1 1 auto",
        },
        '& .big-number-line-2': {
            fontSize: "1.2vw",
            fontFamily: "inherit",
            lineHeight: 1.8,
        },
        '& .big-number-line-3': {
            fontSize: "0.8vw",            
            color: "#55bff2",
            cursor: "pointer",
        },
    }
}));

export const useMyIncidentsStyles = makeStyles((theme) => ({
    outer: {
        flex: "1 1 auto",
        display: "flex",
        '& .MuiTabPanel-root': {
            flex: "1",
            padding: "0",
            minHeight: "50px",
            // height: "21vw",
            width: '100%',
            // position: "relative",
        }
    },    
    "tabs-grid-wrapper": {
        display: "flex",
        flexFlow: "column",
        height: "inherit",
        flex: 1,
        '& .MuiTabs-root': {
            minHeight: "22px",
        },
        '& .MuiTab-root': {
            color: "window",
            backgroundColor: "#55bff2",
            opacity: "0.5",
        },
        '& .Mui-selected': {
            opacity: "1",
            backgroundColor: "#007dba",
        },
        '& .home-tab': {
            fontSize: "0.8vw",
            lineHeight: 0.8,
            padding: "3px 6px",
            minHeight: "22px",
        }
    },
    "outer-layout": {
        height: "inherit",
    },
    "outer-tab-panel": {
    },
}));
