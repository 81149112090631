import { faAdd, faRefresh, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Toolbar, Typography } from "@material-ui/core";
import React from "react";
import { ToolbarButton, useToolbarStyle } from "../../controls/shared.components";

export const ActionToolbar = (props) => {
    const {onRefresh, newAction, title1, title2, onExport2Excel, ...other} = props;
    const cls = useToolbarStyle();

    return (
        <Toolbar {...other} className={cls.toolbarroot}>
            <ToolbarButton name="New"
                handleClick={newAction}
                tooltip="Add New Action"
                icon={<FontAwesomeIcon icon={faAdd}/>}
            />
            <Box sx={{flexGrow: 1}}>
                <Grid container style={{width: "60%", margin: "auto"}}>
                    <Grid item xs={12}>
                        <Typography component={`div`}>
                            {`Ticket #: ${title1}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component={`div`} variant="body2" >
                            {title2}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <ToolbarButton 
                name="Export"
                handleClick={onExport2Excel}
                tooltip="Export to Excel"
                icon={<FontAwesomeIcon icon={faFileExcel}/>}
            />                
            <ToolbarButton
                name="Refresh"
                handleClick={onRefresh}
                tooltip="Refresh"
                icon={<FontAwesomeIcon icon={faRefresh}/>}
            />
        </Toolbar>
    );
}
