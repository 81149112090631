import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { useHistory, useParams } from 'react-router-dom';
import { CustomAlert } from '../../alerts/alert.snackbar';
import { WrapperPaper } from '../../controls/shared.components';
import { useActionColumns } from './action.hook';
import { rowsperpage } from '../../controls/shared.components';
import { useDataFromBridge } from '../../controls/custom.hooks';
import { useDappContext } from '../../auth/dapp.context';
import { useGridStyles } from '../../controls/datagrid.striped';
import clsx from "clsx";
import { ActionDetailModal } from './action.detail';
import { ActionToolbar } from './action.toolbar';
import { NewActionModal } from './actions.new';
import { Toolbar, Typography } from '@material-ui/core';
import { utils, writeFileXLSX } from "xlsx";
import swal from "sweetalert";
import { EXPORTALERTMSG } from '../../controls/dapp.constants';

// import { set_cptable } from 'xlsx';
// import * as cptable from 'xlsx/dist/cpexcel.full.mjs';
// set_cptable(cptable);

const initLoadParams = {
  IncidentID: 0,
  UserType: '',
  IsSharedOperator: true,
  GetTotal: true,
  SortField: 'ActionID',
  AscDesc: 'DESC',
  PageNum: 1,
  PageSize: rowsperpage[0],
};

const bridgedResourceName = 'Actions by Incident ID';

export const ActionList = (props) => {
  const {...other} = props;
    const { id } = useParams();
    const [apiparms, setApiparms] = useState();
    const [exportParams, setExportParams] = useState();
    const [gridrows, setGridRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [xUser, setXuser] = useState(null);
    const [totalRows, setTotalRows] = useState();
    // const [pageToken, setPageToken] = useState('');    
    const [currentpage, setCurrentpage] = useState(0);
    const [pagesize, setPagesize] = useState();
    const [incidentParams, setIncidentParams] = useState();
    const [selectedIncident, setSelectedIncident] = useState();
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedRowIndex, setSelectedRowIndex] = useState();
    const [ticketbyid, setTicketByID] = useState();
    const [toolbarTitle, setToolbarTitle] = useState();
    const [doexport, setDoExport] = useState(false);
    const [showAlert, setShowAlert] = useState({
        open: false, severity: "error", message: "",
        onSnackBarClose: () => {}
    });

    const [auth2view, setAuth2View] = useState(true);


    const history = useHistory();

    const [newactionModal, setNewactionModal] = useState({
      open: false, 
      onClose: () => {},
      onError: () => {},
      onSave: () => {},
    });

    const [showDetail, setShowDetail] = useState({});
    const dappCntx = useDappContext();
    const cls = useGridStyles();
    const gridApiRef = useGridApiRef();

    useEffect(() => {
      if (!dappCntx) return;
      dappCntx.dappSubtitle("Actions");
    }, [dappCntx])

    const showActionDetail = useCallback(({row}) => {
      setShowDetail({
        ...showDetail, open: true, actionrow: row, 
        onCloseModal: () => setShowDetail({ ...showDetail, open: false, actionrow: null}),
      });
    }, [showDetail]);

    const columns = useActionColumns({
        handleEdit: ({row}) => showActionDetail({row}),
        selectedRowIndex,
        handleContact: ({contactID}) => history.push(`/contacts/view/${contactID}`),
        userType: dappCntx?.user?.user.UserType,
    });

    const bridgecall = useDataFromBridge({ 
        xUser: xUser, 
        apiValues: apiparms,
        bridgedResourceName: bridgedResourceName
    });

    const getSingleIncident = useDataFromBridge({ 
      xUser: xUser, apiValues: incidentParams,
      bridgedResourceName: "Incident by ID"
    });

    const getdata4exportcall = useDataFromBridge({
      xUser: xUser,
      apiValues: exportParams,
      bridgedResourceName: "Actions by Incident ID Export"
    });

    useEffect(() => {
      if (!id) return;
      setIncidentParams({IncidentID: id});
    }, [id]);

    useEffect(() => {
      if (!dappCntx.user) return;
      setXuser(dappCntx.user.user);
    }, [dappCntx]);

    useEffect(() => {
      let changes = (apiparms && {...apiparms, 
        IncidentID: id, 
        UserType: dappCntx?.user?.user?.UserType || ' ',
        PageNum: currentpage + 1,
        PageSize: pagesize || rowsperpage[0],
      }) || { ...initLoadParams,
        IncidentID: id, 
        UserType: dappCntx?.user?.user?.UserType || ' ',
        PageNum: currentpage + 1,
        PageSize: pagesize || rowsperpage[0],
      }

      if (JSON.stringify(changes) === JSON.stringify(apiparms)) return;
      setLoading(true);
      setGridRows([]);
      setApiparms(changes);
    }, [dappCntx, id, currentpage, pagesize, apiparms]);
  
    const handlePageChange = useCallback((model, details) => {
      if (model.pageSize !== pagesize)
        model.page = 0;
      setCurrentpage(model.page);
      setPagesize(model.pageSize);      
      }, [pagesize]);

    const showError = useCallback(({message}) => {
        setShowAlert({...showAlert, open: true, 
            severity: "error", message: message,
            onSnackBarClose: () => { setShowAlert({open: false, message: "random"})}
        });
    }, [showAlert])
        
    useEffect(() => {
      if (!bridgecall) return;

      setLoading(false);
      if (!bridgecall?.success) {
        showError({message: bridgecall?.data?.Message || bridgecall?.data?.message});
        return;
      }

      let recs = bridgecall?.data?.records.map((row, indx) => {
        return { ...row, id: row["Action ID"]};
      });

      setTotalRows(bridgecall?.data?.metadata?.count || 0)
      setGridRows(recs);

    }, [bridgecall, showError]);

    useEffect(() => {
      if (!getSingleIncident) return;
      setSelectedIncident(getSingleIncident.data.record);
    }, [getSingleIncident]);

    const handleRefresh = useCallback(() => {
      setLoading(true);
      setApiparms({...apiparms, UserType: ''});
      setApiparms({...apiparms, UserType: dappCntx.user.user.UserType});
    }, [apiparms, dappCntx])

    const newActionClose = useCallback(() => {
      setNewactionModal({...newactionModal, open: false});
    }, [newactionModal])

    const handleActionError = useCallback((props) => {
      setNewactionModal({...newactionModal, open: false, incidentID: null});
      showError({message: (props.message || "Faile to Load new action form")});
    }, [newactionModal, showError])

    const handleActionSave = useCallback((props) => {
      setNewactionModal({...newactionModal, open: false, incidentID: null});
      setShowAlert({
        open: true, severity: "success",
        message: "New Action Created",
        onSnackBarClose: () => {setShowAlert({open: false, message: "random"})}
      });
    }, [newactionModal]);

    const createActionModal = useCallback(() => {
      setNewactionModal({ ...newactionModal,
        open: true, handleClose: newActionClose,
        handleError: handleActionError,
        handleSave: handleActionSave,
        incidentID: selectedIncident.IncidentID,
      })
    }, [newactionModal, newActionClose, selectedIncident, handleActionError, handleActionSave]);

    const handleSelectionChange = useCallback((newSelection) => {
      let sel = new Set(selectionModel);
      let diff = [...newSelection].filter(x => !sel.has(x));
      let indx = newSelection.findIndex(x => x !== diff[0]);
      indx !== -1 && newSelection.splice(indx, 1);
      setSelectionModel((newSelection.length > 0 && newSelection) || selectionModel);
      setSelectedRowIndex(((newSelection.length > 0 && newSelection) || selectionModel)[0]);
      // let row = griddata.find((x) => x.id === newSelection[0]);
      // if (!!row) setSelected({row: row});
    }, [selectionModel])

    useEffect(() => {
      if (!id) return;
      setTicketByID({IncidentID: id});
    }, [id]);

    useEffect(() => {
      if (!dappCntx.currentRecord) return;
      setToolbarTitle([`${dappCntx.currentRecord["IncidentNo"]}`, `${dappCntx.currentRecord?.ShortDescription || ''}`]);
    }, [dappCntx]);

    const ticket = useDataFromBridge({
      xUser: dappCntx.user?.user, apiValues: ticketbyid,
      bridgedResourceName: "Incident by ID"
    });
  
    useEffect(() => {
      if (!ticket?.data) return;
      dappCntx.setCurrentRecord(ticket?.data.record);
      if (dappCntx?.user?.user.UserType === "User" && ticket?.data?.record?.ContactID !== dappCntx?.user?.user.ContactID.toString()){
        setAuth2View(false);
      }
    }, [dappCntx, ticket]);

    useEffect(() => {
      if (auth2view) return;
      setShowAlert({
        open: true, message: "You're not authorized to view/edit this ticket",
        severity: "error",
      })
    }, [auth2view]);

    useEffect(() => {
      if (!doexport) return;

      swal({
        title: "Export Incident Actions",
        content: {
          element: "span",
          attributes: {
            innerHTML: EXPORTALERTMSG.replace('REPLACE-STRING', 'incident actions'),
          },
        },
        icon: undefined,
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: {
          confirm: true,
          cancel: true,
        },
        dangerMode: false,
      }).then((accepted) => {
        accepted && setExportParams({
          ...apiparms,
          PageNum: 1,
          PageSize: 2000,
        });
      });

      setDoExport(!doexport);  
    }, [doexport, apiparms]);

    useEffect(() => {
      if (!getdata4exportcall || !getdata4exportcall?.data) return;
  
      if (getdata4exportcall?.data?.error){
        showError({message: getdata4exportcall?.data?.error.message});
        return;
      }

      const d = new Date();
      const stamp = `${d.getFullYear()}${d.getMonth()}${d.getDay()}-${d.getHours()}${d.getMinutes()}`;
      const fileName = `${dappCntx?.currentRecord?.IncidentNo}_ACTIONS_CMS_${stamp}.xlsx`;

      const data4export = getdata4exportcall?.data?.records.map(x => {
        x["Action Desc"] = x["Action Desc"]?.replace(/<[^>]+>/g, '');
        x.IncidentDescription = x.IncidentDescription?.replace(/<[^>]+>/g, '');
        return x;
      });

      const ws = utils.json_to_sheet(data4export);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "ACTIONS");
      writeFileXLSX(wb, fileName);
    }, [getdata4exportcall, dappCntx, showError]);

  return (
    <Fragment>
      {(auth2view &&
        <ActionToolbar
          onRefresh={handleRefresh}
          newAction={createActionModal}
          title1={toolbarTitle?.[0]}
          title2={toolbarTitle?.[1]}
          onExport2Excel={() => setDoExport(true)}
        />) || <Toolbar />}
      <WrapperPaper style={(!auth2view && { alignItems: "center", justifyContent: "center" }) || {}}>
        {(auth2view &&
          <ActionsGrid 
            rows={gridrows}
            columns={columns}
            loading={loading}
            total={totalRows}
            apiRef={gridApiRef}
            onPageChange={handlePageChange}
            onSelectionChange={handleSelectionChange}
            cls={cls}
            other={other}
          />
          ) || <Typography component={`div`}>
            You're not authorized to view or edit this ticket
          </Typography>}
      </WrapperPaper>
      <CustomAlert {...showAlert} />
      <ActionDetailModal {...showDetail} />
      <NewActionModal {...newactionModal} />
    </Fragment>
  );
};

const ActionsGrid = (props) => {
  const { 
    rows, columns, loading, total, apiRef,
    onPageChange, cls, onSelectionChange,
    ...other 
  } = props;
 
  return (
    <DataGridPro {...other}
      className={clsx(cls.gridheadfoot, cls.gridrows)}
      rows={rows || []}
      apiRef={apiRef}
      columns={columns}
      loading={loading}
      density='compact'
      rowCount={total || 0}
      pageSizeOptions={rowsperpage}
      onPaginationModelChange={onPageChange}
      onRowSelectionModelChange={onSelectionChange}
      paginationMode='server'
      autoPageSize
      pagination
    />
  )
}