import React, { useEffect, useState, useMemo } from "react";
import { getDataUniversal } from "../../apicalls/universal.api";
import PropTypes from 'prop-types';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const useSurveyData = (props) => {
    const { usr, apiparms } = props;
    const [surveys, setSurveys] = useState(null);

    useEffect(() => {
        try{
            getDataUniversal({user: usr, apiparams: apiparms, cmstype: "survey"}).then((response) => {
                setSurveys({success: true, data: response.data});
            }).catch((error) => {
                setSurveys({success: false, data: error.response.data});
            });
        } catch (exp) {
            setSurveys({success: false, data: `failed to reach api with: ${exp.message}`});
        }

    }, [apiparms, usr]);

    return surveys;
}

useSurveyData.PropTypes = {
    usr: PropTypes.object.isRequired,
    apiParams: PropTypes.object.isRequired,
}

export const useSurveyColumns = () => {
    // const priceFormatInfo = useMemo(() => new Intl.NumberFormat('en-US', {
    //     style: 'currency', currency: 'USD', minimumFractionDigits: 2
    // }), []);
    
    // const quantityFormatInfo = useMemo(() => new Intl.NumberFormat('en-US', {
    //      minimumFractionDigits: 2
    // }), []);
    
    return useMemo(() => [
        {
            field: "Spacer", headerName: " ", width: 40, disableColumnMenu: true,
        },
        {
            field: "DateSubmitted", headerName: "Submitted", flex: .5, 
            disableColumnMenu: true, type: "date"
        },
        {
            field: "Source", headerName: "Source", flex: 1, disableColumnMenu: true,
        },
        {
            field: "SubmittedByContact", headerName: "Submitted By", flex: 1, disableColumnMenu: true,
        },
        {
            field: "IncidentID", headerName: "Incident #", flex: 1, 
            disableColumnMenu: true, align: "center",
        },
        {
            field: "Response", headerName: "Response", width: 160, disableColumnMenu: true,
            renderCell: (params) => (
                params.value > 0 
                ? <FontAwesomeIcon icon="fa-solid fa-thimbs-up"/>
                : <FontAwesomeIcon icon="fa-solid fa-thumbs-down"/>
            )
        },
        {
            field: "AssignedTo", headerName: "Assigned", flex: .5, disableColumnMenu: true
        }
    ], []);
}