import { Toolbar, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDappContext } from "../../auth/dapp.context";
import { WrapperPaper } from "../../controls/shared.components";

export const ContactAccess = () => {
    const {id} = useParams();
    const dappCntx = useDappContext();

    useEffect(() => {
        dappCntx.dappSubtitle("CMS Access");
    }, [dappCntx])

    return (
        <Fragment>
            <Toolbar/>
            <WrapperPaper>
                <Typography component={`div`}>
                    Contact CMS Access placeholder for contact id: {id}
                </Typography>
            </WrapperPaper>
        </Fragment>
    );
}