import { makeStyles } from "@material-ui/core";


export const useFilterStyles = makeStyles(() => ({
    "wrapper-root": {
        fontSize: ".8rem",
        lineHeight: 3,
        "& .title-wrap": {
            display: "flex", 
            flexFlow: "row",
            fontSize: "inherit",
            lineHeight: "inherit",
            "& .filter-prompt": {
                fontSize: "inherit",
                lineHeight: "inherit",
                flex: .5,
                display: "flex",
                justifyContent: "center",
            },
            "& .filter-name": {
                fontSize: "inherit",
                lineHeight: "inherit",
                flex: 1,
                color: "#007dba",
                fontWeight: 600,
            },
        },
    },
}));