import React from "react";
import { 
    Drawer, List, ListItem, ListItemIcon, ListItemText, 
    makeStyles, 
    Paper, Toolbar, Typography,  
} from "@material-ui/core";
import { useDappContext } from "../../auth/dapp.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

const useAlertStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexFlow: "column",
        '& .MuiTypography-body1': {
            fontSize: '0.8rem',        
        },
        '& .MuiTypography-body2': {
            fontSize: '0.725rem'
        }
    },
}))

export const AlertDrawer = (props) => {
    const {open, onCloseAlerts,  ...other} = props
    const dappCntx = useDappContext();
    const stls = useAlertStyles();

    return (
        <Drawer
            anchor="right"
            id="drawer"
            open={open} 
            onClose={onCloseAlerts} {...other}
            className={stls.root}
            >
            <Toolbar style={{backgroundColor: "#0e0c0f"}} />
            <Typography component={`div`} id="panel-inside-drawer"
                style={{
                    backgroundColor: "lightgrey", 
                    flex: "1 1 auto",
                    display: 'flex',
                    padding: '.5rem',
                    minWidth: '380px',
                    maxWidth: '380px',
                }}>
                <List>
                {dappCntx?.customerAlerts?.map((alert, indx) => {
                    let dateval = Date.parse(alert.ExpirationDate).toString() !== 'NaN' && new Date(alert.ExpirationDate);
                    return (
                        <ListItem key={`key-${indx}`} disableGutters style={{fontSize: '0.8rem'}}>
                            <Paper style={{flexGrow: 1, display: 'flex'}} elevation={3}>
                                <ListItemIcon style={{
                                    fontSize: "2.5rem", color: "#F5921F", 
                                    alignItems: "center",
                                    justifyContent: "center",                                    
                                }}>
                                    <FontAwesomeIcon icon={faWarning}/>
                                </ListItemIcon>
                                <ListItemText primary={alert.AlertText}
                                    secondary={dateval && `Expire: ${dateval.toLocaleDateString()} ${dateval.toLocaleTimeString()}`}
                                />
                            </Paper>
                        </ListItem>
                    )
                })}                    
                </List>
            </Typography>
        </Drawer>
    );
};