import React, { useState, useCallback, Fragment } from "react";
import { 
    Dialog, DialogContent, DialogTitle, Tab, Tabs, TablePagination,
    AppBar, DialogActions, Button, Backdrop, CircularProgress, Tooltip, 
    Box, Typography, IconButton, debounce, 
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TabPanel } from "../../controls/shared.components";
import { rowsperpage } from "../../controls/shared.components";
import { ContactList, DeviceList } from "./modal.components";
import { useDappContext } from "../../auth/dapp.context";
import { Close } from "@material-ui/icons";
import { QuickLookup } from "../../controls/search.box";

export const ModalSelection = (props) => {
  const {showPrimary} = props;
    const {open, onCloseDialog} = props;
    const [tabIndex, setTabIndex] = useState(0)
    const [contactSelected, setContactSelected] = useState();
    const [deviceSelected, setDeviceSelected] = useState();
    const [recordCount, setRecordCount] = useState(0);
    const [pagingPage, setPagingPage] = useState(0);
    const [listPage, setListPage] = useState(1);
    const [pagesize, setPageSize] = useState(rowsperpage[0]);
    const [loading, setLoading] = useState(false);
    const [srchVal, setSrchVal] = useState();
    const dappCntx = useDappContext();

    const cls = useTabStyles();

    const handleChangePage = useCallback((event, page) => {
      setLoading(true);
      setPagingPage(page);        
      setListPage(++page);
    }, []);

    const hanldeChangeRowsPerPage = useCallback((event) => {
      setLoading(true);
      setPagingPage(0);
      setListPage(1);
      setPageSize(event.target.value);
    }, []);

    const handleokClicked = useCallback(() => {      
      const type = (tabIndex === 0 && "contact") || (tabIndex === 1 && "device")
      const record = (tabIndex === 0 && contactSelected) || (tabIndex === 1 && deviceSelected);
      onCloseDialog({ok: true, selection: {type: type, record: record}});
      // eslint-disable-next-line
    }, [tabIndex, contactSelected, deviceSelected]);

    const handlePrimaryClicked = useCallback(() => {
      onCloseDialog({ok: true, selection: {type: "primaryContact", record: dappCntx.user.user}});
    }, [dappCntx, onCloseDialog])

    const tabChanged = useCallback((event, newValue) => {
      setContactSelected(null);
      setDeviceSelected(null);
      setTabIndex(newValue);
      setSrchVal(null);
    }, []);

    const handleSearch = debounce((val) => {
      setSrchVal(val);
      setPagingPage(0)
      setListPage(1)
    }, 800);

    return (
      <Fragment>
      <Dialog open={open} onClose={onCloseDialog}>
        <DialogTitle style={{ padding: 'unset', backgroundColor: '#007dba', color: 'window' }}>
          <Typography component={`div`} className={cls.dappTitle}>
            Select a contact or a device
            <IconButton
              onClick={onCloseDialog}
              style={{position: 'absolute', right: 8, top: 8, color: 'inherit', backgroundColor: '#bc202f'}}
            >
              <Close/>
            </IconButton>
          </Typography>
          <AppBar position="static" className={cls.dappTabs}>
            <Tabs value={tabIndex} onChange={tabChanged}>
              <Tab label="Contacts" />
              <Tab label="Devices" />
            </Tabs>
          </AppBar>
          <Typography component={`div`} style={{
            backgroundColor: "window", padding: "5px",
          }}>
            <QuickLookup
              placeholder="TYPE NAME"
              onInputChange={(e) => handleSearch(e.target.value)}            
            />
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '480px', height: '420px' }}>
          <TabPanel value={tabIndex} index={0}>
            <ContactList 
              onLoading={(flag) => setLoading(flag)} 
              setContactCount={setRecordCount} 
              page={listPage} 
              pageSize={pagesize}
              onContactSelect={(record) => setContactSelected(record)} 
              searchVal={tabIndex === 0 && srchVal}
            />              
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <DeviceList 
              onLoading={(flag) => setLoading(flag)} 
              setDeviceCount={setRecordCount} 
              page={listPage} 
              pageSize={pagesize}
              onDeviceSelect={(record) => setDeviceSelected(record)} 
              searchVal={tabIndex === 1 && srchVal}
            />
          </TabPanel>
        </DialogContent>
        <ListPagination total={recordCount} page={pagingPage} pagesize={pagesize}
            handleChangePage={handleChangePage} hanldeChangeRowsPerPage={hanldeChangeRowsPerPage}/>
        <DialogActions>
          {(dappCntx?.user?.user.UserType !== "User" && 
          <Box component={`div`} sx={{display: !showPrimary && "none"}}>
          <Tooltip title={`Create Ticket for Organization: ${dappCntx.user?.user.OrganizationName}`}>
          <Button onClick={handlePrimaryClicked} hidden={!showPrimary} variant="contained" color="primary">
            {`For ${dappCntx.user?.user.CID}`}
          </Button>
          </Tooltip>
          </Box>) || <Fragment/>}
          <Box sx={{flexGrow: 1}}></Box>
            <Button color="primary" onClick={handleokClicked} disabled={!contactSelected && !deviceSelected}>
                Create Ticket
            </Button>
            <Button color="secondary" onClick={onCloseDialog}>
                Cancel
            </Button>
        </DialogActions>
      </Dialog>
      <LoadingOverlay open={loading}/>
      </Fragment>
    );
}


const ListPagination = (props) => {
    const {total, page, handleChangePage, pagesize, hanldeChangeRowsPerPage} = props;
    return (
        <TablePagination 
        component="div"
        count={total}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={pagesize || rowsperpage[0]}
        rowsPerPageOptions={rowsperpage}
        onRowsPerPageChange={hanldeChangeRowsPerPage}
      />
    );
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 250,
        color: '#fff',
    },
}));

const LoadingOverlay = ({open}) => {
    const cls = useStyles();
    return (
        <Backdrop className={cls.backdrop} open={open}>
            <CircularProgress color="inherit"/>
        </Backdrop>
    );
};

const useTabStyles = makeStyles(() => ({
  dappTabs: {
    '& .MuiAppBar-colorPrimary': {
      color: "window",
      backgroundColor: "#55bff2",
      opacity: "0.5",
    },
    '& .Mui-selected': {
      opacity: "1",
      backgroundColor: "#007dba",
    },
  },
  dappTitle: {
    minHeight: 60, 
    display: "flex", 
    justifyContent: "center", 
    alignItems: "center", 
    textAlign: "center",    
  }
}));
