import React, { Fragment, useState, useCallback, useRef } from "react";
import { Box, Grid, Toolbar, Typography } from "@material-ui/core";
import { CustomAlert } from "../../alerts/alert.snackbar";
import { useFormStyle, WrapperPaper } from "../../controls/shared.components";
import { CoreForm } from "@kineticdata/react";
import { useParams, useLocation, useHistory, Prompt } from "react-router-dom";
import { useDappContext } from "../../auth/dapp.context";
import { useEffect } from "react";
import { ServiceRequestStats } from "./service.request.stats";
import { IncidentEditToolbar } from "../incidents/incidents.toolbar";
import { useDataFromBridge } from "../../controls/custom.hooks";
import swal from "sweetalert";
import { useAuthStyle } from "../incidents/incidents.edit";
import { cancelApiCall, requestStatusUpdate, checkAnyFormSubmitStatus } from "../../apicalls/contact.api";
import { NEW_CONTACT_STATUS_CHECK, REQUEST_STATUS_UPDATE_WEBAPI_REQ } from "../../controls/dapp.constants";
import QueryString from "qs";
import { LoadingProgress } from "../Loading2";

export const EditServiceRequest = ({type}) => {
    return (
        <AddServiceRequestContactDevice type={`${type}-service-request`} />
    );
}

export const AddServiceRequest = () => {
    return (
        <AddServiceRequestContactDevice type="new-service-request" />
    );
}

export const AddServiceRequestContactDevice = ({type}) => {
    const { id } = useParams();
    const location = useLocation();
    const [toolbarTitle, setToolbarTitle] = useState();
    const [showMessage, setShowMessage] = useState({
        open: false, severity: "error", message: "",
        onSnackBarClose: () => {},
    });
    const [values, setValues] = useState({});
    const dappCntx = useDappContext();
    const history = useHistory();
    const [ticketbyid, setTicketByID] = useState();
    const [auth2view, setAuth2View] = useState(true);
    const [editEnabled, setEditEnabled] = useState(false);
    const [ask2leave, setAsk2Leave] = useState(true);
    const [qsParms, setQsParms] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const cls = useFormStyle();
    const kForm = useRef();
    const authCls = useAuthStyle();

    const handleError = useCallback(({message}) => {
        setShowMessage({ ...showMessage, 
            open: true, message: message || `something failed on form`, severity: "error",
            onSnackBarClose: () => setShowMessage({ open: false, message: 'random'}),
        });
    }, [showMessage]);

    useEffect(() => {
      if (!!qsParms) return;
      setQsParms(QueryString.parse(location.search.replace('?', '')));    
    }, [location, qsParms]);

    const handleSaveComplete = useCallback((data, action) => {
      const submissionCheck = {
        "SubmissionId": data.submission.id,
        "SubmissionLabel": data.submission.label,
        "SubmissionHandle": data.submission.handle
      };

      try {
        setIsLoading(true);
        setShowMessage({...showMessage, open: false, severity: '', message: ''});
        setTimeout(() => {
          checkAnyFormSubmitStatus({
            user: dappCntx.user?.user, data: submissionCheck, 
            url: NEW_CONTACT_STATUS_CHECK, timeout: 30             
          }).then(response => {
            setIsLoading(false);
            const result = response.data && response.data.length > 0 && JSON.parse(response.data[0]?.data);              
            if (result.Success) {
              setShowMessage({
                ...showMessage,
                open: true,
                severity: 'success', message: 'Service Request is Saved'
              });
              setTimeout(() => { history.replace(location.state?.prevPath || "/requests") }, 1500);
            } else {
              !result.Success && setShowMessage({
                ...showMessage,
                open: true,
                severity: 'error', message: result?.Message || 'Unable to save new contact',
              });
            }
          }).catch(error => {
            setIsLoading(false);
            setShowMessage({
              ...showMessage,
              open: true,
              severity: 'error',
              message: `${error?.response?.data?.Message || error?.message}`
            });          
          })
        }, 3000);
      } 
      catch (exp) {

      }
    }, [showMessage, history, location, dappCntx]);

    useEffect(() => {
      let formType = '';
      switch (type.split("-")[0]) {
        case "update": 
          formType = "Update";
          break;
        case "view": 
          formType = "View";
          setAsk2Leave(false);
          break;
        default: 
          formType = "New";
          break;
      }

      dappCntx.dappSubtitle(`${formType} Service Request`);
    }, [dappCntx, type]);

    useEffect(() => {
      if (!dappCntx.currentRecord) return;
      setToolbarTitle([`${dappCntx.currentRecord["IncidentNo"]}`, `${dappCntx.currentRecord?.ShortDescription || ''}`]);
    }, [dappCntx.currentRecord])

    const ticket = useDataFromBridge({
      xUser: dappCntx.user?.user, apiValues: ticketbyid,
      bridgedResourceName: "Service Request by ID"
    });
  
    useEffect(() => {
      if (!ticket?.data) return;

      if (!!ticket.data?.error) {
        handleError({message: ticket?.data?.error.message});
        setAuth2View(false);
        return;
      }
      
      if (ticket.data?.record?.CID !== dappCntx.user?.user?.CID){
        handleError({message: "Not Authorized"});
        setAuth2View(false);
        return;
      }

      dappCntx.setCurrentRecord(ticket?.data.record);
      if (type.startsWith("new")){
        setAuth2View(!(dappCntx?.user?.user.UserType === "User" && dappCntx?.user?.user.ContactID.toString() !== id));
        return;
      }
      setAuth2View(!(dappCntx?.user?.user.UserType === "User" && ticket?.data.record.ContactID !== dappCntx?.user?.user.ContactID.toString()));

      setEditEnabled(dappCntx?.user?.user.UserType !== "User" 
      // && (parseInt(Number(ticket?.data?.record.AssignedUserID)) < 0 || parseInt(Number(ticket?.data?.record.ReAssignmentTemplateID)) < 0)
      )
      // eslint-disable-next-line
    }, [dappCntx, ticket, id, type]);

    useEffect(() => {
      if (!qsParms) return;
      type !== "new-service-request" && setTicketByID({ServiceRequestID: id});
        switch(qsParms.type){
            case "contact": 
                setValues({ContactID: `${id}`});
                break;
            case "primaryContact":
                setValues({PrimaryContactID: `${id}`});
                break;
            case "device": 
                setValues({DeviceID: `${id}`, DeviceType: `${qsParms.devicetype}`});
                break;
            default:
                setValues({IncidentID: `${id}`});
                break;
        }
    }, [location.search, id, type, qsParms]);

    const handleLoaded = useCallback((form) => {
      kForm.current = form;
    }, [kForm]);

    const handleSave = useCallback(() => {
      swal({
        title: "Save Changes?",
        content: {
          element: "span",
          attributes: {
            innerHTML: 
              (dappCntx.currentRecord?.IncidentNo && `You're about to update ticket<br/><b>${dappCntx.currentRecord["IncidentNo"]}<b>`) ||
              `You're about to create new ticket<br/>`,
          },
        },
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: {
          confirm: true,
          cancel: true,
        },
        dangerMode: false,
      }).then((accepted) => {
        setAsk2Leave(false);
        accepted && kForm.current && kForm.current.submitPage && typeof kForm.current.submitPage === 'function' &&
        kForm.current.submitPage();
      });
    }, [kForm, dappCntx]);

    const handleCancel = useCallback(() => {
      swal({
        title: "Are you sure?",
        text: "If you have unsaved changes, they will be lost.",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: {
          confirm: true,
          cancel: true,
        },
        dangerMode: false,
      }).then((accepted) => {
        if (accepted){
          setAsk2Leave(false);
          history.goBack();
        }        
      });
    }, [history]);    

    useEffect(() => {
      if (auth2view) return;
      setShowMessage({
        open: true, message: "You're not authorized to view or edit this ticket",
        severity: "error",
      });
    }, [auth2view])

    const showError = useCallback((errMsg) => {
      swal({
        title: "Error",
        text: `Failed to request status update with: 
          ${errMsg.message}`,
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: {
          confirm: true,
        },
        dangerMode: true,
      })
    }, []);

    const handleStatusUpdate = useCallback(() => {
      // let btns = kForm.current.find('.submit-button');
      // let stsBtn = btns.find(btn => btn.name === "status-request-button");
      swal({
        title: "Request Status Update?",
        text: "This action will issue a request for status update on this ticket",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: {
          confirm: true,
          cancel: true,
        },
        dangerMode: false,
      }).then((accepted) => {
        if (accepted){
          // !!stsBtn && stsBtn.click();
          try{
            requestStatusUpdate({
              user: dappCntx.user?.user, data: id,
              url: REQUEST_STATUS_UPDATE_WEBAPI_REQ,
            }).then((response) => {
                history.goBack();
            }).catch((error) => {
                showError({message: `${error.toString()}`});
            })
            } catch (ex) {
                showError({message: `${ex.toString()}`});
            }
            return () => {
                cancelApiCall();
            }
        }
      });
    }, [dappCntx, id, history, showError]);

    return (
      <Fragment>
        {(auth2view && 
        <IncidentEditToolbar isNew={type.startsWith("new")}
          handleActions={() => history.push(`/servicerequest/actions/${id}`)}
          handleStats={() => {}}
          handleFiles={() => {}}
          title1={toolbarTitle?.[0]} title2={toolbarTitle?.[1]}
          handleSaveForm={handleSave} 
          handleCancelChanges={handleCancel}
          handleStatusUpdate={handleStatusUpdate}
          type={(type.split("-")[0] === "update" || type.startsWith("new"))  && "edit"}
          editEnabled={editEnabled}          
       />) || <Toolbar/>}       
        <WrapperPaper isForm={true}>
          <Grid container>
            <Grid item xs={8} className={cls.root} style={(!auth2view && {display: "flex"}) || {}}>
              <Box component={`div`} name="box-around-core" className={(!auth2view && authCls.withAuth) || null}>
                {(auth2view && 
                <CoreForm
                  kapp="customer-portal"
                  form={type}
                  onError={handleError}
                  onCompleted={handleSaveComplete}
                  loaded={handleLoaded}
                  values={values}
                  components={{
                    Pending: Pending,
                    Layout: FormLayout,
                  }}
                />) || <Typography component={`div`}>
                You're not authorized to view or edit this ticket
              </Typography>} 
              </Box>
            </Grid>
            <Grid item xs={4} style={{padding: "1.5rem"}}>
              {(dappCntx?.user?.user.UserType !== "User" &&
              <ServiceRequestStats />) || <Fragment/>}
            </Grid>
          </Grid>
        </WrapperPaper>
        <CustomAlert {...showMessage} />
        <Prompt when={ask2leave && !type.startsWith('view-')} message={"Are you sure you want to leave?"} />
        <LoadingProgress open={isLoading}/>
      </Fragment>
    );
}

const FormLayout = ({ form, content}) => {
    return (
    <div className="row">
      <div className="col-12">
        <div className="form-header">{form?.name}</div>
        <div className="form-container">
          {content}
        </div>
      </div>
    </div>
)
};
  
const Pending = () => (<div className="loading">Loading form...</div>);